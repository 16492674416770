export const DATE_FORMAT = 'DD/MM/YYYY'
export const TIME_FORMAT = 'HH:mm'
export const DATETIME_FORMAT = 'HH:mm DD/MM/YYYY'
export const STAFF_TABLE_LIST = 10
export const RESERVATION_TABLE_LIST = 10
export const ROOM_TABLE_LIST = 10
export const CUSTOMER_TABLE_LIST = 10
export const PREPAID_HISTORY_TABLE_LIST = 10
export const HOST_TABLE_LIST = 10
export const SERVICE_TABLE_LIST = 10
export const BUILDING_TABLE_LIST = 10
export const COUPON_TABLE_LIST = 10
export const REPORT_ORDER_LIST = 10
export const REFUND_100_2HOME = 2 * 24

//! NO USE IN PRODUCTION
const dev = {
  endpoint: {
    api: 'http://localhost:1337',
    apiv2: 'http://localhost:3000',
    customer: 'http://localhost:3333',
    host: 'http://localhost:3335',
  },
  R2_UPLOAD_URL: 'https://2home-dev.b7b9fdd1caeb7ea585fb05a6b5916a1c.r2.cloudflarestorage.com',
  BASE_IMG_URL: 'https://asset.2home.world', // 'https://2home-dev.s3.ap-southeast-1.amazonaws.com',
  BASE_DESKTOP_IMG_URL: 'https://asset.2home.world', // 'https://2home-dev.s3.ap-southeast-1.amazonaws.com',
  BASE_MOBILE_IMG_URL: 'https://asset.2home.world', // 'https://2home-dev.s3.ap-southeast-1.amazonaws.com',
  CLOUDINARY_API: 'https://api.cloudinary.com/v1_1/dbiaaqjr9/image/upload',
  CLOUDINARY_API_KEY: '114381472751235',
}

//! PRODUCTION ENVIRONMENT
const prod = {
  endpoint: {
    api: 'https://api.2home.vn',
    apiv2: 'https://apiv2.2home.vn',
    customer: 'https://2home.vn',
    host: 'https://host.2home.vn',
  },
  R2_UPLOAD_URL: 'https://2home-prod.b7b9fdd1caeb7ea585fb05a6b5916a1c.r2.cloudflarestorage.com',
  BASE_IMG_URL: 'https://asset.2home.vn', // 'https://2home-dev.s3.ap-southeast-1.amazonaws.com',
  BASE_DESKTOP_IMG_URL: 'https://asset.2home.vn', // 'https://2home-dev.s3.ap-southeast-1.amazonaws.com',
  BASE_MOBILE_IMG_URL: 'https://asset.2home.vn', // 'https://2home-dev.s3.ap-southeast-1.amazonaws.com',
  CLOUDINARY_API: 'https://api.cloudinary.com/v1_1/imageserver2home/image/upload',
  CLOUDINARY_API_KEY: '369459834487145',
}

const config = () => {
  switch (process.env.REACT_APP_STAGE) {
    case 'development':
      return dev
    case 'production':
      return prod
    default:
      return dev
  }
}

export default {
  ...config(),
}
