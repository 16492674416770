import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core'
import { NavLink as RouterLink } from 'react-router-dom'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { applyFiltersToUrl, getFiltersFromUrl } from 'utils/url'
import Toolbar from './Toolbar'
import Table from './Table'
import serviceListStyle from './styled'

const ServiceList = ({
  classes,
  service,
  location,
  history,
  find,
}) => {
  const updateUrl = (query) => {
    const url = `${location.pathname}${applyFiltersToUrl(query)}`
    history.push(url, { replace: false })
  }

  useEffect(() => {
    const query = getFiltersFromUrl(location.search)
    find({ ...query, active: query.active || 1 })
    // updateUrl({ ...query, active: query.active || 1 })
    return () => {}
    // eslint-disable-next-line
  }, [location.search])

  const applyFilter = (fltData) => {
    const query = {
      ...service.query,
      ...fltData,
      page: 1,
    }
    updateUrl(query)
  }

  const changePage = (page) => {
    const query = { ...service.query, page }
    updateUrl(query)
  }

  const changeRowsPerPage = (limit) => {
    const query = { ...service.query, page: 1, limit }
    updateUrl(query)
  }

  const sort = (sortBy, sortDirection) => {
    const query = { ...service.query, sortBy, sortDirection }
    updateUrl(query)
  }

  const update = (id) => {
    history.push(`/service/${id}/update`)
  }

  return (
    <div className={classes.root}>
      <Toolbar classes={classes} location={location} applyFilter={applyFilter} />
      <div className={classes.content}>
        <Table
          classes={classes}
          find={find}
          update={update}
          loading={service.loading}
          services={service.data}
          meta={service.meta}
          defaultSort={
            service.query
              ? {
                sortBy: service.query.sortBy,
                sortDirection: service.query.sortDirection,
              }
              : {}
          }
          onPageChange={changePage}
          onRowsPerPageChange={changeRowsPerPage}
          onSortChange={sort}
          location={location}
        />
      </div>
      <RouterLink to="/service/create">
        <Fab color="primary" aria-label="add" className={classes.fab}>
          <AddIcon />
        </Fab>
      </RouterLink>
    </div>
  )
}

ServiceList.propTypes = {
  classes: PropTypes.shape(),
  service: PropTypes.arrayOf(PropTypes.shape()),
  find: PropTypes.func,
  location: PropTypes.shape(),
  history: PropTypes.shape(),
}

export default withStyles(serviceListStyle)(ServiceList)
