import * as customerApi from 'provider/customer'
import { CUSTOMER_ACTION } from '../type'

export const find = query => async (dispatch, getState) => {
  const { page: pageProp, limit: limitProp } = getState().getIn(['customer', 'meta']).toJS()
  const data = getState().getIn(['customer', 'data']).toJS()
  const pageQuery = getState().getIn(['customer', 'query']).toJS()
  const convertedQuery = {
    page: pageProp,
    limit: limitProp,
    ...query,
  }
  if (!data || data.length === 0 || JSON.stringify(convertedQuery) !== pageQuery) {
    dispatch({
      type: CUSTOMER_ACTION.FETCH,
    })
    try {
      const customer = await customerApi.find(query)
      dispatch({
        type: CUSTOMER_ACTION.FETCH_OK,
        payload: { query, ...customer },
      })
    } catch (e) {
      dispatch({
        type: CUSTOMER_ACTION.FETCH_ERR,
        payload: { message: 'Something went wrong' },
      })
    }
  }
}
