import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { SearchTextInput } from 'components'
import {
  Grid,
  Typography,
  Button,
  Badge,
} from '@material-ui/core'
import { getFiltersFromUrl } from 'utils/url'
import { Search } from '@material-ui/icons'
import { SOCKET_ACTION } from 'redux/type'
import { BOOKING_STATUS } from 'contants'
import Filter from './Filters'

const ReservationsToolbar = ({
  classes,
  location,
  applyFilter,
  socket,
  resetSocket,
}) => {
  const [fltData, setFltData] = useState({ active: 0 })

  const {
    order: {
      active: newEventOrder,
      refund: rejectEventOrder,
      deposit: depositEventOrder,
    },
  } = socket

  const filter = (data) => {
    setFltData(data)
    const {
      active,
      searchTxt,
      status,
      startdate,
      enddate,
      startCreatedAt,
      endCreatedAt,
      room,
      customer,
      host,
      hasService,
      noExtraInfo,
    } = data
    applyFilter({
      active,
      searchTxt: searchTxt || undefined,
      status: status || [],
      startdate: startdate || undefined,
      enddate: enddate || undefined,
      startCreatedAt: startCreatedAt || undefined,
      endCreatedAt: endCreatedAt || undefined,
      room: room || undefined,
      customer: customer || undefined,
      host: host || undefined,
      hasService,
      noExtraInfo,
    })
  }

  useEffect(() => {
    const query = getFiltersFromUrl(location.search)
    if (!query.active) {
      query.active = 0
    } else {
      // eslint-disable-next-line no-restricted-globals
      query.active = !isNaN(query.active) ? Number(query.active) : 0
    }
    setFltData(query || {})
    return () => {}
  }, [location.search])

  const onSearch = (textStr) => {
    const query = getFiltersFromUrl(location.search)
    filter({ ...query, searchTxt: textStr })
  }

  const groupByStatus = (data) => {
    filter(data)
  }

  return (
    <>
      {/* Title */}
      <Grid container spacing={1}>
        {/* Title */}
        <Grid item xs={9}>
          <Typography variant="h3" gutterBottom>Danh sách đơn đặt phòng</Typography>
        </Grid>
        {/* Search */}
        <Grid item xs={3} style={{ marginLeft: 'auto' }}>
          <SearchTextInput
            fullWidth
            className={classes.searchInput}
            placeholder="Tìm kiếm đơn (theo mã, theo khách hàng, theo phòng, ...)"
            initValue={getFiltersFromUrl(location.search)?.searchTxt}
            debounceTime={800}
            onChange={str => onSearch(str)}
            leftIcon={<Search />}
            size="small"
            style={{ marginBottom: 0 }}
          />
        </Grid>
      </Grid>
      {/* Filter */}
      <Grid container className={classes.filter}>
        {/* Customer */}
        <Grid item>
          <Typography variant="body2">Khách hàng</Typography>
          <Filter.Customer
            value={fltData.customer}
            onApply={customer => filter({ ...fltData, customer })}
          />
        </Grid>
        {/* Host */}
        <Grid item>
          <Typography variant="body2">Chủ nhà</Typography>
          <Filter.Host
            value={fltData.host}
            onApply={host => filter({ ...fltData, host })}
          />
        </Grid>
        {/* Room */}
        <Grid item>
          <Typography variant="body2">Phòng/Căn hộ</Typography>
          <Filter.Room
            value={fltData.room}
            onApply={room => filter({ ...fltData, room })}
          />
        </Grid>
        {/* Status */}
        {
          [3, 4].includes(fltData.active)
            ? null
            : (
              <Grid item>
                <Typography variant="body2">Trạng thái đơn</Typography>
                <Filter.Status
                  // eslint-disable-next-line no-restricted-globals
                  data={BOOKING_STATUS.filter(item => isNaN(fltData.active) || fltData.active === -1 ? true : item.active === Number(fltData.active))}
                  value={fltData.status}
                  onApply={status => filter({ ...fltData, status })}
                />
              </Grid>
            )
        }
        {/* Checkin/Checkout */}
        <Grid item>
          <Typography variant="body2">Ngày checkout</Typography>
          <Filter.BookingDate
            startdate={Number(fltData.startdate)}
            enddate={Number(fltData.enddate)}
            onApply={({ startdate, enddate }) => filter({ ...fltData, startdate, enddate })}
          />
        </Grid>
        {/* Created at */}
        <Grid item>
          <Typography variant="body2">Ngày tạo đơn</Typography>
          <Filter.BookingDate
            startdate={Number(fltData.startCreatedAt)}
            enddate={Number(fltData.endCreatedAt)}
            onApply={({ startdate, enddate }) => filter({ ...fltData, startCreatedAt: startdate, endCreatedAt: enddate })}
          />
        </Grid>
        {/* Service */}
        <Grid item>
          <Typography variant="body2">Có dịch vụ</Typography>
          <Filter.Service
            value={fltData.hasService}
            noExtraInfo={fltData.noExtraInfo}
            onApply={service => filter({ ...fltData, hasService: service })}
            onExtraInfoFilter={value => filter({ ...fltData, noExtraInfo: value })}
          />
        </Grid>
      </Grid>
      {/* Active/Inactive */}
      <Grid container spacing={1}>
        {/* Đang hoạt động */}
        <Grid item xs={2} style={{ marginTop: 'auto' }}>
          <Button
            fullWidth
            size="small"
            variant={`${fltData.active === 0 ? 'contained' : 'default'}`}
            color={`${fltData.active === 0 ? 'primary' : 'default'}`}
            onClick={() => {
              groupByStatus({ ...fltData, active: 0, status: fltData.active !== 0 ? [] : fltData.status })
              resetSocket(SOCKET_ACTION.RESET_ORDER_LIST_ACTIVE)
            }}
          >
            <span className="mr-3">Hoạt động</span>
            {newEventOrder ? <Badge badgeContent={newEventOrder} color="secondary" /> : null}
          </Button>
        </Grid>
        {/* Chưa hoàn tiền */}
        <Grid item xs={2} style={{ marginTop: 'auto' }}>
          <Button
            fullWidth
            size="small"
            variant={`${fltData.active === 2 ? 'contained' : 'default'}`}
            color={`${fltData.active === 2 ? 'primary' : 'default'}`}
            onClick={() => {
              groupByStatus({ ...fltData, active: 2, status: fltData.active !== 2 ? [] : fltData.status })
              resetSocket(SOCKET_ACTION.RESET_ORDER_LIST_REFUND)
            }}
          >
            <span className="mr-3">Chưa hoàn tiền</span>
            {rejectEventOrder ? <Badge badgeContent={rejectEventOrder} color="secondary" /> : null}
          </Button>
        </Grid>
        {/* Chưa hoàn cọc */}
        <Grid item xs={2} style={{ marginTop: 'auto' }}>
          <Button
            fullWidth
            size="small"
            variant={`${fltData.active === 3 ? 'contained' : 'default'}`}
            color={`${fltData.active === 3 ? 'primary' : 'default'}`}
            onClick={() => {
              groupByStatus({ ...fltData, active: 3, status: fltData.active !== 3 ? [] : fltData.status })
              resetSocket(SOCKET_ACTION.RESET_ORDER_LIST_DEPOSIT)
            }}
          >
            <span className="mr-3">Chưa hoàn cọc</span>
            {depositEventOrder ? <Badge badgeContent={depositEventOrder} color="secondary" /> : null}
          </Button>
        </Grid>
        {/* Hoàn thành */}
        <Grid item xs={2} style={{ marginTop: 'auto' }}>
          <Button
            fullWidth
            size="small"
            variant={`${fltData.active === 1 ? 'contained' : 'default'}`}
            color={`${fltData.active === 1 ? 'primary' : 'default'}`}
            onClick={() => groupByStatus({ ...fltData, active: 1, status: fltData.active !== 1 ? [] : fltData.status })}
          >
            Hoàn thành
          </Button>
        </Grid>
        {/* Khoá phòng */}
        <Grid item xs={2} style={{ marginTop: 'auto' }}>
          <Button
            fullWidth
            size="small"
            variant={`${fltData.active === 4 ? 'contained' : 'default'}`}
            color={`${fltData.active === 4 ? 'primary' : 'default'}`}
            onClick={() => groupByStatus({ ...fltData, active: 4, status: fltData.active !== 4 ? [] : fltData.status })}
          >
            Khoá phòng
          </Button>
        </Grid>
        {/* Tất cả đơn */}
        <Grid item xs={2} style={{ marginTop: 'auto' }}>
          <Button
            fullWidth
            size="small"
            variant={`${fltData.active === -1 ? 'contained' : 'default'}`}
            color={`${fltData.active === -1 ? 'primary' : 'default'}`}
            onClick={() => groupByStatus({ ...fltData, active: -1, status: fltData.active !== -1 ? [] : fltData.status })}
          >
            Tất cả
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

ReservationsToolbar.propTypes = {
  classes: PropTypes.shape(),
  applyFilter: PropTypes.func,
  location: PropTypes.shape(),
  socket: PropTypes.shape(),
  resetSocket: PropTypes.func,
}

export default ReservationsToolbar
