import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { FormatListBulleted, Close } from '@material-ui/icons'
import { FormControlLabel, Checkbox, Typography } from '@material-ui/core'
import * as commonApi from 'provider/common'

const Membership = ({ value, onApply }) => {
  const [membership, setMembership] = useState([])

  const fetchMembership = async () => {
    try {
      const resp = await commonApi.getMembership()
      setMembership(
        resp.data.map(item => ({
          value: item.id,
          label: item.name,
        })),
      )
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    try {
      const prevMembership = value
      setMembership(membership.map(item => ({
        ...item,
        checked: prevMembership.includes(item.value),
      })))
    } catch (e) {
      // TODO: Do nothing
    }
    return () => {
    }
  }, [value])

  useEffect(() => {
    fetchMembership()
    return () => {
    }
  }, [])

  const onSelectMembership = (idx, checked) => {
    setMembership([
      ...membership.slice(0, idx),
      { ...membership[idx], checked },
      ...membership.slice(idx + 1, membership.length),
    ])
  }

  return (
    <PopupState variant="popover" popupId="customer-filter">
      {popupState => (
        <div>
          <Button variant="outlined" {...bindTrigger(popupState)} style={{ backgroundColor: 'white', textTransform: 'none' }}>
            <FormatListBulleted />
            {membership.filter(item => item.checked).length
              ? membership.filter(item => item.checked).map(item => item.label).join(', ')
              : 'Chọn hạng thành viên'}
            {value
              ? (
                <Close onClick={(e) => {
                  e.stopPropagation()
                  setMembership(membership.map(item => ({ ...item, checked: false })))
                  onApply(undefined)
                }}
                />
              )
              : null}
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box p={2} style={{ width: 200 }}>
              {membership.map(
                (item, idx) => (
                  <div>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={item.checked}
                          onChange={e => onSelectMembership(idx, e.target.checked)}
                          color="primary"
                        />
                      )}
                      label={<Typography variant="body1">{item.label}</Typography>}
                    />
                  </div>
                ),
              )}
              <Button
                onClick={() => {
                  onApply(membership.filter(item => item.checked).map(item => item.value))
                  popupState.close()
                }}
                style={{ float: 'right', marginBottom: 7 }}
              >
              Áp dụng
              </Button>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

Membership.propTypes = {
  onApply: PropTypes.func,
  value: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.string),
}

export default Membership
