import {
  STAFF_TABLE_LIST,
  RESERVATION_TABLE_LIST,
  ROOM_TABLE_LIST,
  CUSTOMER_TABLE_LIST,
  PREPAID_HISTORY_TABLE_LIST,
  HOST_TABLE_LIST,
  SERVICE_TABLE_LIST,
  COUPON_TABLE_LIST,
} from 'config'

export const DEFAULT_STAFF_LIST = {
  limit: STAFF_TABLE_LIST,
  page: 1,
  total: 1,
}

export const DEFAULT_RESERVATION_LIST = {
  limit: RESERVATION_TABLE_LIST,
  page: 1,
  total: 1,
}

export const DEFAULT_ROOM_LIST = {
  limit: ROOM_TABLE_LIST,
  page: 1,
  total: 1,
  active: 0,
  inactive: 0,
  pending: 0,
}

export const DEFAULT_CUSTOMER_LIST = {
  limit: CUSTOMER_TABLE_LIST,
  page: 1,
  total: 1,
}

export const DEFAULT_PREPAID_HISTORY_LIST = {
  limit: PREPAID_HISTORY_TABLE_LIST,
  page: 1,
  total: 1,
}

export const DEFAULT_HOST_LIST = {
  limit: HOST_TABLE_LIST,
  page: 1,
  total: 1,
}

export const DEFAULT_SERVICE_LIST = {
  limit: SERVICE_TABLE_LIST,
  page: 1,
  total: 1,
}

export const DEFAULT_COUPON_LIST = {
  limit: COUPON_TABLE_LIST,
  page: 1,
  total: 1,
}
