import axios from 'axios'
import * as storage from 'utils/storage'
import * as ENDPOINT from './url'

export const login = async (email, password) => {
  try {
    const resp = await axios.post(ENDPOINT.LOGIN, {
      email,
      password,
    })
    const { data } = resp
    if (!data) {
      const e = {
        message: 'User not found',
      }
      throw e
    }
    axios.defaults.headers.common.Authorization = `Bearer ${data.token}`
    const { KEYS } = storage
    storage.set(KEYS.TOKEN, data.token)
    return data
  } catch (e) {
    throw e
  }
}

export const get = async () => {
  const { KEYS } = storage
  const token = storage.get(KEYS.TOKEN)
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  } else {
    const e = { message: 'Token not found' }
    throw e
  }
  try {
    const resp = await axios.get(ENDPOINT.GET_USER)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const update = async (data) => {
  try {
    const resp = await axios.patch(ENDPOINT.UPDATE_USER, data)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const signout = () => {
  axios.defaults.headers.common.Authorization = null
  const { KEYS } = storage
  storage.remove(KEYS.TOKEN)
}
