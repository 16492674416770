import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { FormatListBulleted, Close } from '@material-ui/icons'
import { FormControlLabel, Checkbox, Typography } from '@material-ui/core'
import * as commonApi from 'provider/common'

const Status = ({ value, onApply }) => {
  const [roles, setRoles] = useState([])

  const fetchRole = async () => {
    const resp = await commonApi.getRole()
    setRoles(
      resp.data.map(item => ({
        value: item.id,
        label: item.title,
        checked: false,
      })),
    )
  }

  useEffect(() => {
    try {
      const prevRoles = value
      setRoles(roles.map(item => ({
        ...item,
        checked: prevRoles.includes(item.label),
      })))
    } catch (e) {
      // TODO: Do nothing
    }
    return () => {
    }
  }, [value])

  useEffect(() => {
    fetchRole()
    return () => {
    }
  }, [])

  const onSelectRole = (idx, checked) => {
    setRoles([
      ...roles.slice(0, idx),
      { ...roles[idx], checked },
      ...roles.slice(idx + 1, roles.length),
    ])
  }

  return (
    <PopupState variant="popover" popupId="customer-filter">
      {popupState => (
        <div>
          <Button variant="outlined" {...bindTrigger(popupState)} style={{ backgroundColor: 'white', textTransform: 'none' }}>
            <FormatListBulleted />
            {roles.filter(item => item.checked).length
              ? roles.filter(item => item.checked).map(item => item.label).join(', ')
              : 'Quyền hạn'}
            {value
              ? (
                <Close onClick={(e) => {
                  e.stopPropagation()
                  setRoles(roles.map(item => ({ ...item, checked: false })))
                  onApply(undefined)
                }}
                />
              )
              : null}
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box p={2} style={{ width: 200 }}>
              {roles.map(
                (item, idx) => (
                  <div>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={item.checked}
                          onChange={e => onSelectRole(idx, e.target.checked)}
                          color="primary"
                        />
                      )}
                      label={<Typography variant="body1">{item.label}</Typography>}
                    />
                  </div>
                ),
              )}
              <Button
                onClick={() => {
                  onApply(JSON.stringify(roles.filter(item => item.checked).map(item => item.value)))
                  popupState.close()
                }}
                style={{ float: 'right', marginBottom: 7 }}
              >
              Áp dụng
              </Button>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

Status.propTypes = {
  onApply: PropTypes.func,
  value: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.string),
}

export default Status
