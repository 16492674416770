import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Button,
  FormHelperText,
} from '@material-ui/core'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import DatePicker from 'components/DatePicker'
import { create, update, findOne } from 'provider/staff'
import { getRole, getDistrict } from 'provider/common'
import moment from 'moment'
import BackgroundBackdrop from 'components/Backdrop'
import { useSnackbar } from 'notistack'

const validateSchema = action => Yup.object().shape({
  // account: Yup.string()
  //   .required('* Thông tin tài khoản không được để trống'),
  password:
      action === 'update'
        ? Yup.string()
        : Yup.string().required('* Mật khẩu không được để trống'),
  name: Yup.string(),
  email: Yup.string()
    .email()
    .required('* Địa chỉ email không được để trống'),
  phone: Yup.string(),
  birthday: Yup.number(),
  role: Yup.string().required('* Chọn quyền cho nhân viên'),
  manageArea: Yup.array(),
  address: Yup.string(),
})

const initUser = {
  // account: '',
  password: '',
  name: '',
  email: '',
  phone: '',
  birthday: moment().valueOf(),
  role: [],
  manageArea: [],
  address: '',
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  subTitle: {
    fontSize: 14,
    fontWeight: '900',
    marginBottom: 15,
  },
  formControl: {
    backgroundColor: 'white',
  },
}))

const UserForm = (props) => {
  const { history, id, action } = props
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const [role, setRole] = useState([])
  const [user, setUser] = useState(initUser)
  const [districts, setDistricts] = useState([])
  const [loading, setLoading] = useState(false)

  const fetchDistrict = async () => {
    try {
      const resp = await getDistrict()
      setDistricts(
        resp.data.map(item => ({
          value: item.id,
          label: item.name,
        })),
      )
    } catch (e) {
      console.log(e)
    }
  }

  // Fetch user's roles
  const fetchRole = async () => {
    try {
      const resp = await getRole()
      setRole(
        resp.data.map(item => ({
          value: item.id,
          label: item.title,
        })),
      )
    } catch (e) {
      console.log(e)
    }
  }

  // Fetch user data if in updating mode
  const fetchUser = async () => {
    setLoading(true)
    try {
      const resp = await findOne(id)
      setUser({
        ...resp,
        role: resp.role.map(r => r.id),
        birthday: resp.birthday || moment().valueOf(),
        manageArea: resp.manageArea || [],
      })
      setLoading(false)
    } catch (e) {
      setLoading(false)
      enqueueSnackbar(`Máy chủ lỗi (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  useEffect(() => {
    fetchRole()
    fetchDistrict()
    if (action === 'update') {
      fetchUser()
    }
  }, [])

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      let resp = null
      switch (action) {
        case 'update':
          resp = await update(data)
          setUser({ ...resp, role: resp.role })
          setLoading(false)
          enqueueSnackbar('Cập nhật thông tin nhân viên thành công.', { variant: 'success' })
          break
        default:
          resp = await create(data)
          history.push(`/user/${resp.id}/update`)
          setLoading(false)
          enqueueSnackbar('Thêm mới nhân viên thành công.', { variant: 'success' })
      }
    } catch (e) {
      setLoading(false)
      enqueueSnackbar(`Máy chủ lỗi (code: ${e.response ? e.response.status : 'Unknown'}). Không thể cập nhật thông tin nhân viên.`, { variant: 'error' })
    }
  }

  return (
    <BackgroundBackdrop loading={loading}>
      <Formik
        initialValues={user}
        enableReinitialize
        validationSchema={validateSchema(action)}
        onSubmit={onSubmit}
      >
        {({
          values, touched, errors, handleChange, setFieldValue,
        }) => (
          <Form className={classes.root}>
            <Typography variant="h3">
              {action === 'update'
                ? 'Cập nhật thông tin tài khoản nhân viên'
                : 'Thêm mới tài khoản nhân viên'}
            </Typography>
            <br />
            <Typography className={classes.subTitle}>
              Thông tin đăng nhập
            </Typography>
            {/* Email and Password */}
            <Grid container spacing={6}>
              <Grid item lg={6} md={6}>
                <TextField
                  className={classes.formControl}
                  label="Hòm thư"
                  variant="outlined"
                  name="email"
                  fullWidth
                  value={values.email}
                  onChange={handleChange}
                  error={errors.email && touched.email}
                  helperText={errors.email && touched.email && errors.email}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Nhập địa chỉ email"
                />
              </Grid>
              <Grid item lg={6} md={6}>
                <TextField
                  className={classes.formControl}
                  label="Mật khẩu"
                  variant="outlined"
                  // type="password"
                  name="password"
                  // disabled={action === 'update'}
                  fullWidth
                  value={values.password || '*******'}
                  onChange={handleChange}
                  error={errors.password && touched.password}
                  helperText={errors.password && touched.password && errors.password}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Nhập mật khẩu"
                />
              </Grid>
            </Grid>
            <br />
            <Typography className={classes.subTitle}>
              Thông tin cá nhân
            </Typography>
            {/* Name and Phone */}
            <Grid container spacing={6}>
              <Grid item lg={6} md={6}>
                <TextField
                  className={classes.formControl}
                  label="Họ tên"
                  variant="outlined"
                  name="name"
                  fullWidth
                  value={values.name}
                  onChange={handleChange}
                  error={errors.name && touched.name}
                  helperText={errors.name && touched.name && errors.name}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Nhập họ tên nhân viên"
                />
              </Grid>
              <Grid item lg={6} md={6}>
                <TextField
                  className={classes.formControl}
                  label="Số điện thoại"
                  variant="outlined"
                  name="phone"
                  fullWidth
                  value={values.phone}
                  onChange={handleChange}
                  error={errors.phone && touched.phone}
                  helperText={errors.phone && touched.phone && errors.phone}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Nhập số điện thoại"
                />
              </Grid>
            </Grid>
            {/* Birthday and Address */}
            <Grid container spacing={6}>
              <Grid item lg={6} md={6}>
                <DatePicker
                  fullWidth
                  name="birthday"
                  label="Ngày sinh"
                  value={values.birthday}
                  onChange={value => setFieldValue('birthday', value)}
                />
              </Grid>
              <Grid item lg={6} md={6}>
                <TextField
                  className={classes.formControl}
                  label="Địa chỉ"
                  variant="outlined"
                  name="address"
                  fullWidth
                  value={values.address}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder="Nhập địa chỉ"
                />
              </Grid>
            </Grid>
            <br />
            <Typography className={classes.subTitle}>Phân quyền</Typography>
            {/* Role and Area manager */}
            <Grid container spacing={6}>
              <Grid item lg={6} md={6}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  fullWidth
                  error={errors.role && touched.role}
                >
                  <InputLabel
                    style={{ backgroundColor: 'white', padding: '0px 5px' }}
                    id="user-role"
                  >
                    Quyền
                  </InputLabel>
                  <Select
                    labelId="user-role"
                    id="user-role-label"
                    name="role"
                    value={values.role}
                    onChange={handleChange}
                    multiple
                  >
                    {role.map(item => (
                      <MenuItem value={item.value}>{item.label}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText style={{ color: 'red' }}>
                  {errors.role && touched.role && errors.role}
                </FormHelperText>
              </Grid>
              <Grid item lg={6} md={6}>
                <FormControl
                  variant="outlined"
                  className={classes.formControl}
                  fullWidth
                  error={errors.manageArea && touched.manageArea}
                >
                  <InputLabel
                    style={{ backgroundColor: 'white', padding: '0px 5px' }}
                    id="user-area-manager"
                  >
                    Khu vực quản lý
                  </InputLabel>
                  <Select
                    labelId="user-area-manager"
                    id="user-area-manager-label"
                    name="manageArea"
                    value={values.manageArea}
                    onChange={handleChange}
                    helperText={errors.manageArea && touched.manageArea && errors.manageArea}
                    multiple
                  >
                    {districts.map(d => (
                      <MenuItem value={d.value}>{d.label}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText style={{ color: 'red' }}>
                    {errors.area && touched.area && errors.area}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
            {/* Create button */}
            <br />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              {action === 'update' ? 'Cập nhật tài khoản' : 'Tạo tài khoản'}
            </Button>
          </Form>
        )}
      </Formik>
    </BackgroundBackdrop>
  )
}

UserForm.propTypes = {
  history: PropTypes.shape(),
  id: PropTypes.string,
  action: PropTypes.string,
}

export default UserForm
