import { SOCKET_ACTION } from '../type'

export const setMessage = message => ({
  type: SOCKET_ACTION.SET_MESSAGE,
  payload: message,
})

export const reset = type => ({
  type,
})
