/* eslint-disable no-constant-condition */
import { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  withStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import Upload from 'components/Upload'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { PAYMENT_TYPE, REJECT_AS_HOST } from 'contants'
import { flatten } from 'underscore'
import * as reservationApi from 'provider/reservation'
import * as roomApi from 'provider/room'
import styled from './styled'
import BlockUI from 'components/BlockUI'

const RejectDialog = ({ classes, reservation, open, onClose, onConfirm }) => {
  const [isBusy, setIsBusy] = useState(false)
  const [allRooms, setAllRooms] = useState([])
  const [sltRoom, setSltRoom] = useState([])
  const [paymentType, setPaymentType] = useState('2home')
  const [rejectAs, setRejectAs] = useState('customer')
  const [warningMessage, setWarningMessage] = useState('')
  const [hasRefund, setHasRefund] = useState(false)
  const [rejectImage, setRejectImage] = useState([])
  const [refundUser, setRefundUser] = useState(undefined)
  const [refundBankAccount, setRefundBankAccount] = useState(undefined)
  const [refundBankName, setRefundBankName] = useState(undefined)
  const [rejectNote, setRejectNote] = useState(undefined)

  // Fetch room
  const fetchRoom = async (query) => {
    try {
      const resp = await roomApi.find({ ...query, status: JSON.stringify(['active']), limit: 10000000 })
      setAllRooms([
        {
          id: -1,
          name: 'Tất cả',
          code: 'Tất cả',
          reservations: flatten(resp.data.map((r) => r.reservations)),
        },
        ...resp.data.map((item) => ({
          id: item.id,
          name: item.name,
          code: item.code,
          bookingType: item.bookingType,
          address: item.address,
          hasService: item.hasService,
          host: item.hostId,
          reservations: item.reservations,
        })),
      ])
    } catch (e) {
      // console.log(e)
    }
  }

  const getWarning = async (rejectMode, rejectPaymentType) => {
    if (!reservation) return
    if (rejectMode === 'customer') {
      try {
        const resp = await reservationApi.warning(reservation.code, 'reject', { paymentType: rejectPaymentType })
        if (resp?.refund) {
          setWarningMessage(`${resp.message}`)
          setHasRefund(resp.refund)
        } else {
          if (reservation.depositStatus === 'hold') {
            setHasRefund(true)
          } else {
            setHasRefund(false)
          }
          setWarningMessage('')
        }
      } catch (e) {
        setWarningMessage('')
        setHasRefund(false)
      }
    }
    if (rejectMode === 'host') {
      setWarningMessage(REJECT_AS_HOST)
      setHasRefund(true)
    }
  }

  useEffect(() => {
    if (open) {
      setPaymentType('2home')
      setRejectAs('customer')
      setWarningMessage('')
      setRejectImage([])
      setHasRefund(false)
      setRefundUser(undefined)
      setRefundBankAccount(undefined)
      setRefundBankName(undefined)
      setRejectNote(undefined)
      fetchRoom()
      setSltRoom([])
      switch (reservation?.status) {
        case 'paid':
          setRejectAs('host')
          getWarning('customer', '2home')
          break
        case 'using':
          setRejectAs('host')
          break
        default:
      }
      if (reservation?.customer?.email === 'customer@2home.vn') {
        setPaymentType('bank-transfer')
      }
    }
    return () => {}
  }, [open])

  useEffect(() => {
    if (!reservation) {
      return () => {}
    }
    if (open) {
      switch (reservation?.status) {
        case 'paid':
          getWarning(rejectAs, paymentType)
          break
        default:
      }
    }
    return () => {}
  }, [rejectAs, paymentType])

  const onRejectImageChange = (files) => {
    setRejectImage(files)
  }

  const rejectReservation = async () => {
    setIsBusy(true)

    try {
      await onConfirm(rejectAs, paymentType, refundUser, refundBankAccount, refundBankName, rejectNote, rejectImage, sltRoom)
    } finally {
      setIsBusy(false)
    }
  }

  if (!reservation) return null

  // Cân nhắc đang cho phép huỷ đơn đã hoàn thành và đang sử dụng
  return (
    <Dialog
      open={open}
      onClose={() => {
        setRejectAs('customer')
        setPaymentType('2home')
        setWarningMessage('')
        setHasRefund(false)
        onClose()
      }}
    >
      <BlockUI isLoading={isBusy}>
        <DialogTitle disableTypography>
          <h3>Huỷ đơn đặt phòng</h3>
        </DialogTitle>
        <DialogContent style={{ minWidth: 600, minHeight: 80 }}>
          {reservation.status === 'lock' ? (
            <Grid
              container
              spacing={3}
            >
              <Grid item>{`Xác nhận huỷ đơn khoá phòng ${reservation.code}`}</Grid>
            </Grid>
          ) : (
            <>
              <Grid
                container
                spacing={3}
              >
                {['using', 'completed'].includes(reservation?.status) ? (
                  <div>Hãy chắc chắn rằng bạn muốn thực hiện hành động huỷ đơn khi khách đang sử dụng hoặc đã checkout</div>
                ) : null}
                {/* Reject as customer */}
                <Grid
                  item
                  xs={6}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    color={rejectAs === 'customer' ? 'primary' : 'default'}
                    onClick={() => setRejectAs('customer')}
                  >
                    Huỷ do khách
                  </Button>
                </Grid>
                {/* Reject as host */}
                <Grid
                  item
                  xs={6}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    color={rejectAs === 'host' ? 'primary' : 'default'}
                    onClick={() => setRejectAs('host')}
                  >
                    {reservation?.status === 'processing' ? 'Hết phòng' : 'Huỷ do chủ nhà'}
                  </Button>
                </Grid>
              </Grid>
              <br />
              {rejectAs === 'customer' ? (
                <>
                  {warningMessage ? (
                    <>
                      <Grid container>
                        {/* Warning */}
                        <p dangerouslySetInnerHTML={{ __html: warningMessage }} />
                      </Grid>
                      <br />
                    </>
                  ) : null}
                  {hasRefund ? (
                    <>
                      <div className="mb-2">{`Khách thanh toán đơn bằng ${
                        reservation?.paymentType === '2home' ? 'tài khoản 2home' : 'tài khoản ngân hàng'
                      }`}</div>
                      <Grid
                        container
                        spacing={2}
                      >
                        {/* Refund info */}
                        <Grid
                          item
                          xs={6}
                        >
                          {/* Payment type: 2home/bank-transfer */}
                          <FormControl
                            variant="outlined"
                            className="my-1"
                            fullWidth
                          >
                            <InputLabel
                              className="px-1 white-bg"
                              // style={{ backgroundColor: 'white'}}
                              id="refund-payment-type"
                            >
                              Hình thức hoàn tiền
                            </InputLabel>
                            <Select
                              labelId="refund-payment-type"
                              id="refund-payment-type-label"
                              value={reservation?.customer?.email === 'customer@2home.vn' ? 'bank-transfer' : paymentType}
                              onChange={(e) => setPaymentType(e.target.value)}
                              disabled={reservation?.customer?.email === 'customer@2home.vn' || reservation?.paymentType === '2home'}
                            >
                              {PAYMENT_TYPE.map((item) => (
                                <MenuItem
                                  key={item.value}
                                  value={item.value}
                                >
                                  {item.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {/* Bank information */}
                          {paymentType === 'bank-transfer' || reservation?.customer?.email === 'customer@2home.vn' ? (
                            <>
                              <TextField
                                className="my-1"
                                label="Chủ tài khoản"
                                variant="outlined"
                                fullWidth
                                value={refundUser}
                                onChange={(e) => setRefundUser(e.target.value)}
                              />
                              <TextField
                                className="my-1"
                                label="Số tài khoản"
                                variant="outlined"
                                fullWidth
                                value={refundBankAccount}
                                onChange={(e) => setRefundBankAccount(e.target.value)}
                              />
                              <TextField
                                className="my-1"
                                label="Ngân hàng"
                                variant="outlined"
                                fullWidth
                                value={refundBankName}
                                onChange={(e) => setRefundBankName(e.target.value)}
                              />
                            </>
                          ) : null}
                        </Grid>
                        {/* Refund image */}
                        <Grid
                          item
                          xs={6}
                          style={{ textAlign: 'center' }}
                        >
                          <Upload
                            files={rejectImage}
                            multiple
                            onChange={(files) => onRejectImageChange(files)}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        className="mt-3"
                      >
                        <TextField
                          label="Lý do huỷ"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={3}
                          value={rejectNote}
                          onChange={(e) => setRejectNote(e.target.value)}
                          className={classes.formControl}
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid
                        item
                        xs={12}
                        className="mb-2 text-center"
                      >
                        <Upload
                          files={rejectImage}
                          multiple
                          onChange={(files) => onRejectImageChange(files)}
                        />
                      </Grid>
                      <Grid container>
                        <TextField
                          label="Lý do huỷ"
                          variant="outlined"
                          fullWidth
                          multiline
                          rows={3}
                          value={rejectNote}
                          onChange={(e) => setRejectNote(e.target.value)}
                          className={classes.formControl}
                        />
                      </Grid>
                    </>
                  )}
                </>
              ) : (
                <>
                  {warningMessage ? (
                    <>
                      <Grid container>
                        {/* Warning */}
                        <p dangerouslySetInnerHTML={{ __html: warningMessage }} />
                      </Grid>
                      <br />
                    </>
                  ) : null}
                  <Grid
                    container
                    spacing={2}
                  >
                    {/* Refund info */}
                    {['paid', 'using', 'completed'].includes(reservation?.status) ? (
                      <Grid
                        item
                        xs={6}
                      >
                        {/* Payment type: 2home/bank-transfer */}
                        <FormControl
                          variant="outlined"
                          className="my-1"
                          fullWidth
                        >
                          <InputLabel
                            className="px-1 white-bg"
                            id="refund-payment-type"
                          >
                            Hình thức hoàn tiền
                          </InputLabel>
                          <Select
                            labelId="refund-payment-type"
                            id="refund-payment-type-label"
                            value={reservation?.customer?.email === 'customer@2home.vn' ? 'bank-transfer' : paymentType}
                            onChange={(e) => {
                              if (reservation?.customer?.email !== 'customer@2home.vn') {
                                setPaymentType(e.target.value)
                              }
                            }}
                            disabled={reservation?.customer?.email === 'customer@2home.vn'}
                          >
                            {PAYMENT_TYPE.map((item) => (
                              <MenuItem
                                key={item.value}
                                value={item.value}
                              >
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {/* Bank information */}
                        {paymentType === 'bank-transfer' ? (
                          <>
                            <TextField
                              className="my-1"
                              label="Chủ tài khoản"
                              variant="outlined"
                              fullWidth
                              value={refundUser}
                              onChange={(e) => setRefundUser(e.target.value)}
                            />
                            <TextField
                              className="my-1"
                              label="Số tài khoản"
                              variant="outlined"
                              fullWidth
                              value={refundBankAccount}
                              onChange={(e) => setRefundBankAccount(e.target.value)}
                            />
                            <TextField
                              className="my-1"
                              label="Ngân hàng"
                              variant="outlined"
                              fullWidth
                              value={refundBankName}
                              onChange={(e) => setRefundBankName(e.target.value)}
                            />
                          </>
                        ) : null}
                      </Grid>
                    ) : false ? (
                      <>
                        <Grid
                          item
                          xs={12}
                        >
                          <InputLabel className="px-1 white-bg">Gợi ý các phòng tương tự:</InputLabel>
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                        >
                          <FormControl
                            className={classes.formControl}
                            variant="outlined"
                            fullWidth
                            size="small"
                          >
                            <Autocomplete
                              multiple
                              limitTags={2}
                              freeSolo
                              value={sltRoom}
                              options={allRooms}
                              getOptionLabel={(opt) => `${opt.name} (${opt.code})`}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Phòng"
                                  variant="outlined"
                                  InputLabelProps={{
                                    ...params.InputLabelProps,
                                    shrink: true,
                                  }}
                                  placeholder="Chọn phòng ..."
                                  size="small"
                                />
                              )}
                              onChange={(e, sltItem) => {
                                if (sltItem.find((r) => r.id === -1)) {
                                  setSltRoom(allRooms)
                                } else {
                                  setSltRoom(sltItem)
                                }
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </>
                    ) : null}
                    {/* Refund image */}
                    <Grid
                      item
                      xs={reservation?.customer?.email === 'customer@2home.vn' ? 6 : 12}
                      className="text-center"
                    >
                      <Upload
                        files={rejectImage}
                        multiple
                        onChange={(files) => onRejectImageChange(files)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <TextField
                      label="Lý do huỷ"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={3}
                      value={rejectNote}
                      onChange={(e) => setRejectNote(e.target.value)}
                      className={classes.formControl}
                    />
                  </Grid>
                </>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={rejectReservation}
            color="primary"
            disabled={!rejectAs}
          >
            Huỷ đơn
          </Button>
        </DialogActions>
      </BlockUI>
    </Dialog>
  )
}

RejectDialog.propTypes = {
  classes: PropTypes.shape(),
  reservation: PropTypes.shape(),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default withStyles(styled)(RejectDialog)
