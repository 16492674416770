const styles = theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  ellipsisCell: {
    maxWidth: 150,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  amountCell: {
    minWidth: 150,
  },
  statusCell: {
    minWidth: 150,
  },
  codeCell: {
    color: '#3f51b5',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  filter: {
    '& > div': {
      margin: theme.spacing(1),
    },
  },
})

export default styles
