import Customer from './Customer'
import Room from './Room'
import Status from './Status'
import StartDate from './StartDate'
import EndDate from './EndDate'
import Service from './Service'
import BookingDate from './BookingDate'

export default {
  Customer,
  Room,
  Status,
  StartDate,
  EndDate,
  Service,
  BookingDate,
}
