import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Typography,
  TextField,
  withStyles,
} from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import { useSnackbar } from 'notistack'
import { Table as LockTable } from 'components'
import PropTypes from 'prop-types'
import DatePicker from 'components/DatePicker2'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckinIcon from 'assets/icons/icon-date-in.png'
import CheckoutIcon from 'assets/icons/icon-date-out.png'
import * as hostApi from 'provider/host'
import * as roomApi from 'provider/room'
import * as orderApi from 'provider/reservation'
import { flatten } from 'underscore'
import styled from './styled'

const LockingRoomDialog = ({
  classes,
  open,
  onClose,
  onConfirm,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [allHosts, setAllHosts] = useState([])
  const [allRooms, setAllRooms] = useState([])
  const [rooms, setRooms] = useState([])
  const [sltHost, setSltHost] = useState(null)
  const [sltRoom, setSltRoom] = useState([])
  const [checkin, setCheckin] = useState(null)
  const [checkout, setCheckout] = useState(null)
  const [duplicateLockOrder, setDuplicateLockOrder] = useState([])

  // Fetch host
  const fetchHost = async () => {
    try {
      const resp = await hostApi.find({ limit: Number.MAX_SAFE_INTEGER })
      setAllHosts(
        resp.data.map(item => ({
          id: item.id,
          name: item.name,
          code: item.code,
          phone: item.phone,
        })),
      )
    } catch (e) {
      // console.log(e)
    }
  }

  // Fetch room
  const fetchRoom = async (query) => {
    try {
      const resp = await roomApi.find({ ...query, status: ['active'], limit: 10000000 })
      setAllRooms([{
        id: -1,
        name: 'Tất cả',
        code: 'Tất cả',
        reservations: flatten(resp.data.map(r => r.reservations)),
      },
      ...resp.data.map(item => ({
        id: item._id,
        name: item.name,
        code: item.code,
        bookingType: item.bookingType,
        address: item.address,
        hasService: item.hasService,
        host: item.owner?._id,
        reservations: item.reservations,
      }))])
      setRooms([{
        id: -1,
        name: 'Tất cả',
        code: 'Tất cả',
        reservations: flatten(resp.data.map(r => r.reservations)),
      },
      ...resp.data.map(item => ({
        id: item._id,
        name: item.name,
        code: item.code,
        bookingType: item.bookingType,
        address: item.address,
        hasService: item.hasService,
        host: item.owner?._id,
      }))])
    } catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    setSltHost(null)
    setSltRoom([])
    setCheckin(null)
    setCheckout(null)
    setDuplicateLockOrder([])
    if (open) {
      fetchHost()
      fetchRoom()
    }
    return () => {
    }
  }, [open])

  useEffect(() => {
    if (checkin && checkout && sltRoom?.length) {
      sltRoom.forEach((item) => {
        setDuplicateLockOrder(item?.reservations?.filter(r => (r.checkin < checkin && checkin < r.checkout) || (r.checkin < checkout && checkout < r.checkout) || (checkin < r.checkin && r.checkout < checkout)))
      })
    }
    return () => {

    }
  }, [checkin, checkout, sltRoom])

  useEffect(() => {
    if (sltHost && sltHost.id && allHosts.length) {
      setRooms(allRooms.filter(r => r.host === sltHost.id || r.id === -1))
    }
    if (!sltHost) {
      setSltRoom([])
      setRooms(allRooms)
    }
    return () => {
    }
  }, [sltHost])

  const lockRoom = async () => {
    if (!sltRoom?.length || !checkin || !checkout) {
      enqueueSnackbar('Không thể khoá phòng, cần đầy đủ thông tin (phòng, thời gian khoá)', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      return
    }
    try {
      await roomApi.lock(sltRoom?.map(r => r.id), checkin, checkout)
      enqueueSnackbar('Khóa phòng thành công', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      setSltHost(null)
      setSltRoom([])
      setCheckin(null)
      setCheckout(null)
      onConfirm({ })
    } catch (e) {
      // console.log(e.response)
      enqueueSnackbar(`Không thể khoá phòng, ${e.response?.data}.`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
    onClose()
  }

  const renderStatus = (status) => {
    switch (status) {
      case 'processing-pay-extra':
      case 'confirmed-pay-extra':
      case 'processing-refund-in-update':
      case 'waiting-refund-in-update':
      case 'host-confirm-refund-update':
      case 'add-checkout-processing':
      case 'paid':
        return <Typography variant="body1">Đã thanh toán</Typography>
      case 'lock':
        return <Typography variant="body1">Đã khoá</Typography>
      default:
        return null
    }
  }

  const rejectLockingOrder = async (id) => {
    // Huỷ đơn
    try {
      await orderApi.reject(id)
      setDuplicateLockOrder([...duplicateLockOrder].filter(o => o.id !== id))
    } catch (e) {
      enqueueSnackbar('Không thể huỷ khoá phòng, đã có lỗi xảy ra!')
    }
  }

  const renderAction = (status, origin) => {
    switch (status) {
      case 'lock':
        return (<Button variant="outlined" onClick={() => rejectLockingOrder(origin.id)}>Xoá</Button>)
      case 'processing-pay-extra':
      case 'confirmed-pay-extra':
      case 'processing-refund-in-update':
      case 'waiting-refund-in-update':
      case 'host-confirm-refund-update':
      case 'add-checkout-processing':
      case 'paid':
      default:
        return null
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="body"
      PaperProps={{ style: { overflowY: 'unset', maxWidth: '100%' } }}
    >
      <DialogTitle disableTypography>
        <h3>Khoá phòng tạm thời</h3>
      </DialogTitle>
      <DialogContent style={{ minWidth: '80vw', minHeight: 80, overflowY: 'hidden' }}>
        <Grid container>
          {/* Chọn phòng khoá */}
          <Grid container item md={6}>
            <Grid container item xs={12} spacing={3}>
              <Grid item lg={12} md={12}>
              Lưu ý: Không thể khoá phòng nếu có đơn đã thanh toán trong khoảng thời gian khoá hoặc thời gian khoá trùng với đơn khoá phòng khác.
              Sau khi khoá phòng, các đơn chưa thanh toán sẽ bị huỷ.
              </Grid>
            </Grid>
            {/* Phòng/chủ nhà */}
            <Grid container item xs={12} spacing={3}>
              {/* Chủ nhà */}
              <Grid item lg={12} md={12}>
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                  fullWidth
                  size="small"
                >
                  <Autocomplete
                    value={sltHost}
                    options={allHosts}
                    getOptionLabel={opt => `${opt.name} (${opt.code})`}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Chủ nhà"
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Chọn chủ nhà ..."
                        size="small"
                      />
                    )}
                    onChange={(e, sltItem) => {
                      setSltHost(sltItem)
                      setSltRoom([])
                    }}
                  />
                </FormControl>
              </Grid>
              {/* Phòng */}
              <Grid item lg={12} md={12}>
                <FormControl
                  className={classes.formControl}
                  variant="outlined"
                  fullWidth
                  size="small"
                >
                  <Autocomplete
                    disabled={!sltHost}
                    multiple
                    limitTags={2}
                    freeSolo
                    value={sltRoom}
                    options={rooms}
                    getOptionLabel={opt => `${opt.name} (${opt.code})`}
                    renderInput={params => (
                      <TextField
                        {...params}
                        label="Phòng"
                        variant="outlined"
                        InputLabelProps={{
                          ...params.InputLabelProps,
                          shrink: true,
                        }}
                        placeholder="Chọn phòng ..."
                        size="small"
                      />
                    )}
                    onChange={(e, sltItem) => {
                      if (sltItem.find(r => r.id === -1)) {
                        setSltRoom(rooms)
                      } else {
                        setSltRoom(sltItem)
                      }
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>
            {/* Thời gian khoá */}
            <Grid container item xs={12} spacing={3} style={{ marginTop: 20 }}>
              <Grid item lg={6} md={6}>
                <DatePicker
                  type="datetime"
                  fullWidth
                  icon={<img src={CheckinIcon} style={{ width: 20, marginRight: 10 }} alt="checkin-icon" />}
                  label="Từ ngày/giờ"
                  value={checkin}
                  onSelect={value => setCheckin(value)}
                  size="small"
                  picked={flatten(sltRoom?.map(r => r.reservations))?.map(r => ({ start: r?.checkin, end: r?.checkout }))}
                />
              </Grid>
              <Grid item lg={6} md={6}>
                <DatePicker
                  type="datetime"
                  fullWidth
                  icon={<img src={CheckoutIcon} style={{ width: 20, marginRight: 10 }} alt="checkout-icon" />}
                  label="Đến ngày/giờ"
                  value={checkout}
                  onSelect={value => setCheckout(value)}
                  size="small"
                  min={checkin}
                  picked={flatten(sltRoom?.map(r => r.reservations))?.map(r => ({ start: r?.checkin, end: r?.checkout }))}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Thông tin lịch trùng */}
          <Grid container item md={6}>
            <Grid item md={12}>
              <Typography variant="h6">Lịch trùng</Typography>
            </Grid>
            <Grid item md={12}>
              <LockTable
                className="w-100"
                data={duplicateLockOrder}
                columns={[
                  {
                    Head: 'Mã',
                    Cell: (value, origin) => (
                      <div className="d-flex align-items-center">
                        {moment.duration(moment().diff(moment(origin.updatedAt))).asMinutes() > 15 && origin.status === 'processing'
                          ? <FiberManualRecord fontSize="small" style={{ color: '#ffa854', marginRight: 8 }} />
                          : null}
                        <RouterLink to={`/reservation/${value}/view`} className={classes.codeCell}>
                          <Typography variant="body1">{value}</Typography>
                          <Typography variant="body2">
                            {moment(origin.createdAt).format('DD/MM/YYYY')}
                          </Typography>
                        </RouterLink>
                      </div>
                    ),
                    accessor: 'code',
                    sortable: false,
                  },
                  {
                    Head: 'Thời gian',
                    Cell: (value, origin) => (
                      <div className="text-left">
                        <Typography variant="body1" className="d-flex">
                          <div>
                            <div>{moment(origin?.checkin).format('HH:mm')}</div>
                            <div>{moment(origin?.checkin).format('DD/MM')}</div>
                          </div>
                          <div style={{ margin: 'auto 20px' }}>-</div>
                          <div>
                            <div>{moment(origin?.checkout).format('HH:mm')}</div>
                            <div>{moment(origin?.checkout).format('DD/MM')}</div>
                          </div>
                        </Typography>
                      </div>
                    ),
                    accessor: 'checkin',
                    sortable: false,
                  },
                  {
                    Head: 'Trạng thái',
                    Cell: value => renderStatus(value),
                    accessor: 'status',
                    sortable: false,
                  },
                  {
                    Head: '',
                    Cell: (value, origin) => renderAction(value, origin),
                    accessor: 'status',
                  },
                ]}
                total={duplicateLockOrder?.length}
                showPaginate={false}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={lockRoom} color="primary" disabled={duplicateLockOrder?.length || !checkin || !checkout || !sltRoom?.length}>
        Xác nhận
        </Button>
      </DialogActions>
    </Dialog>
  )
}

LockingRoomDialog.propTypes = {
  classes: PropTypes.shape(),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default withStyles(styled)(LockingRoomDialog)
