import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button, Checkbox, FormControlLabel, Icon, Typography,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  active: {
    backgroundColor: '#3f51b5',
    textTransform: 'none',
    color: 'white',
  },
  inactive: {
    backgroundColor: 'white',
    textTransform: 'none',
  },
}))

const Service = ({
  value,
  noExtraInfo,
  onApply,
  onExtraInfoFilter,
}) => {
  const classes = useStyles()
  const [hasService, setHasService] = useState(false)
  const [hasExtraInfo, setHasExtraInfo] = useState(!noExtraInfo)

  useEffect(() => {
    setHasService(String(value) === 'true')
    return () => {}
  }, [value])

  return (
    <div>
      <Button
        variant="outlined"
        className={hasService ? `${classes.active} w-100` : `${classes.inactive} w-100`}
        onClick={() => onApply(!hasService)}
      >
        <Icon className="fa fa-birthday-cake" style={{ fontSize: 18, marginRight: 10 }} />
      Có dịch vụ
      </Button>
      {hasService
        ? (
          <FormControlLabel
            control={(
              <Checkbox
                checked={hasExtraInfo}
                onChange={(e) => {
                  onExtraInfoFilter(e.target.checked)
                  setHasExtraInfo(!hasExtraInfo)
                }}
                color="primary"
              />
            )}
            label={<Typography variant="body1">Chưa cung cấp thông tin</Typography>}
          />
        )
        : null}
    </div>
  )
}

Service.propTypes = {
  value: PropTypes.string,
  noExtraInfo: PropTypes.bool,
  onApply: PropTypes.func,
  onExtraInfoFilter: PropTypes.func,
}

export default Service
