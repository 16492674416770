import React from 'react'
import PropTypes from 'prop-types'
import vi from 'date-fns/locale/vi'
import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  DateTimePicker,
  TimePicker,
} from '@material-ui/pickers'

const DatePicker = ({
  onChange, value, type, ...props
}) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={vi}>
    {(() => {
      switch (type) {
        case 'datetime':
          return (
            <DateTimePicker
              style={{ backgroundColor: 'white' }}
              {...props}
              value={value ? new Date(value) : null}
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy HH:mm"
              minutesStep={30}
              onChange={date => onChange(new Date(date).getTime())}
            />
          )
        case 'time':
          return (
            <TimePicker
              style={{ backgroundColor: 'white' }}
              {...props}
              variant="inline"
              inputVariant="outlined"
              format="HH:mm"
              value={value ? new Date(value) : null}
              onChange={date => onChange(new Date(date).getTime())}
            />
          )
        default:
          return (
            <KeyboardDatePicker
              style={{ backgroundColor: 'white' }}
              {...props}
              value={value ? new Date(value) : null}
              variant="inline"
              inputVariant="outlined"
              format="dd/MM/yyyy"
              onChange={date => onChange(new Date(date).getTime())}
            />
          )
      }
    })()}
  </MuiPickersUtilsProvider>
)

DatePicker.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.number,
  type: PropTypes.string,
}

export default DatePicker
