import { connect } from 'react-redux'
import ReportPage from 'views/Report'
import { getUserData } from 'redux/selector/user'
import { update } from 'redux/action/user'

const mapDispatchToProps = dispatch => ({
  update: data => dispatch(update(data)),
})

export default connect(getUserData, mapDispatchToProps)(ReportPage)
