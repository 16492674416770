import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import DateRangePicker from 'components/TimeRange'
import moment from 'moment'

const BookingDate = ({
  startdate,
  enddate,
  onApply,
}) => {
  const [startDate, setStartdate] = useState(startdate)
  const [endDate, setEnddate] = useState(enddate)

  useEffect(() => {
    if (startdate) {
      setStartdate(startdate)
    }
  }, [startdate])

  useEffect(() => {
    if (enddate) {
      setEnddate(enddate)
    }
  }, [enddate])

  const onDateChange = (sltDate) => {
    setStartdate(sltDate?.startDate)
    setEnddate(sltDate?.endDate)
    onApply({ startdate: moment(sltDate?.startDate).startOf('day').valueOf(), enddate: moment(sltDate?.endDate).endOf('day').valueOf() })
  }

  const onDateClear = () => {
    setStartdate(null)
    setEnddate(null)
    onApply({ startdate: null, enddate: null })
  }

  return (
    <DateRangePicker
      value={{ startDate, endDate }}
      onApply={onDateChange}
      onClear={onDateClear}
    />
  )
}

BookingDate.propTypes = {
  onApply: PropTypes.func,
  startdate: PropTypes.number,
  enddate: PropTypes.number,
}

export default BookingDate
