import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography,
} from '@material-ui/core'
import RichEditor from 'components/RichEditor'

const Description = ({
  classes,
  values,
  touched,
  errors,
  // handleChange,
  setFieldValue,
}) => (
  <>
    <Typography className={classes.subTitle}>Mô tả dịch vụ:</Typography>
    <Grid container item lg={12} md={12}>
      <RichEditor
        style={{ backgroundColor: 'white' }}
        label="Viết nội dung mô tả cho dịch vụ ..."
        name="description"
        value={values.description}
        onChange={e => setFieldValue('description', e)}
        error={errors.description && touched.description}
        helperText={errors.description && touched.description && errors.description}
        placeholder="Nhập mô tả cho dịch vụ"
      />
    </Grid>
  </>
)

Description.propTypes = {
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
  touched: PropTypes.shape(),
  errors: PropTypes.shape(),
  // handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
}

export default Description
