import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  FormHelperText,
} from '@material-ui/core'
import { NavLink as RouterLink } from 'react-router-dom'
import Autocomplete from '@material-ui/lab/Autocomplete'
import * as hostApi from 'provider/host'
import * as roomApi from 'provider/room'
import { debounce } from 'underscore'

const General = ({
  editable,
  classes,
  values,
  touched,
  errors,
  handleChange,
  setFieldValue,
  onServiceSelected,
  onRoomPriceRecalculate,
  onRoomSelected,
  allServices,
}) => {
  const [allHosts, setAllHosts] = useState([])
  const [allRooms, setAllRooms] = useState([])
  const [sltHost, setSltHost] = useState(null)
  const [sltRoom, setSltRoom] = useState(null)

  // Loading previous host
  const loadSelectedHost = async (id) => {
    try {
      if (!allHosts.length) {
        const prevHost = await hostApi.findOne(id)
        setSltHost({
          ...prevHost,
          id: prevHost._id || prevHost.id,
        })
        setAllHosts([{
          ...prevHost,
          id: prevHost._id || prevHost.id,
        }])
      }
    } catch (e) {
      console.log(e)
    }
  }

  // Loading previous room
  const loadSelectedRoom = async (id) => {
    try {
      if (!allRooms.length) {
        const prevRoom = await roomApi.findOne(id)
        setSltRoom({
          ...prevRoom,
          id: prevRoom._id || prevRoom.id,
        })
        setAllRooms([{
          ...prevRoom,
          id: prevRoom._id || prevRoom.id,
          host: prevRoom.owner?._id,
          limitRoom: prevRoom.hasLimitBedRoom,
        }])
        onRoomSelected({
          ...prevRoom,
          id: prevRoom._id || prevRoom.id,
        })
        loadSelectedHost(prevRoom.owner._id || prevRoom.owner.id)
      }
    } catch (e) {
      console.log(e)
    }
  }

  // Fetch host
  const fetchHost = async (query) => {
    try {
      const resp = await hostApi.find({
        ...query,
        active: 1,
        limit: Number.MAX_SAFE_INTEGER,
       })
       const hosts = resp?.data?.map((item) => ({
        ...item,
        id: item._id || item.id,
      }))
      setAllHosts(hosts)
      return hosts
    } catch (e) {
      // console.log(e)
      return []
    }
  }

  // Fetch room
  const fetchRoom = async (query) => {
    try {
      const resp = await roomApi.find({
        ...query,
        active: 0,
        limit: 10000000,
       })
      setAllRooms(
        resp?.data?.map((item) => ({
          ...item,
          id: item._id || item.id,
          host: item.owner?._id,
          limitRoom: item.hasLimitBedRoom,
        })),
      )
    } catch (e) {
      // console.log(e)
    }
  }

  const onSearchHost = useCallback(debounce((input) => fetchHost(input), 800), [])

  const onSearchRoom = useCallback(debounce((input) => fetchRoom(input), 800), [])

  const onSelectHost = (sltItem) => {
    if (sltItem) {
      setSltHost(sltItem)
      setFieldValue('host', sltItem?.id)
      fetchRoom({
        owner: sltItem?.id,
      })
      if (sltRoom && sltItem?.id !== sltRoom?.host) {
        setFieldValue('room', undefined)
        setSltRoom(null)
        onRoomSelected(null)
      }
    } else {
      setSltHost(null)
      setFieldValue('room', undefined)
      setSltRoom(null)
      onRoomSelected(null)
    }
  }

  const onSelectRoom = async (sltItem) => {
    if (sltItem) {
      setSltRoom(sltItem)
      setFieldValue('room', sltItem?.id)
      onRoomSelected(sltItem)
      if (sltItem.host !== sltHost?.id) {
        const hosts = await fetchHost({
          ids: [sltItem.host],
        })
        setSltHost(hosts?.[0])
        setFieldValue('host', hosts?.[0]._id)
      }
    } else {
      setSltRoom(null)
      setFieldValue('room', undefined)
      onRoomSelected(null)
    }
  }

  useEffect(() => {
    if (values.services && allServices?.length) {
      onServiceSelected(allServices.filter(h => values.services.includes(h.id)))
    }
    return () => {
    }
  }, [values.services, allServices])

  useEffect(() => {
    if (editable && values.room && allRooms.length) {
      onRoomPriceRecalculate(values.room, values.checkin, values.checkout, values.guest, values.limitRoom)
    }
    return () => {
    }
  }, [editable, values.room, values.checkin, values.checkout, values.guest, values.limitRoom])

  useEffect(() => {
    if (values.room) {
      loadSelectedRoom(values.room)
    }
  }, [values.room])

  return (
    <>
      <Typography className={classes.subTitle}>
        {['lock', 'reject-lock'].includes(values.status) ? 'Thông tin khoá phòng:' : 'Thông tin phòng:'}
      </Typography>
      {/* Phòng & chủ nhà */}
      <Grid container spacing={3}>
        {/* Host */}
        {editable
          ? (
            <Grid item lg={6} md={6}>
              <FormControl
                className={classes.formControl}
                variant="outlined"
                fullWidth
                error={errors.host && touched.host}
                disabled={!editable || values.code}
                size="small"
              >
                <Autocomplete
                  value={sltHost}
                  options={allHosts}
                  getOptionLabel={opt => `${opt.name} (${opt.code})`}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Chủ nhà"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Chọn chủ nhà ..."
                      size="small"
                      onChange={(e) => onSearchHost({
                        searchTxt: e.target.value,
                      })}
                    />
                  )}
                  onChange={(e, sltItem) => onSelectHost(sltItem)}
                  disabled={!editable || values.code}
                />
              </FormControl>
              <FormHelperText style={{ color: 'red' }}>
                {errors.host && touched.host && errors.host}
              </FormHelperText>
            </Grid>
          )
          : (
            <Grid item lg={6} md={6}>
              <InputLabel className={classes.itemTitle}>Chủ nhà:</InputLabel>
              <Typography variant="body1" style={{ fontWeight: 500 }}>
                <RouterLink to={`/host/${sltHost?.id}/update`} target="_blank">{`${sltHost?.name} (${sltHost?.code})`}</RouterLink>
              </Typography>
            </Grid>
          )}
        {/* Room */}
        {editable
          ? (
            <Grid item lg={6} md={6}>
              <FormControl
                className={classes.formControl}
                variant="outlined"
                fullWidth
                error={errors.room && touched.room}
                size="small"
                disabled={!editable || values.code}
              >
                <Autocomplete
                  value={sltRoom}
                  options={allRooms}
                  getOptionLabel={opt => `${opt.name} (${opt.code})`}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Phòng"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Chọn phòng ..."
                      size="small"
                      onChange={(e) => onSearchRoom({
                        owner: sltHost?.id,
                        searchTxt: e.target.value,
                      })}
                    />
                  )}
                  onChange={(e, sltItem) => onSelectRoom(sltItem)}
                  disabled={!editable || values.code}
                />
              </FormControl>
              <FormHelperText style={{ color: 'red' }}>
                {errors.room && touched.room && errors.room}
              </FormHelperText>
            </Grid>
          )
          : (
            <Grid item lg={6} md={6}>
              <InputLabel className={classes.itemTitle}>Phòng:</InputLabel>
              <Typography variant="body1" style={{ fontWeight: 500 }}>
                <RouterLink to={`/room/${sltRoom?.id}/update`} target="_blank">{`${sltRoom?.name} (${sltRoom?.code})`}</RouterLink>
              </Typography>
            </Grid>
          )}
      </Grid>
      {/* Dịch vụ */}
      {
        ['lock', 'reject-lock'].includes(values.status) || !sltRoom?.hasService
          ? null
          : (
            <Typography className={classes.subTitle} style={{ marginTop: 20 }}>
              Thông tin dịch vụ:
            </Typography>
          )
      }
      <Grid container spacing={3}>
        {['lock', 'reject-lock'].includes(values.status)
          ? null
          : editable
            ? sltRoom && sltRoom.hasService
              ? (
                <Grid item lg={12} md={12}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                    fullWidth
                    error={errors.services && touched.services}
                    disabled={!editable || ['completed', 'using'].includes(values.status)}
                    size="small"
                  >
                    <Select
                      labelId="reservation-service"
                      id="reservation-service-label"
                      name="services"
                      value={values.services}
                      onChange={handleChange}
                      multiple
                      disabled={!editable || ['completed', 'using'].includes(values.status)}
                    >
                      {allServices?.map(item => (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormHelperText style={{ color: 'red' }}>
                    {errors.services && touched.services && errors.services}
                  </FormHelperText>
                </Grid>
              )
              : (
                <Grid item lg={12} md={12}>
                  {sltRoom && !sltRoom.hasService ? (
                    <p>Phòng hiện tại không hỗ trợ setup dịch vụ</p>
                  ) : null}
                </Grid>
              )
            : sltRoom && sltRoom.hasService && values.services.length
              ? (
                <Grid item lg={12} md={12}>
                  <Grid container>
                    {
                      values?.prevState
                        ? (
                          <Grid item lg={5} md={5}>
                            {
                              allServices.filter(item => values?.prevState.services.map(s => s.id).includes(item.id)).map(item => (
                                <Typography variant="body1" style={{ fontWeight: 500 }}>{`- ${item.name}`}</Typography>
                              ))
                            }
                          </Grid>
                        )
                        : null
                    }
                    {values?.prevState ? <Grid item lg={1} md={1}>&rarr;</Grid> : ''}
                    <Grid item lg={6} md={6}>
                      {
                        allServices.filter(item => values.services.includes(item.id)).map(item => (
                          <Typography variant="body1" style={{ fontWeight: 500 }}>{`- ${item.name}`}</Typography>
                        ))
                      }
                    </Grid>
                  </Grid>
                </Grid>
              )
              : (
                <Grid item lg={12} md={12}>
                  Không sử dụng dịch vụ
                </Grid>
              )}
      </Grid>
    </>
  )
}

General.propTypes = {
  editable: PropTypes.bool,
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
  touched: PropTypes.shape(),
  errors: PropTypes.shape(),
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  onServiceSelected: PropTypes.func,
  onRoomPriceRecalculate: PropTypes.func,
  onRoomSelected: PropTypes.func,
  allServices: PropTypes.arrayOf(PropTypes.shape()),
}

export default General
