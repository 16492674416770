import * as notifyApi from 'provider/notification'
import { NOTIFICATION_ACTION } from '../type'

export const find = () => async (dispatch) => {
  dispatch({
    type: NOTIFICATION_ACTION.FETCH,
  })
  try {
    const notification = await notifyApi.find({})
    dispatch({
      type: NOTIFICATION_ACTION.FETCH_OK,
      payload: notification,
    })
  } catch (e) {
    dispatch({
      type: NOTIFICATION_ACTION.FETCH_ERR,
      payload: {
        message: '',
      },
    })
  }
}

export const update = id => async (dispatch) => {
  dispatch({
    type: NOTIFICATION_ACTION.UPDATE,
  })
  try {
    const notification = await notifyApi.update(id)
    dispatch({
      type: NOTIFICATION_ACTION.UPDATE_OK,
      payload: notification,
    })
  } catch (e) {
    dispatch({
      type: NOTIFICATION_ACTION.UPDATE_ERR,
      payload: {
        message: '',
      },
    })
  }
}
