import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Switch, Redirect } from 'react-router-dom'
import { getSocketData } from 'redux/selector/socket'
import { setMessage } from 'redux/action/socket'
import Amenity from 'views/Amenity'
import CustomerReportPage from 'container/CustomerReport'
import { RouteWithLayout } from './components'
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
} from './layouts'
import SigninContainer from './container/Signin'
import StaffContainer from './container/Staff'
import ReservationContainer from './container/Reservation'
import RoomContainer from './container/Room'
import CustomerContainer from './container/Customer'
import PrepaidHistoryContainer from './container/PrepaidHistory'
import AccountContainer from './container/Account'
import HostContainer from './container/Host'
import StaffFormContainer from './container/StaffForm'
import HostFormContainer from './container/HostForm'
import CustomerFormContainer from './container/CustomerForm'
import ReservationFormContainer from './container/ReservationForm'
import RoomFormContainer from './container/RoomForm'
import ServiceFormContainer from './container/ServiceForm'
import ServiceContainer from './container/Service'
import DashboardContainer from './container/Dashboard'
import CouponContainer from './container/Coupon'
import CouponFormContainer from './container/CouponForm'
import ReportContainer from './container/Report'
import OrderReportContainer from './container/OrderReport'
import RoomReportContainer from './container/RoomReport'
import ServiceReportContainer from './container/ServiceReport'
import HostReportPage from './container/HostReport'
import InvoiceContainer from './container/Invoice'
import AreaContainer from './container/Area'
import AppConfig from './config'
import {
  Settings as SettingsView,
  SignUp as SignUpView,
  NotFound as NotFoundView,
} from './views'

const Routes = ({ setSocketMessage }) => {
  useEffect(() => {
    // Integrate socket
    window.isSocketEnabled = setInterval(() => {
      if (window.io && window.io.sails) {
        clearInterval(window.isSocketEnabled)
        window.io.sails.url = AppConfig.endpoint.api
        const mySocket = window.io.sails.connect()
        mySocket.on('connect', () => {
          mySocket.on('message', (body) => {
            // Send messsage from server: sails.sockets.broadcast([], { greeting: 'Hola!' })
            // console.log(body)
            /**
             * body:
             * - from
             * - to
             * - owner (optional): id of owner's message
             * - type: ORDER | CUSTOMER | ...
             * - message: NEW | PAID | ...
             */
            const {
              to,
            } = body
            if (to === 'admin') {
              setSocketMessage(body)
            }
          })
          mySocket.on('disconnect', () => {
            // eslint-disable-next-line no-underscore-dangle
            mySocket._raw.io._reconnection = true
          })
        })
      }
    }, 500)
    return () => {
    }
  }, [])

  return (
    <Switch>
      <Redirect
        exact
        from="/"
        to="/dashboard"
      />
      <RouteWithLayout
        component={DashboardContainer}
        exact
        layout={MainLayout}
        path="/dashboard"
      />
      <RouteWithLayout
        component={StaffContainer}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        component={StaffFormContainer}
        exact
        layout={MainLayout}
        path="/user/create"
      />
      <RouteWithLayout
        component={StaffFormContainer}
        exact
        layout={MainLayout}
        path="/user/:id/:action"
      />
      <RouteWithLayout
        component={ReportContainer}
        exact
        layout={MainLayout}
        path="/report"
      />
      <RouteWithLayout
        component={OrderReportContainer}
        exact
        layout={MainLayout}
        path="/report-order"
      />
      <RouteWithLayout
        component={RoomReportContainer}
        exact
        layout={MainLayout}
        path="/report-room"
      />
      <RouteWithLayout
        component={ServiceReportContainer}
        exact
        layout={MainLayout}
        path="/report-service"
      />
      <RouteWithLayout
        component={CustomerReportPage}
        exact
        layout={MainLayout}
        path="/report-customer"
      />
      <RouteWithLayout
        component={HostReportPage}
        exact
        layout={MainLayout}
        path="/report-host"
      />
      <RouteWithLayout
        component={StaffFormContainer}
        exact
        layout={MainLayout}
        path="/user/:id/:action"
      />
      <RouteWithLayout
        component={ReservationContainer}
        exact
        layout={MainLayout}
        path="/reservations"
      />
      <RouteWithLayout
        component={ReservationFormContainer}
        exact
        layout={MainLayout}
        path="/reservation/create"
      />
      <RouteWithLayout
        component={ReservationFormContainer}
        exact
        layout={MainLayout}
        path="/reservation/:id"
      />
      <RouteWithLayout
        component={ReservationFormContainer}
        exact
        layout={MainLayout}
        path="/reservation/:id/:action"
      />
      <RouteWithLayout
        component={AreaContainer}
        exact
        layout={MainLayout}
        path="/area"
      />
      <RouteWithLayout
        component={RoomContainer}
        exact
        layout={MainLayout}
        path="/room"
      />
      <RouteWithLayout
        component={RoomFormContainer}
        exact
        layout={MainLayout}
        path="/room/create"
      />
      <RouteWithLayout
        component={RoomFormContainer}
        exact
        layout={MainLayout}
        path="/room/:id/:action"
      />
      <RouteWithLayout
        component={ServiceContainer}
        exact
        layout={MainLayout}
        path="/service"
      />
      <RouteWithLayout
        component={ServiceFormContainer}
        exact
        layout={MainLayout}
        path="/service/create"
      />
      <RouteWithLayout
        component={ServiceFormContainer}
        exact
        layout={MainLayout}
        path="/service/:id/:action"
      />
      <RouteWithLayout
        component={CustomerContainer}
        exact
        layout={MainLayout}
        path="/customer"
      />
      <RouteWithLayout
        component={CustomerFormContainer}
        exact
        layout={MainLayout}
        path="/customer/create"
      />
      <RouteWithLayout
        component={CustomerFormContainer}
        exact
        layout={MainLayout}
        path="/customer/:id/:action"
      />
      <RouteWithLayout
        component={HostContainer}
        exact
        layout={MainLayout}
        path="/host"
      />
      <RouteWithLayout
        component={HostFormContainer}
        exact
        layout={MainLayout}
        path="/host/create"
      />
      <RouteWithLayout
        component={HostFormContainer}
        exact
        layout={MainLayout}
        path="/host/:id/:action"
      />
      <RouteWithLayout
        component={CouponContainer}
        exact
        layout={MainLayout}
        path="/coupon"
      />
      <RouteWithLayout
        component={CouponFormContainer}
        exact
        layout={MainLayout}
        path="/coupon/create"
      />
      <RouteWithLayout
        component={CouponFormContainer}
        exact
        layout={MainLayout}
        path="/coupon/:id/:action"
      />
      <RouteWithLayout
        component={InvoiceContainer}
        exact
        layout={MainLayout}
        path="/invoice"
      />
      <RouteWithLayout
        component={Amenity}
        exact
        layout={MainLayout}
        path="/amenity"
      />
      <RouteWithLayout
        component={PrepaidHistoryContainer}
        exact
        layout={MainLayout}
        path="/prepaid-history"
      />
      <RouteWithLayout
        component={AccountContainer}
        exact
        layout={MainLayout}
        path="/account"
      />
      <RouteWithLayout
        component={SettingsView}
        exact
        layout={MainLayout}
        path="/settings"
      />
      <RouteWithLayout
        component={SignUpView}
        exact
        layout={MinimalLayout}
        path="/sign-up"
      />
      <RouteWithLayout
        component={SigninContainer}
        exact
        layout={MinimalLayout}
        path="/sign-in"
      />
      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  )
}

const mapDispatchToProps = dispatch => ({
  setSocketMessage: data => dispatch(setMessage(data)),
})

Routes.propTypes = {
  setSocketMessage: PropTypes.func,
}

export default connect(getSocketData, mapDispatchToProps)(Routes)
