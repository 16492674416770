/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  withStyles,
  Grid,
  Typography,
  Button,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import {
  create,
  update,
  findOne,
  recalculate,
} from 'provider/reservation'
import { getCalculatedArray } from 'utils/service'
import moment from 'moment'
import BackgroundBackdrop from 'components/Backdrop'
import { useSnackbar } from 'notistack'
import uploadApi from 'provider/upload'
import * as reservationApi from 'provider/reservation'
import * as serviceApi from 'provider/service'
import { isArray, last } from 'underscore'
import { formatPrice } from 'utils/formatter'
import AppConfig from 'config'
import PaymentDialog from 'views/ReservationForm/Dialog/Payment'
import RejectDialog from 'views/ReservationForm/Dialog/Reject'
import UndoDialog from 'views/ReservationForm/Dialog/Undo'
import PunishDepositDialog from 'views/ReservationForm/Dialog/PunishDeposit'
import ConfirmDialog from 'views/ReservationForm/Dialog/Confirm'
import RefundDialog from 'views/ReservationForm/Dialog/Refund'
import AddCheckoutPointDialog from 'views/ReservationForm/Dialog/AddCheckoutPoint'
import { PAYMENT_TYPE } from 'contants'
import reservationFormStyle from './styled'
import General from './General'
import BookingInfo from './BookingInfo'
import CustomerInfo from './CustomerInfo'
import PriceInfo from './PriceInfo'
import Description from './Description'
import PaymentInfo from './PaymentInfo'
import DetailInfoDialog from './Dialog/DetailInfoDialog'
import History from './History'

const validateSchema = Yup.object().shape({
  receiverName: Yup.string()
    .required('* Thông tin khách hàng không được để trống'),
  receiverEmail: Yup.string()
    .email()
    .required('* Email khách hàng không được để trống'),
  receiverPhone: Yup.string()
    .required('* Số điện thoại khách hàng không được để trống'),
  customer: Yup.string(),
  guest: Yup.number()
    .required('* Số khách không được để trống'),
  checkin: Yup.number()
    .typeError('* Giờ checkin không được để trống')
    .required('* Giờ checkin không được để trống'),
  checkout: Yup.number()
    .typeError('* Giờ checkout không được để trống')
    .required('* Giờ checkout không được để trống')
    .when('checkin', (checkin, schema) => checkin ? schema.min(checkin, '* Giờ checkout không hợp lệ') : schema),
  room: Yup.string().required('* Thông tin phòng không được để trống'),
  host: Yup.string(),
  services: Yup.array(),
  coupon: Yup.string().nullable(),
  note: Yup.string(),
  extraInfo: Yup.object(),
  payment: Yup.string(),
  useServiceAt: Yup.number()
    .when('checkin', (checkin, schema) => checkin ? schema.min(checkin, '* Giờ sử dụng dịch vụ không hợp lệ') : schema)
    .when('checkout', (checkout, schema) => checkout ? schema.max(checkout, '* Giờ sử dụng dịch vụ không hợp lệ') : schema),
  roomCheckin: Yup.number()
    .when('useServiceAt', (useServiceAt, schema) => useServiceAt ? schema.max(useServiceAt, '* Giờ 2home nhận phòng không hợp lệ') : schema),
})

const initCheckin = moment().minute() >= 30 ? moment().add(1, 'hour').set({ minute: 0, second: 0, millisecond: 0 }).valueOf() : moment().set({ minute: 30, second: 0, millisecond: 0 }).valueOf()
const initCheckout = moment(initCheckin).add(2, 'hour').valueOf()

const initReservation = {
  receiverName: '',
  receiverEmail: '',
  receiverPhone: '',
  customer: '',
  guest: 2,
  checkin: initCheckin,
  checkout: initCheckout,
  room: '',
  host: '',
  services: [],
  coupon: '',
  note: '',
  extraInfo: {}, // [{service, serviceOption, value}]
  payment: '',
  payOnBehaft: [],
  limitRoom: false,
  depositWith: undefined,
  depositAmount: 0,
}

const ReservationForm = ({
  classes,
  history,
  id,
  action,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [editable, setEditable] = useState(true)
  const [allServices, setAllServices] = useState([])
  const [sltRoom, setSltRoom] = useState(null)
  const [reservation, setReservation] = useState(initReservation)
  const [sltServices, setSltServices] = useState([])
  const [roomPrice, setRoomPrice] = useState([])
  const [couponData, setCouponData] = useState(null)
  const [loading, setLoading] = useState(false)
  const [customer, setCustomer] = useState(null)
  const [paymentType, setPaymentType] = useState('2home')
  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const [hasRefund, setHasRefund] = useState(false)
  const [openRefundDialog, setOpenRefundDialog] = useState(false)
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [orderData, setOrderData] = useState(null)
  const [warningUpdateText, setWarningUpdateText] = useState('')
  const [showWarningUpdate, setShowWarningUpdate] = useState(false)
  const [showOrderInfoDialog, setShowOrderInfoDialog] = useState(false)
  const [openDepositDialog, setOpenDepositDialog] = useState(false)
  const [hasDepositPunish, setHasDepositPunish] = useState(true)
  const [openUndoDialog, setOpenUndoDialog] = useState(false)
  const [openAddTimeDialog, setOpenAddTimeDialog] = useState(false)

  const onRoomPriceRecalculate = async (room, checkin, checkout, guest, limitRoom, hasService) => {
    if (room && checkin && checkout && guest) {
      try {
        const resp = await recalculate({
          roomid: room,
          guest,
          starttime: checkin,
          endtime: checkout,
          limitRoom,
          hasService,
        })
        setRoomPrice(getCalculatedArray(resp))
      } catch (e) {
        console.log(e)
      }
    }
  }

  const onServiceSelected = (services) => {
    setSltServices(services)
  }

  // Fetch reservation if in updating mode
  const fetchReservation = async () => {
    if (!id) return
    setLoading(true)
    setReservation(initReservation)
    try {
      const resp = await findOne(id)
      const extraInfo = {}
      resp.extraInfo.forEach((item) => {
        if (!extraInfo[item.service]) {
          extraInfo[item.service] = {
            [item.serviceOption]: item.value,
          }
        } else {
          const opt = resp.services?.find((s) => s.id === item.service)?.options?.find((o) => o.id === item.serviceOption)
          if (opt?.name === 'attach') {
            extraInfo[item.service][item.serviceOption] = item.value?.map((url) => ({ isLink: true, file: url }))
          } else if (opt?.name === 'combo') {
            if (isArray(item.value)) {
              extraInfo[item.service][item.serviceOption] = item.value?.map((url) => ({ isLink: true, file: url }))
            } else {
              extraInfo[item.service][item.serviceOption] = item.value
            }
          } else {
            extraInfo[item.service][item.serviceOption] = item.value
          }
        }
      })
      const prevExtraInfo = {}
      if (resp.prevState && resp.prevState.extraInfo) {
        resp.prevState.extraInfo.forEach((item) => {
          if (!prevExtraInfo[item.service]) {
            prevExtraInfo[item.service] = {
              [item.serviceOption]: item.value,
            }
          } else {
            const opt = resp.prevState.services?.find((s) => s.id === item.service)?.options?.find((o) => o.id === item.serviceOption)
            if (opt?.name === 'attach') {
              prevExtraInfo[item.service][item.serviceOption] = item.value?.map((url) => ({ isLink: true, file: url }))
            } else if (opt?.name === 'combo') {
              if (isArray(item.value)) {
                prevExtraInfo[item.service][item.serviceOption] = item.value?.map((url) => ({ isLink: true, file: url }))
              } else {
                prevExtraInfo[item.service][item.serviceOption] = item.value
              }
            } else {
              prevExtraInfo[item.service][item.serviceOption] = item.value
            }
          }
        })
      }
      setReservation({
        ...resp,
        id: resp._id || resp.id,
        room: resp.room?._id || resp.room?.id,
        host: resp.host?._id || resp.host?.id,
        services: resp.services?.map((s) => s._id || s.id),
        customer: resp.customer?._id || resp.customer?.id,
        coupon: resp.coupon?.code || '',
        couponInfo: resp.coupon,
        extraInfo,
        prevExtraInfo,
        note: resp.note || undefined,
        roomInfo: resp.room,
        serviceInfo: resp.services,
        depositWith: resp.depositWith || undefined,
        depositAmount: resp.depositAmount || 0,
      })
      setCustomer(resp.customer)
      setCouponData(resp.coupon)
      setRoomPrice(getCalculatedArray(resp.roomPriceInfo?.data))
      setLoading(false)
    } catch (e) {
      console.log(e)
      enqueueSnackbar(`${e.response?.data}(code: ${e.response ? e.response.status : 'Unknown'})`, { variant: 'error' })
      setLoading(false)
    }
  }

  // Fetch service
  const fetchService = async () => {
    try {
      const resp = await serviceApi.find({ limit: Number.MAX_SAFE_INTEGER, active: 1 })
      setAllServices(
        resp.data.map((item) => ({
          value: item.id,
          label: item.name,
          ...item,
        })),
      )
    } catch (e) {
      // console.log(e)
    }
  }

  useEffect(() => {
    fetchService()
    return () => {
    }
  }, [action])

  useEffect(() => {
    if ((!action || ['update', 'view'].includes(action)) && id) {
      fetchReservation()
    }
  }, [action, id])

  useEffect(() => {
    if (action === 'view'
      || (id && ![
        'processing',
        'confirmed',
        'paid',
        'using',
        'completed',
        'lock',
        'processing-pay-extra',
        'waiting-refund-in-update',
        'processing-refund-in-update',
        'host-confirm-refund-update',
        'confirmed-pay-extra',
      ].includes(reservation.status))) {
      setEditable(false)
    } else {
      setEditable(true)
    }
    return () => {
    }
  }, [reservation, action])

  const renderStatus = () => {
    const {
      refundRoom,
      refundService,
      addCheckoutPoint,
      depositStatus,
      depositWith,
      depositAmount,
      checkout,
    } = reservation
    const lastCheckoutPoint = last(addCheckoutPoint || [])
    switch (reservation.status) {
      case 'reject':
        return (
          <Chip
            classes={{
              label: classes.status,
            }}
            style={{
              backgroundColor: '#999999',
            }}
            label="Huỷ"
          />
        )
      case 'reject-by-host':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#999999',
            }}
            label="Hết phòng"
          />
        )
      case 'lock':
        return (
          <Chip
            classes={{
              label: classes.status,
            }}
            style={{
              backgroundColor: '#999999',
            }}
            label="Khoá phòng"
          />
        )
      case 'reject-waiting':
        return (
          <Chip
            classes={{
              label: classes.status,
            }}
            style={{
              backgroundColor: '#999999',
            }}
            label="Chờ cung cấp thông tin"
          />
        )
      case 'reject-norefund':
        return (
          <Chip
            classes={{
              label: classes.status,
            }}
            style={{
              backgroundColor: '#999999',
            }}
            label="Huỷ không hoàn tiền"
          />
        )
      case 'reject-refund':
        return (
          <Chip
            classes={{
              label: classes.status,
            }}
            style={{
              backgroundColor: '#35a211',
            }}
            label="Huỷ hoàn tiền"
          />
        )
      case 'reject-processing':
        return (
          <Chip
            classes={{
              label: classes.status,
            }}
            style={{
              backgroundColor: '#ffc107',
            }}
            label="Huỷ chờ hoàn tiền"
          />
        )
      case 'processing':
        return (
          <Chip
            classes={{
              label: classes.status,
            }}
            style={{
              backgroundColor: '#ffa854',
            }}
            label="Chờ xác nhận"
          />
        )
      case 'confirmed':
        return (
          <Chip
            classes={{
              label: classes.status,
            }}
            style={{
              backgroundColor: '#ff1744',
            }}
            label="Chờ thanh toán"
          />
        )
      case 'paid':
        // if (refundRoom + refundService <= 0) {
        //   return (
        //     <Chip
        //       classes={{
        //         label: classes.status,
        //       }}
        //       style={{
        //         backgroundColor: '#00b0ff',
        //       }}
        //       label="Đã thanh toán chỉnh sửa"
        //     />
        //   )
        // }
        // if (refundRoom + refundService > 0) {
        //   return (
        //     <Chip
        //       classes={{
        //         label: classes.status,
        //       }}
        //       style={{
        //         backgroundColor: '#00b0ff',
        //       }}
        //       label="Đã hoàn tiền chỉnh sửa"
        //     />
        //   )
        // }
        return (
          <Chip
            classes={{
              label: classes.status,
            }}
            style={{
              backgroundColor: '#00b0ff',
            }}
            label="Đã thanh toán"
          />
        )
      case 'using':
        return (
          <>
            <Chip
              classes={{
                label: classes.status,
              }}
              style={{
                backgroundColor: addCheckoutPoint?.filter((item) => item.status === 'paid')?.length ? '#0052cc' : '#00e676',
              }}
              label={addCheckoutPoint?.filter((item) => item.status === 'paid')?.length ? 'Đang ở thêm' : 'Đang sử dụng'}
            />
            &nbsp;
            {
              addCheckoutPoint?.filter((item) => item.status === 'paid')?.length
                ? (
                  <Chip
                    classes={{
                      label: classes.status,
                    }}
                    style={{
                      backgroundColor: '#0052cc',
                    }}
                    label="Đã thanh toán chỉnh sửa"
                  />
                )
                : null
            }
          </>
        )
      case 'completed':
        return depositWith === '2home' && depositAmount > 0 && checkout < moment().valueOf() && !['none', 'release'].includes(depositStatus)
          ? (
            <>
              {/* Yêu cầu phạt cọc */}
              {
                depositStatus === 'hold'
                  ? (
                    <Chip
                      size="small"
                      style={{
                        color: 'white',
                        fontSize: 12,
                        fontWeight: '900',
                        backgroundColor: '#3e51b5',
                      }}
                      label="Chờ xác nhận hoàn cọc"
                    />
                  )
                  : null
              }
              {/* Đồng ý yêu cầu phạt cọc */}
              {
                depositStatus === 'customer-complain'
                  ? (
                    <Chip
                      size="small"
                      style={{
                        color: 'white',
                        fontSize: 12,
                        fontWeight: '900',
                        backgroundColor: '#3e51b5',
                      }}
                      label="Khách gửi yêu cầu phạt cọc"
                    />
                  )
                  : null
              }
              {
                ['host-complain'].includes(depositStatus)
                  ? (
                    <Chip
                      size="small"
                      style={{
                        color: 'white',
                        fontSize: 12,
                        fontWeight: '900',
                        backgroundColor: '#3e51b5',
                      }}
                      label="Chủ nhà yêu cầu phạt cọc"
                    />
                  )
                  : null
              }
              {
                ['2home-handle'].includes(depositStatus)
                  ? (
                    <Chip
                      size="small"
                      style={{
                        color: 'white',
                        fontSize: 12,
                        fontWeight: '900',
                        backgroundColor: '#3e51b5',
                      }}
                      label="2home đang xử lý cọc"
                    />
                  )
                  : null
              }
              {
                ['waiting-release'].includes(depositStatus)
                  ? (
                    <Chip
                      size="small"
                      style={{
                        color: 'white',
                        fontSize: 12,
                        fontWeight: '900',
                        backgroundColor: '#3e51b5',
                      }}
                      label="Chờ khách cung cấp thông tin hoàn cọc"
                    />
                  )
                  : null
              }
              {
                ['processing-release'].includes(depositStatus)
                  ? (
                    <Chip
                      size="small"
                      style={{
                        color: 'white',
                        fontSize: 12,
                        fontWeight: '900',
                        backgroundColor: '#3e51b5',
                      }}
                      label="Khách chờ hoàn cọc"
                    />
                  )
                  : null
              }
            </>
          )
          : (
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#3e51b5',
              }}
              label="Hoàn thành"
            />
          )
      case 'expired':
        return (
          <Chip
            classes={{
              label: classes.status,
            }}
            style={{
              backgroundColor: '#9e9e9e',
            }}
            label="Hết hạn"
          />
        )
      case 'processing-pay-extra':
        return (
          <>
            <Chip
              classes={{
                label: classes.status,
              }}
              style={{
                backgroundColor: '#00b0ff',
              }}
              label="Đã thanh toán"
            />
            &nbsp;
            <Chip
              classes={{
                label: classes.status,
              }}
              style={{
                backgroundColor: '#ffa854',
              }}
              label="Chờ xác nhận sau chỉnh sửa"
            />
          </>
        )
      case 'confirmed-pay-extra':
        return (
          <Chip
            classes={{
              label: classes.status,
            }}
            style={{
              backgroundColor: '#ff1744',
            }}
            label="Chờ thanh toán chỉnh sửa"
          />
        )
      case 'processing-refund-in-update':
        return (
          <>
            <Chip
              classes={{
                label: classes.status,
              }}
              style={{
                backgroundColor: '#00b0ff',
              }}
              label="Đã thanh toán"
            />
            &nbsp;
            <Chip
              classes={{
                label: classes.status,
              }}
              style={{
                backgroundColor: '#52c41a',
              }}
              label={refundRoom + refundService > 0 ? 'Chờ hoàn tiền chỉnh sửa' : 'Chờ xác nhận'}
            />
          </>
        )
      case 'waiting-refund-in-update':
      case 'host-confirm-refund-update':
        return (
          <>
            <Chip
              classes={{
                label: classes.status,
              }}
              style={{
                backgroundColor: '#00b0ff',
              }}
              label="Đã thanh toán"
            />
            &nbsp;
            <Chip
              classes={{
                label: classes.status,
              }}
              style={{
                backgroundColor: '#52c41a',
              }}
              label="Chờ hoàn tiền chỉnh sửa"
            />
          </>
        )
      case 'add-checkout-processing':
        if (last(addCheckoutPoint)?.checkout < moment().valueOf()) {
          return (
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#3e51b5',
              }}
              label="Hoàn thành"
            />
          )
        }
        return (
          <>
            <Chip
              size="small"
              classes={{
                label: classes.status,
              }}
              style={{
                backgroundColor: '#00e676',
              }}
              label="Đang sử dụng"
            />
            &nbsp;
            {lastCheckoutPoint?.status === 'processing' && (
              <Chip
                size="small"
                classes={{
                  label: classes.status,
                }}
                style={{
                  backgroundColor: '#41cc78',
                }}
                label="Chờ xác nhận ở thêm"
              />
            )}
            &nbsp;
            {lastCheckoutPoint?.status === 'confirmed' && (
              <Chip
                size="small"
                classes={{
                  label: classes.status,
                }}
                style={{
                  backgroundColor: '#41CC78',
                }}
                label="Chờ thanh toán ở thêm"
              />
            )}
          </>
        )
      default:
        return null
    }
  }

  const renderTitle = () => {
    switch (action) {
      case 'update':
        return `Cập nhật thông tin đơn đặt phòng ${id}`
      case 'view':
        return `Thông tin đơn đặt phòng ${id}`
      default:
        return 'Thêm mới đơn đặt phòng'
    }
  }

  const onSubmit = async (order) => {
    setShowWarningUpdate(false)
    const submitData = {
      ...order,
      receiverName: order?.receiverName || '',
      receiverPhone: order?.receiverPhone || '',
      receiverEmail: order?.receiverEmail || '',
      refundBankName: order?.refundBankName || '',
      refundBankAccount: order?.refundBankAccount || '',
      refundUser: order?.refundUser || '',
      purpose: order?.purpose || '',
      useServiceAt: order?.services && order?.services.length ? order?.useServiceAt : undefined,
      roomCheckin: order?.services && order?.services.length ? order?.roomCheckin > order?.checkin ? order?.checkin : order?.roomCheckin : undefined,
      roomCheckout: order?.services && order?.services.length ? order?.roomCheckout : undefined,
    }
    if (order.services?.length && (order.checkin > order.useServiceAt || order.checkout < order.useServiceAt)) {
      enqueueSnackbar('Thời điểm sử dụng dịch vụ không hợp lệ', { variant: 'error' })
      return
    }
    if (hasRefund && paymentType === 'bank-transfer' && (!order.refundUser || !order.refundBankAccount || !order.refundBankName)) {
      enqueueSnackbar('Thiếu thông tin tài khoản ngân hàng hoàn tiền', { variant: 'error' })
      return
    }
    if (submitData.extraInfo && Object.keys(submitData.extraInfo).length) {
      // Create/update order
      setLoading(true)
      try {
        let resp = null
        const flow = []
        switch (action) {
          case 'update':
            // Upload extra info images
            Object.keys(submitData.extraInfo).forEach((s) => {
              if (!submitData.extraInfo[s]) return
              Object.keys(submitData.extraInfo[s]).forEach(async (o) => {
                if (o === 'type') return
                flow.push(new Promise(async (resolve, reject) => {
                  let attachData = []
                  try {
                    if (Array.isArray(submitData.extraInfo[s][o]) && submitData.extraInfo[s][o].length && Object.keys(submitData.extraInfo[s][o][0]).includes('isLink')) {
                      const needToUpload = []
                      const noUpload = []
                      submitData.extraInfo[s][o].forEach((item) => {
                        if (!item.isLink) {
                          needToUpload.push(item.file)
                        } else {
                          noUpload.push(item.file)
                        }
                      })
                      const servicePhotoAttach = await uploadApi.uploadImage(needToUpload, `Customer/${customer?.code}/Reservation/${submitData.code}`)
                      attachData = [...servicePhotoAttach.map((item) => item.shortUrl), ...noUpload]
                    }
                    resolve({
                      service: s,
                      serviceOption: o,
                      value: attachData.length ? attachData : submitData.extraInfo[s][o],
                    })
                  } catch (e) {
                    reject(e)
                  }
                }))
              })
            })
            Promise.all(flow).then(async (extraInfoData) => {
              resp = await update(submitData.id, { ...submitData, extraInfo: extraInfoData, paymentType })
              setLoading(false)
              enqueueSnackbar('Cập nhật đơn đặt phòng thành công.', { variant: 'success' })
              history.push(`/reservation/${resp.code}/view`)
            }).catch((err) => {
              setLoading(false)
              enqueueSnackbar(`Cập nhật đơn đặt phòng không thành công. ${err?.response ? err.response?.data : err}`, { variant: 'error' })
            })
            break
          default:
            const {
              extraInfo,
              ...rest
            } = submitData
            resp = await create(rest)
            // Upload extra info images
            Object.keys(extraInfo).forEach((s) => {
              if (!extraInfo[s]) return
              Object.keys(extraInfo[s]).forEach(async (o) => {
                if (o === 'type') return
                flow.push(new Promise(async (resolve, reject) => {
                  let attachData = []
                  try {
                    if (Array.isArray(extraInfo[s][o]) && extraInfo[s][o].length && Object.keys(extraInfo[s][o][0]).includes('isLink')) {
                      const needToUpload = []
                      const noUpload = []
                      extraInfo[s][o].forEach((item) => {
                        if (!item.isLink) {
                          needToUpload.push(item.file)
                        } else {
                          noUpload.push(item.file)
                        }
                      })
                      const servicePhotoAttach = await uploadApi.uploadImage(needToUpload, `Customer/${resp.customer?.code}/Reservation/${resp.code}`)
                      attachData = [...servicePhotoAttach.map((item) => item.shortUrl), ...noUpload]
                    }
                    resolve({
                      service: s,
                      serviceOption: o,
                      value: attachData.length ? attachData : extraInfo[s][o],
                    })
                  } catch (e) {
                    reject(e)
                  }
                }))
              })
            })
            Promise.all(flow).then(async (extraInfoData) => {
              await update(resp.id, { ...rest, extraInfo: extraInfoData, noEmail: true })
              setLoading(false)
              enqueueSnackbar('Thêm mới đơn đặt phòng thành công.', { variant: 'success' })
              history.push(`/reservation/${resp.code}/view`)
            }).catch((err) => {
              setLoading(false)
              enqueueSnackbar(`Thêm mới đơn đặt phòng không thành công. ${err?.response ? err.response?.data : err}`, { variant: 'error' })
            })
        }
      } catch (e) {
        // console.log(e.response)
        fetchReservation()
        setLoading(false)
        enqueueSnackbar(`${e.response?.data}(code: ${e.response ? e.response.status : 'Unknown'})`, { variant: 'error', autoHideDuration: 5000 })
      }
    } else {
      // Create/update order
      setLoading(true)
      try {
        let resp = null
        switch (action) {
          case 'update':
            resp = await update(submitData.id, { ...submitData, paymentType })
            setLoading(false)
            enqueueSnackbar('Cập nhật đơn đặt phòng thành công.', { variant: 'success' })
            history.push(`/reservation/${resp.code}/view`)
            break
          default:
            resp = await create(submitData)
            setLoading(false)
            enqueueSnackbar('Thêm mới đơn đặt phòng thành công.', { variant: 'success' })
            history.push(`/reservation/${resp.code}/view`)
        }
      } catch (e) {
        console.log(e)
        fetchReservation()
        setLoading(false)
        enqueueSnackbar(`${e.response?.data} (code: ${e.response ? e.response.status : 'Unknown'})`, { variant: 'error', autoHideDuration: 5000 })
      }
    }
  }

  const getWarningUpdate = async (order) => {
    setOrderData(order)
    // Kiểm tra đơn đặt phòng và hiển thị thông báo cho khách hàng
    let warning = null
    if (order.code) {
      warning = await reservationApi.warning(order.code, 'edit', {
        checkin: order.checkin,
        checkout: order.checkout,
        guest: order.guest,
        services: order.services,
      })
    }
    if (warning && warning.message) {
      setWarningUpdateText(`${warning.message}`)
      setHasRefund(warning.refund)
      setShowWarningUpdate(true)
    } else if (order.code) {
      setWarningUpdateText('')
      setHasRefund(false)
      setShowWarningUpdate(true)
    } else {
      onSubmit(order)
    }
  }

  const confirmReservation = async (extraPaymentInfo) => {
    const {
      addCheckoutPoint,
    } = reservation || {}
    const lastCheckoutPoint = last(addCheckoutPoint || [])
    try {
      switch (reservation.status) {
        case 'add-checkout-processing':
          if (lastCheckoutPoint?.status === 'processing') {
            await reservationApi.confirmCheckoutPoint(reservation.id)
          }
          if (lastCheckoutPoint?.status === 'confirmed') {
            await reservationApi.payCheckoutPoint(reservation.id, extraPaymentInfo)
          }
          break

        default:
          await reservationApi.confirm(reservation.id)
          break
      }
      enqueueSnackbar('Xác nhận đơn đặt phòng thành công.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    } catch (error) {
      enqueueSnackbar('Xác nhận đơn đặt phòng không thành công.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
    setOpenConfirmDialog(false)
    fetchReservation()
  }

  const undoReservation = async () => {
    try {
      switch (reservation.status) {
        case 'add-checkout-processing':
          await reservationApi.rejectCheckout(reservation.id)
          break

        default:
          await reservationApi.undo(reservation.id)
          break
      }
      enqueueSnackbar('Huỷ thay đổi đơn đặt phòng thành công.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      setOpenUndoDialog(false)
      fetchReservation()
    } catch (error) {
      enqueueSnackbar('Huỷ thay đổi đơn đặt phòng không thành công.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
  }

  // Add các function còn thiếu (đặt ở thêm, huỷ chỉnh sửa)
  const getActions = (values) => {
    const {
      checkout,
      depositStatus,
      depositAmount,
      depositWith,
      addCheckoutPoint,
    } = reservation
    const lastCheckoutPoint = last(addCheckoutPoint || [])
    switch (action) {
      case 'view':
        switch (reservation.status) {
          case 'processing':
            return (
              <div className="d-flex justify-content-end">
                <Button
                  className="mx-1 w-100"
                  color="primary"
                  variant="contained"
                  onClick={() => history.push(`/reservation/${reservation.code}/update`)}
                >
                  Chỉnh sửa
                </Button>
                <Button
                  className="mx-1 w-100"
                  color="secondary"
                  variant="contained"
                  onClick={() => setOpenConfirmDialog(true)}
                >
                  Xác nhận
                </Button>
                <Button
                  className="mx-1 w-100"
                  style={{ backgroundColor: 'red', color: 'white' }}
                  variant="contained"
                  onClick={() => setOpenRejectDialog(true)}
                >
                  Huỷ đơn
                </Button>
              </div>
            )
          case 'processing-pay-extra': // Chờ xác nhận đơn trước khi thanh toán thêm
          case 'waiting-refund-in-update': // Chờ hoàn tiền vào tk ngân hàng sau khi chỉnh sửa
          case 'processing-refund-in-update': // Chờ xác nhận đơn trước khi hoàn tiền cho khách
            return (
              <div className="d-flex justify-content-end">
                <Button
                  className="mx-1 w-100"
                  color="primary"
                  variant="contained"
                  onClick={() => history.push(`/reservation/${reservation.code}/update`)}
                >
                  Chỉnh sửa
                </Button>
                <Button
                  className="mx-1 w-100"
                  color="secondary"
                  variant="contained"
                  onClick={() => setOpenConfirmDialog(true)}
                >
                  Xác nhận
                </Button>
                <Button
                  className="mx-1 w-100"
                  style={{ backgroundColor: 'red', color: 'white' }}
                  variant="contained"
                  onClick={() => setOpenUndoDialog(true)}
                >
                  Hủy thay đổi
                </Button>
              </div>
            )
          case 'host-confirm-refund-update':
            return (
              <div className="d-flex justify-content-end">
                <Button
                  className="mx-1 w-100"
                  color="secondary"
                  variant="contained"
                  onClick={() => setOpenConfirmDialog(true)}
                >
                  Xác nhận
                </Button>
              </div>
            )
          case 'confirmed':
          case 'confirmed-pay-extra': // Đơn chỉnh sửa đã được xác nhận, chờ thanh toán thêm
            return (
              <div className="d-flex justify-content-end">
                <Button
                  className="mx-1 w-100"
                  color="primary"
                  variant="contained"
                  onClick={() => history.push(`/reservation/${reservation.code}/update`)}
                >
                  Chỉnh sửa
                </Button>
                <Button
                  className="mx-1 w-100"
                  color="secondary"
                  variant="contained"
                  onClick={() => setOpenPaymentDialog(true)}
                >
                  Thanh toán
                </Button>
                <Button
                  className="mx-1 w-100"
                  style={{ backgroundColor: 'red', color: 'white' }}
                  variant="contained"
                  onClick={() => setOpenRejectDialog(true)}
                >
                  {reservation.status === 'confirmed-pay-extra' ? 'Huỷ thay đổi' : 'Huỷ đơn'}
                </Button>
              </div>
            )
          case 'add-checkout-processing':
            // if (lastCheckoutPoint?.checkout < moment().valueOf()) {
            //   return null
            // }
            if (lastCheckoutPoint?.status === 'processing') {
              return (
                <div className="d-flex justify-content-end">
                  {/* Xác nhận */}
                  <Button
                    className="mx-1 w-100"
                    color="secondary"
                    variant="contained"
                    onClick={() => setOpenConfirmDialog(true)}
                  >
                    Xác nhận ở thêm
                  </Button>
                  {/* Tù chối */}
                  <Button
                    className="mx-1 w-100"
                    style={{ backgroundColor: 'red', color: 'white' }}
                    variant="contained"
                    onClick={() => setOpenUndoDialog(true)}
                  >
                    Từ chối ở thêm
                  </Button>
                </div>
              )
            }
            if (lastCheckoutPoint?.status === 'confirmed') {
              return (
                <div className="d-flex justify-content-end">
                  {/* Xác nhận */}
                  <Button
                    className="w-100"
                    color="secondary"
                    variant="contained"
                    onClick={() => setOpenConfirmDialog(true)}
                  >
                    Xác nhận thanh toán ở thêm
                  </Button>
                  {/* Huỷ */}
                  <Button
                    className="mx-1 w-100"
                    style={{ backgroundColor: 'red', color: 'white' }}
                    variant="contained"
                    onClick={() => setOpenUndoDialog(true)}
                  >
                    Từ chối ở thêm
                  </Button>
                </div>
              )
            }
            return (
              <div className="d-flex justify-content-end">
                <Button
                  className="mx-1 w-100"
                  color="secondary"
                  variant="contained"
                  onClick={() => setOpenConfirmDialog(true)}
                >
                  Xác nhận ở thêm
                </Button>
              </div>
            )
          case 'completed':
            return (
              <div className="d-flex justify-content-end">
                <Button
                  className="mx-1 w-100"
                  style={{ backgroundColor: '#0052cc', color: 'white' }}
                  variant="contained"
                  onClick={() => setOpenAddTimeDialog(true)}
                >
                  Đặt ở thêm
                </Button>
                <Button
                  className="mx-1 w-100"
                  color="primary"
                  variant="contained"
                  onClick={() => history.push(`/reservation/${reservation.code}/update`)}
                >
                  Chỉnh sửa
                </Button>
                {
                  depositWith === '2home' && depositAmount > 0 && checkout < moment().valueOf() && !['none', 'release'].includes(depositStatus)
                    ? (
                      <>
                        {/* Yêu cầu phạt cọc */}
                        {depositStatus === 'hold' ? (
                          <>
                            <Button
                              className="mx-1 w-100"
                              variant="contained"
                              onClick={() => setOpenDepositDialog(true)}
                              style={{ backgroundColor: '#cc41c4', color: 'white' }}
                            >
                              Yêu cầu phạt cọc cho chủ nhà
                            </Button>
                            <Button
                              className="mx-1 w-100"
                              variant="contained"
                              onClick={() => {
                                setHasDepositPunish(false)
                                setOpenDepositDialog(true)
                              }}
                              style={{ backgroundColor: 'white', color: 'black' }}
                            >
                              Hoàn cọc 100%
                            </Button>
                          </>
                        ) : null}
                        {/* Đồng ý yêu cầu phạt cọc */}
                        {depositStatus === 'customer-complain' ? (
                          <Button
                            className="mx-1 w-100"
                            variant="contained"
                            onClick={() => setOpenDepositDialog(true)}
                            style={{ backgroundColor: '#cc41c4', color: 'white' }}
                          >
                            Xác nhận yêu cầu phạt cọc
                          </Button>
                        ) : null}
                        {depositStatus === 'host-complain' ? (
                          <Button
                            className="mx-1 w-100"
                            variant="text"
                            onClick={() => {
                              setOpenDepositDialog(true)
                            }}
                            style={{ backgroundColor: '#d5d5d5', color: 'black' }}
                          >
                            Đề xuất phạt cọc cho khách
                          </Button>
                        ) : null}
                        {depositStatus === '2home-handle' ? (
                          <Button
                            className="mx-1 w-100"
                            variant="text"
                            style={{ backgroundColor: '#d5d5d5', color: 'black' }}
                            onClick={() => setOpenDepositDialog(true)}
                          >
                            2home xử lý cọc
                          </Button>
                        ) : null}
                        {depositStatus === 'processing-release' ? (
                          <Button
                            className="mx-1 w-100"
                            variant="contained"
                            onClick={() => setOpenDepositDialog(true)}
                            style={{ backgroundColor: '#cc41c4', color: 'white' }}
                          >
                            Xác nhận hoàn cọc
                          </Button>
                        ) : null}
                        {depositStatus === 'waiting-release' ? (
                          <Button
                            className="mx-1 w-100"
                            variant="contained"
                            onClick={() => setOpenDepositDialog(true)}
                            style={{ backgroundColor: '#cc41c4', color: 'white' }}
                          >
                            Hoàn cọc giúp khách
                          </Button>
                        ) : null}
                      </>
                    )
                    : null
                }
                <Button
                  className="mx-1 w-100"
                  style={{ backgroundColor: 'red', color: 'white' }}
                  variant="contained"
                  onClick={() => {
                    setOpenRejectDialog(true)
                  }}
                >
                  Huỷ đơn
                </Button>
              </div>
            )
          case 'paid':
          case 'lock':
            return (
              <div className="d-flex justify-content-end">
                <Button
                  className="mx-1 w-100"
                  color="primary"
                  variant="contained"
                  onClick={() => history.push(`/reservation/${reservation.code}/update`)}
                >
                  Chỉnh sửa
                </Button>
                <Button
                  className="mx-1 w-100"
                  style={{ backgroundColor: 'red', color: 'white' }}
                  variant="contained"
                  onClick={() => setOpenRejectDialog(true)}
                >
                  Huỷ đơn
                </Button>
              </div>
            )
          case 'using':
            return (
              <div className="d-flex justify-content-end">
                <Button
                  className="mx-1 w-100"
                  style={{ backgroundColor: '#0052cc', color: 'white' }}
                  variant="contained"
                  onClick={() => setOpenAddTimeDialog(true)}
                >
                  Đặt ở thêm
                </Button>
                <Button
                  className="mx-1 w-100"
                  color="primary"
                  variant="contained"
                  onClick={() => history.push(`/reservation/${reservation.code}/update`)}
                >
                  Chỉnh sửa
                </Button>
                <Button
                  className="mx-1 w-100"
                  style={{ backgroundColor: 'red', color: 'white' }}
                  variant="contained"
                  onClick={() => {
                    setOpenRejectDialog(true)
                  }}
                >
                  Huỷ đơn
                </Button>
              </div>
            )
          case 'reject-waiting':
            return null && (
              <div className="d-flex justify-content-end">
                <Button
                  className="w-50"
                  style={{ backgroundColor: 'red', color: 'white' }}
                  variant="contained"
                  onClick={() => { }}
                >
                  Chọn hoàn tiền
                </Button>
              </div>
            )
          case 'reject-processing':
            return (
              <div className="d-flex justify-content-end">
                <Button
                  className="w-100"
                  color="primary"
                  variant="contained"
                  onClick={() => setOpenRefundDialog(true)}
                >
                  Xác nhận hoàn tiền
                </Button>
              </div>
            )
          case 'reject':
          case 'reject-by-host':
          case 'reject-refund':
          case 'reject-norefund':
          default:
            return null
        }
      case 'update':
        switch (reservation.status) {
          case 'processing':
          case 'confirmed':
          case 'paid':
          case 'lock':
          case 'processing-pay-extra':
          case 'waiting-refund-in-update':
          case 'processing-refund-in-update':
          case 'confirmed-pay-extra':
          case 'completed':
          case 'using':
            return (
              <div className="d-flex justify-content-end">
                <Button
                  className="mr-2 w-100"
                  color="primary"
                  variant="contained"
                  onClick={() => getWarningUpdate(values)}
                >
                  Cập nhật
                </Button>
                <Button
                  className="ml-2 w-100"
                  variant="contained"
                  onClick={() => history.push(`/reservation/${reservation.code}/view`)}
                >
                  Thoát
                </Button>
              </div>
            )
          case 'reject':
          case 'reject-by-host':
          case 'reject-processing':
          case 'reject-waiting':
          case 'reject-refund':
          case 'reject-norefund':
          default:
            return null
        }
      default:
        return (
          <div className="d-flex justify-content-end">
            <Button className="mr-1 w-50" color="primary" variant="contained" onClick={() => getWarningUpdate(values)}>
              Tạo đơn
            </Button>
          </div>
        )
    }
  }

  const rejectReservation = async (rejectUser, payment, user, bankAccount, bankName, note, images, suggestRooms) => {
    if (reservation.status === 'lock') {
      // Huỷ đơn
      await reservationApi.reject(reservation.id, '', {})
      setOpenRejectDialog(false)
      fetchReservation()
      return
    }
    if (rejectUser === 'customer' && (!images.length || (payment === 'bank-transfer' && (!user || !bankAccount || !bankName)))) {
      enqueueSnackbar('Huỷ đơn không thành công, yêu cầu cung cấp đầy đủ thông tin.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      return
    }
    // Upload image and add to reject apis
    let rejectImageUrls = []
    if (images.length) {
      rejectImageUrls = await uploadApi.uploadImage(images.filter((file) => !file.isLink).map((item) => item.file), `Customer/${customer?.code}/Reservation/${reservation?.code}`)
    }// Huỷ đơn
    await reservationApi.reject(reservation.id, rejectUser, {
      rejectNote: note,
      refundBankName: bankName,
      refundBankAccount: bankAccount,
      refundUser: user,
      paymentType: customer.email === 'customer@2home.vn' ? 'bank-transfer' : payment,
      rejectImage: rejectImageUrls.map((item) => item.shortUrl),
      sameroom: suggestRooms && suggestRooms.length ? suggestRooms?.map((r) => r.code)?.join(', ') : undefined,
    })
    setOpenRejectDialog(false)
    fetchReservation()
  }

  const refundReservation = async (bankNote) => {
    try {
      await reservationApi.refund(reservation.id, bankNote)
      enqueueSnackbar('Xác nhận hoàn tiền cho đơn đặt phòng thành công.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    } catch (error) {
      enqueueSnackbar('Xác nhận hoàn tiền cho đơn đặt phòng không thành công.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
    setOpenRefundDialog(false)
    fetchReservation()
  }

  const payReservation = async (payment, images) => {
    try {
      let paidRequestImageUrls = []
      // Upload ảnh bằng chứng khách hàng yêu cầu thanh toán bằng tk 2home
      if (payment === '2home') {
        paidRequestImageUrls = await uploadApi.uploadImage(images.map((item) => item.file), `Customer/${customer?.code}/Reservation/${reservation?.code}`)
      }
      await reservationApi.pay(reservation.id, payment, paidRequestImageUrls.map((item) => item.shortUrl))
      enqueueSnackbar('Thanh toán đơn đặt phòng thành công.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    } catch (error) {
      enqueueSnackbar('Thanh toán đơn đặt phòng không thành công.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
    setOpenPaymentDialog(false)
    fetchReservation()
  }

  const confirmPunishDeposit = async (customerAgree, hostAgree, punishDepositInfo, bankNote) => {
    switch (reservation?.depositStatus) {
      case 'hold':
        try {
          if (hasDepositPunish) {
            if (punishDepositInfo.images?.length) {
              const punishImageUrls = await uploadApi.uploadImage(punishDepositInfo.images.filter((file) => !file.isLink).map((item) => item.file), `Customer/${customer?.code}/Reservation/${reservation?.code}`)
              punishDepositInfo.images = punishImageUrls?.map((item) => item.shortUrl)
            }
            await reservationApi.punishDepositByHost(reservation.id, punishDepositInfo)
            enqueueSnackbar('Phạt cọc cho đơn đặt phòng thành công.')
          } else {
            await reservationApi.releaseDeposit(reservation.id)
            enqueueSnackbar('Hoàn cọc cho đơn đặt phòng thành công.')
          }
        } catch (e) {
          enqueueSnackbar('Xác nhận không thành công.')
        }
        break

      case 'customer-complain':
        try {
          await reservationApi.agreePunishDeposit(reservation.id, hostAgree === '1')
          enqueueSnackbar('Xác nhận phạt cọc cho đơn đặt phòng thành công.')
        } catch (e) {
          enqueueSnackbar('Xác nhận phạt cọc cho đơn đặt phòng không thành công.')
        }
        break

      case 'host-complain':
        try {
          if (punishDepositInfo.images?.length) {
            const punishImageUrls = await uploadApi.uploadImage(punishDepositInfo.images.filter((file) => !file.isLink).map((item) => item.file), `Customer/${customer?.code}/Reservation/${reservation?.code}`)
            punishDepositInfo.images = punishImageUrls?.map((item) => item.shortUrl)
          }
          await reservationApi.punishDepositByCustomer(reservation.id, { ...punishDepositInfo, isAgree: customerAgree === '1' })
          enqueueSnackbar('Đề xuất phạt cọc cho đơn đặt phòng thành công.')
        } catch (e) {
          enqueueSnackbar('Đề xuất phạt cọc cho đơn đặt phòng không thành công.')
        }
        break

      case 'processing-release':
        try {
          await reservationApi.handlePunishDeposit(reservation.id, { amount: 0, refundBankNote: bankNote })
          enqueueSnackbar('Hoàn cọc cho đơn đặt phòng thành công.')
          setOpenDepositDialog(false)
        } catch (e) {
          enqueueSnackbar('Hoàn cọc cho đơn đặt phòng không thành công.')
          setOpenDepositDialog(false)
        }
        break

      case '2home-handle':
        try {
          await reservationApi.handlePunishDeposit(reservation.id, punishDepositInfo)
          enqueueSnackbar('Xác nhận phạt cọc cho đơn đặt phòng thành công.')
        } catch (e) {
          enqueueSnackbar('Xác nhận phạt cọc cho đơn đặt phòng không thành công.')
        }
        break

      case 'waiting-release':
        try {
          await reservationApi.provideCustomerDepositInfo(reservation.id, {
            bankAccount: punishDepositInfo.bankAccount,
            bankName: punishDepositInfo.bankName,
            accountName: punishDepositInfo.accountName,
            paymentType: punishDepositInfo.paymentType,
          })
          enqueueSnackbar('Cung cấp thông tin hoàn cọc cho đơn đặt phòng thành công.')
        } catch (e) {
          enqueueSnackbar('Hoàn cọc cho đơn đặt phòng không thành công.')
        }
        break

      default:
        break
    }
    setOpenDepositDialog(false)
    setHasDepositPunish(true)
    fetchReservation()
  }

  const addTimeReservation = async (data) => {
    setOpenAddTimeDialog(false)
    // Call api to add more checkout point
    try {
      await reservationApi.addCheckout(id, data)
      enqueueSnackbar('Xác nhận ở thêm thành công.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      setOpenAddTimeDialog(false)
      fetchReservation()
    } catch (e) {
      enqueueSnackbar('Xác nhận ở thêm không thành công', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
  }

  return (
    <BackgroundBackdrop loading={loading}>
      {/* Warning update dialog */}
      <Dialog
        open={showWarningUpdate}
        onClose={() => setShowWarningUpdate(false)}
      >
        <DialogTitle disableTypography>
          <h3>Chỉnh sửa đơn đặt phòng</h3>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {/* Payment type: 2home/bank-transfer */}
              {
                orderData && orderData.status === 'paid'
                  ? (
                    <>
                      {warningUpdateText && <p dangerouslySetInnerHTML={{ __html: warningUpdateText }} />}
                      {/* Thông tin hoàn tiền */}
                      {hasRefund ? (
                        <>
                          <FormControl
                            variant="outlined"
                            className="my-1"
                            fullWidth
                          >
                            <InputLabel
                              className="px-1 white-bg"
                              id="refund-payment-type"
                            >
                              Hình thức hoàn tiền
                            </InputLabel>
                            <Select
                              labelId="refund-payment-type"
                              id="refund-payment-type-label"
                              value={paymentType}
                              onChange={(e) => setPaymentType(e.target.value)}
                            >
                              {PAYMENT_TYPE.map((item) => (
                                <MenuItem value={item.value}>{item.label}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          {/* Bank information */}
                          {paymentType === 'bank-transfer' ? (
                            <>
                              <TextField
                                className="my-2"
                                label="Chủ tài khoản"
                                variant="outlined"
                                fullWidth
                                value={orderData?.refundUser}
                                onChange={(e) => setOrderData({ ...orderData, refundUser: e.target.value })}
                              />
                              <TextField
                                className="my-2"
                                label="Số tài khoản"
                                variant="outlined"
                                fullWidth
                                value={orderData?.refundBankAccount}
                                onChange={(e) => setOrderData({ ...orderData, refundBankAccount: e.target.value })}
                              />
                              <TextField
                                className="my-2"
                                label="Ngân hàng"
                                variant="outlined"
                                fullWidth
                                value={orderData?.refundBankName}
                                onChange={(e) => setOrderData({ ...orderData, refundBankName: e.target.value })}
                              />
                            </>
                          ) : null}
                        </>
                      ) : null}
                      <p>
                        {warningUpdateText ? '' : 'Xác nhận điều chỉnh đơn đặt phòng. '}
                        {hasRefund ? 'Đồng ý thay đổi và nhận hoàn tiền?' : 'Đồng ý thay đổi?'}
                      </p>
                    </>
                  )
                  : (
                    <p>Xác nhận điều chỉnh đơn đặt phòng?</p>
                  )
              }
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={() => onSubmit(orderData)}
            disabled={hasRefund && paymentType === 'bank-transfer' && (!orderData?.refundUser || !orderData?.refundBankAccount || !orderData?.refundBankName)}
          >
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
      {/* Payment dialog */}
      <PaymentDialog
        classes={classes}
        reservation={reservation}
        open={openPaymentDialog}
        onClose={() => setOpenPaymentDialog(false)}
        onConfirm={payReservation}
      />
      {/* Reject dialog */}
      <RejectDialog
        classes={classes}
        reservation={reservation}
        open={openRejectDialog}
        onClose={() => setOpenRejectDialog(false)}
        onConfirm={rejectReservation}
      />
      {/* Refund dialog */}
      <RefundDialog
        classes={classes}
        open={openRefundDialog}
        reservation={reservation}
        onClose={() => setOpenRefundDialog(false)}
        onConfirm={refundReservation}
      />
      {/* Confirm dialog */}
      <ConfirmDialog
        classes={classes}
        reservation={reservation}
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={confirmReservation}
      />
      {/* Phạt cọc */}
      <PunishDepositDialog
        classes={classes}
        reservation={reservation}
        open={openDepositDialog}
        hasDepositPunish={hasDepositPunish}
        onClose={() => {
          setOpenDepositDialog(false)
          setHasDepositPunish(true)
        }}
        onConfirm={confirmPunishDeposit}
      />
      {/* Undo dialog */}
      <UndoDialog
        classes={classes}
        reservation={reservation}
        open={openUndoDialog}
        onClose={() => setOpenUndoDialog(false)}
        onConfirm={undoReservation}
      />
      {/* Order info dialog */}
      <DetailInfoDialog
        visible={showOrderInfoDialog}
        onClose={() => setShowOrderInfoDialog(false)}
        reservation={reservation}
      />
      {/* Add time dialog */}
      <AddCheckoutPointDialog
        classes={classes}
        reservation={reservation}
        open={openAddTimeDialog}
        onClose={() => setOpenAddTimeDialog(false)}
        onConfirm={addTimeReservation}
      />
      {/* Thông tin đơn đặt phòng */}
      <Formik
        initialValues={reservation}
        enableReinitialize
        validationSchema={validateSchema}
        validateOnChange
        validateOnBlur
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          setFieldValue,
        }) => (
          <Form className={classes.root}>
            <Grid container spacing={3}>
              {/* Thông tin đặt phòng */}
              <Grid item xs={7} md={7}>
                <Typography variant="h3">
                  <span>{renderTitle()}</span>
                  <span className="float-right">{renderStatus()}</span>
                </Typography>
                <br />
                {/* Thông tin phòng và dịch vụ */}
                <General
                  editable={editable}
                  classes={classes}
                  values={values}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  onServiceSelected={onServiceSelected}
                  onRoomPriceRecalculate={onRoomPriceRecalculate}
                  onRoomSelected={(room) => setSltRoom(room)}
                  allServices={allServices}
                />
                <br />
                {/* Thông tin đặt phòng (checkin, checkout, guest) */}
                <BookingInfo
                  editable={editable}
                  classes={classes}
                  values={values}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  onRoomPriceRecalculate={onRoomPriceRecalculate}
                  sltRoom={sltRoom}
                  allServices={allServices}
                  services={sltServices}
                  order={reservation}
                />
                <br />
                {/* Thông tin thêm */}
                <Description
                  editable={editable}
                  classes={classes}
                  values={values}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
                <br />
                {/* Thông tin khách hàng */}
                <CustomerInfo
                  editable={editable}
                  classes={classes}
                  values={values}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              {/* Chi tiết giá và thanh toán/hoàn tiền */}
              <Grid item xs={5} md={5}>
                <Typography variant="h3">Chi tiết:</Typography>
                <br />
                <PriceInfo
                  editable={editable}
                  values={values}
                  classes={classes}
                  roomPrice={roomPrice}
                  services={sltServices}
                  allServices={allServices}
                  coupon={couponData}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
                {/* Thông tin thanh toán/hoàn/huỷ */}
                {!action || action === 'view'
                  ? (
                    <PaymentInfo
                      values={values}
                      classes={classes}
                    />
                  )
                  : null}
                {/* Thông tin xem lại */}
                {
                  [
                    'paid',
                    'processing-pay-extra',
                    'confirmed-pay-extra',
                    'waiting-refund-in-update',
                    'processing-refund-in-update',
                    'host-confirm-refund-update',
                    'add-checkout-processing',
                    'reject-waiting',
                    'reject-processing',
                    'reject-refund',
                    'reject-norefund',
                    'using',
                    'completed',
                  ].includes(values.status)
                    ? (
                      <div className="mb-4 text-right">
                        <Button
                          variant="text"
                          className="mr-2"
                          style={{ textDecoration: 'underline', textTransform: 'none' }}
                          onClick={() => setShowOrderInfoDialog(true)}
                        >
                          Xem doanh thu đơn này
                        </Button>
                      </div>
                    )
                    : null
                }
                {/* Hành động */}
                {getActions(values)}
                {
                  reservation?.depositPunishInfo?.length
                    ? (
                      <>
                        <br />
                        <Typography className={`${classes.subTitle} mb-2`}>Thông tin phạt cọc:</Typography>
                        {
                          reservation.depositPunishInfo.map((d) => {
                            switch (d.from) {
                              case 'host':
                                return (
                                  <>
                                    <p>{`Chủ nhà yêu cầu phạt một khoản ${formatPrice(d.amount)} VND trừ vào tiền đặt cọc của bạn với lý do "${d.description}".`}</p>
                                    {
                                      d.images?.map((image) => (<img src={`${AppConfig.BASE_DESKTOP_IMG_URL}/${image}`} width={120} alt="host-prove" />))
                                    }
                                  </>
                                )
                              case 'customer':
                                return (
                                  <>
                                    <p>{`Bạn yêu cầu phạt ${formatPrice(d.amount)} VND trừ vào tiền đặt cọc của bạn với lý do "${d.description}".`}</p>
                                    {
                                      d.images?.map((image) => (<img src={`${AppConfig.BASE_DESKTOP_IMG_URL}/${image}`} width={120} alt="customer-prove" />))
                                    }
                                  </>
                                )
                              case '2home':
                                if (!d.amount) return null
                                return (
                                  <>
                                    <p>{`2home quyết định số tiền phạt là ${formatPrice(d.amount)} VND.`}</p>
                                    {
                                      d.images?.map((image) => (<img src={`${AppConfig.BASE_DESKTOP_IMG_URL}/${image}`} width={120} alt="2home-decision" />))
                                    }
                                  </>
                                )
                              default:
                                return null
                            }
                          })
                        }
                      </>
                    )
                    : null
                }
                {
                  reservation?.refundDepositInfo
                    ? (
                      <>
                        <Typography className={`${classes.subTitle} mt-3 mb-2`}>Hoàn cọc:</Typography>
                        <Grid container lg={12} md={12} className="my-2">
                          Hoàn tiền vào tài khoản ngân hàng
                        </Grid>
                        <Grid container lg={12} md={12} className="my-2">
                          <Grid item lg={4} md={4}>
                            <InputLabel>Chủ tài khoản:</InputLabel>
                          </Grid>
                          <Grid item lg={8} md={8} style={{ textAlign: 'right' }}>
                            <InputLabel className="font-weight-bold">{reservation.refundDepositInfo?.accountName}</InputLabel>
                          </Grid>
                        </Grid>
                        <Grid container lg={12} md={12} className="my-2">
                          <Grid item lg={4} md={4}>
                            <InputLabel>Số tài khoản:</InputLabel>
                          </Grid>
                          <Grid item lg={8} md={8} style={{ textAlign: 'right' }}>
                            <InputLabel className="font-weight-bold">{reservation.refundDepositInfo?.bankAccount}</InputLabel>
                          </Grid>
                        </Grid>
                        <Grid container lg={12} md={12} className="my-2">
                          <Grid item lg={4} md={4}>
                            <InputLabel>Ngân hàng:</InputLabel>
                          </Grid>
                          <Grid item lg={8} md={8} style={{ textAlign: 'right' }}>
                            <InputLabel className="font-weight-bold">{reservation.refundDepositInfo?.bankName}</InputLabel>
                          </Grid>
                        </Grid>
                      </>
                    )
                    : null
                }
                <br />
                {/* Lịch sử giao dịch */}
                <History data={reservation?.log || []} classes={classes} />
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </BackgroundBackdrop>
  )
}

ReservationForm.propTypes = {
  classes: PropTypes.shape(),
  history: PropTypes.shape(),
  id: PropTypes.string,
  action: PropTypes.string,
}

export default withStyles(reservationFormStyle)(ReservationForm)
