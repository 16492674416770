import React, { useState } from 'react'
import {
  Avatar,
  Typography,
  IconButton,
  Tooltip,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'
import { NavLink as RouterLink } from 'react-router-dom'
import { getInitials } from 'helpers'
import moment from 'moment'
import PropTypes from 'prop-types'
import { StatusBullet, Table } from 'components'
import { Edit, Delete, Restore } from '@material-ui/icons'
import * as customerApi from 'provider/customer'
import { formatPrice } from 'utils/formatter'
import { applyFiltersToUrl, getFiltersFromUrl } from 'utils/url'

const UsersTable = (props) => {
  const {
    classes,
    loading,
    customers,
    meta,
    find,
    update,
    location,
    defaultSort,
    onSortChange,
    onPageChange,
    onRowsPerPageChange,
  } = props
  const [sltCustomer, setSltCustomer] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  const handlePageChange = (page) => {
    onPageChange(page)
  }

  const handleRowsPerPageChange = (limit) => {
    onRowsPerPageChange(limit)
  }

  const handleSortChange = (sortBy, sortDirection) => {
    onSortChange(sortBy, sortDirection)
  }

  const handleOpenConfirmDialog = async (customer) => {
    setOpenConfirmDialog(true)
    setSltCustomer(customer)
  }

  const confirmActive = async () => {
    await customerApi.update({ id: sltCustomer.id, status: sltCustomer.status === 2 ? 1 : 2 })
    const query = getFiltersFromUrl(location.search)
    find(query)
    setOpenConfirmDialog(false)
    setSltCustomer(null)
  }

  const renderCustomerStatus = (status) => {
    switch (status) {
      case 0:
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#ead180',
            }}
            label="Chờ kích hoạt"
          />
        )
      case 1:
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#00e676',
            }}
            label="Đang hoạt động"
          />
        )
      case 2:
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#9a9a9a',
            }}
            label="Dừng hoạt động"
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      <Dialog
        open={openConfirmDialog}
        onClose={() => {
          setOpenConfirmDialog(false)
          setSltCustomer(null)
        }}
      >
        <DialogTitle disableTypography>
          {sltCustomer && <h3>{`${sltCustomer.status !== 2 ? 'Tạm dừng' : 'Khôi phục'} hoạt động khách hàng`}</h3>}
        </DialogTitle>
        <DialogContent style={{ minWidth: 600, minHeight: 80 }}>
          {sltCustomer && <div dangerouslySetInnerHTML={{ __html: `Xác nhận ${sltCustomer.status !== 2 ? 'tạm dừng' : 'khôi phục'} tài khoản khách hàng <b>${sltCustomer.name}</b>?` }} />}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenConfirmDialog(false)
              setSltCustomer(null)
            }}
            color="default"
          >
            Thoát
          </Button>
          <Button
            onClick={confirmActive}
            color="primary"
          >
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
      <Table
        data={customers}
        columns={[
          {
            Head: 'Mã',
            Cell: (value, origin) => (
              <RouterLink to={`/customer/${origin.id}/update`} className={classes.codeCell}>
                {value}
              </RouterLink>
            ),
            accessor: 'code',
            sortable: false,
          },
          {
            Head: 'Tên',
            Cell: (value, origin) => (
              <div className={classes.nameContainer}>
                <Avatar className={classes.avatar} src={origin.avatar}>
                  {getInitials(value || origin.email || 'N')}
                </Avatar>
                <Typography variant="body1">
                  <div className="d-flex align-items-center">
                    <StatusBullet color={origin.online ? 'success' : 'default'} size="sm" className="mr-2" />
                    {`${!origin.gender ? '' : (origin.gender === 'male' ? 'Mr.' : 'Ms.')} ${origin.name}`}
                  </div>
                  <p style={{ fontSize: '0.6rem', marginTop: 10 }}>
                    {
                      origin && origin.membershipName
                        ? (
                          <Chip
                            size="small"
                            variant="outlined"
                            label={origin.membershipName}
                            style={{
                              marginRight: 5,
                            }}
                          />
                        )
                        : null
                    }
                  </p>
                </Typography>
              </div>
            ),
            accessor: 'name',
            sortable: true,
          },
          {
            Head: 'Liên hệ',
            Cell: (value, origin) => (
              <>
                <Tooltip title={value}>
                  <Typography variant="body1">{value}</Typography>
                </Tooltip>
                {origin.phone ? <Typography variant="body2">{`SĐT: ${origin.phone}`}</Typography> : null}
              </>
            ),
            accessor: 'email',
            sortable: false,
          },
          {
            Head: 'Đặt phòng',
            Cell: (value, origin) => (
              <RouterLink to={`/reservations${applyFiltersToUrl({ customer: [origin._id], active: -1 })}`} target="_blank">
                <a style={{ color: '#3f51b5' }}>{`${origin.orders[0]?.total || 0} đơn`}</a>
              </RouterLink>
            ),
            accessor: 'reservationTotal',
            sortable: true,
          },
          {
            Head: 'Tài khoản',
            Cell: (value, origin) => (
              <>
                {`${formatPrice(value)} VND`}
                <p style={{ fontSize: '0.8rem' }}>{`${origin.point} điểm`}</p>
              </>
            ),
            accessor: 'amount',
            sortable: true,
          },
          {
            Head: 'Trạng thái',
            Cell: (value, origin) => (
              <>
                <div>{renderCustomerStatus(value)}</div>
                <p style={{ fontSize: '0.7rem', color: 'gray' }}>
                  {`Cập nhật: ${moment(origin.createdAt).format('DD/MM/YYYY')}`}
                </p>
              </>
            ),
            accessor: 'status',
            sortable: false,
          },
          {
            Head: '',
            Cell: (value, origin) => {
              if (origin.status === 2) {
                return (
                  <Tooltip title="Khôi phục tài khoản" aria-label="Khôi phục tài khoản">
                    <IconButton
                      aria-label="edit"
                      className={classes.margin}
                      onClick={() => handleOpenConfirmDialog(origin)}
                    >
                      <Restore />
                    </IconButton>
                  </Tooltip>
                )
              }
              return (
                <>
                  <Tooltip title="Chỉnh sửa" aria-label="Chỉnh sửa">
                    <IconButton
                      aria-label="edit"
                      className={classes.margin}
                      onClick={() => update(value)}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Xoá" aria-label="Xoá">
                    <IconButton
                      aria-label="delete"
                      className={classes.margin}
                      onClick={() => handleOpenConfirmDialog(origin)}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </>
              )
            },
            accessor: 'id',
          },
        ]}
        loading={loading}
        page={meta.page}
        limit={meta.limit}
        total={meta.total}
        defaultSort={defaultSort}
        onSortChange={handleSortChange}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </>
  )
}

UsersTable.propTypes = {
  classes: PropTypes.shape(),
  loading: PropTypes.bool,
  customers: PropTypes.arrayOf(PropTypes.shape()),
  meta: PropTypes.shape(),
  find: PropTypes.func,
  update: PropTypes.func,
  defaultSort: PropTypes.shape(),
  onSortChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  location: PropTypes.shape(),
}

export default UsersTable
