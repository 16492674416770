import { fromJS } from 'immutable'
import { RESERVATION_ACTION } from '../type'

export default function reservation(state, action) {
  const { type, payload } = action

  switch (type) {
    case RESERVATION_ACTION.FETCH:
    case RESERVATION_ACTION.PAY:
    case RESERVATION_ACTION.REJECT:
    case RESERVATION_ACTION.CONFIRM:
    case RESERVATION_ACTION.UNDO:
    case RESERVATION_ACTION.REFUND:
      return state.set('loading', true).set('error', null)

    case RESERVATION_ACTION.FETCH_OK:
      const { data, query, ...meta } = payload
      return state
        .set('loading', false)
        .set('data', fromJS(data))
        .set('query', fromJS(query))
        .set('meta', fromJS(meta))

    case RESERVATION_ACTION.PAY_OK:
    case RESERVATION_ACTION.REJECT_OK:
    case RESERVATION_ACTION.CONFIRM_OK:
    case RESERVATION_ACTION.UNDO_OK:
    case RESERVATION_ACTION.REFUND_OK:
      const { id } = payload
      const list = state
        .get('data')
        .toJS()
        .map(item => (item.id === id ? payload : item))
      return state.set('loading', false).set('data', fromJS(list))

    case RESERVATION_ACTION.FETCH_ERR:
    case RESERVATION_ACTION.PAY_ERR:
    case RESERVATION_ACTION.REJECT_ERR:
    case RESERVATION_ACTION.CONFIRM_ERR:
    case RESERVATION_ACTION.UNDO_ERR:
    case RESERVATION_ACTION.REFUND_ERR:
      return state
        .set('loading', false)
        .set('error', payload ? payload.message : '')

    default:
      return state
  }
}
