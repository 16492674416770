import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  withStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import styled from './styled'
import BlockUI from 'components/BlockUI'

const UndoDialog = ({
  // classes,
  reservation,
  open,
  onClose,
  onConfirm,
}) => {
  const [isBusy, setIsBusy] = useState(false)

  const undoReservation = async () => {
    setIsBusy(true)

    try {
      await onConfirm()
    } finally {
      setIsBusy(false)
    }
  }

  // Cân nhắc đang cho phép huỷ đơn đã hoàn thành và đang sử dụng
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <BlockUI isLoading={isBusy}>
        <DialogTitle disableTypography>
          <h4>Huỷ thay đổi đơn đặt phòng</h4>
        </DialogTitle>
        <DialogContent style={{ minWidth: 600, minHeight: 80, paddingTop: 0 }}>
          <div className="mb-2">
            Xác nhận huỷ thay đổi đơn đặt phòng
            {' '}
            <b>{reservation?.code}</b>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={undoReservation} color="primary">
            Xác nhận
          </Button>
        </DialogActions>
      </BlockUI>
    </Dialog>
  )
}

UndoDialog.propTypes = {
  // classes: PropTypes.shape(),
  reservation: PropTypes.shape(),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default withStyles(styled)(UndoDialog)
