import React, { useEffect, useState } from 'react'
import {
  Typography,
  // Chip,
  // Tooltip,
} from '@material-ui/core'
import { NavLink as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { formatPrice } from 'utils/formatter'
import { Table } from 'components'

const Customers = ({
  // classes,
  loading,
  data,
  meta,
  setting,
  onSortChange,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const [sortBy, setSortBy] = useState(null)
  const [sortDirection, setSortDirection] = useState(null)

  useEffect(() => {
    console.log('here')
    return () => {
    }
  }, [])

  const handlePageChange = (page) => {
    onPageChange(page)
  }

  const handleRowsPerPageChange = (limit) => {
    onRowsPerPageChange(limit)
  }

  const handleSortChange = (_sortBy, _sortDirection) => {
    onSortChange(_sortBy, _sortDirection)
    setSortBy(_sortBy)
    setSortDirection(_sortDirection)
  }

  return (
    <>
      {/* Reservation list */}
      <Table
        data={data}
        columns={[
          {
            Head: 'Khách hàng',
            Cell: (value, origin) => (
              <div className="d-flex flex-column">
                <RouterLink to={`/reservations?active=1&page=1&customer=%5B"${origin.id}"%5D`} target="_blank">
                  <Typography variant="body1">{`${value} (${origin.code})`}</Typography>
                </RouterLink>
              </div>
            ),
            accessor: 'name',
            sortable: false,
            hidden: !setting['Khách hàng'],
          },
          {
            Head: (
              <div>
                <div>Số lượt đặt</div>
                <div>{`(${meta?.totalOrders} đơn)`}</div>
              </div>
            ),
            Cell: value => (<Typography variant="div">{`${value}`}</Typography>),
            accessor: 'totalOrders',
            sortable: true,
            hidden: !setting['Số lượt đặt'],
          },
          {
            Head: (
              <div>
                <div>Thời gian</div>
                <div>{`(${Number((meta?.totalOrderTime / 60) / (meta?.totalOrders || 1)).toFixed(2)} giờ)`}</div>
              </div>
            ),
            Cell: value => (<Typography variant="div">{`${Number(value / 60).toFixed(2)}`}</Typography>),
            accessor: 'orderTime',
            sortable: true,
            hidden: !setting['Thời gian'],
          },
          {
            Head: (
              <div>
                <div>Chi tiêu phòng</div>
                <div>{`(${formatPrice(meta?.totalRevenueRoom)}đ)`}</div>
              </div>
            ),
            Cell: value => (<Typography variant="div">{`${formatPrice(value)}`}</Typography>),
            accessor: 'revenueRoom',
            sortable: true,
            hidden: !setting['Chi tiêu phòng'],
          },
          {
            Head: (
              <div>
                <div>Chi tiêu dịch vụ</div>
                <div>{`(${formatPrice(meta?.totalRevenueService)}đ)`}</div>
              </div>
            ),
            Cell: value => (<Typography variant="div">{`${formatPrice(value)}`}</Typography>),
            accessor: 'revenueService',
            sortable: true,
            hidden: !setting['Chi tiêu dịch vụ'],
          },
          {
            Head: (
              <div>
                <div>Tiền phòng TB</div>
                <div>{`(${formatPrice(meta?.totalRevenueRoom / (meta?.totalOrders || 1))}đ)`}</div>
              </div>
            ),
            Cell: (value, origin) => (<Typography variant="div">{`${formatPrice(Math.round(value / (origin.totalOrders || 1)))}`}</Typography>),
            accessor: 'revenueRoom',
            sortable: true,
            hidden: !setting['Tiền phòng TB'],
          },
          {
            Head: (
              <div>
                <div>Tiền dịch vụ TB</div>
                <div>{`(${formatPrice(meta?.totalRevenueService / (meta?.totalOrders || 1))}đ)`}</div>
              </div>
            ),
            Cell: (value, origin) => (<Typography variant="div">{`${formatPrice(Math.round(value / (origin.totalOrders || 1)))}`}</Typography>),
            accessor: 'revenueService',
            sortable: true,
            hidden: !setting['Tiền dịch vụ TB'],
          },
          {
            Head: 'Khuyến mại',
            Cell: value => (<Typography variant="div">{`${formatPrice(value)}`}</Typography>),
            accessor: 'usedCoupon',
            sortable: false,
            hidden: !setting['Khuyến mại'],
          },
        ]}
        loading={loading}
        page={meta?.page || 1}
        limit={meta?.limit || 10}
        total={meta?.total || 0}
        defaultSort={{ sortBy, sortDirection }}
        onSortChange={handleSortChange}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </>
  )
}

Customers.propTypes = {
  // classes: PropTypes.shape(),
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape()),
  meta: PropTypes.shape(),
  setting: PropTypes.shape(),
  onSortChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
}

export default Customers
