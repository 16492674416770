import vi from 'date-fns/locale/vi'
import React, { Component } from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { Chart } from 'react-chartjs-2'
import { ThemeProvider } from '@material-ui/styles'
import validate from 'validate.js'
import moment from 'moment'
import { Provider } from 'react-redux'
import makeStore from 'redux/store'
import { loadCSS } from 'fg-loadcss'
import { registerLocale } from 'react-datepicker'
import { chartjs } from './helpers'
import theme from './theme'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './assets/scss/index.scss'
import 'react-datepicker/dist/react-datepicker.css'
import validators from './common/validators'
import Routes from './Routes'
import 'moment/locale/vi'

registerLocale('vi', vi)
moment.locale('vi')

const browserHistory = createBrowserHistory()

Chart.helpers.extend(Chart.elements.Rectangle.prototype, {
  draw: chartjs.draw,
})

validate.validators = {
  ...validate.validators,
  ...validators,
}

const store = makeStore()

export default class App extends Component {
  componentDidMount() {
    loadCSS(
      'https://use.fontawesome.com/releases/v5.12.0/css/all.css',
      document.querySelector('#font-awesome-css'),
    )
  }

  render() {
    return (
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Router history={browserHistory}>
            <Routes />
          </Router>
        </ThemeProvider>
      </Provider>
    )
  }
}
