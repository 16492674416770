import axios from 'axios'
import * as ENDPOINT from './url'

export const find = async (query) => {
  const {
    page,
    limit,
    ...params
  } = query
  try {
    const resp = await axios.get(ENDPOINT.FETCH_NOTIFICATION({
      page: query.page || 1,
      limit: query.limit || 10,
    }), {
      params,
    })
    return resp.data
  } catch (e) {
    throw e
  }
}

export const update = async (id) => {
  try {
    const resp = await axios.patch(ENDPOINT.UPDATE_NOTIFICATION(id), { status: true })
    return resp.data
  } catch (e) {
    throw e
  }
}

export const setAsRead = async ({ type, action }) => {
  try {
    const resp = await axios.post(ENDPOINT.READ_NOTIFICATION, { type, action })
    return resp.data
  } catch (e) {
    throw e
  }
}
