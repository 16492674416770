/* eslint-disable no-useless-catch */
import axios from 'axios'
import { CUSTOMER_TABLE_LIST } from 'config'
import * as ENDPOINT from './url'

export const find = async (query) => {
  try {
    const resp = await axios.get(ENDPOINT.FETCH_CUSTOMER(), {
      params: {
        ...query,
        page: query.page || 1,
        limit: query.limit || CUSTOMER_TABLE_LIST,
      },
    })
    return resp.data
  } catch (e) {
    throw e
  }
}

export const findOne = async (id) => {
  try {
    const resp = await axios.get(ENDPOINT.FETCH_CUSTOMER_BY_ID(id))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const create = async (data) => {
  try {
    const resp = await axios.post(ENDPOINT.CREATE_CUSTOMER, data)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const update = async (data) => {
  try {
    const resp = await axios.patch(ENDPOINT.UPDATE_CUSTOMER(data.id), data)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const remove = async (ids) => {
  try {
    const resp = await axios.delete(ENDPOINT.DELETE_CUSTOMER, { data: { ids } })
    return resp.data
  } catch (e) {
    throw e
  }
}
