import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { SearchTextInput } from 'components'
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core'
import { getFiltersFromUrl } from 'utils/url'
import { Search } from '@material-ui/icons'
import Filter from './Filters'

export const STATUS = [{
  value: 'valid',
  label: 'Còn hiệu lực',
}, {
  value: 'expired',
  label: 'Đã hết hạn',
}]

const CouponToolbar = ({
  classes,
  location,
  applyFilter,
}) => {
  const [fltData, setFltData] = useState({
    creator: 'system',
    status: 'valid',
  })

  useEffect(() => {
    const query = getFiltersFromUrl(location.search)
    query.creator = query.creator || 'system'
    query.status = query.status || 'valid'
    setFltData(query)
    return () => {

    }
  }, [location.search])

  const filter = (data) => {
    setFltData(data)
    const {
      creator,
      searchTxt,
      status,
      room,
      customer,
    } = data
    applyFilter({
      creator,
      searchTxt: searchTxt || undefined,
      status: status && status.length ? status : undefined,
      room: room || undefined,
      customer: customer || undefined,
    })
  }

  const onSearch = (textStr) => {
    const query = getFiltersFromUrl(location.search)
    filter({ ...query, searchTxt: textStr })
  }

  const groupByCreator = (data) => {
    filter(data)
  }

  return (
    <>
      {/* Title */}
      <Grid container spacing={1}>
        {/* Title */}
        <Grid item lg={9} md={8}>
          <Typography variant="h3" gutterBottom>Danh sách mã khuyến mại</Typography>
        </Grid>
      </Grid>
      {/* Filter */}
      <Grid container className={classes.filter}>
        {/* Customer */}
        <Filter.Customer
          value={fltData.customer}
          onApply={customer => filter({ ...fltData, customer })}
        />
        {/* Room */}
        <Filter.Room
          value={fltData.room}
          onApply={room => filter({ ...fltData, room })}
        />
        {/* Status */}
        <Filter.Status
          // eslint-disable-next-line no-restricted-globals
          data={STATUS}
          value={fltData.status}
          onApply={status => filter({ ...fltData, status })}
        />
      </Grid>
      {/* Active/Inactive */}
      <Grid container spacing={1}>
        <Grid item lg={2} style={{ marginTop: 'auto' }}>
          <Button
            fullWidth
            size="small"
            variant={`${fltData.creator === 'system' ? 'contained' : 'default'}`}
            color={`${fltData.creator === 'system' ? 'primary' : 'default'}`}
            onClick={() => groupByCreator({ ...fltData, creator: 'system' })}
          >
            <span className="mr-3">2 Home</span>
          </Button>
        </Grid>
        <Grid item lg={2} style={{ marginTop: 'auto' }}>
          <Button
            fullWidth
            size="small"
            variant={`${fltData.creator === 'host' ? 'contained' : 'default'}`}
            color={`${fltData.creator === 'host' ? 'primary' : 'default'}`}
            onClick={() => groupByCreator({ ...fltData, creator: 'host' })}
          >
            Chủ nhà
          </Button>
        </Grid>
        <Grid item lg={2} style={{ marginTop: 'auto' }}>
          <Button
            fullWidth
            size="small"
            variant={`${fltData.creator === 'customer' ? 'contained' : 'default'}`}
            color={`${fltData.creator === 'customer' ? 'primary' : 'default'}`}
            onClick={() => groupByCreator({ ...fltData, creator: 'customer' })}
          >
            <span className="mr-3">Khách</span>
          </Button>
        </Grid>
        {/* Search */}
        <Grid item lg={3} md={4} style={{ marginLeft: 'auto' }}>
          <SearchTextInput
            fullWidth
            className={classes.searchInput}
            placeholder="Tìm kiếm (theo mã, theo khách hàng, theo phòng, ...)"
            initValue={getFiltersFromUrl(location.search)?.searchTxt}
            debounceTime={800}
            onChange={str => onSearch(str)}
            leftIcon={<Search />}
            size="small"
            style={{ marginBottom: 0 }}
          />
        </Grid>
      </Grid>
    </>
  )
}

CouponToolbar.propTypes = {
  classes: PropTypes.shape(),
  applyFilter: PropTypes.func,
  location: PropTypes.shape(),
}

export default CouponToolbar
