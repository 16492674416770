import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography,
  TextField,
  Radio,
} from '@material-ui/core'
import CurrencyInput from 'components/CurrencyInput'

const Type = ({
  // classes,
  errors,
  touched,
  values,
  handleChange,
  setFieldValue,
}) => (
  <>
    <Typography variant="h6" className="ml-2">Kiểu khuyến mãi</Typography>
    <Grid container item lg={12} md={12}>
      <Grid item lg={6} md={6} xs={12} className="d-flex align-items-center">
        <Radio
          name="valueType"
          checked={values.valueType === 'percent'}
          onChange={() => setFieldValue('valueType', 'percent')}
          size="small"
        />
        <Typography variant="body1">Mã giảm giá theo %</Typography>
      </Grid>
      <Grid item lg={6} md={6} xs={12} className="d-flex align-items-center">
        <Radio
          name="valueType"
          checked={values.valueType === 'money'}
          onChange={() => setFieldValue('valueType', 'money')}
          size="small"
        />
        <Typography variant="body1">Mã giảm giá theo số tiền</Typography>
      </Grid>
    </Grid>
    <Grid container item lg={12} md={12}>
      {
        values.valueType === 'money'
          ? (
            <CurrencyInput
              style={{ backgroundColor: 'white' }}
              label="Giá trị"
              variant="outlined"
              name="value"
              fullWidth
              value={values.value}
              onChange={(value) => {
                setFieldValue('value', value)
              }}
              error={errors.value && touched.value}
              helperText={errors.value && touched.value && errors.value}
              size="small"
            />
          )
          : (
            <TextField
              type="number"
              style={{ backgroundColor: 'white' }}
              label="Giá trị"
              variant="outlined"
              name="value"
              fullWidth
              value={values.value}
              onChange={handleChange}
              error={errors.value && touched.value}
              helperText={errors.value && touched.value && errors.value}
              InputLabelProps={{ shrink: true }}
              size="small"
            />
          )
      }
    </Grid>
  </>
)

Type.propTypes = {
  // classes: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  values: PropTypes.shape(),
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
}

export default Type
