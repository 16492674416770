import { fromJS } from 'immutable'
import USER from 'redux/default/user'
import { USER_ACTION } from '../type'

export default function user(state, action) {
  const { type, payload } = action

  switch (type) {
    case USER_ACTION.LOGIN:
    case USER_ACTION.GET_INFO:
    case USER_ACTION.UPDATE_INFO:
      return state.set('loading', true).set('error', null)

    case USER_ACTION.LOGIN_OK:
    case USER_ACTION.GET_INFO_OK:
      return state.set('loading', false).set('data', fromJS(payload))

    case USER_ACTION.UPDATE_INFO_OK:
      const data = state.get('data').toJS()
      data.avatar = payload.avatar
      return state.set('loading', false).set('data', fromJS(data))

    case USER_ACTION.LOGIN_ERR:
    case USER_ACTION.GET_INFO_ERR:
    case USER_ACTION.UPDATE_INFO_ERR:
      return state
        .set('loading', false)
        .set('error', payload ? payload.message : '')

    case USER_ACTION.LOGOUT:
      window.location.replace('/sign-in')
      return state.set('data', fromJS(USER))

    default:
      return state
  }
}
