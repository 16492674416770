import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core'
import { getFiltersFromUrl } from 'utils/url'
import { SearchTextInput } from 'components'
import { Search } from '@material-ui/icons'
import Filter from './Filters'

const useStyles = makeStyles((theme) => ({
  root: {},
  filter: {
    '& > div': {
      margin: theme.spacing(1),
    },
  },
}))
const ROOM_STATUS = [{
  value: 'pending',
  label: 'Chờ duyệt',
  active: 1,
}, {
  value: 'active',
  label: 'Đang hoạt động',
  active: 0,
}, {
  value: 'inactive',
  label: 'Đang ẩn',
  active: 1,
}]

const RoomsToolbar = ({
  className,
  location,
  applyFilter,
  meta,
  ...rest
}) => {
  const classes = useStyles()
  const [fltData, setFltData] = useState({})

  const filter = (data) => {
    setFltData(data)
    const {
      active,
      searchTxt,
      owner,
      building,
      status,
      service,
      // fromPrice,
      // toPrice,
      price,
    } = data
    applyFilter({
      active,
      searchTxt,
      owner,
      building,
      status,
      service,
      // fromPrice: fromPrice ? parseInt(fromPrice.toString().replace(/,/g, ''), 0) : PRICE_MIN,
      // toPrice: toPrice ? parseInt(toPrice.toString().replace(/,/g, ''), 0) : PRICE_MAX,
      price,
    })
  }

  useEffect(() => {
    const query = getFiltersFromUrl(location.search)
    if (!query.active) {
      query.active = 0
    } else {
      // eslint-disable-next-line no-restricted-globals
      query.active = !isNaN(query.active) ? Number(query.active) : 0
    }
    setFltData(query)
    return () => {
    }
  }, [location.search])

  const onSearch = (textStr) => {
    const query = getFiltersFromUrl(location.search)
    filter({ ...query, searchTxt: textStr })
  }

  const groupByStatus = (data) => {
    filter(data)
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={1}>
        <Typography variant="h3" gutterBottom>Danh sách phòng</Typography>
      </Grid>
      <Grid container spacing={1} className={classes.filter}>
        {/* Host */}
        <Filter.Host
          onApply={(owner) => filter({ ...fltData, owner })}
          value={fltData.owner}
        />
        {/* Building */}
        <Filter.Building
          value={fltData.building}
          onApply={(building) => filter({ ...fltData, building })}
        />
        {/* Price */}
        <Filter.Price
          price={fltData.price?.length ? fltData.price[0] : {}}
          onApply={({ price }) => filter({ ...fltData, price })}
        />
        {/* Status */}
        <Filter.Status
          data={ROOM_STATUS.filter((item) => item.active === (fltData.active || 0))}
          value={fltData.status}
          onApply={(status) => filter({ ...fltData, status })}
        />
        {/* Service */}
        <Filter.Service
          value={fltData.service}
          onApply={(service) => filter({ ...fltData, service })}
        />
      </Grid>
      <Grid container spacing={1}>
        <Grid item lg={3} style={{ marginTop: 'auto' }}>
          <Button
            fullWidth
            size="small"
            variant={`${fltData.active === 0 ? 'contained' : 'default'}`}
            color={`${fltData.active === 0 ? 'primary' : 'default'}`}
            onClick={() => groupByStatus({ ...fltData, active: 0, status: undefined })}
          >
            <div>
              {meta && <div>{`${meta?.active} phòng`}</div>}
              <div>Hoạt động</div>
            </div>
          </Button>
        </Grid>
        <Grid item lg={3} style={{ marginTop: 'auto' }}>
          <Button
            fullWidth
            size="small"
            variant={`${fltData.active === 1 ? 'contained' : 'default'}`}
            color={`${fltData.active === 1 ? 'primary' : 'default'}`}
            onClick={() => groupByStatus({ ...fltData, active: 1, status: undefined })}
          >
            <div>
              {meta && <div>{`${meta?.pending || 0} phòng`}</div>}
              <div>Xem xét</div>
            </div>
          </Button>
        </Grid>
        <Grid item lg={3} style={{ marginTop: 'auto' }}>
          <Button
            fullWidth
            size="small"
            variant={`${fltData.active === 2 ? 'contained' : 'default'}`}
            color={`${fltData.active === 2 ? 'primary' : 'default'}`}
            onClick={() => groupByStatus({ ...fltData, active: 2, status: undefined })}
          >
            <div>
              {meta && <div>{`${meta?.inactive} phòng`}</div>}
              <div>Ẩn</div>
            </div>
          </Button>
        </Grid>
        <Grid item lg={3} md={4} style={{ marginLeft: 'auto' }}>
          <SearchTextInput
            size="small"
            fullWidth
            className={classes.searchInput}
            placeholder="Tìm kiếm (theo mã, theo khách hàng, theo phòng, ...)"
            initValue={getFiltersFromUrl(location.search)?.searchTxt}
            debounceTime={800}
            onChange={(str) => onSearch(str)}
            leftIcon={<Search />}
            style={{ marginBottom: 0 }}
          />
        </Grid>
      </Grid>
    </div>
  )
}

RoomsToolbar.propTypes = {
  className: PropTypes.string,
  applyFilter: PropTypes.func,
  location: PropTypes.shape(),
  meta: PropTypes.shape(),
}

export default RoomsToolbar
