import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import { fromJS } from 'immutable'
import { combineReducers } from 'redux-immutable'
// import { apiMiddleware } from '@aftonbladet/redux-api-middleware'
import USER from 'redux/default/user'
import rootReducer from './reducer'
import {
  DEFAULT_STAFF_LIST,
  DEFAULT_RESERVATION_LIST,
  DEFAULT_ROOM_LIST,
  DEFAULT_CUSTOMER_LIST,
  DEFAULT_PREPAID_HISTORY_LIST,
  DEFAULT_HOST_LIST,
  DEFAULT_SERVICE_LIST,
  DEFAULT_COUPON_LIST,
} from './default/list'

export const defaultState = {
  common: {
    loading: false,
    dashboard: {
      status: {
        customer: {
          total: 0,
          online: 0,
        },
        order: {
          total: 0,
          pending: 0,
          paid: 0,
        },
        host: {
          total: 0,
          online: 0,
        },
        room: {
          total: 0,
          active: 0,
        },
        prepaidPackage: {
          total: 0,
          pending: 0,
          paid: 0,
        },
      },
      data: {
        orders: [],
        revenue: {},
      },
    },
  },
  user: {
    data: USER,
    loading: false,
    error: null,
  },
  notification: {
    query: {},
    data: [],
    loading: false,
    meta: {},
    error: null,
  },
  staff: {
    query: {},
    data: [],
    loading: false,
    meta: DEFAULT_STAFF_LIST,
    error: null,
  },
  host: {
    query: {},
    data: [],
    loading: false,
    meta: DEFAULT_HOST_LIST,
    error: null,
  },
  service: {
    query: {},
    data: [],
    loading: false,
    meta: DEFAULT_SERVICE_LIST,
    error: null,
  },
  reservation: {
    query: {},
    data: [],
    loading: false,
    meta: DEFAULT_RESERVATION_LIST,
    error: null,
  },
  area: {
    data: [],
    loading: false,
    error: null,
  },
  room: {
    query: {},
    data: [],
    loading: false,
    meta: DEFAULT_ROOM_LIST,
    error: null,
  },
  customer: {
    query: {},
    data: [],
    loading: false,
    meta: DEFAULT_CUSTOMER_LIST,
    error: null,
  },
  coupon: {
    query: {},
    data: [],
    loading: false,
    meta: DEFAULT_COUPON_LIST,
    error: null,
  },
  prepaidHistory: {
    query: {},
    data: [],
    loading: false,
    meta: DEFAULT_PREPAID_HISTORY_LIST,
    error: null,
  },
  socket: {
    order: {
      new: 0,
      paid: 0,
      update: 0,
      reject: 0,
      active: 0,
      refund: 0,
      deposit: 0,
    },
    customer: {
      join: 0,
      online: 0,
    },
    prepaidPackage: {
      buy: 0,
      paid: 0,
      reject: 0,
    },
    coupon: {
      created: 0,
    },
    sidebar: {
      order: 0,
      customer: 0,
      prepaidPackage: 0,
    },
  },
}

export default (initialState = defaultState) => createStore(
  combineReducers({
    ...rootReducer,
  }),
  fromJS(initialState),
  composeWithDevTools(applyMiddleware(thunkMiddleware)),
)
