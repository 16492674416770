import palette from '../palette'
import typography from '../typography'

export default {
  root: {
    ...typography.body1,
    borderBottom: `1px solid ${palette.divider}`,
    '@media (max-width:1280px)': {
      fontSize: 12,
      padding: 8,
    },
    '@media (min-width:1281px) and (max-width:1440px)': {
      fontSize: 14,
      padding: 8,
    },
  },
}
