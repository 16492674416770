import moment from 'moment'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { ArrowRightAlt, Close } from '@material-ui/icons'
import CheckinIcon from 'assets/icons/icon-date-in.png'
import CheckoutIcon from 'assets/icons/icon-date-out.png'
import DatePicker from 'components/DatePicker2'

const BookingDate = ({
  startLabel,
  endLabel,
  startdate,
  enddate,
  onApply,
}) => {
  const [start, setStart] = useState(null)
  const [end, setEnd] = useState(null)
  const [active, setActive] = useState(0) // 0: Không xác định, 1: Chuẩn bị checkin, 2: Hôm nay, 3: Ngày mai, 4: Tuỳ chọn

  useEffect(() => {
    if (startdate) {
      setStart(startdate)
    }
  }, [startdate])

  useEffect(() => {
    if (enddate) {
      setEnd(enddate)
    }
  }, [enddate])

  return (
    <PopupState variant="popover" popupId="customer-filter">
      {popupState => (
        <div>
          <Button variant="outlined" {...bindTrigger(popupState)} style={{ backgroundColor: 'white', textTransform: 'none' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={CheckinIcon} style={{ width: 20, marginRight: 10 }} alt="checkin-icon" />
              <span>{!startdate ? startLabel : moment(startdate).format('DD/MM/YYYY HH:mm')}</span>
            </div>
            <ArrowRightAlt style={{ margin: 'auto 20px' }} />
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={CheckoutIcon} style={{ width: 20, marginRight: 10 }} alt="checkout-icon" />
              <span>{!enddate ? endLabel : moment(enddate).format('DD/MM/YYYY HH:mm')}</span>
            </div>
            {startdate || enddate
              ? (
                <Close onClick={(e) => {
                  e.stopPropagation()
                  // onSelect(null, null)
                }}
                />
              )
              : null}
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box p={2} style={{ width: 400 }}>
              {/* Default date: today, yesterday, ... */}
              <div style={{ display: 'flex' }}>
                <Button
                  color={active === 1 ? 'primary' : ''}
                  variant="outlined"
                  size="small"
                  className="mr-1"
                  onClick={() => {
                    setActive(1)
                    setStart(moment().subtract(3, 'hours').valueOf())
                    setEnd(moment().valueOf())
                  }}
                >
                  Sắp đến
                </Button>
                <Button
                  color={active === 2 ? 'primary' : ''}
                  variant="outlined"
                  size="small"
                  className="mx-1"
                  onClick={() => {
                    setActive(2)
                    setStart(moment().startOf('day').valueOf())
                    setEnd(moment().endOf('day').valueOf())
                  }}
                >
                  Hôm nay
                </Button>
                <Button
                  color={active === 3 ? 'primary' : ''}
                  variant="outlined"
                  size="small"
                  className="mx-1"
                  onClick={() => {
                    setActive(3)
                    setStart(moment().add(1, 'days').startOf('day').valueOf())
                    setEnd(moment().add(1, 'days').endOf('day').valueOf())
                  }}
                >
                  Ngày mai
                </Button>
                <Button
                  color={active === 4 ? 'primary' : ''}
                  variant="outlined"
                  size="small"
                  className="ml-1"
                  onClick={() => setActive(4)}
                >
                  Tuỳ chọn
                </Button>
              </div>
              <br />
              {/* Date range */}
              {
                active === 4
                  ? (
                    <>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <DatePicker
                          icon={<img src={CheckinIcon} style={{ width: 20, marginRight: 10 }} alt="checkin-icon" />}
                          type="date"
                          value={start}
                          label="Nhận phòng"
                          onSelect={sltDate => setStart(moment(sltDate).startOf('day').valueOf())}
                          calendarStyle={{ position: 'fixed' }}
                        />
                        <ArrowRightAlt style={{ margin: 'auto 10px' }} />
                        <DatePicker
                          icon={<img src={CheckinIcon} style={{ width: 20, marginRight: 10 }} alt="checkin-icon" />}
                          type="date"
                          value={end}
                          label="Nhận phòng"
                          onSelect={sltDate => setEnd(moment(sltDate).endOf('day').valueOf())}
                          calendarStyle={{ position: 'fixed' }}
                        />
                      </div>
                      <br />
                    </>
                  )
                  : null
              }
              <Button
                onClick={() => {
                  onApply({ startdate: start, enddate: end })
                  popupState.close()
                }}
                style={{ float: 'right', marginBottom: 7 }}
              >
              Áp dụng
              </Button>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

BookingDate.propTypes = {
  onApply: PropTypes.func,
  startLabel: PropTypes.string,
  endLabel: PropTypes.string,
  startdate: PropTypes.number,
  enddate: PropTypes.number,
}

export default BookingDate
