import React from 'react'
import PropTypes from 'prop-types'
import { registerLocale } from 'react-datepicker'
import vi from 'date-fns/locale/vi'
import CustomDatePicker from './DatePicker'
import CustomDateTimePicker from './DateTimePicker'

registerLocale('vi', vi)

const DatePicker = ({ type, ...props }) => {
  switch (type) {
    case 'time':
    case 'datetime':
      return <CustomDateTimePicker {...props} />
    case 'date':
    default:
      return <CustomDatePicker {...props} />
  }
}

DatePicker.propTypes = {
  type: PropTypes.oneOf(['date', 'time', 'datetime']),
}

DatePicker.defaultProps = {
  type: 'date',
}

export default DatePicker
