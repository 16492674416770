const styles = theme => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  inner: {
    minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    borderRadius: 5,
    width: 120,
    height: 80,
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  codeCell: {
    color: '#3f51b5',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  serviceDescription: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 200,
    color: '#818181',
  },
  filter: {
    '& > div': {
      margin: theme.spacing(1),
    },
  },
})

export default styles
