import { connect } from 'react-redux'
import { ReservationList as ReservationPage } from 'views'
import { getReservationData } from 'redux/selector/reservation'
import { getSocketData } from 'redux/selector/socket'
import {
  find,
  pay,
  confirm,
  reject,
  undo,
  refund,
  addCheckout,
  rejectCheckout,
} from 'redux/action/reservation'
import {
  reset,
} from 'redux/action/socket'

const mapDispatchToProps = dispatch => ({
  find: query => dispatch(find(query)),
  pay: (id, paymentType, paidRequestImages) => dispatch(pay(id, paymentType, paidRequestImages)),
  reject: (id, rejectAs, data) => dispatch(reject(id, rejectAs, data)),
  confirm: id => dispatch(confirm(id)),
  undo: id => dispatch(undo(id)),
  refund: (id, bankNote) => dispatch(refund(id, bankNote)),
  addCheckout: (id, data) => dispatch(addCheckout(id, data)),
  rejectCheckout: id => dispatch(rejectCheckout(id)),
  resetSocket: type => dispatch(reset(type)),
})

export default connect(state => ({
  ...getReservationData(state),
  ...getSocketData(state),
}),
mapDispatchToProps)(ReservationPage)
