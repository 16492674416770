import moment from 'moment'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  TextField,
  InputLabel,
  Typography,
  Button,
} from '@material-ui/core'
import { Field } from 'formik'
// import MediaUpload from 'components/MediaUpload'
import * as reservationApi from 'provider/reservation'
import { formatPrice } from 'utils/formatter'
import CurrencyInput from 'components/CurrencyInput'
import { DATETIME_FORMAT } from 'config'

const PriceInfo = ({
  editable,
  values,
  classes,
  roomPrice,
  services,
  allServices,
  coupon,
  setFieldValue,
}) => {
  const [remainingRoomPrice, setRemainingRoomPrice] = useState(0)
  const [remainingServicePrice, setRemainingServicePrice] = useState(0)

  const calculateRemainingRoomPrice = async (id, checkin, checkout, guest, limitRoom) => {
    if (!id) {
      setRemainingRoomPrice(0)
      return
    }
    // Lấy thông tin tiền phòng còn lại
    try {
      const remaining = await reservationApi.getRemainingRoomPrice({
        id,
        checkin,
        checkout,
        guest,
        limitRoom,
      })
      setRemainingRoomPrice(remaining.remainingRoom + remaining.remainingGuest)
    } catch (e) {
      setRemainingRoomPrice(0)
    }
  }

  const calculateRemainingServicePrice = () => {
    if (!values || !values.id) {
      setRemainingServicePrice(0)
    }
    // Lấy thông tin giá dịch vụ của đơn hiện tại
    const oldOrder = values.prevState || values
    const newServices = values.services
    const oldServices = (oldOrder.services || []).map(s => s.id)
    const oldCheckin = oldOrder.checkin
    const diffToNow = moment(oldCheckin).diff(moment().valueOf(), 'hours')
    //! Nếu trước 48h (2 ngày) thì hoàn 100% tiền cho các dịch vụ đã đặt trước đó
    if (diffToNow >= 48) {
      setRemainingServicePrice(allServices?.filter(s => oldServices.includes(s.id)).map(s => s.price).reduce((a, b) => a + b, 0))
    } else {
      //! Nếu sau 48h (2 ngày) thì chỉ được giữ tiền cho các dịch vụ còn lại
      const existedServices = oldServices.filter(s => newServices.includes(s))
      setRemainingServicePrice(allServices?.filter(s => existedServices.includes(s.id)).map(s => s.price).reduce((a, b) => a + b, 0))
    }
  }

  useEffect(() => {
    if (values?.id && values?.prevState) {
      const {
        id,
        checkin,
        checkout,
        guest,
        limitRoom,
      } = values
      calculateRemainingRoomPrice(id, checkin, checkout, guest, limitRoom)
      calculateRemainingServicePrice()
    }
    return () => {
    }
  }, [values, allServices])

  const getTotal = () => {
    let total = 0
    roomPrice.forEach((item) => {
      total += Number(item.price)
    })
    services.forEach((item) => {
      total += Number(item.price)
    })
    if (!values?.applyCoupon && !values?.prevState && coupon) {
      switch (coupon?.valueType) {
        case 'money':
          total -= coupon?.value
          break
        case 'percent':
          total -= (coupon?.value * total) / 100
          break
        default:
      }
    }
    return total
  }

  const getPrevTotal = () => remainingRoomPrice + remainingServicePrice

  const getExtraCheckout = () => values.addCheckoutPoint?.filter(item => item.status !== 'reject')?.map(item => item.value).reduce((a, b) => a + b, 0) || 0

  const renderPriceItem = (title, price) => (
    <Grid container lg={12} md={12} style={{ marginTop: 10, marginBottom: 10 }}>
      <Grid item lg={6} md={6}>
        <InputLabel>{title}</InputLabel>
      </Grid>
      <Grid item lg={6} md={6} style={{ textAlign: 'right' }}>
        <InputLabel>{`${price} VND`}</InputLabel>
      </Grid>
    </Grid>
  )

  const onAddPayOnBehaft = () => {
    setFieldValue('payOnBehaft', [...values.payOnBehaft, { content: '', value: 0 }])
  }

  if (['lock', 'reject-lock'].includes(values.status)) {
    return null
  }

  return (
    <>
      <Typography className={classes.subTitle}>Giá phòng chi tiết:</Typography>
      {/* Giá phòng */}
      {roomPrice.map(item => renderPriceItem(item.title, formatPrice(item.price)))}
      {/* Giá dịch vụ */}
      {services.map(item => renderPriceItem(item.name, formatPrice(item.price)))}
      {/* Khuyến mãi */}
      {
      !values?.applyCoupon && !values?.prevState && coupon
        ? coupon.valueType === 'money'
          ? (
            <Grid container lg={12} md={12} style={{ marginTop: 10, marginBottom: 10 }}>
              <Grid item lg={6} md={6}>
                <InputLabel>{`Khuyến mại ${coupon?.creator === 'host' ? 'từ chủ nhà' : ''}`}</InputLabel>
              </Grid>
              <Grid item lg={6} md={6} style={{ textAlign: 'right' }}>
                <InputLabel>{`- ${formatPrice(coupon.value)} VND`}</InputLabel>
              </Grid>
            </Grid>
          )
          : (
            <Grid container lg={12} md={12} style={{ marginTop: 10, marginBottom: 10 }}>
              <Grid item lg={6} md={6}>
                <InputLabel>{`Khuyến mại ${coupon?.creator === 'host' ? 'từ chủ nhà' : ''}`}</InputLabel>
              </Grid>
              <Grid item lg={6} md={6} style={{ textAlign: 'right' }}>
                <InputLabel>{`- ${coupon.value}%`}</InputLabel>
              </Grid>
            </Grid>
          )
        : null
      }
      {/* Đặt cọc */}
      {
        values.depositWith === '2home' && values.depositAmount
          ? (
            <Grid container lg={12} md={12} className="my-2">
              <Grid item lg={6} md={6}>
                <InputLabel>Đặt cọc</InputLabel>
              </Grid>
              <Grid item lg={6} md={6} style={{ textAlign: 'right' }}>
                <InputLabel>
                  {`${formatPrice((values.depositWith === '2home' ? (values.depositAmount || 0) : 0))} VND`}
                </InputLabel>
              </Grid>
            </Grid>
          )
          : null
      }
      <hr />
      {/* Tổng tiền */}
      <Grid container lg={12} md={12} className="my-2">
        <Grid item lg={6} md={6}>
          <InputLabel className="font-weight-bold">Tổng tiền</InputLabel>
        </Grid>
        <Grid item lg={6} md={6} style={{ textAlign: 'right' }}>
          <InputLabel className="font-weight-bold">{`${formatPrice(getTotal() + (values.depositWith === '2home' ? (values.depositAmount || 0) : 0))} VND`}</InputLabel>
        </Grid>
      </Grid>
      {/* Số dư khả dụng từ đơn cũ */}
      {
        values?.prevState
          ? (
            <>
              <Grid container lg={12} md={12} className="my-2">
                <Grid item lg={6} md={6}>
                  <InputLabel>Số dư khả dụng từ đơn đặt phòng cũ</InputLabel>
                </Grid>
                <Grid item lg={6} md={6} style={{ textAlign: 'right' }}>
                  <InputLabel>{`${formatPrice(getPrevTotal())} VND`}</InputLabel>
                </Grid>
              </Grid>
            </>
          )
          : null
      }
      {/* Đặt ở thêm */}
      {
        values.addCheckoutPoint?.filter(item => item.status !== 'reject')
          ? (
            values.addCheckoutPoint?.filter(item => item.status !== 'reject')?.map(item => renderPriceItem(`Ở thêm tới ${moment(item.checkout).format(DATETIME_FORMAT)}`, `+${formatPrice(item.value)}`))
          )
          : null
      }
      {
        values?.prevState
          ? (
            <>
              <hr />
              <Grid container lg={12} md={12} className="my-2" style={{ color: getPrevTotal() < getTotal() ? 'red' : '#52c41a' }}>
                <Grid item lg={6} md={6}>
                  <InputLabel className="font-weight-bold" style={{ color: 'inherit' }}>
                    {getPrevTotal() < getTotal() ? 'Thanh toán thêm' : 'Hoàn tiền'}
                  </InputLabel>
                </Grid>
                <Grid item lg={6} md={6} style={{ textAlign: 'right' }}>
                  <InputLabel className="font-weight-bold" style={{ color: 'inherit' }}>
                    {`${formatPrice(Math.abs(getPrevTotal() - getTotal()))} VND`}
                  </InputLabel>
                </Grid>
              </Grid>
            </>
          )
          : values.addCheckoutPoint?.filter(item => item.status !== 'reject')
            ? (
              <>
                <hr />
                <Grid container lg={12} md={12} className="my-2">
                  <Grid item lg={6} md={6}>
                    <InputLabel>Tất cả:</InputLabel>
                  </Grid>
                  <Grid item lg={6} md={6} style={{ textAlign: 'right' }}>
                    <InputLabel>{`${formatPrice(getTotal() + getExtraCheckout() + (values.depositWith === '2home' ? (values.depositAmount || 0) : 0))} VND`}</InputLabel>
                  </Grid>
                </Grid>
              </>
            )
            : null
      }
      {/* 2home chi hộ phát sinh */}
      {['paid', 'using', 'completed'].includes(values.status)
        ? (
          <>
            {values.payOnBehaft?.length ? <Typography className={classes.subTitle}>Chi hộ phát sinh:</Typography> : null}
            {
              values.payOnBehaft?.map((item, index) => (
                <Grid key={index} container spacing={2} className="mb-2">
                  {editable
                    ? (
                      <>
                        <Grid item xs={8}>
                          <Field name={`payOnBehaft[${index}][content]`}>
                            {({ field }) => (
                              <TextField
                                style={{ backgroundColor: 'white' }}
                                label="Nội dung"
                                variant="outlined"
                                fullWidth
                                value={field.value}
                                onChange={e => setFieldValue(`payOnBehaft[${index}][content]`, e.target.value)}
                                size="small"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                placeholder="Nhập nội dung chi hộ"
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'right' }}>
                          <Field name={`payOnBehaft[${index}][value]`}>
                            {({ field }) => (
                              <CurrencyInput
                                style={{ backgroundColor: 'white' }}
                                label="Số tiền"
                                variant="outlined"
                                fullWidth
                                value={field.value}
                                onChange={value => setFieldValue(`payOnBehaft[${index}][value]`, value)}
                                size="small"
                                placeholder="Nhập số tiền"
                              />
                            )}
                          </Field>
                        </Grid>
                      </>
                    )
                    : (
                      <>
                        <Grid item xs={9}><InputLabel>{`- ${item.content}: `}</InputLabel></Grid>
                        <Grid item xs={3}><InputLabel className="text-right">{`${formatPrice(item.value)} VND`}</InputLabel></Grid>
                      </>
                    )}
                </Grid>
              ))
            }
            {editable
              ? <Button className="mb-4" disableRipple variant="contained" style={{ textTransform: 'none' }} onClick={onAddPayOnBehaft}>Thêm chi hộ</Button>
              : null}
          </>
        )
        : null}
      <hr />
    </>
  )
}

PriceInfo.propTypes = {
  editable: PropTypes.bool,
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
  roomPrice: PropTypes.arrayOf(PropTypes.shape()),
  services: PropTypes.arrayOf(PropTypes.shape()),
  coupon: PropTypes.shape(),
  setFieldValue: PropTypes.func,
  allServices: PropTypes.arrayOf(PropTypes.shape()),
}

export default PriceInfo
