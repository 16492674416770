import { connect } from 'react-redux'
import { HostList as HostPage } from 'views'
import { getHostData } from 'redux/selector/host'
import { find } from 'redux/action/host'

const mapDispatchToProps = dispatch => ({
  find: query => dispatch(find(query)),
})

export default connect(getHostData, mapDispatchToProps)(HostPage)
