import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import {
  Grid,
  Popover,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
  FormControl,
  TextField,
} from '@material-ui/core'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { Toc } from '@material-ui/icons'
import * as invoiceApi from 'provider/invoice'
import * as hostApi from 'provider/host'
import { useSnackbar } from 'notistack'
// import moment from 'moment'
// import GeneralInfo from './GeneralInfo'
import Autocomplete from '@material-ui/lab/Autocomplete'
import WarningDialog from 'views/Dialog/WarningDialog'
import Invoices from './List'
import ConfirmDialog from './ConfirmDialog'
import OrderInvoiceDialog from './OrderInvoiceDialog'

const InvoiceList = ({
  classes,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [hosts, setHosts] = useState([])
  const [sltHost, setSltHost] = useState({
    id: -1,
    name: 'Tất cả',
    code: '',
  })
  const [sltInvoice, setSltInvoice] = useState(null)
  const [list, setList] = useState([])
  const [meta, setMeta] = useState({
    page: 1,
    total: 0,
    limit: 10,
  })
  const [sltColumns, setSltColumns] = useState({
    'Chủ nhà': true,
    'Số đơn': true,
    'Doanh thu phòng': true,
    'Doanh thu host': true,
    'Chi hộ': true,
    'Doanh thu net chủ nhà': true,
    'Tiền cọc': true,
    'Tình trạng': true,
    'Mã bút toán': true,
  })
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const [showWarningDialog, setShowWarningDialog] = useState(false)
  const [warningTitle, setWarningTitle] = useState('')
  const [warningContent, setWarningContent] = useState('')
  const [showInvoiceDetailDialog, setShowInvoiceDetailDialog] = useState('')

  const fetchHost = async () => {
    try {
      const resp = await hostApi.find({ limit: Number.MAX_SAFE_INTEGER })
      const {
        data,
      } = resp
      setHosts([{
        id: -1,
        name: 'Tất cả',
        code: '',
      }, ...data?.map(item => ({
        id: item.id,
        name: item.name,
        code: item.code,
      }))])
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi, không lấy được thông tin chủ nhà (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  const fetchInvoices = async (query) => {
    try {
      const resp = await invoiceApi.find({ ...query })
      const {
        data,
        ...rest
      } = resp
      setList(data)
      setMeta(rest)
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi, không lấy được thông tin đối soát (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  useEffect(() => {
    fetchHost()
    fetchInvoices(meta)
    return () => {
    }
  }, [])

  useEffect(() => {
    if (!sltHost) return
    if (sltHost?.id !== -1) {
      fetchInvoices({ ...meta, host: sltHost?.id })
    } else {
      fetchInvoices({ ...meta })
    }
  }, [sltHost])

  const onSortChange = (sortBy, sortDirection) => {
    fetchInvoices({
      ...meta,
      host: sltHost,
      sortBy,
      sortDirection,
    })
  }

  const onPageChange = (page) => {
    setMeta({
      ...meta,
      page,
    })
    fetchInvoices({
      ...meta,
      schedule: sltHost,
      page,
    })
  }

  const onRowsPerPageChange = (limit) => {
    setMeta({
      ...meta,
      limit,
    })
    fetchInvoices({
      ...meta,
      schedule: sltHost,
      limit,
    })
  }

  const selectHost = async (value) => {
    setSltHost(value)
  }

  const onPaidInvoice = (id) => {
    setSltInvoice(id)
    setShowConfirmDialog(true)
  }

  const paidInvoice = async (bankNote) => {
    try {
      await invoiceApi.paid(sltInvoice, {
        bankNote,
      })
      fetchInvoices({ ...meta, schedule: sltHost })
      setSltInvoice(null)
      setShowConfirmDialog(false)
      enqueueSnackbar('Xác nhận thanh toán đối soát thành công.', { variant: 'success' })
    } catch (e) {
      console.log(e)
      enqueueSnackbar(`Máy chủ lỗi, xác nhận đối soát không thành công (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  const onRejectInvoice = (id) => {
    setSltInvoice(id)
    setWarningTitle('Huỷ đối soát')
    setWarningContent('Bạn có chắc chắn muốn huỷ đối soát này?')
    setShowWarningDialog(true)
  }

  const rejectInvoice = async () => {
    try {
      await invoiceApi.reject(sltInvoice)
      fetchInvoices({ ...meta, schedule: sltHost })
      setSltInvoice(null)
      setWarningTitle('')
      setWarningContent('')
      setShowWarningDialog(false)
      enqueueSnackbar('Huỷ đối soát thành công.', { variant: 'success' })
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi, huỷ đối soát không thành công (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  const onShowInvoiceDetail = (invoice) => {
    setSltInvoice(invoice)
    setShowInvoiceDetailDialog(true)
  }

  return (
    <>
      <WarningDialog
        visible={showWarningDialog}
        title={warningTitle}
        content={warningContent}
        onClose={() => {
          setWarningTitle('')
          setWarningContent('')
          setShowWarningDialog(false)
        }}
        onConfirm={rejectInvoice}
      />
      <ConfirmDialog
        invoice={list?.find(item => item.id === sltInvoice)}
        visible={showConfirmDialog}
        onClose={() => setShowConfirmDialog(false)}
        onConfirm={bankNote => paidInvoice(bankNote)}
      />
      <OrderInvoiceDialog
        data={list?.find(item => item.id === sltInvoice)?.ordersData}
        visible={showInvoiceDetailDialog}
        onClose={() => {
          setSltInvoice(null)
          setShowInvoiceDetailDialog(false)
        }}
      />
      <div className={classes.root}>
        <Grid container spacing={1}>
          {/* Time range */}
          <Grid item md={5} xs={12}>
            <div className="border p-2 h-100 w-100" style={{ backgroundColor: 'white' }}>
              <FormControl
                className={classes.formControl}
                variant="outlined"
                fullWidth
                size="small"
              >
                <Autocomplete
                  value={sltHost}
                  options={hosts}
                  getOptionLabel={opt => `${opt.name} ${opt.code ? `(${opt.code})` : ''}`}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Chủ nhà"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      placeholder="Chọn chủ nhà ..."
                      size="small"
                    />
                  )}
                  onChange={(e, sltItem) => selectHost(sltItem)}
                />
              </FormControl>
            </div>
          </Grid>
          {/* Information */}
          {/* <Grid item md={7} xs={12}>
            <div className="border p-2 h-100 w-100" style={{ backgroundColor: 'white' }}>
              <Button variant="outlined" onClick={() => setShowInvoiceDialog(true)}>Tạo đối soát</Button>
            </div>
          </Grid> */}
        </Grid>
        {/* Table columns */}
        <Grid container spacing={1} className="mt-4">
          <PopupState variant="popover" popupId="customer-filter">
            {popupState => (
              <div>
                <Button variant="outlined" {...bindTrigger(popupState)} style={{ backgroundColor: 'white', textTransform: 'none' }}>
                  <Toc />
                Cột thông tin danh sách
                </Button>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Box p={2} style={{ width: 600 }}>
                    <Grid container>
                      {
                      Object.keys(sltColumns).map(item => (
                        <Grid item md={4}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                checked={sltColumns[item]}
                                onChange={() => {
                                  const clone = { ...sltColumns }
                                  clone[item] = !clone[item]
                                  setSltColumns(clone)
                                }}
                                name={item}
                              />
                          )}
                            label={item}
                          />
                        </Grid>
                      ))
                    }
                    </Grid>
                  </Box>
                </Popover>
              </div>
            )}
          </PopupState>
        </Grid>
        {/* List */}
        <Grid container className="mt-4">
          <Grid item md={12} xs={12}>
            <Invoices
              setting={sltColumns}
              data={list}
              meta={meta}
              onSortChange={onSortChange}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              onPaid={onPaidInvoice}
              onReject={onRejectInvoice}
              onInvoiceSelect={onShowInvoiceDetail}
            />
          </Grid>
        </Grid>
      </div>
    </>
  )
}

InvoiceList.propTypes = {
  classes: PropTypes.shape(),
}

export default withStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}))(InvoiceList)
