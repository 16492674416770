import axios from 'axios'
import { PREPAID_HISTORY_TABLE_LIST } from 'config'
import * as ENDPOINT from './url'

export const find = async (query) => {
  try {
    const resp = await axios.get(ENDPOINT.FETCH_PREPAID_HISTORY({
      ...query,
      page: query.page || 1,
      limit: query.limit || PREPAID_HISTORY_TABLE_LIST,
    }))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const findOne = async (id) => {
  try {
    const resp = await axios.get(ENDPOINT.FETCH_CUSTOMER_BY_ID(id))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const update = async (id, data) => {
  try {
    const resp = await axios.patch(ENDPOINT.UPDATE_PREPAID_HISTORY(id), { ...data })
    return resp.data
  } catch (e) {
    throw e
  }
}
