import { connect } from 'react-redux'
import { UserList as StaffPage } from 'views'
import { getStaffData } from 'redux/selector/staff'
import { find } from 'redux/action/staff'

const mapDispatchToProps = dispatch => ({
  find: query => dispatch(find(query)),
})

export default connect(getStaffData, mapDispatchToProps)(StaffPage)
