import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Typography,
} from '@material-ui/core'
import RichEditor from 'components/RichEditor'

const fixRule = `
<ul>
  <li>Không sử dụng chất kích thích</li>
  <li>Không sử dụng các vật liệu dễ gây cháy nổ</li>
  <li>Không bật các loại âm thanh gây tiếng ồn lớn</li>
</ul>
`
const Policy = ({
  classes,
  errors,
  touched,
  values,
  setFieldValue,
}) => (
  <>
    <Typography className={classes.subTitle}>
      Quy định trong căn hộ:
    </Typography>
    <div className="pl-3" dangerouslySetInnerHTML={{ __html: fixRule }} />
    <Grid container item lg={12} md={12}>
      <RichEditor
        style={{ backgroundColor: 'white' }}
        label="Viết quy định khi sử dụng căn hộ ..."
        name="rule"
        fullWidth
        value={values.rule}
        onChange={value => setFieldValue('rule', value)}
        error={errors.rule && touched.rule}
        helperText={errors.rule && touched.rule && errors.rule}
        placeholder="Nhập nội dung quy định khi sử dụng căn hộ"
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
  </>
)

Policy.propTypes = {
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  setFieldValue: PropTypes.func,
}

export default Policy
