import JSZip from 'jszip'
import JSZipUtils from 'jszip-utils'
import saveAs from 'save-as'
import * as XLSX from 'xlsx'

export const download = (urls, name) => {
  const zip = new JSZip()
  let count = 0
  const zipFilename = `${name}.zip` || 'unknown.zip'
  urls.forEach(async (url) => {
    const urlArr = url.split('/')
    const filename = urlArr[urlArr.length - 1]
    try {
      const file = await JSZipUtils.getBinaryContent(url)
      zip.file(filename, file, { binary: true })
      count += 1
      if (count === urls.length) {
        zip.generateAsync({ type: 'blob' }).then((content) => {
          saveAs(content, zipFilename)
        })
      }
    } catch (err) {
      console.log(err)
    }
  })
}

export const exportExcelFile = (data) => {
  const worksheet = XLSX.utils.json_to_sheet(data)
  const workbook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1')
  // let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
  // XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workbook, 'report.xlsx')
}
