import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { Person, Close, Remove } from '@material-ui/icons'
import { SearchSelectionInput } from 'components'
// import * as commonApi from 'provider/common'
import * as hostApi from 'provider/host'
import { Typography, Grid } from '@material-ui/core'

const Host = ({ value, onApply }) => {
  const [hostSuggestion, setHostSuggestion] = useState([])
  const [sltHost, setSltHost] = useState({})
  const [hosts, setHosts] = useState([])

  const fetchHost = async (ids) => {
    // TODO: Tạm thời sử dụng JSON.stringify() cho api version 1
    const resp = await hostApi.find({ ids })
    setHosts(
      resp.data.map(opt => ({
        id: opt._id || opt.id,
        title: `${opt.name} (${opt.code})`,
        subTitle: `${opt.email} - ${opt.phone}`,
      })),
    )
  }

  useEffect(() => {
    try {
      if (!value) {
        setHosts([])
        return () => {}
      }
      const ids = value
      if (ids?.length) {
        fetchHost(ids)
      }
    } catch (e) {
      setHosts([])
      onApply(undefined)
    }
    return () => {}
  }, [value])

  const onHostChange = async (str) => {
    if (!str) return
    const resp = await hostApi.find({
      searchTxt: str,
    })
    setHostSuggestion(
      resp.data?.map(opt => ({
        id: opt._id || opt.id,
        title: `${opt.name} (${opt.code})`,
        subTitle: `${opt.email} - ${opt.phone}`,
      })),
    )
  }

  const onHostSelect = async (item) => {
    if (!item) return
    setSltHost(item)
    if (!hosts.find(c => item && c.id === item.id)) {
      setHosts([...hosts].concat(item))
    }
  }

  const renderHostOption = (option) => {
    const { title, subTitle } = option
    return (
      <div>
        <div style={{ fontSize: 16 }}>
          <strong>{title}</strong>
        </div>
        <div>
          <small>{subTitle}</small>
        </div>
      </div>
    )
  }

  return (
    <PopupState variant="popover" popupId="customer-filter">
      {popupState => (
        <div>
          <Button variant="outlined" {...bindTrigger(popupState)} style={{ backgroundColor: 'white', textTransform: 'none' }} className="text-left w-100">
            <Person />
            {hosts.filter(c => value && value.includes(c.id)).length
              ? hosts.filter(c => value && value.includes(c.id)).map(c => c.title).join(', ')
              : 'Chọn chủ nhà'}
            {value && value.length
              ? (
                <Close
                  onClick={(e) => {
                    e.stopPropagation()
                    onApply(undefined)
                  }}
                />
              )
              : null}
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box p={2} style={{ width: 400 }}>
              <SearchSelectionInput
                label="Chủ nhà"
                placeholder="Nhập thông tin chủ nhà"
                value={sltHost.title}
                renderOption={renderHostOption}
                suggestions={hostSuggestion}
                onChange={str => onHostChange(str)}
                onSelect={onHostSelect}
              />
              {/* Selected hosts */}
              <div>
                {hosts.length ? <Typography>Danh sách chủ nhà tìm kiếm:</Typography> : ''}
                {
                  hosts.map((c, i) => (
                    <Grid container>
                      <Typography variant="body1" style={{ fontWeight: 500 }}>{`${i + 1}. ${c.title}`}</Typography>
                      <Remove style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => setHosts([...hosts].filter(item => item.id !== c.id))} />
                    </Grid>
                  ))
                }
              </div>
              <Button
                onClick={() => {
                  onApply(hosts.map(c => c.id))
                  popupState.close()
                }}
                style={{ float: 'right', marginBottom: 7 }}
              >
              Áp dụng
              </Button>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

Host.propTypes = {
  onApply: PropTypes.func,
  value: PropTypes.string,
}

export default Host
