import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
} from '@material-ui/core'
import BlockUI from 'components/BlockUI'
import * as buildingApi from 'provider/building'
import { Close } from '@material-ui/icons'
import { useSnackbar } from 'notistack'

const BuildingDialog = ({
  open,
  data,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [allBuildings, setAllBuildings] = useState([])
  const [buildings, setBuildings] = useState([])
  const [searchTxt, setSearchTxt] = useState('')
  const [loading, setLoading] = useState(false)

  const fetchBuildings = async () => {
    try {
      const resp = await buildingApi.find({
        district: data?._id,
        limit: 1000,
      })
      setAllBuildings(resp?.data || [])
      setBuildings(resp?.data || [])
    } catch (e) {
      console.log(e.response.data)
      enqueueSnackbar(`${e.response.data} (code: ${e.response ? e.response.status : 'Unknown'})`, { variant: 'error' })
    }
  }

  useEffect(() => {
    if (open) {
      fetchBuildings()
    } else {
      setAllBuildings([])
    }
    setSearchTxt('')
    return () => { }
  }, [open])

  const onSearch = (v) => {
    setSearchTxt(v)
    setBuildings((allBuildings || []).filter(b => b.name?.toLowerCase().includes(v?.toLowerCase())))
  }

  const onCreateBuilding = async () => {
    try {
      const resp = await buildingApi.create({ name: searchTxt, district: data._id })
      setAllBuildings([...allBuildings, resp])
      setBuildings([...allBuildings, resp])
      setSearchTxt('')
    } catch (e) {
      console.log(e)
      enqueueSnackbar(`${e.response.data} (code: ${e.response ? e.response.status : 'Unknown'})`, { variant: 'error' })
    }
  }

  const onRemoveBuilding = async (id) => {
    try {
      setLoading(true)
      await buildingApi.remove(id)
      setAllBuildings(allBuildings.filter(b => b.id !== id))
      setBuildings(allBuildings.filter(b => b.id !== id))
      setSearchTxt('')
      setLoading(false)
    } catch (e) {
      setLoading(false)
      enqueueSnackbar(`${e.response.data} (code: ${e.response ? e.response.status : 'Unknown'})`, { variant: 'error' })
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <BlockUI isLoading={loading}>
        <DialogTitle disableTypography>
          <h3>Danh sách toà nhà</h3>
        </DialogTitle>
        <div className="d-flex align-items-end px-4">
          <TextField
            style={{ backgroundColor: 'white', marginTop: 16, marginRight: 8 }}
            label="Tên phòng"
            variant="outlined"
            fullWidth
            value={searchTxt}
            onChange={(e) => onSearch(e.target.value)}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Nhập tên toà nhà"
          />
          <Button variant="outlined" style={{ width: 150 }} onClick={onCreateBuilding}>Tạo mới</Button>
        </div>
        <DialogContent
          style={{
            minWidth: 600,
            minHeight: 80,
            maxHeight: 600,
            paddingTop: 0,
          }}
        >
          {
            buildings?.map(building => (
              <>
                <div className="d-flex align-items-center my-2">
                  <div className="my-2 w-100">{building.name}</div>
                  <Close onClick={() => onRemoveBuilding(building.id)} />
                </div>
                <Divider />
              </>
            ))
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Đóng
          </Button>
        </DialogActions>
      </BlockUI>
    </Dialog>
  )
}

BuildingDialog.propTypes = {
  open: PropTypes.bool,
  data: PropTypes.shape(),
  onClose: PropTypes.func,
}

export default BuildingDialog
