import { connect } from 'react-redux'
import { Area as AreaPage } from 'views'
import { getAreaData } from 'redux/selector/area'
import { find } from 'redux/action/area'

const mapDispatchToProps = dispatch => ({
  find: query => dispatch(find(query)),
})

export default connect(getAreaData, mapDispatchToProps)(AreaPage)
