import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Select,
  MenuItem,
  InputBase,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const BootstrapInput = withStyles(theme => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase)

const DepositRoom = ({
  rooms,
  defaultValue,
  onApplyRoom,
}) => {
  const [sltRooms, setSltRooms] = useState([])

  useEffect(() => {
    setSltRooms(defaultValue || [])
    return () => {
    }
  }, [defaultValue])

  const onSelectRoom = (ids) => {
    if (ids.includes(-1)) {
      setSltRooms(rooms.map(r => r._id))
      onApplyRoom(rooms.map(r => r._id))
    } else {
      setSltRooms(ids)
      onApplyRoom(ids)
    }
  }

  return (
    <Select
      className="w-100"
      variant="outlined"
      multiple
      input={<BootstrapInput />}
      value={sltRooms}
      onChange={e => onSelectRoom(e.target.value)}
      renderValue={selected => (
        <div>
          {rooms?.filter(r => selected?.includes(r._id))?.map(r => `${r.name} (${r.code})`)?.join(', ')}
        </div>
      )}
    >
      <MenuItem key={-1} value={-1}>
          Tất cả
      </MenuItem>
      {rooms?.map(r => (
        <MenuItem key={r._id} value={r._id}>
          {r.name}
        </MenuItem>
      ))}
    </Select>
  )
}

DepositRoom.propTypes = {
  rooms: PropTypes.shape(),
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  onApplyRoom: PropTypes.func,
}

export default DepositRoom
