import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/styles'
import {
  withStyles,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Divider,
  Typography,
} from '@material-ui/core'
import Apartment from '@material-ui/icons/Apartment'
import Spa from '@material-ui/icons/Spa'
import RefreshIcon from '@material-ui/icons/Refresh'
import { formatPrice } from 'utils/formatter'
import { VpnKey } from '@material-ui/icons'
import styles from './styled'

const Revenue = ({
  classes,
  data: {
    roomTo2home,
    roomToHost,
    service,
  },
  onRefresh,
}) => {
  const theme = useTheme()

  const data = {
    datasets: [{
      data: [roomTo2home, roomToHost, service],
      backgroundColor: [
        theme.palette.primary.main,
        theme.palette.error.main,
        theme.palette.warning.main,
      ],
      borderWidth: 5,
      borderColor: theme.palette.white,
      hoverBorderColor: theme.palette.white,
      weight: 2,
    }],
    labels: ['Doanh thu phòng 2home', 'Doanh thu phòng chủ nhà', 'Doanh thu dịch vụ'],
  }

  const options = {
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutoutPercentage: 60,
    layout: { padding: 0 },
    tooltips: {
      enabled: true,
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: theme.palette.divider,
      backgroundColor: theme.palette.white,
      titleFontColor: theme.palette.text.primary,
      bodyFontColor: theme.palette.text.secondary,
      footerFontColor: theme.palette.text.secondary,
      callbacks: {
        label: (tooltipItem, d) => `${d.labels[tooltipItem.index]}: ${formatPrice(d.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] / 1000)}K`,
      },
    },
  }

  const devices = [
    {
      title: '2home',
      value: `${formatPrice(roomTo2home / 1000)}K`,
      icon: <Apartment />,
      color: theme.palette.primary.main,
    },
    {
      title: 'Chủ nhà',
      value: `${formatPrice(roomToHost / 1000)}K`,
      icon: <VpnKey />,
      color: theme.palette.error.main,
    },
    {
      title: 'Dịch vụ',
      value: `${formatPrice(service / 1000)}K`,
      icon: <Spa />,
      color: theme.palette.warning.main,
    },
  ]

  return (
    <Card>
      <CardHeader
        action={(
          <IconButton size="small" onClick={onRefresh}>
            <RefreshIcon />
          </IconButton>
        )}
        title="Doanh thu hôm nay (VND)"
      />
      <Divider />
      <CardContent>
        <div className={classes.chartContainer}>
          <Doughnut data={data} options={options} />
        </div>
        <div className={classes.stats}>
          {devices.map(device => (
            <div key={device.title} className={classes.device}>
              <span className={classes.deviceIcon}>{device.icon}</span>
              <Typography variant="body1">{device.title}</Typography>
              <Typography style={{ color: device.color }} variant="h3">
                {device.value}
              </Typography>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  )
}

Revenue.propTypes = {
  classes: PropTypes.shape(),
  data: PropTypes.shape(),
  onRefresh: PropTypes.func,
}

export default withStyles(styles)(Revenue)
