import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import {
  Grid,
  Typography,
  Popover,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { Toc, Search } from '@material-ui/icons'
import * as reportApi from 'provider/report'
import { useSnackbar } from 'notistack'
import TimeRange from 'components/TimeRange'
import moment from 'moment'
import { SearchTextInput } from 'components'
import GeneralInfo from './GeneralInfo'
import Rooms from './List'

const RoomReport = ({
  classes,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [filter, setFilter] = useState({
    startdate: moment().startOf('day').valueOf(),
    enddate: moment().endOf('day').valueOf(),
    sortBy: undefined,
    sortDirection: undefined,
    searchTxt: undefined,
  })
  const [info, setInfo] = useState({})
  const [list, setList] = useState([])
  const [meta, setMeta] = useState({
    page: 1,
    total: 0,
    limit: 10,
  })
  const [sltColumns, setSltColumns] = useState({
    Phòng: true,
    'Số đơn hoàn thành': true,
    'Số đơn huỷ': true,
    'Số khách': true,
    'Doanh thu phòng': true,
    'Doanh thu dịch vụ': true,
    'Trung bình đặt phòng': true,
  })

  const fetchRoomReport = async (query) => {
    try {
      const resp = await reportApi.fetchRoomList({ ...query })
      const {
        data,
        ...rest
      } = resp
      setMeta(rest)
      setList(JSON.parse(JSON.stringify(data)))
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi, không lấy được thông tin doanh thu (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  const fetchRoomAverage = async (query) => {
    try {
      const resp = await reportApi.fetchRoomAverage({ ...query })
      setInfo(resp)
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi, không lấy được thông tin doanh thu (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  useEffect(() => {
    fetchRoomReport({ ...filter })
    fetchRoomAverage({ ...filter })
    return () => {
    }
  }, [])

  const onSearch = (searchData) => {
    setFilter({
      ...filter,
      ...searchData,
    })
    fetchRoomReport({
      ...filter,
      ...searchData,
      ...meta,
      page: 1,
    })
    fetchRoomAverage({
      ...filter,
      ...searchData,
      ...meta,
      page: 1,
     })
  }

  const onSortChange = (sortBy, sortDirection) => {
    setFilter({
      ...filter,
      sortBy,
      sortDirection,
    })
    fetchRoomReport({
      ...filter,
      sortBy,
      sortDirection,
    })
    fetchRoomAverage({
      ...filter,
      sortBy,
      sortDirection,
    })
  }

  const onPageChange = (page) => {
    setMeta({
      ...meta,
      page,
    })
    fetchRoomReport({
      ...filter,
      ...meta,
      page,
    })
    fetchRoomAverage({
      ...filter,
      ...meta,
      page,
    })
  }

  const onRowsPerPageChange = (limit) => {
    setMeta({
      ...meta,
      limit,
    })
    fetchRoomReport({
      ...filter,
      ...meta,
      limit,
    })
    fetchRoomAverage({
      ...filter,
      ...meta,
      page,
    })
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        {/* Time range */}
        <Grid item md={5} xs={12}>
          <div className="border p-2 h-100 w-100" style={{ backgroundColor: 'white' }}>
            <Typography variant="h4" className="mb-3">Thống kê phòng: </Typography>
            <TimeRange
              value={{ startDate: filter.startdate, endDate: filter.enddate }}
              onApply={searchDate => onSearch({ ...filter, startdate: searchDate.startDate, enddate: searchDate.endDate })}
              maxDate={moment().valueOf()}
            />
          </div>
        </Grid>
        {/* Information */}
        <Grid item md={7} xs={12}>
          <div className="border p-2 h-100 w-100" style={{ backgroundColor: 'white' }}>
            <GeneralInfo data={info} />
          </div>
        </Grid>
      </Grid>
      {/* Table columns */}
      <Grid container spacing={1} className="mt-4">
        <Grid item md={3} className="mt-auto">
          <PopupState variant="popover" popupId="customer-filter">
            {popupState => (
              <div>
                <Button variant="outlined" {...bindTrigger(popupState)} style={{ backgroundColor: 'white', textTransform: 'none' }}>
                  <Toc />
                Cột thông tin danh sách
                </Button>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                >
                  <Box p={2} style={{ width: 600 }}>
                    <Grid container>
                      {
                      Object.keys(sltColumns).map(item => (
                        <Grid item md={4}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                checked={sltColumns[item]}
                                onChange={() => {
                                  const clone = { ...sltColumns }
                                  clone[item] = !clone[item]
                                  setSltColumns(clone)
                                }}
                                name={item}
                              />
                          )}
                            label={item}
                          />
                        </Grid>
                      ))
                    }
                    </Grid>
                  </Box>
                </Popover>
              </div>
            )}
          </PopupState>
        </Grid>
        <Grid item md={4} className="ml-auto">
          <SearchTextInput
            fullWidth
            className={classes.searchInput}
            placeholder="Tìm kiếm theo tên phòng, mã phòng"
            debounceTime={800}
            onChange={searchTxt => onSearch({ ...filter, searchTxt })}
            leftIcon={<Search />}
            size="small"
            style={{ marginBottom: 0 }}
          />
        </Grid>
      </Grid>
      <Grid container className="mt-4">
        <Grid item md={12} xs={12}>
          <Rooms
            setting={sltColumns}
            data={list}
            meta={meta}
            info={info}
            onSortChange={onSortChange}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </Grid>
      </Grid>
    </div>
  )
}

RoomReport.propTypes = {
  classes: PropTypes.shape(),
}

export default withStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
}))(RoomReport)
