import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import { Container, IconButton } from '@material-ui/core'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import Popover from '@material-ui/core/Popover'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import TextInput from '@material-ui/core/TextField'
import {
  isSameDay,
} from 'date-fns'
import { vi } from 'date-fns/locale'
import moment from 'moment'
import { AccessTime, Close } from '@material-ui/icons'
import style from './style'
import { staticRanges, CustomStaticRangeLabelContent } from './elements'

const isTableMode = width => width < 1024

const DAY_PICKER_DESKTOP_PROPS = {
  months: 2,
  direction: 'horizontal',
}

const DAY_PICKER_TABLET_PROPS = {
  months: 1,
}


const TimeRange = ({
  classes,
  // placeholder,
  value,
  minDate,
  maxDate,
  className,
  onApply,
  onClear,
}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const defaultRange = {
    startDate: null,
    endDate: null,
    key: 'selection',
  }

  const [dataRange, setDataRange] = useState([defaultRange])
  const [tempRange, setTempRange] = useState({
    startDate: '',
    endDate: '',
  })
  const [disablePreview, setDisabledPreview] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOpen = (event) => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setDataRange([
      {
        ...defaultRange,
        startDate: value.startDate ? new Date(value.startDate) : null,
        endDate: value.endDate ? new Date(value.endDate) : null,
      },
    ])
    setAnchorEl(null)
  }

  const handleChange = (item) => {
    setDataRange([item.selection])
  }

  const handleInputChange = (e) => {
    setTempRange({
      ...tempRange,
      [e.target.name]: e.target.value,
    })
  }

  const handleInputBlur = (e) => {
    const { target } = e
    if (!target.value || target.value.length < 8 || target.value.length > 10 || !moment(target.value, 'MM/DD/YYYY', true).isValid()) {
      setDataRange([
        {
          ...dataRange[0],
          [target.name]: null,
        },
      ])
    } else if (target.name === 'endDate' && (!dataRange[0].startDate || moment(dataRange[0].startDate).isAfter(new Date(target.value)))) {
      setDataRange([
        {
          ...dataRange[0],
          startDate: new Date(target.value),
          endDate: new Date(target.value),
        },
      ])
    } else if (target.name === 'startDate' && !target.value && dataRange[0].endDate) {
      setDataRange([
        {
          ...dataRange[0],
          startDate: null,
          endDate: null,
        },
      ])
    } else if (target.name === 'startDate' && (dataRange[0].startDate && moment(dataRange[0].endDate).isBefore(new Date(target.value)))) {
      setDataRange([
        {
          ...dataRange[0],
          startDate: new Date(target.value),
          endDate: new Date(target.value),
        },
      ])
    } else {
      setDataRange([
        {
          ...dataRange[0],
          [target.name]: new Date(target.value),
        },
      ])
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleInputBlur(e)
    }
  }

  const handleApply = () => {
    onApply({
      startDate: dataRange[0].startDate ? moment(new Date(dataRange[0].startDate).getTime()).startOf('day').valueOf() : null,
      endDate: dataRange[0].endDate ? moment(new Date(dataRange[0].endDate).getTime()).endOf(moment(), 'day').valueOf() : null,
    })
    setAnchorEl(null)
  }

  useEffect(() => {
    setDataRange([{
      ...defaultRange,
      startDate: value.startDate ? new Date(value.startDate) : null,
      endDate: value.endDate ? new Date(value.endDate) : null,
    }])
  }, [value]) // eslint-disable-line

  useEffect(() => {
    setTempRange({
      startDate: dataRange[0].startDate ? moment(dataRange[0].startDate).format('MM/DD/YYYY') : '',
      endDate: dataRange[0].endDate ? moment(dataRange[0].endDate).format('MM/DD/YYYY') : '',
    })
  }, [dataRange, setTempRange])

  const { startDate, endDate } = dataRange[0]

  const renderDisplayedValue = () => {
    if (!startDate && !endDate) {
      return staticRanges[0].label
    }

    const existedLabel = staticRanges.find(s => (isSameDay(startDate, s.range()?.startDate) && isSameDay(endDate, s.range()?.endDate)))

    if (existedLabel && existedLabel.label !== 'Custom') {
      return existedLabel.label
    }

    if (startDate || endDate) {
      return `${startDate ? moment(startDate).format('MM/DD/YYYY') : '--/--/--'} - ${endDate ? moment(endDate).format('MM/DD/YYYY') : '--/--/--'}`
    }

    return ''
  }

  return (
    <Container className={`${classes.container}${className ? ` ${className}` : ''}`}>
      {/* Date Info */}
      <Button
        variant="outlined"
        style={{ backgroundColor: 'white', textTransform: 'none', width: '100%' }}
        onClick={handleOpen}
      >
        <div className="d-flex w-100 align-items-center mr-auto">
          <AccessTime />
          <span className="ml-2">{renderDisplayedValue()}</span>
          {
            startDate && endDate && onClear && <IconButton onClick={onClear} size="small" className="ml-auto"><Close /></IconButton>
          }
        </div>
      </Button>
      {/* Select Date */}
      <Popover
        className={classes.popover}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Paper elevation={0} className={classes.paper}>
          <DateRangePicker
            locale={vi}
            className={`${classes.customDateRangePicker + (disablePreview ? ' disablePreview' : '')}`}
            onChange={item => handleChange(item)}
            showMonthAndYearPickers={false}
            moveRangeOnFirstSelection={false}
            editableDateInputs
            weekdayDisplayFormat="EEEEEE"
            monthDisplayFormat="MMMM yyyy"
            startDatePlaceholder="MM/DD/YYYY"
            endDatePlaceholder="MM/DD/YYYY"
            rangeColors={[]}
            inputRanges={[]}
            staticRanges={staticRanges}
            fixedHeight
            ranges={dataRange}
            maxDate={maxDate ? moment(maxDate).toDate() : undefined}
            minDate={minDate ? moment(minDate).toDate() : undefined}
            renderStaticRangeLabel={definedRange => (
              <CustomStaticRangeLabelContent {...definedRange} dataRange={dataRange} setDisabledPreview={setDisabledPreview} />
            )}
            {...(isTableMode(windowWidth) ? DAY_PICKER_TABLET_PROPS : DAY_PICKER_DESKTOP_PROPS)}
          />
          <div className={classes.footer}>
            <div className={classes.fieldGroup}>
              <div className={classes.fieldControl}>
                <span className={classes.fieldLabel}>Từ ngày</span>
                <TextInput
                  name="startDate"
                  placeholder="MM/DD/YYYY"
                  value={tempRange.startDate || ''}
                  onChange={e => handleInputChange(e)}
                  onBlur={e => handleInputBlur(e)}
                  onKeyDown={e => handleKeyDown(e)}
                />
              </div>
              <div className={classes.fieldControl}>
                <span className={classes.fieldLabel}>Đến ngày</span>
                <TextInput
                  name="endDate"
                  placeholder="MM/DD/YYYY"
                  value={tempRange.endDate || ''}
                  onChange={e => handleInputChange(e)}
                  onBlur={e => handleInputBlur(e)}
                  onKeyDown={e => handleKeyDown(e)}
                />
              </div>
            </div>
            <div className={classes.applyBtnWrapper}>
              <Button className={classes.applyBtn} onClick={() => handleApply()}>Áp dụng</Button>
            </div>
          </div>
        </Paper>
      </Popover>
    </Container>
  )
}

TimeRange.defaultProps = {
  value: {
    startDate: null,
    endDate: null,
  },
  onApply: () => {},
  onChange: () => {},
  placeholder: '',
  className: '',
  onClear: null,
  minDate: null,
  maxDate: null,
}

TimeRange.propTypes = {
  classes: PropTypes.shape().isRequired,
  className: PropTypes.string,
  value: PropTypes.shape(),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onApply: PropTypes.func,
  onClear: PropTypes.func,
  minDate: PropTypes.number,
  maxDate: PropTypes.number,
}

export default withStyles(style)(TimeRange)
