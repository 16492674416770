import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { Table } from 'components'
import { formatPrice } from 'utils/formatter'
import { exportExcelFile } from 'utils/download'

const OrderInvoiceDialog = ({
  data,
  visible,
  onClose,
}) => {
  const [orders, setOrders] = useState([])

  useEffect(() => {
    if (data) {
      setOrders(data)
    }
  }, [data])

  const downloadReportExcel = () => {
    exportExcelFile(orders?.sort((a, b) => a.checkout - b.checkout)?.map(item => ({
      'Ngày checkout': moment(item.checkout).format('DD/MM/YYYY'),
      'Mã đơn': item.code,
      'Doanh thu phòng': formatPrice(item?.roomTotalRevenue || 0),
      'Doanh thu phòng (2home)': formatPrice(item?.room2homeRevenue || 0),
      'Doanh thu phòng (Chủ nhà)': formatPrice(item?.roomHostRevenue || 0),
      'Phí huỷ': formatPrice(item?.rejectRevenue || 0),
      'Chi hộ phát sinh': formatPrice(item?.payOnBehaft || 0),
      '2home thuê phòng': formatPrice(item?.setupFee || 0),
      'Doanh thu net chủ nhà': formatPrice(item?.roomHostNet || 0),
      'Giảm giá': (() => {
        if (!item.coupon) return 0
        if (item.coupon?.creator !== 'host') return 0
        switch (item.coupon?.useFor) {
          case 'room':
            if (item.coupon.valueType === 'money') {
              return formatPrice(item.coupon.value || 0)
            }
            return formatPrice((item.room2homeRevenue + item.roomHostRevenue) * (item.coupon.value || 0) / 100)
          default:
            return 0
        }
      })(),
    })))
  }

  return (
    <Dialog
      open={visible}
      onClose={onClose}
      maxWidth="80vw"
    >
      <DialogTitle disableTypography>
        <h3>Danh sách đơn đặt phòng</h3>
      </DialogTitle>
      <DialogContent style={{ minWidth: '80vw', minHeight: 80, paddingTop: 0 }}>
        <div className="p-2">
          <Table
            data={orders}
            columns={[
              {
                Head: 'Mã đơn',
                Cell: (value, origin) => (
                  <div
                    className="d-flex align-items-center"
                    style={{
                      position: 'sticky',
                      background: '#fff',
                      left: 0,
                      zIndex: 1,
                    }}
                  >
                    <RouterLink to={`/reservation/${value}/view`} target="_blank">
                      <Typography variant="body1">{value}</Typography>
                      <Typography variant="body2">
                        {moment(origin.createdAt).format('DD/MM/YYYY HH:mm')}
                      </Typography>
                    </RouterLink>
                  </div>
                ),
                accessor: 'code',
                sortable: false,
              },
              {
                Head: 'Doanh thu phòng',
                Cell: (value, origin) => (<Typography variant="div">{formatPrice((origin?.room2homeRevenue || 0) + (origin?.roomHostRevenue || 0))}</Typography>),
                accessor: 'room2homeRevenue',
                sortable: true,
              },
              {
                Head: 'Phòng (2home)',
                Cell: value => (<Typography variant="div">{formatPrice(value || 0)}</Typography>),
                accessor: 'room2homeRevenue',
                sortable: true,
              },
              {
                Head: 'Phòng (Chủ nhà)',
                Cell: value => (<Typography variant="div">{formatPrice(value || 0)}</Typography>),
                accessor: 'roomHostRevenue',
                sortable: true,
              },
              {
                Head: 'Phí huỷ',
                Cell: value => (<Typography variant="div">{formatPrice(value || 0)}</Typography>),
                accessor: 'rejectRevenue',
                sortable: true,
              },
              {
                Head: 'Chi hộ phát sinh',
                Cell: value => (<Typography variant="div">{formatPrice(value || 0)}</Typography>),
                accessor: 'payOnBehaft',
                sortable: true,
              },
              {
                Head: '2home thuê phòng',
                Cell: value => (<Typography variant="div">{formatPrice(value || 0)}</Typography>),
                accessor: 'setupFee',
                sortable: true,
              },
              {
                Head: 'Doanh thu net (Chủ nhà)',
                Cell: (value, origin) => (<Typography variant="div">{formatPrice((origin?.roomHostRevenue || 0) + (origin?.setupFee || 0) - (origin?.payOnBehaft || 0))}</Typography>),
                accessor: 'roomHostRevenue',
                sortable: true,
              },
              {
                Head: 'Giảm giá',
                Cell: (coupon, origin) => {
                  if (coupon?.creator !== 'host') return 0
                  switch (coupon?.useFor) {
                    case 'room':
                      if (coupon.valueType === 'money') {
                        return <Typography variant="div">{formatPrice(coupon.value || 0)}</Typography>
                      }
                      return <Typography variant="div">{formatPrice((origin.room2homeRevenue + origin.roomHostRevnue) * (coupon.value || 0) / 100)}</Typography>
                    default:
                      return 0
                  }
                },
                accessor: 'coupon',
                sortable: true,
              },
              {
                Head: 'Tiền cọc',
                Cell: value => <Typography variant="div">{formatPrice(value || 0)}</Typography>,
                accessor: 'depositRevenue',
                sortable: true,
              },
            ]}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={downloadReportExcel} color="secondary" className="mr-auto">
          Download Excel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

OrderInvoiceDialog.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  visible: PropTypes.bool,
  onClose: PropTypes.func,
}

export default OrderInvoiceDialog
