import React from 'react'
import PropTypes from 'prop-types'
import DatePicker from 'components/DatePicker2'
import CheckoutIcon from 'assets/icons/icon-date-out.png'

const EndDate = ({ onApply, init }) => {
  const onDateChange = (sltDate) => {
    onApply(sltDate)
  }

  return (
    <DatePicker
      icon={<img src={CheckoutIcon} style={{ width: 20, marginRight: 10 }} alt="checkout-icon" />}
      type="date"
      init={init}
      label="Trả phòng"
      onSelect={onDateChange}
    />
  )
}

EndDate.propTypes = {
  onApply: PropTypes.func,
  init: PropTypes.number,
}

export default EndDate
