import moment from 'moment'
import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
  Typography,
} from '@material-ui/core'
import PropTypes from 'prop-types'

const ConfirmDialog = ({
  invoice,
  visible,
  onClose,
  onConfirm,
}) => {
  const [bankNote, setBankNote] = useState(null)
  const [step, setStep] = useState(1)

  useEffect(() => {
    if (visible) {
      setStep(1)
      setBankNote(null)
    }
  }, [visible])

  const confirm = () => {
    if (step === 1) {
      setStep(2)
      return
    }
    if (step === 2) {
      onConfirm(bankNote)
      setStep(1)
      setBankNote(null)
    }
  }

  return (
    <Dialog
      open={visible}
      onClose={onClose}
    >
      <DialogTitle disableTypography>
        <h3>Xác nhận thanh toán</h3>
      </DialogTitle>
      <DialogContent style={{ minWidth: 600, minHeight: 80, paddingTop: 0 }}>
        <p>
          <div dangerouslySetInnerHTML={{ __html: `Xác nhận thanh toán đối soát từ ngày <b>${moment(invoice?.startdate).format('DD/MM/YYYY')}</b> tới ngày <b>${moment(invoice?.enddate).format('DD/MM/YYYY')}</b> cho chủ nhà <b>${invoice?.host?.name}</b>` }} />
        </p>
        {
          step === 1
            ? (
              <>
                <Grid container className="align-items-center my-1">
                  <Typography variant="subtitle1" className="mr-2">Chủ tài khoản: </Typography>
                  <Typography variant="h5">{invoice?.host?.bankAccountName}</Typography>
                </Grid>
                <Grid container className="align-items-center my-1">
                  <Typography variant="subtitle1" className="mr-2">Số tài khoản: </Typography>
                  <Typography variant="h5">{invoice?.host?.bankAccountNumber}</Typography>
                </Grid>
                <Grid container className="align-items-center my-1">
                  <Typography variant="subtitle1" className="mr-2">Ngân hàng: </Typography>
                  <Typography variant="h5">{invoice?.host?.bankName}</Typography>
                </Grid>
              </>
            )
            : null
        }
        {
          step === 2
            ? (
              <Grid container className="p-2">
                <TextField
                  label="Mã bút toán"
                  variant="outlined"
                  fullWidth
                  value={bankNote}
                  onChange={e => setBankNote(e.target.value)}
                  style={{ backgroundColor: 'white' }}
                  hiddenLabel
                />
              </Grid>
            )
            : null
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={confirm} color="primary">
          Xác nhận
        </Button>
      </DialogActions>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  invoice: PropTypes.shape(),
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default ConfirmDialog
