import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'
import * as reportApi from 'provider/report'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import RoomRevenue from './Cards/Revenue'
import OrderNumber from './Cards/OrderNumber'
import RoomNumber from './Cards/RoomNumber'
import HostNumber from './Cards/HostNumber'
import OrderAverage from './Cards/OrderAverage'
import CustomerNumber from './Cards/CustomerNumber'
import CustomerOrder from './Cards/CustomerOrder'
import Filter from './Filter'

const Report = ({
  classes,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [revenue, setRevenue] = useState({})
  const [orderNumber, setOrderNumber] = useState({})
  const [roomNumber, setRoomNumber] = useState({})
  const [hostNumber, setHostNumber] = useState({})
  const [orderAverage, setOrderAverage] = useState({})
  const [customerNumber, setCustomerNumber] = useState({})
  const [customerOrder, setCustomerOrder] = useState({})
  const [filter, setFilter] = useState({
    startDate: moment().subtract(14, 'day').startOf('day').valueOf(),
    endDate: moment().subtract(1, 'day').endOf('day').valueOf(),
  })

  const fetchRevenueGraph = async (query) => {
    try {
      const resp = await reportApi.fetchRevenueGraph(query)
      setRevenue(resp)
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi, không lấy được thông tin doanh thu (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  const fetchOrderCountGraph = async (query) => {
    try {
      const resp = await reportApi.fetchOrderCountGraph(query)
      setOrderNumber(resp)
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi, không lấy được thông tin doanh thu (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  const fetchRoomCountGraph = async (query) => {
    try {
      const resp = await reportApi.fetchRoomCountGraph(query)
      setRoomNumber(resp)
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi, không lấy được thông tin doanh thu (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  const fetchHostCountGraph = async (query) => {
    try {
      const resp = await reportApi.fetchHostCountGraph(query)
      setHostNumber(resp)
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi, không lấy được thông tin doanh thu (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  const fetchOrderAverageGraph = async (query) => {
    try {
      const resp = await reportApi.fetchOrderAverageGraph(query)
      setOrderAverage(resp)
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi, không lấy được thông tin doanh thu (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  const fetchCustomerCountGraph = async (query) => {
    try {
      const resp = await reportApi.fetchCustomerCountGraph(query)
      setCustomerNumber(resp)
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi, không lấy được thông tin doanh thu (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  const fetchCustomerOrderGraph = async (query) => {
    try {
      const resp = await reportApi.fetchCustomerOrderGraph(query)
      setCustomerOrder(resp)
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi, không lấy được thông tin doanh thu (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  useEffect(() => {
    fetchRevenueGraph(filter)
    fetchOrderCountGraph(filter)
    fetchRoomCountGraph(filter)
    fetchHostCountGraph(filter)
    fetchOrderAverageGraph(filter)
    fetchCustomerCountGraph(filter)
    fetchCustomerOrderGraph(filter)
    return () => {
    }
  }, [])

  const onOrderPeriodChange = (orderCountPeriod) => {
    fetchOrderCountGraph({
      type: orderCountPeriod,
    })
  }

  const onRoomPeriodChange = (roomCountPeriod) => {
    fetchRoomCountGraph({
      type: roomCountPeriod,
    })
  }

  const onHostPeriodChange = (hostCountPeriod) => {
    fetchHostCountGraph({
      type: hostCountPeriod,
    })
  }

  const onOrderAveragePeriodChange = (orderAveragePeriod) => {
    fetchOrderAverageGraph({
      type: orderAveragePeriod,
    })
  }

  const onCustomerPeriodChange = (customerCountPeriod) => {
    fetchCustomerCountGraph({
      type: customerCountPeriod,
    })
  }

  const onCustomerOrderPeriodChange = (customerOrderPeriod) => {
    fetchCustomerOrderGraph({
      type: customerOrderPeriod,
    })
  }

  const applyFilter = (fltData) => {
    setFilter(fltData)
    fetchRevenueGraph(fltData)
    fetchOrderCountGraph(fltData)
    fetchRoomCountGraph(fltData)
    fetchHostCountGraph(fltData)
    fetchCustomerCountGraph(fltData)
    fetchOrderAverageGraph(fltData)
    fetchCustomerOrderGraph(fltData)
  }

  return (
    <div className={classes.root}>
      {/* Bộ lọc */}
      <Grid container spacing={4}>
        <Grid item md={6} xs={12}>
          <Filter.Date
            onApply={(start, end) => applyFilter({ ...filter, startDate: start, endDate: end })}
            startdate={filter.startDate}
            enddate={filter.endDate}
            maxDate={moment().valueOf()}
          />
        </Grid>
      </Grid>
      {/* Biểu đồ */}
      <Grid container spacing={4}>
        {/* Doanh thu phòng và dịch vụ */}
        <Grid item md={6} xs={12}>
          <RoomRevenue data={revenue} />
        </Grid>
        {/* Số đơn đặt phòng */}
        <Grid item md={6} xs={12}>
          <OrderNumber data={orderNumber} onPeriodChange={onOrderPeriodChange} />
        </Grid>
        {/* Tỷ lệ thời gian và giá trị đơn đặt phòng */}
        <Grid item md={6} xs={12}>
          <OrderAverage data={orderAverage} onPeriodChange={onOrderAveragePeriodChange} />
        </Grid>
        {/* Số khách đặt phòng */}
        <Grid item md={6} xs={12}>
          <CustomerOrder data={customerOrder} onPeriodChange={onCustomerOrderPeriodChange} />
        </Grid>
        {/* Số lượng phòng */}
        <Grid item md={6} xs={12}>
          <RoomNumber data={roomNumber} onPeriodChange={onRoomPeriodChange} />
        </Grid>
        {/* Số lượng chủ nhà */}
        <Grid item md={6} xs={12}>
          <HostNumber data={hostNumber} onPeriodChange={onHostPeriodChange} />
        </Grid>
        {/* Số lượng khách */}
        <Grid item md={6} xs={12}>
          <CustomerNumber data={customerNumber} onPeriodChange={onCustomerPeriodChange} />
        </Grid>
      </Grid>
    </div>
  )
}

Report.propTypes = {
  classes: PropTypes.shape(),
}

export default withStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
}))(Report)
