import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  // InputLabel,
  TextField,
  Typography,
} from '@material-ui/core'

const Description = ({
  editable,
  classes,
  values,
  // touched,
  // errors,
  handleChange,
  // setFieldValue,
}) => (
  <>
    {['lock', 'reject-lock'].includes(values.status)
      ? null
      : (
        <>
          <Typography className={classes.subTitle}>
            Ghi chú
          </Typography>
          {/* Ghi chú cho đặt phòng */}
          <Grid container spacing={3}>
            {editable
              ? (
                <Grid item lg={12} md={12}>
                  <TextField
                    // label="Ghi chú"
                    variant="outlined"
                    name="note"
                    fullWidth
                    multiline
                    rows={5}
                    value={values.note}
                    onChange={handleChange}
                    className={classes.formControl}
                    size="small"
                    placeholder="Nhập thông tin lưu ý cho đơn đặt phòng"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              )
              : (
                <Grid item lg={6} md={6}>
                  {/* <InputLabel className={classes.itemTitle}>Ghi chú:</InputLabel> */}
                  <Typography variant="body1">
                    {values.note || <i>Không có ghi chú</i>}
                  </Typography>
                </Grid>
              )}
          </Grid>
        </>
      )
    }
  </>
)

Description.propTypes = {
  editable: PropTypes.bool,
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
  // touched: PropTypes.shape(),
  // errors: PropTypes.shape(),
  handleChange: PropTypes.func,
  // setFieldValue: PropTypes.func,
}

export default Description
