const styles = theme => ({
  titleAlign: {
    marginTop: 10,
    marginBottom: 10,
  },
  root: {
    flexGrow: 1,
    padding: 10,
    backgroundColor: 'white',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: 3,
  },
  subTitle: {
    fontSize: 16,
    fontWeight: '900',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  formControl: {
    backgroundColor: 'white',
  },
  title: {
    margin: 10,
  },
  itemTitle: {
    fontSize: 12,
    marginBottom: 5,
  },
  inner: {
    // minWidth: 1050,
  },
  link: {
    fontSize: '1rem',
    color: '#3e51b5',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  roomCell: {
    width: 250,
    '& div': {
      color: '#3e51b5',
      cursor: 'pointer',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '& a': {
      color: '#3e51b5',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  customerCell: {
    minWidth: 150,
    '& a': {
      color: '#3e51b5',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  actionCell: {
    minWidth: 100,
    '& div': {
      display: 'inline-block',
    },
  },
  actionBtn: {
    padding: 5,
  },
  serviceThumbnail: {
    width: 70,
    height: 70,
    borderRadius: 0,
    marginRight: theme.spacing(2),
  },
  serviceName: {
    '& span': {
      fontSize: '1rem',
    },
  },
  servicePrice: {
    textAlign: 'right',
    '& span': {
      fontSize: '1rem',
      fontWeight: '500',
    },
  },
  actions: {
    justifyContent: 'flex-end',
  },
  confirmBtn: {
    margin: 5,
  },
  rejectBtn: {
    backgroundColor: 'red',
    color: 'white',
    margin: 5,
  },
  customerName: {
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
  customerInfoIcon: {
    color: '#3f51b5',
    marginLeft: 5,
    fontSize: '1rem',
    cursor: 'pointer',
  },
  groupFilter: {
    margin: 0,
  },
  avatar: {
    width: 120,
    height: 80,
    '@media (max-width:1280px)': {
      width: 90,
      height: 60,
    },
    '@media (min-width:1281px) and (max-width:1440px)': {
      width: 100,
      height: 70,
    },
  },
  ellipsisCell: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  codeCell: {
    width: 30,
    '& a': {
      color: '#3f51b5',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  avatarCell: {
    width: 80,
  },
})

export default styles
