import AppConfig from 'config'
import qs from 'qs'

//! VERSION 2
/**
 * Các api chung
 */
export const CITY = `${AppConfig.endpoint.api}/city`
export const DISTRICT = `${AppConfig.endpoint.api}/district`
export const BUILDING = `${AppConfig.endpoint.api}/building`
export const DASHBOARD = `${AppConfig.endpoint.apiv2}/common/dashboard`
export const LAST_ORDERS = `${AppConfig.endpoint.apiv2}/common/last-orders`
export const REVENUE = `${AppConfig.endpoint.apiv2}/common/revenue`
export const GET_MEMBERSHIP = `${AppConfig.endpoint.api}/membership`
export const GET_PACKAGE = `${AppConfig.endpoint.api}/prepaid-package`

/**
 * Phòng
 */
export const FETCH_ROOM = (query) => `${AppConfig.endpoint.apiv2}/room${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_ROOM_BY_ID = (id) => `${AppConfig.endpoint.apiv2}/room/${id}`
export const GET_ROOM_TYPE = `${AppConfig.endpoint.api}/room-type`

/**
 * Đơn đặt phòng
 */
export const FETCH_RESERVATION = `${AppConfig.endpoint.apiv2}/reservation`
export const FETCH_RESERVATION_BY_ID = (id) => `${AppConfig.endpoint.apiv2}/reservation/${id}`

/**
 * Lịch sử mua gói trả trước
 */
export const FETCH_PREPAID_HISTORY = (query) => `${AppConfig.endpoint.api}/prepaid-package-history${query ? `?${qs.stringify(query)}` : ''}`

//! VERSION 1
export const LOGIN = `${AppConfig.endpoint.api}/login`
export const GET_USER = `${AppConfig.endpoint.api}/user/info`
export const UPDATE_USER = `${AppConfig.endpoint.api}/user/info`
export const GET_ROOM_SUGGESTION = (searchTxt) => `${AppConfig.endpoint.api}/room/suggestion?text=${searchTxt}`
export const GET_BUILDING_SUGGESTION = (searchTxt) => `${AppConfig.endpoint.api}/building/suggestion?text=${searchTxt}`
export const GET_SERVICE_TYPE = `${AppConfig.endpoint.api}/service-type?limit=${Number.MAX_SAFE_INTEGER}`
export const GET_SERVICE_OPTION = `${AppConfig.endpoint.api}/service-option?limit=${Number.MAX_SAFE_INTEGER}`
export const GET_SERVICE_COMPONENT = `${AppConfig.endpoint.api}/service-component?limit=${Number.MAX_SAFE_INTEGER}`
export const AMENITY = (id) => `${AppConfig.endpoint.api}/amenity${id ? `/${id}` : ''}`

// Báo cáo
export const FETCH_REVENUE_GRAPH = (query) => `${AppConfig.endpoint.api}/report/graph/revenue${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_ORDER_COUNT_GRAPH = (query) => `${AppConfig.endpoint.api}/report/graph/order-count${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_ROOM_COUNT_GRAPH = (query) => `${AppConfig.endpoint.api}/report/graph/room-count${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_HOST_COUNT_GRAPH = (query) => `${AppConfig.endpoint.api}/report/graph/host-count${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_CUSTOMER_COUNT_GRAPH = (query) => `${AppConfig.endpoint.api}/report/graph/customer-count${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_CUSTOMER_ORDER_GRAPH = (query) => `${AppConfig.endpoint.api}/report/graph/customer-order${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_ORDER_AVERAGE_GRAPH = (query) => `${AppConfig.endpoint.api}/report/graph/order-average${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_ORDER_LIST_REPORT = (query) => `${AppConfig.endpoint.apiv2}/report/order-list${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_ROOM_LIST_REPORT = (query) => `${AppConfig.endpoint.apiv2}/report/room-list${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_SERVICE_LIST_REPORT = (query) => `${AppConfig.endpoint.api}/report/service-list${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_CUSTOMER_LIST_REPORT = (query) => `${AppConfig.endpoint.api}/report/customer-list${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_HOST_LIST_REPORT = (query) => `${AppConfig.endpoint.api}/report/host-list${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_ROOM_AVERAGE_REPORT = (query) => `${AppConfig.endpoint.apiv2}/report/room-average${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_ORDER_AVERAGE_REPORT = (query) => `${AppConfig.endpoint.apiv2}/report/order-average${query ? `?${qs.stringify(query)}` : ''}`

// Quyền
export const GET_ROLE = `${AppConfig.endpoint.api}/role`

// Nhân viên
export const FETCH_STAFF = (query) => `${AppConfig.endpoint.api}/user${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_STAFF_BY_ID = (id) => `${AppConfig.endpoint.api}/user/${id}`
export const GET_USER_SUGGESTION = (searchTxt) => `${AppConfig.endpoint.api}/user/suggestion?text=${searchTxt}`
export const CREATE_STAFF = `${AppConfig.endpoint.api}/user/create`
export const UPDATE_STAFF = (id) => `${AppConfig.endpoint.api}/user/${id}`
export const DELETE_STAFF = `${AppConfig.endpoint.api}/user`
export const RESTORE_STAFF = `${AppConfig.endpoint.api}/user/restore`

// Đơn đặt phòng
export const RECALCULATE_RESERVATION = `${AppConfig.endpoint.api}/reservation/recalculate`

export const CREATE_RESERVATION = `${AppConfig.endpoint.api}/reservation`
export const PAY_RESERVATION = (id) => `${AppConfig.endpoint.api}/reservation/${id}/pay`
export const REJECT_RESERVATION = (id) => `${AppConfig.endpoint.api}/reservation/${id}/reject`
export const CONFIRM_RESERVATION = (id) => `${AppConfig.endpoint.api}/reservation/${id}/confirm`
export const REFUND_RESERVATION = (id) => `${AppConfig.endpoint.api}/reservation/${id}/refund`
export const UPDATE_RESERVATION = (id) => `${AppConfig.endpoint.api}/reservation/${id}`
export const GET_REMAINING_ROOM_PRICE = (id) => `${AppConfig.endpoint.api}/reservation/${id}/remaining-room-price`
export const GET_REMAINING_SERVICE_PRICE = (id) => `${AppConfig.endpoint.api}/reservation/${id}/remaining-service-price`
export const UNDO_RESERVATION = (id) => `${AppConfig.endpoint.api}/reservation/${id}/undo`
export const ADD_CHECKOUT = (id) => `${AppConfig.endpoint.api}/reservation/${id}/add-checkout`
export const REJECT_CHECKOUT = (id) => `${AppConfig.endpoint.api}/reservation/${id}/reject-checkout`
export const PAY_CHECKOUT_POINT = (id) => `${AppConfig.endpoint.api}/reservation/${id}/pay-checkout`
export const CONFIRM_CHECKOUT_POINT = (id) => `${AppConfig.endpoint.api}/reservation/${id}/confirm-checkout`
export const WARNING = (id, mode) => `${AppConfig.endpoint.api}/reservation/${id}/warning?mode=${mode}`

export const GET_RESERVATION_SUGGESTION = (searchTxt) => `${AppConfig.endpoint.api}/reservation/suggestion?text=${searchTxt}`
export const UPDATE_RESERVATION_STATUS = (id) => `${AppConfig.endpoint.api}/reservation/${id}/status`
export const GET_REVENUE_INFO = (id) => `${AppConfig.endpoint.api}/reservation/${id}/revenue-info`
export const HOST_PUNISH_DEPOSIT_RESERVATION = (id) => `${AppConfig.endpoint.api}/host/reservation/${id}/punish-deposit`
export const CUSTOMER_PUNISH_DEPOSIT_RESERVATION = (id) => `${AppConfig.endpoint.api}/customer/reservation/${id}/punish-deposit`
export const AGREE_PUNISH_DEPOSIT = (id) => `${AppConfig.endpoint.api}/host/reservation/${id}/agree-deposit`
export const CONFIRM_PUNISH_DEPOSIT_RESERVATION = (id) => `${AppConfig.endpoint.api}/reservation/${id}/confirm-deposit`
export const RELEASE_DEPOSIT = (id) => `${AppConfig.endpoint.api}/host/reservation/${id}/release-deposit`
export const PROVIDE_CUSTOMER_REFUND_INFO = (id) => `${AppConfig.endpoint.api}/reservation/${id}/provide-customer-refund-info`
export const PROVIDE_CUSTOMER_DEPOSIT_INFO = (id) => `${AppConfig.endpoint.api}/reservation/${id}/customer-provide-deposit-payment`
export const SEND_ORDER_EMAIL = (id) => `${AppConfig.endpoint.api}/reservation/${id}/send-order-email`

// Phòng
export const CREATE_ROOM = `${AppConfig.endpoint.api}/room`
export const UPDATE_ROOM = `${AppConfig.endpoint.api}/room`
export const RESTORE_ROOM = `${AppConfig.endpoint.api}/room/restore`
export const LOCK_ROOM = `${AppConfig.endpoint.api}/room/lock`
export const DELETE_ROOM = `${AppConfig.endpoint.api}/room`
export const PENDING_ROOM = `${AppConfig.endpoint.api}/room/pending`

// Đối soát
export const FETCH_SCHEDULE = () => `${AppConfig.endpoint.api}/invoice/schedule`
export const FETCH_INVOICE = (query) => `${AppConfig.endpoint.api}/invoice${query ? `?${qs.stringify(query)}` : ''}`
export const CREATE_INVOICE = `${AppConfig.endpoint.api}/invoice`
export const REJECT_INVOICE = (id) => `${AppConfig.endpoint.api}/invoice/${id}/reject`
export const PAID_INVOICE = (id) => `${AppConfig.endpoint.api}/invoice/${id}/paid`

// Dịch vụ
export const FETCH_SERVICE = (query) => `${AppConfig.endpoint.api}/service${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_SERVICE_BY_ID = (id) => `${AppConfig.endpoint.api}/service/${id}`
export const GET_SERVICE_SUGGESTION = (searchTxt) => `${AppConfig.endpoint.api}/service/suggestion?text=${searchTxt}`
export const CREATE_SERVICE = `${AppConfig.endpoint.api}/service`
export const UPDATE_SERVICE = `${AppConfig.endpoint.api}/service`
export const DELETE_SERVICE = `${AppConfig.endpoint.api}/service`

// Khách hàng
export const FETCH_CUSTOMER = (query) => `${AppConfig.endpoint.apiv2}/customer${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_CUSTOMER_BY_ID = (id) => `${AppConfig.endpoint.api}/customer/${id}`
export const GET_CUSTOMER_SUGGESTION = (searchTxt) => `${AppConfig.endpoint.api}/customer/suggestion?text=${searchTxt}`
export const CREATE_CUSTOMER = `${AppConfig.endpoint.api}/customer`
export const UPDATE_CUSTOMER = (id) => `${AppConfig.endpoint.api}/customer/${id}`
export const DELETE_CUSTOMER = `${AppConfig.endpoint.api}/customer`

// Lịch sử mua gói
export const UPDATE_PREPAID_HISTORY = (id) => `${AppConfig.endpoint.api}/prepaid-package-history/${id}`
export const FETCH_PREPAID_HISTORY_BY_ID = (id) => `${AppConfig.endpoint.api}/prepaid-package-history/${id}`

// Chủ nhà
export const FETCH_HOST = (query) => `${AppConfig.endpoint.apiv2}/host${query ? `?${qs.stringify(query)}` : ''}`
export const GET_HOST_SUGGESTION = (searchTxt) => `${AppConfig.endpoint.api}/host/suggestion?text=${searchTxt}`
export const FETCH_HOST_BY_ID = (id) => `${AppConfig.endpoint.apiv2}/host/${id}`
export const CREATE_HOST = `${AppConfig.endpoint.api}/host`
export const UPDATE_HOST = (id) => `${AppConfig.endpoint.api}/host/${id}`
export const DELETE_HOST = `${AppConfig.endpoint.api}/host`
export const DEACTIVE_HOST = (id) => `${AppConfig.endpoint.api}/host/${id}/deactive`

// Phương thức thanh toán
export const FETCH_PAYMENT = (query) => `${AppConfig.endpoint.api}/payment-method${query ? `?${qs.stringify(query)}` : ''}`

// Thông báo
export const FETCH_NOTIFICATION = (query) => `${AppConfig.endpoint.api}/notification${query ? `?${qs.stringify(query)}` : ''}`
export const UPDATE_NOTIFICATION = (id) => `${AppConfig.endpoint.api}/notification/${id}`
export const READ_NOTIFICATION = `${AppConfig.endpoint.api}/notification/read`

// Toà nhà
export const FETCH_BUILDING = (query) => `${AppConfig.endpoint.api}/building${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_BUILDING_BY_ID = (id) => `${AppConfig.endpoint.api}/building/${id}`
export const FETCH_AREA = () => `${AppConfig.endpoint.apiv2}/common/area`

// Mã khuyến mãi
export const FETCH_COUPON = (query) => `${AppConfig.endpoint.api}/coupon${query ? `?${qs.stringify(query)}` : ''}`
export const FETCH_COUPON_BY_ID = (id) => `${AppConfig.endpoint.api}/coupon/${id}`
export const CREATE_COUPON = `${AppConfig.endpoint.api}/coupon`
export const UPDATE_COUPON = (id) => `${AppConfig.endpoint.api}/coupon/${id}`
export const REJECT_COUPON = (id) => `${AppConfig.endpoint.api}/coupon/${id}`

// Upload image
export const CLOUDINARY_SIGN_UPLOAD_IMAGE = () => `${AppConfig.endpoint.api}/upload/image/sign`
export const S3_SIGN_UPLOAD_IMAGE = () => `${AppConfig.endpoint.api}/s3/presigned-url`
export const R2_SIGN_UPLOAD_IMAGE = () => `${AppConfig.endpoint.api}/r2/presigned-url`
