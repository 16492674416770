import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { Person, Close, People } from '@material-ui/icons'
import { SearchSelectionInput } from 'components'
import * as commonApi from 'provider/common'
import * as hostApi from 'provider/host'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  searchInput: {
    marginRight: theme.spacing(1),
  },
}))

const Host = ({ value, onApply }) => {
  const classes = useStyles()
  const [hostSuggestion, setHostSuggestion] = useState([])
  const [sltHost, setSltHost] = useState({})
  const [host, setHost] = useState(null)

  const fetchHost = async (hostId) => {
    try {
      const resp = await hostApi.findOne(hostId)
      setHost(`${resp.name} (${resp.code})`)
      setSltHost({
        id: resp.id,
        title: `${resp.name} (${resp.code})`,
        subTitle: `${resp.email} - ${resp.phone}`,
      })
      setHostSuggestion(
        [resp].map(opt => ({
          id: opt.id,
          title: `${opt.name} (${opt.code})`,
          subTitle: `${opt.email} - ${opt.phone}`,
        })),
      )
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (!value) {
      setHost(null)
      return () => {}
    }
    if (sltHost.id !== value) {
      fetchHost(value)
    }
    return () => {}
  }, [value])

  const onHostChange = async (str) => {
    const suggestions = await commonApi.getHostSuggestion(str)
    setHostSuggestion(
      suggestions.map(opt => ({
        id: opt.id,
        title: `${opt.display} (${opt.code})`,
        subTitle: `${opt.email} - ${opt.phone}`,
      })),
    )
  }

  const onHostSelect = async (item) => {
    setSltHost(item)
  }

  const renderHostOption = (option) => {
    const { title, subTitle } = option
    return (
      <div>
        <div style={{ fontSize: 16 }}>
          <strong>{title}</strong>
        </div>
        <div>
          <small>{subTitle}</small>
        </div>
      </div>
    )
  }

  return (
    <PopupState variant="popover" popupId="customer-filter">
      {popupState => (
        <div>
          <Button variant="outlined" {...bindTrigger(popupState)} style={{ backgroundColor: 'white', textTransform: 'none' }}>
            <Person />
            {host || 'Chủ nhà'}
            {value
              ? (
                <Close onClick={(e) => {
                  e.stopPropagation()
                  onApply(undefined)
                }}
                />
              )
              : null}
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box p={2} style={{ width: 400 }}>
              <SearchSelectionInput
                label="Chủ nhà"
                className={classes.searchInput}
                placeholder="Nhập thông tin chủ nhà"
                value={sltHost.title}
                renderOption={renderHostOption}
                suggestions={hostSuggestion}
                onChange={str => onHostChange(str)}
                onSelect={onHostSelect}
                leftIcon={<People />}
              />
              <Button
                onClick={() => {
                  onApply(sltHost.id)
                  setHost(sltHost.title)
                  popupState.close()
                }}
                style={{ float: 'right', marginBottom: 7 }}
              >
              Áp dụng
              </Button>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

Host.propTypes = {
  onApply: PropTypes.func,
  value: PropTypes.string,
}

export default Host
