import {
  Typography,
  Avatar,
  Tooltip,
  Chip,
  Button,
  Icon,
} from '@material-ui/core'
import { NavLink as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'
import { formatPrice } from 'utils/formatter'
import { Table } from 'components'
import * as roomApi from 'provider/room'
import AppConfig from 'config'
import { OfflineBolt } from '@material-ui/icons'
import { copy } from 'utils/service'
import { useSnackbar } from 'notistack'

const RoomsTable = ({
  classes,
  owner,
  loading,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [rooms, setRooms] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const [total, setTotal] = useState(0)

  const fetchRoom = async (query) => {
    try {
      const resp = await roomApi.find(query)
      setRooms(resp.data)
      setTotal(resp.total)
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (owner) {
      fetchRoom({ owner, page, limit })
    }
    return () => { }
    // eslint-disable-next-line
  }, [owner])

  const changePage = (p) => {
    setPage(p)
    const query = { owner, page: p, limit }
    fetchRoom(query)
  }

  const changeRowsPerPage = (l) => {
    setLimit(l)
    const query = { owner, page: 1, limit: l }
    fetchRoom(query)
  }

  const renderStatus = (status) => {
    switch (status) {
      case 'inactive':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontWeight: '900',
              backgroundColor: '#999999',
            }}
            label="Ẩn"
          />
        )
      case 'pending':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontWeight: '900',
              backgroundColor: '#ffa854',
            }}
            label="Xem xét"
          />
        )
      case 'active':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontWeight: '900',
              backgroundColor: '#00e676',
            }}
            label="Đang hoạt động"
          />
        )
      default:
        return null
    }
  }

  return (
    <Table
      className={classes.root}
      data={rooms}
      columns={[
        {
          Head: 'Mã',
          Cell: (value, origin) => (
            <RouterLink to={`/room/${origin._id}/update`}>
              <div>{value}</div>
            </RouterLink>
          ),
          accessor: 'code',
          className: `${classes.codeCell}`,
        },
        {
          Head: 'Ảnh',
          Cell: (value) => (
            <Avatar variant="square" src={`${AppConfig.BASE_IMG_URL}/${value}`} className={classes.avatar} />
          ),
          accessor: 'thumbnail',
          sortable: false,
          className: `${classes.avatarCell}`,
        },
        {
          Head: 'Tên phòng',
          Cell: (value, origin) => (
            <>
              <div style={{ display: 'flex' }}>
              <a
                href={`${AppConfig.endpoint.customer}/phong-can-ho/${origin.id}`}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.name}
              >
                {origin.bookingType === 'instant' ? <OfflineBolt style={{ color: '#ffc107' }} /> : null}
                <Tooltip title={value}>
                  <Typography variant="body1" className={classes.ellipsisCell}>
                    {value}
                  </Typography>
                </Tooltip>
              </a>
              <Tooltip title="Copy link đặt phòng">
                <Button
                  onClick={() => copy(`${AppConfig.endpoint.customer}/phong-can-ho/${origin.code}`, () => enqueueSnackbar('Copy link đặt phòng', { variant: 'success' }))}
                >
                  <Icon
                    fontSize="small"
                    className="far fa-clone"
                    style={{ cursor: 'pointer' }}
                  />
                </Button>
              </Tooltip>
              </div>
              {/* <Typography variant="body2" className={classes.ellipsisCell}>
                {`Toà nhà: ${origin.buildingName}`}
              </Typography> */}
              <Typography variant="body2" className={classes.ellipsisCell}>
                {`${origin.address}`}
              </Typography>
            </>
          ),
          accessor: 'name',
          sortable: false,
          className: `${classes.roomCell}`,
        },
        {
          Head: 'Giá theo giờ',
          Cell: (value, origin) => (
            <Typography variant="body1">
            {
              origin.price?.hourFirstAmount && origin.price.hourFirstBaseFee
                ? `${formatPrice(origin.price.hourFirstAmount * origin.price.hourFirstBaseFee)}VND/${origin.price.hourFirstAmount}h`
                : '-'
            }
            </Typography>
          ),
          accessor: 'hourPrice',
        },
        {
          Head: 'Giá ngày',
          Cell: (value, origin) => <Typography variant="body1">{`${formatPrice(origin.price.dayBaseFee)}VND`}</Typography>,
          accessor: 'dayPrice',
        },
        {
          Head: 'Số đơn',
          Cell: (value, origin) => (
            <RouterLink to={`/reservations?room=["${origin.id}"]`} target="_blank">
              <a style={{ color: '#3f51b5' }}>{`${value?.total || 0} đơn`}</a>
            </RouterLink>
          ),
          accessor: 'orders',
          sortable: true,
        },
        {
          Head: 'Trạng thái',
          Cell: value => renderStatus(value),
          accessor: 'status',
          sortable: false,
        },
      ]}
      loading={loading}
      page={page}
      limit={limit}
      total={total}
      onPageChange={changePage}
      onRowsPerPageChange={changeRowsPerPage}
    />
  )
}

RoomsTable.propTypes = {
  classes: PropTypes.shape(),
  owner: PropTypes.string,
  loading: PropTypes.bool,
}

export default RoomsTable
