import React, { useEffect, useState } from 'react'
import {
  Typography,
  // Chip,
  // Tooltip,
} from '@material-ui/core'
import { NavLink as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { formatPrice } from 'utils/formatter'
import { Table } from 'components'

const Services = ({
  // classes,
  loading,
  data,
  meta,
  setting,
  onSortChange,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const [sortBy, setSortBy] = useState(null)
  const [sortDirection, setSortDirection] = useState(null)

  useEffect(() => {
    console.log('here')
    return () => {
    }
  }, [])

  const handlePageChange = (page) => {
    onPageChange(page)
  }

  const handleRowsPerPageChange = (limit) => {
    onRowsPerPageChange(limit)
  }

  const handleSortChange = (_sortBy, _sortDirection) => {
    onSortChange(_sortBy, _sortDirection)
    setSortBy(_sortBy)
    setSortDirection(_sortDirection)
  }

  return (
    <>
      {/* Reservation list */}
      <Table
        data={data}
        columns={[
          {
            Head: 'Dịch vụ',
            Cell: (value, origin) => (
              <div className="d-flex flex-column">
                <RouterLink to={`/service/${origin.code}/update`} target="_blank">
                  <Typography variant="body1">{`${value} (${origin.code})`}</Typography>
                </RouterLink>
              </div>
            ),
            accessor: 'name',
            sortable: false,
            hidden: !setting['Dịch vụ'],
          },
          {
            Head: (
              <div>
                <div>Số lượt đặt</div>
                <div>{`(${meta?.totalOrders} đơn)`}</div>
              </div>
            ),
            Cell: (value, origin) => (<Typography variant="div">{`${value} đơn (${origin.rating}%)`}</Typography>),
            accessor: 'totalOrders',
            sortable: true,
            hidden: !setting['Số lượt đặt'],
          },
          {
            Head: (
              <div>
                <div>Doanh thu</div>
                <div>{`(${formatPrice(meta?.totalRevenueService)}đ (${Number((meta?.totalRevenueService * 100) / ((meta?.totalRevenueService + meta?.totalRevenueRoom) || 1)).toFixed(2)}%))`}</div>
              </div>
            ),
            Cell: (value, origin) => (<Typography variant="div">{`${formatPrice(value)}đ (${Number((origin?.revenueService * 100) / ((origin?.revenueService + origin?.revenueRoom) || 1)).toFixed(2)}%)`}</Typography>),
            accessor: 'revenueService',
            sortable: true,
            hidden: !setting['Doanh thu'],
          },
        ]}
        loading={loading}
        page={meta?.page || 1}
        limit={meta?.limit || 10}
        total={meta?.total || 0}
        defaultSort={{ sortBy, sortDirection }}
        onSortChange={handleSortChange}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </>
  )
}

Services.propTypes = {
  // classes: PropTypes.shape(),
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape()),
  meta: PropTypes.shape(),
  setting: PropTypes.shape(),
  onSortChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
}

export default Services
