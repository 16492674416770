import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Typography,
  Divider,
  LinearProgress,
} from '@material-ui/core'
import { DATETIME_FORMAT } from 'config'
import MediaUpload from 'components/MediaUpload'

const AccountProfile = ({
  classes,
  user,
  updateInfo,
}) => {
  const onUploadAvatarSuccess = (files) => {
    updateInfo({ avatar: files[0] })
  }

  return (
    <Card>
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography variant="h2">{user.name}</Typography>
            <Typography
              className={classes.locationText}
              color="textSecondary"
              variant="body2"
            >
              {user.account}
            </Typography>
            <Typography
              className={classes.locationText}
              style={{ marginTop: 15 }}
              color="textSecondary"
              variant="body1"
            >
              {user.address}
            </Typography>
            <Typography
              className={classes.dateText}
              color="textSecondary"
              variant="body1"
            >
              {moment(user.createdAt).format(DATETIME_FORMAT)}
            </Typography>
          </div>
          <Avatar className={classes.avatar} src={user.avatar} />
        </div>
        <div className={classes.progress}>
          <Typography variant="body1">Hoàn thành hồ sơ: 100%</Typography>
          <LinearProgress value={100} variant="determinate" />
        </div>
      </CardContent>
      <Divider />
      <CardActions>
        <MediaUpload
          title="Tải lên ảnh đại diện"
          multiple
          onSuccess={onUploadAvatarSuccess}
        />
        {/* <Button variant="text">Xoá ảnh</Button> */}
      </CardActions>
    </Card>
  )
}

AccountProfile.propTypes = {
  classes: PropTypes.shape(),
  user: PropTypes.shape(),
  updateInfo: PropTypes.func,
}

export default AccountProfile
