import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core'
import BlockUI from 'components/BlockUI'
import { useSnackbar } from 'notistack'
import Upload from 'components/Upload'
import uploadApi from 'provider/upload'
import * as commonApi from 'provider/common'

const DistrictDialog = ({
  open,
  city,
  onClose,
  onConfirm,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [image, setImage] = useState([])
  const [name, setName] = useState('')

  const onCreateDistrict = async () => {
    try {
      setLoading(true)
      const districtImage = await uploadApi.uploadImage([image[0]?.file], 'Admin/District')
      await commonApi.createDistrict(city, name, districtImage[0].shortUrl)
      setLoading(false)
      setImage([])
      setName('')
      onConfirm()
    } catch (e) {
      setLoading(false)
      enqueueSnackbar(`${e.response.data} (code: ${e.response ? e.response.status : 'Unknown'})`, { variant: 'error' })
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <BlockUI isLoading={loading}>
        <DialogTitle disableTypography>
          <h4>Thông tin quận mới</h4>
        </DialogTitle>
        <DialogContent style={{ minWidth: 600 }}>
          <Typography variant="body">Ảnh đại diện:</Typography>
          <div style={{ marginBottom: 10 }}>
            <Upload
              files={image}
              multiple={false}
              onChange={files => setImage(files)}
            />
          </div>
          <TextField
            style={{ backgroundColor: 'white', marginTop: 16, marginRight: 8 }}
            label="Tên quận"
            variant="outlined"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Nhập tên quận"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onCreateDistrict} color="primary">
            Xác nhận
          </Button>
        </DialogActions>
      </BlockUI>
    </Dialog>
  )
}

DistrictDialog.propTypes = {
  open: PropTypes.bool,
  city: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default DistrictDialog
