const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  subTitle: {
    fontSize: 16,
    fontWeight: '900',
    marginBottom: 15,
  },
  formControl: {
    backgroundColor: 'white',
  },
  rowAlign: {
    margin: '0px -16px',
  },
  priceBox: {
    backgroundColor: '#e5e5e5',
    // borderRadius: 10,
  },
  mediaBox: {
    overflowX: 'auto',
    display: 'flex',
    backgroundColor: '#e5e5e5',
    marginBottom: 5,
    '& img': {
      width: 150,
      height: 150,
      padding: 5,
    },
  },
  optionBox: {
    padding: 15,
    marginTop: 10,
    marginBottom: 10,
    border: 'solid 1px #d5d5d5',
  },
})

export default styles
