import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  Button,
  Divider,
  Grid,
  withStyles,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import {
  findOne,
  create,
  update,
} from 'provider/coupon'
import { useSnackbar } from 'notistack'
import BackgroundBackdrop from 'components/Backdrop'
import styles from './styled'
import Room from './Room'
import Customer from './Customer'
import Type from './Type'
import Value from './Value'
import ExpireDate from './ExpireDate'
import Service from './Service'
// import Media from './Media'
// import Description from './Description'

const validateSchema = Yup.object().shape({})

const init = {
  code: '',
  useFor: 'room', // room | service
  customers: [],
  rooms: [],
  services: [],
  type: '', // date_range | time_limit | date_or_time
  count: 0, // Số lần có thể sử dụng coupon
  value: 0, // Giá trị của coupon, tính bằng phần trăm giảm giá hoặc số tiền
  valueType: 'money', // money | percent
  startdate: undefined,
  enddate: undefined,
  description: '',
}

const CouponForm = ({
  classes,
  id,
  action,
  history,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [coupon, setCoupon] = useState(init)
  const [loading, setLoading] = useState(false)
  const [submitData, setSubmitData] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  const fetchCoupon = async () => {
    try {
      const resp = await findOne(id)
      setCoupon({
        code: resp.code,
        useFor: resp.useFor,
        customers: resp?.customers?.map(c => c.id),
        rooms: resp?.rooms?.map(r => r.id),
        type: resp.type,
        count: resp.count,
        value: resp.value,
        valueType: resp.valueType,
        startdate: resp.startdate,
        enddate: resp.enddate,
        description: resp.description,
      })
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (action === 'update') {
      fetchCoupon()
    }
    // eslint-disable-next-line
  }, [])

  const getCouponType = (data) => {
    if (data.count && (!data.startdate || !data.enddate)) {
      return 'time_limit'
    }
    if (!data.count && (data.startdate && data.enddate)) {
      return 'date_range'
    }
    if (data.count && (data.startdate && data.enddate)) {
      return 'date_or_time'
    }
    return 'time_limit'
  }

  const onSubmit = async (data) => {
    setSubmitData(data)
    setOpenConfirmDialog(true)
  }

  const confirm = async () => {
    if (!submitData) return
    setLoading(true)
    try {
      switch (action) {
        case 'update':
          await update(id, submitData)
          // setCoupon(submitData)
          history.push('/coupon')
          setLoading(false)
          enqueueSnackbar('Cập nhật mã khuyến mãi thành công.', { variant: 'success' })
          break
        default:
          await create({ ...submitData, count: submitData.count || Number.MAX_SAFE_INTEGER, type: getCouponType(submitData) })
          history.push('/coupon')
          setLoading(false)
          enqueueSnackbar('Tạo mã khuyến mãi thành công.', { variant: 'success' })
      }
    } catch (e) {
      console.log(e)
      setLoading(false)
      enqueueSnackbar(`Máy chủ lỗi (code: ${e.response ? e.response.status : 'Unknown'}). Không thể tạo mã khuyến mãi.`, { variant: 'error' })
    }
  }

  return (
    <BackgroundBackdrop loading={loading}>
      {/* Confirm dialog */}
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
      >
        <DialogTitle disableTypography>
          Xác nhận tạo mã khuyến mại
        </DialogTitle>
        <DialogContent style={{ minWidth: 600, minHeight: 80, paddingTop: 0 }}>
          Bạn có chắc chắn muốn tạo mã khuyến mại này?
        </DialogContent>
        <DialogActions>
          <Button onClick={confirm} color="primary">
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
      <Formik
        initialValues={coupon}
        enableReinitialize
        validationSchema={validateSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          setFieldValue,
        }) => (
          <Form className={classes.root}>
            <Typography variant="h3">
              {action === 'update' ? 'Cập nhật mã khuyến mãi' : 'Tạo mã khuyến mãi'}
            </Typography>
            {/* Danh sách khách hàng áp dụng */}
            <Grid container lg={6} md={6} className="my-3" spacing={2}>
              <Grid item lg={6} md={6}>
                <Customer
                  classes={classes}
                  values={values}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              </Grid>
            </Grid>
            {/* Lựa chọn coupon sử dụng cho phòng hay dịch vụ? */}
            <FormControl component="fieldset">
              <FormLabel component="legend">Mã khuyến mại dành cho: </FormLabel>
              <RadioGroup className="d-flex flex-row" aria-label="useFor" name="useFor" value={values.useFor} onChange={handleChange}>
                <FormControlLabel value="room" control={<Radio />} label="Phòng" />
                <FormControlLabel value="service" control={<Radio />} label="Dịch vụ" />
              </RadioGroup>
            </FormControl>
            {/* Danh sách phòng áp dụng */}
            {values.useFor === 'room'
              ? (
                <Grid container lg={6} md={6} className="my-3" spacing={2}>
                  <Grid item lg={6} md={6}>
                    <Room
                      classes={classes}
                      values={values}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                </Grid>
              )
              : null}
            {/* Danh sách dịch vụ áp dụng */}
            {values.useFor === 'service'
              ? (
                <Grid container lg={6} md={6} className="my-3" spacing={2}>
                  <Grid item lg={6} md={6}>
                    <Service
                      classes={classes}
                      values={values}
                      touched={touched}
                      errors={errors}
                      handleChange={handleChange}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                </Grid>
              )
              : null}
            {/* Kiểu coupon */}
            <Grid container lg={6} md={6} className="my-3" spacing={2}>
              <Type
                classes={classes}
                values={values}
                touched={touched}
                errors={errors}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
              />
            </Grid>
            {/* Thông tin coupon */}
            <Grid container lg={12} md={12} className="my-3" spacing={2}>
              {/* Giá trị */}
              <Grid item lg={6} md={6}>
                <Value
                  classes={classes}
                  values={values}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              </Grid>
              {/* Thời hạn sử dụng */}
              <Grid item lg={6} md={6}>
                <ExpireDate
                  classes={classes}
                  values={values}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              </Grid>
            </Grid>
            <Divider style={{ margin: '20px auto', backgroundColor: '#d9d9d9' }} />
            {/* Create button */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
            >
              {action === 'update' ? 'Cập nhật' : 'Tạo mã'}
            </Button>
          </Form>
        )}
      </Formik>
    </BackgroundBackdrop>
  )
}

CouponForm.propTypes = {
  classes: PropTypes.shape(),
  id: PropTypes.string,
  action: PropTypes.string,
  history: PropTypes.shape(),
}

export default withStyles(styles)(CouponForm)
