const styles = theme => ({
  root: {
    padding: 20,
  },
  content: {
    marginTop: theme.spacing(2),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  avatar: {
    borderRadius: 5,
    width: 120,
    height: 80,
    '@media (max-width:1280px)': {
      width: 90,
      height: 60,
    },
    '@media (min-width:1281px) and (max-width:1440px)': {
      width: 100,
      height: 70,
    },
  },
  name: {
    marginRight: 5,
    width: '100%',
    display: 'flex',
    maxWidth: 160,
    '@media (max-width:1280px)': {
      maxWidth: 110,
    },
    '@media (min-width:1281px) and (max-width:1440px)': {
      maxWidth: 130,
    },
  },
  ellipsisCell: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  address: {
    maxWidth: 160,
    '@media (max-width:1280px)': {
      maxWidth: 110,
    },
    '@media (min-width:1281px) and (max-width:1440px)': {
      maxWidth: 130,
    },
  },
  codeCell: {
    color: '#3f51b5',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  hostCell: {
    color: '#3f51b5',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  actionCell: {
    width: 120,
    '& button': {
      padding: 8,
    },
  },
})

export default styles
