import { connect } from 'react-redux'
import { SignIn as SigninPage } from 'views'
import { getUserData } from 'redux/selector/user'
import { login } from 'redux/action/user'

const mapDispatchToProps = dispatch => ({
  login: (email, password) => dispatch(login(email, password)),
})

export default connect(getUserData, mapDispatchToProps)(SigninPage)
