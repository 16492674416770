import { connect } from 'react-redux'
import { CustomerList as CustomerPage } from 'views'
import { getCustomerData } from 'redux/selector/customer'
import { find } from 'redux/action/customer'

const mapDispatchToProps = dispatch => ({
  find: query => dispatch(find(query)),
})

export default connect(getCustomerData, mapDispatchToProps)(CustomerPage)
