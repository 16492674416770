import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { withStyles, Grid } from '@material-ui/core'
import Order from './Order'
import Customer from './Customer'
import Host from './Host'
import Room from './Room'
import Revenue from './Revenue'
import LatestOrders from './LatestOrders'
// import UsersByDevice from './UsersByDevice'
// import LatestSales from './LatestSales'
// import LatestProducts from './LatestProducts'
import styles from './styled'

const Dashboard = ({
  classes,
  dashboard: {
    status,
    data,
  },
  getDashboard,
  getLastOrders,
  getRevenue,
}) => {
  useEffect(() => {
    getDashboard()
    getLastOrders()
    getRevenue()
    return () => {}
  }, [])

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={3} sm={3} xl={3} xs={12}>
          <Order data={status.order} />
        </Grid>
        <Grid item lg={3} sm={3} xl={3} xs={12}>
          <Customer data={status.customer} />
        </Grid>
        <Grid item lg={3} sm={3} xl={3} xs={12}>
          <Host data={status.host} />
        </Grid>
        <Grid item lg={3} sm={3} xl={3} xs={12}>
          <Room data={status.room} />
        </Grid>
        <Grid item lg={8} md={8} xl={9} xs={12}>
          <LatestOrders orders={data.orders} onRefresh={getLastOrders} />
        </Grid>
        <Grid item lg={4} md={4} xl={3} xs={12}>
          <Revenue data={data.revenue} onRefresh={getRevenue} />
        </Grid>
        {/* <Grid item lg={4} md={6} xl={3} xs={12}>
          <LatestProducts />
        </Grid>
        <Grid item lg={8} md={12} xl={9} xs={12}>
          <LatestSales />
        </Grid> */}
      </Grid>
    </div>
  )
}

Dashboard.propTypes = {
  classes: PropTypes.shape(),
  dashboard: PropTypes.shape(),
  getDashboard: PropTypes.func,
  getLastOrders: PropTypes.func,
  getRevenue: PropTypes.func,
}

export default withStyles(styles)(Dashboard)
