export const USER_ACTION = {
  LOGOUT: 'LOGOUT',
  LOGIN: 'LOGIN',
  LOGIN_OK: 'LOGIN_OK',
  LOGIN_ERR: 'LOGIN_ERR',
  GET_INFO: 'GET_INFO',
  GET_INFO_OK: 'GET_INFO_OK',
  GET_INFO_ERR: 'GET_INFO_ERR',
  UPDATE_INFO: 'UPDATE_INFO',
  UPDATE_INFO_OK: 'UPDATE_INFO_OK',
  UPDATE_INFO_ERR: 'UPDATE_INFO_ERR',
}

export const NOTIFICATION_ACTION = {
  FETCH: 'FETCH_NOTIFICATION',
  FETCH_OK: 'FETCH_NOTIFICATION_OK',
  FETCH_ERR: 'FETCH_NOTIFICATION_ERR',
  UPDATE: 'UPDATE_NOTIFICATION',
  UPDATE_OK: 'UPDATE_NOTIFICATION_OK',
  UPDATE_ERR: 'UPDATE_NOTIFICATION_ERR',
}

export const STAFF_ACTION = {
  FETCH: 'FETCH_STAFF',
  FETCH_OK: 'FETCH_STAFF_OK',
  FETCH_ERR: 'FETCH_STAFF_ERR',
}

export const SERVICE_ACTION = {
  FETCH: 'FETCH_SERVICE',
  FETCH_OK: 'FETCH_SERVICE_OK',
  FETCH_ERR: 'FETCH_SERVICE_ERR',
}

export const RESERVATION_ACTION = {
  FETCH: 'FETCH_RESERVATION',
  FETCH_OK: 'FETCH_RESERVATION_OK',
  FETCH_ERR: 'FETCH_RESERVATION_ERR',
  PAY: 'PAY_RESERVATION',
  PAY_OK: 'PAY_RESERVATION_OK',
  PAY_ERR: 'PAY_RESERVATION_ERR',
  REJECT: 'REJECT_RESERVATION',
  REJECT_OK: 'REJECT_RESERVATION_OK',
  REJECT_ERR: 'REJECT_RESERVATION_ERR',
  CONFIRM: 'CONFIRM_RESERVATION',
  CONFIRM_OK: 'CONFIRM_RESERVATION_OK',
  CONFIRM_ERR: 'CONFIRM_RESERVATION_ERR',
  UNDO: 'UNDO_RESERVATION',
  UNDO_OK: 'UNDO_RESERVATION_OK',
  UNDO_ERR: 'UNDO_RESERVATION_ERR',
  REFUND: 'REFUND_RESERVATION',
  REFUND_OK: 'REFUND_RESERVATION_OK',
  REFUND_ERR: 'REFUND_RESERVATION_ERR',
  ADD_CHECKOUT: 'ADD_CHECKOUT',
  ADD_CHECKOUT_OK: 'ADD_CHECKOUT_OK',
  ADD_CHECKOUT_ERR: 'ADD_CHECKOUT_ERR',
  REJECT_CHECKOUT: 'REJECT_CHECKOUT',
  REJECT_CHECKOUT_OK: 'REJECT_CHECKOUT_OK',
  REJECT_CHECKOUT_ERR: 'REJECT_CHECKOUT_ERR',
}

export const AREA_ACTION = {
  FETCH: 'FETCH_AREA',
  FETCH_OK: 'FETCH_AREA_OK',
  FETCH_ERR: 'FETCH_AREA_ERR',
}

export const ROOM_ACTION = {
  FETCH: 'FETCH_ROOM',
  FETCH_OK: 'FETCH_ROOM_OK',
  FETCH_ERR: 'FETCH_ROOM_ERR',
}

export const CUSTOMER_ACTION = {
  FETCH: 'FETCH_CUSTOMER',
  FETCH_OK: 'FETCH_CUSTOMER_OK',
  FETCH_ERR: 'FETCH_CUSTOMER_ERR',
}

export const PREPAID_HISTORY_ACTION = {
  FETCH: 'FETCH_PREPAID_HISTORY',
  FETCH_OK: 'FETCH_PREPAID_HISTORY_OK',
  FETCH_ERR: 'FETCH_PREPAID_HISTORY_ERR',
  UPDATE: 'UPDATE_PREPAID_HISTORY',
  UPDATE_OK: 'UPDATE_PREPAID_HISTORY_OK',
  UPDATE_ERR: 'UPDATE_PREPAID_HISTORY_ERR',
}

export const HOST_ACTION = {
  FETCH: 'FETCH_HOST',
  FETCH_OK: 'FETCH_HOST_OK',
  FETCH_ERR: 'FETCH_HOST_ERR',
}

export const SOCKET_ACTION = {
  SET_MESSAGE: 'SET_MESSAGE',
  RESET_ORDER_NEW: 'RESET_ORDER_NEW',
  RESET_ORDER_PAID: 'RESET_ORDER_PAID',
  RESET_ORDER_UPDATE: 'RESET_ORDER_UPDATE',
  RESET_ORDER_REJECT: 'RESET_ORDER_REJECT',
  RESET_CUSTOMER_JOIN: 'RESET_CUSTOMER_JOIN',
  RESET_PREPAID_PACKAGE: 'RESET_PREPAID_PACKAGE',
  RESET_ORDER_LIST_ACTIVE: 'RESET_LIST_ORDER_ACTIVE',
  RESET_ORDER_LIST_REFUND: 'RESET_ORDER_LIST_REFUND',
  RESET_ORDER_LIST_DEPOSIT: 'RESET_ORDER_LIST_DEPOSIT',
  RESET_ORDER_SIDEBAR: 'RESET_ORDER_SIDEBAR',
  RESET_CUSTOMER_SIDEBAR: 'RRESET_CUSTOMER_SIDEBAR',
  RESET_PACKAGE_SIDEBAR: 'RESET_PACKAGE_SIDEBAR',
}

export const COMMON_ACTION = {
  FETCH_DASHBOARD: 'FETCH_DASHBOARD',
  FETCH_DASHBOARD_OK: 'FETCH_DASHBOARD_OK',
  FETCH_DASHBOARD_ERR: 'FETCH_DASHBOARD_ERR',
  FETCH_LAST_ORDER: 'FETCH_LAST_ORDER',
  FETCH_LAST_ORDER_OK: 'FETCH_LAST_ORDER_OK',
  FETCH_LAST_ORDER_ERR: 'FETCH_LAST_ORDER_ERR',
  FETCH_REVENUE: 'FETCH_REVENUE',
  FETCH_REVENUE_OK: 'FETCH_REVENUE_OK',
  FETCH_REVENUE_ERR: 'FETCH_REVENUE_ERR',
}

export const COUPON_ACTION = {
  FETCH: 'FETCH_COUPON',
  FETCH_OK: 'FETCH_COUPON_OK',
  FETCH_ERR: 'FETCH_COUPON_ERR',
}
