import React, { useState } from 'react'
import {
  Avatar,
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'
import { NavLink as RouterLink } from 'react-router-dom'
import { getInitials } from 'helpers'
import PropTypes from 'prop-types'
import { Table } from 'components'
import { Delete, Restore } from '@material-ui/icons'
import * as hostApi from 'provider/host'
import { getFiltersFromUrl } from 'utils/url'

const HostsTable = ({
  classes,
  loading,
  hosts,
  meta,
  find,
  defaultSort,
  onSortChange,
  onPageChange,
  onRowsPerPageChange,
  location,
}) => {
  const [sltHost, setSltHost] = useState(null)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)

  const handlePageChange = (page) => {
    onPageChange(page)
  }

  const handleRowsPerPageChange = (limit) => {
    onRowsPerPageChange(limit)
  }

  const handleSortChange = (sortBy, sortDirection) => {
    onSortChange(sortBy, sortDirection)
  }

  const handleOpenConfirmDialog = async (host) => {
    setOpenConfirmDialog(true)
    setSltHost(host)
  }

  const deactiveHost = async () => {
    try {
      await hostApi.deactive(sltHost._id || sltHost.id)
      const query = getFiltersFromUrl(location.search)
      find(query)
      setOpenConfirmDialog(false)
      setSltHost(null)
    } catch (e) {
      console.log(e)
    }
  }

  const activeHost = async () => {
    try {
      await hostApi.update({ id: sltHost._id || sltHost.id, status: 1 })
      const query = getFiltersFromUrl(location.search)
      find(query)
      setOpenConfirmDialog(false)
      setSltHost(null)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <Dialog
        open={openConfirmDialog}
        onClose={() => {
          setOpenConfirmDialog(false)
          setSltHost(null)
        }}
      >
        <DialogTitle disableTypography>
          {sltHost && <h3>{`${sltHost.status !== 2 ? 'Tạm dừng' : 'Khôi phục'} tài khoản chủ nhà`}</h3>}
        </DialogTitle>
        <DialogContent style={{ minWidth: 600, minHeight: 80 }}>
          {sltHost && <div dangerouslySetInnerHTML={{ __html: `Xác nhận ${sltHost.status !== 2 ? 'tạm dừng' : 'khôi phục'} tài khoản chủ nhà <b>${sltHost ? sltHost.name : ''}</b>?` }} />}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenConfirmDialog(false)
              setSltHost(null)
            }}
            color="default"
          >
            Thoát
          </Button>
          <Button
            onClick={sltHost?.status === 1 ? deactiveHost : activeHost}
            color="primary"
          >
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
      <Table
        data={hosts}
        columns={[
          {
            Head: 'Mã',
            Cell: (value, origin) => (
              <RouterLink
                to={`/host/${origin.code}/update`}
                className={classes.codeCell}
              >
                {value}
              </RouterLink>
            ),
            accessor: 'code',
            sortable: false,
          },
          {
            Head: 'Tên',
            Cell: (value, origin) => (
              <div className={classes.nameContainer}>
                <Avatar className={classes.avatar} src={origin.avatar}>
                  {getInitials(value)}
                </Avatar>
                <Typography variant="body1">{value}</Typography>
              </div>
            ),
            accessor: 'name',
            sortable: true,
          },
          {
            Head: 'Liên hệ',
            Cell: (value, origin) => (
              <>
                <Tooltip title={value}>
                  <Typography variant="body1">{value}</Typography>
                </Tooltip>
                {origin.phone ? (
                  <Typography variant="body2">{`SĐT: ${origin.phone}`}</Typography>
                ) : null}
              </>
            ),
            accessor: 'email',
            sortable: false,
          },
          {
            Head: 'Phòng',
            Cell: (value, origin) => (
              <RouterLink
                to={`/room?owner=${origin._id}`}
                style={{ color: '#3f51b5' }}
              >
                {`${origin.rooms?.total || 0} phòng`}
              </RouterLink>
            ),
            accessor: 'roomCount',
            sortable: true,
          },
          {
            Head: 'Số đơn',
            Cell: (value, origin) => (
              <RouterLink
                to={`/reservations?host[]=${origin._id}`}
                style={{ color: '#3f51b5' }}
              >
                {`${origin.orders?.total || 0} đơn`}
              </RouterLink>
            ),
            accessor: 'orderCount',
            sortable: true,
          },
          {
            Head: 'Địa chỉ',
            accessor: 'address',
            sortable: false,
          },
          {
            Head: '',
            Cell: (value, origin) => {
              if (origin.status === 2) {
                return (
                  <Tooltip title="Khôi phục tài khoản" aria-label="Khôi phục tài khoản">
                    <IconButton
                      aria-label="edit"
                      className={classes.margin}
                      onClick={() => handleOpenConfirmDialog(origin)}
                    >
                      <Restore />
                    </IconButton>
                  </Tooltip>
                )
              }
              return (
                <Tooltip title="Tạm dừng tài khoản" aria-label="Tạm dừng tài khoản">
                  <IconButton
                    aria-label="pantool"
                    className={classes.margin}
                    onClick={() => handleOpenConfirmDialog(origin)}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              )
            },
            accessor: 'id',
          },
        ]}
        loading={loading}
        page={meta.page}
        limit={meta.limit}
        total={meta.total}
        defaultSort={defaultSort}
        onSortChange={handleSortChange}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </>
  )
}

HostsTable.propTypes = {
  classes: PropTypes.shape(),
  loading: PropTypes.bool,
  hosts: PropTypes.arrayOf(PropTypes.shape()),
  meta: PropTypes.shape(),
  find: PropTypes.func,
  defaultSort: PropTypes.shape(),
  onSortChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  location: PropTypes.shape(),
}

export default HostsTable
