import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControlLabel,
  FormLabel,
  FormControl,
  RadioGroup,
  Radio,
} from '@material-ui/core'

const Status = ({ data, value, onApply }) => {
  const onSelectStatus = (sltValue) => {
    onApply(sltValue)
  }

  return (
    <FormControl component="div" className="d-flex flex-row align-items-center">
      <FormLabel className="mr-2" component="div">Trạng thái:  </FormLabel>
      <RadioGroup className="d-flex flex-row align-items-center mx-1" value={value} onChange={e => onSelectStatus(e.target.value)}>
        {
          data.map(item => <FormControlLabel className="mb-0" value={item.value} control={<Radio />} label={item.label} />)
        }
      </RadioGroup>
    </FormControl>
  )
}

Status.propTypes = {
  onApply: PropTypes.func,
  value: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.string),
}

export default Status
