/* eslint-disable no-useless-catch */
import axios from 'axios'
import { REPORT_ORDER_LIST } from 'config'
import moment from 'moment'
import * as ENDPOINT from './url'

export const fetchRevenueGraph = async (query) => {
  try {
    const startTime = query?.startDate || moment().startOf('day').valueOf()
    const endTime = query?.endDate || moment().endOf('day').valueOf()
    const resp = await axios.get(ENDPOINT.FETCH_REVENUE_GRAPH({
      from: startTime,
      to: endTime,
    }))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const fetchOrderCountGraph = async (query) => {
  try {
    const startTime = query?.startDate || moment().startOf('day').valueOf()
    const endTime = query?.endDate || moment().endOf('day').valueOf()
    const resp = await axios.get(ENDPOINT.FETCH_ORDER_COUNT_GRAPH({
      from: startTime,
      to: endTime,
    }))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const fetchRoomCountGraph = async (query) => {
  try {
    const startTime = query?.startDate || moment().startOf('day').valueOf()
    const endTime = query?.endDate || moment().endOf('day').valueOf()
    const resp = await axios.get(ENDPOINT.FETCH_ROOM_COUNT_GRAPH({
      from: startTime,
      to: endTime,
    }))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const fetchHostCountGraph = async (query) => {
  try {
    const startTime = query?.startDate || moment().startOf('day').valueOf()
    const endTime = query?.endDate || moment().endOf('day').valueOf()
    const resp = await axios.get(ENDPOINT.FETCH_HOST_COUNT_GRAPH({
      from: startTime,
      to: endTime,
    }))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const fetchCustomerCountGraph = async (query) => {
  try {
    const startTime = query?.startDate || moment().startOf('day').valueOf()
    const endTime = query?.endDate || moment().endOf('day').valueOf()
    const resp = await axios.get(ENDPOINT.FETCH_CUSTOMER_COUNT_GRAPH({
      from: startTime,
      to: endTime,
    }))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const fetchCustomerOrderGraph = async (query) => {
  try {
    const startTime = query?.startDate || moment().startOf('day').valueOf()
    const endTime = query?.endDate || moment().endOf('day').valueOf()
    const resp = await axios.get(ENDPOINT.FETCH_CUSTOMER_ORDER_GRAPH({
      from: startTime,
      to: endTime,
    }))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const fetchOrderAverageGraph = async (query) => {
  try {
    const startTime = query?.startDate || moment().startOf('day').valueOf()
    const endTime = query?.endDate || moment().endOf('day').valueOf()
    const resp = await axios.get(ENDPOINT.FETCH_ORDER_AVERAGE_GRAPH({
      from: startTime,
      to: endTime,
    }))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const fetchOrderList = async (query) => {
  const {
    startdate,
    enddate,
    sortBy,
    sortDirection,
    searchTxt,
    building,
    host,
    room,
  } = query || {}
  try {
    const resp = await axios.get(ENDPOINT.FETCH_ORDER_LIST_REPORT({
      startdate,
      enddate,
      sortBy,
      sortDirection,
      searchTxt,
      building,
      host,
      room,
      page: query.page || 1,
      limit: query.limit || REPORT_ORDER_LIST,
    }))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const fetchOrderAverage = async (query) => {
  const {
    startdate,
    enddate,
    searchTxt,
    building,
    host,
    room,
  } = query || {}
  try {
    const resp = await axios.get(ENDPOINT.FETCH_ORDER_AVERAGE_REPORT({
      startdate,
      enddate,
      searchTxt,
      building,
      host,
      room,
    }))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const fetchRoomList = async (query) => {
  const {
    startdate,
    enddate,
    sortBy,
    sortDirection,
    searchTxt,
  } = query || {}
  try {
    const resp = await axios.get(ENDPOINT.FETCH_ROOM_LIST_REPORT(), {
      params: {
        startdate,
        enddate,
        sortBy,
        sortDirection,
        searchTxt,
        page: query.page || 1,
        limit: query.limit || REPORT_ORDER_LIST,
      },
    })
    return resp.data
  } catch (e) {
    throw e
  }
}

export const fetchRoomAverage = async (query) => {
  const {
    startdate,
    enddate,
    searchTxt,
  } = query || {}
  try {
    const resp = await axios.get(ENDPOINT.FETCH_ROOM_AVERAGE_REPORT(), {
      params: {
        startdate,
        enddate,
        searchTxt,
      },
    })
    return resp.data
  } catch (e) {
    throw e
  }
}

export const fetchServiceList = async (query) => {
  const {
    startdate,
    enddate,
    sortBy,
    sortDirection,
    searchTxt,
  } = query || {}
  try {
    const resp = await axios.get(ENDPOINT.FETCH_SERVICE_LIST_REPORT({
      startdate,
      enddate,
      sortBy,
      sortDirection,
      searchTxt,
      page: query.page || 1,
      limit: query.limit || REPORT_ORDER_LIST,
    }))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const fetchCustomerList = async (query) => {
  const {
    startdate,
    enddate,
    sortBy,
    sortDirection,
    searchTxt,
  } = query || {}
  try {
    const resp = await axios.get(ENDPOINT.FETCH_CUSTOMER_LIST_REPORT({
      startdate,
      enddate,
      sortBy,
      sortDirection,
      searchTxt,
      page: query.page || 1,
      limit: query.limit || REPORT_ORDER_LIST,
    }))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const fetchHostList = async (query) => {
  const {
    startdate,
    enddate,
    sortBy,
    sortDirection,
    searchTxt,
  } = query || {}
  try {
    const resp = await axios.get(ENDPOINT.FETCH_HOST_LIST_REPORT({
      startdate,
      enddate,
      sortBy,
      sortDirection,
      searchTxt,
      page: query.page || 1,
      limit: query.limit || REPORT_ORDER_LIST,
    }))
    return resp.data
  } catch (e) {
    throw e
  }
}
