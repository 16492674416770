import * as staffApi from 'provider/staff'
import { STAFF_ACTION } from '../type'

export const find = query => async (dispatch, getState) => {
  const { page: pageProp, limit: limitProp } = getState().getIn(['staff', 'meta']).toJS()
  const data = getState().getIn(['staff', 'data']).toJS()
  const pageQuery = getState().getIn(['staff', 'query']).toJS()
  const convertedQuery = {
    page: pageProp,
    limit: limitProp,
    ...query,
  }
  if (!data || data.length === 0 || JSON.stringify(convertedQuery) !== pageQuery) {
    dispatch({
      type: STAFF_ACTION.FETCH,
    })
    try {
      const staff = await staffApi.find(query)
      dispatch({
        type: STAFF_ACTION.FETCH_OK,
        payload: { query, ...staff },
      })
    } catch (e) {
      dispatch({
        type: STAFF_ACTION.FETCH_ERR,
        payload: { message: 'Something went wrong' },
      })
    }
  }
}
