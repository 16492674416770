import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink as RouterLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { Fab } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { applyFiltersToUrl, getFiltersFromUrl } from 'utils/url'
import { UsersToolbar, UsersTable } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
}))

const UserList = (props) => {
  const {
    staff,
    find,
    location,
    history,
  } = props
  const classes = useStyles()
  const [active, setActive] = useState(0)

  const updateUrl = (query) => {
    const url = `${location.pathname}${applyFiltersToUrl(query)}`
    history.push(url, { replace: false })
  }

  useEffect(() => {
    const query = getFiltersFromUrl(location.search)
    try {
      const { role } = query
      setActive(query.active || 1)
      find({ ...query, active: query.active || 1, role: JSON.stringify(role) })
    } catch (e) {
      delete query.role
      setActive(query.active || 1)
      find({ ...query, active: query.active || 1 })
    }
    return () => {}
    // eslint-disable-next-line
  }, [location.search])

  const applyFilter = (fltData) => {
    const query = { ...staff.query, ...fltData }
    updateUrl(query)
  }

  const changePage = (page) => {
    const query = { ...staff.query, page }
    updateUrl(query)
  }

  const changeRowsPerPage = (limit) => {
    const query = { ...staff.query, page: 1, limit }
    updateUrl(query)
  }

  const update = (id) => {
    history.push(`/user/${id}/update`)
  }

  return (
    <div className={classes.root}>
      <UsersToolbar
        applyFilter={applyFilter}
        location={location}
      />
      <div className={classes.content}>
        <UsersTable
          active={active}
          users={staff.data}
          meta={staff.meta}
          find={find}
          update={update}
          onPageChange={changePage}
          onRowsPerPageChange={changeRowsPerPage}
        />
      </div>
      <RouterLink to="/user/create">
        <Fab color="primary" aria-label="add" className={classes.fab}>
          <AddIcon />
        </Fab>
      </RouterLink>
    </div>
  )
}

UserList.propTypes = {
  staff: PropTypes.arrayOf(PropTypes.shape()),
  find: PropTypes.func,
  history: PropTypes.shape(),
  location: PropTypes.shape(),
}

export default UserList
