const styles = theme => ({
  root: {
    // flexGrow: 1,
    // padding: theme.spacing(3),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  subTitle: {
    fontSize: 16,
    fontWeight: '900',
    marginBottom: 15,
  },
  rowAlign: {
    // margin: '0px -16px',
    marginBottom: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
  priceBox: {
    backgroundColor: '#e5e5e5',
    padding: 10,
  },
  mediaBox: {
    overflowX: 'auto',
    display: 'flex',
    backgroundColor: '#e5e5e5',
    marginBottom: 5,
    '& img': {
      width: 150,
      height: 150,
      padding: 5,
    },
  },
})

export default styles
