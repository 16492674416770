
export const REPORT_GRAPH_PERIOD = 14
export const NOTIFY_TYPE = {
  NEW_ORDER: 'NEW_ORDER',
  PAID_ORDER: 'PAID_ORDER',
  UPDATE_ORDER: 'UPDATE_ORDER',
  REJECT_ORDER: 'REJECT_ORDER',
  SERVICE_ORDER: 'SERVICE_ORDER',
  CUSTOMER: 'CUSTOMER',
  PREPAID_PACKAGE: 'PREPAID_PACKAGE',
  HOST: 'HOST',
  ROOM: 'ROOM',
  DEPOSIT: 'DEPOSIT',
}
export const BOOKING_STATUS = [{
  id: '0',
  value: 'processing',
  label: 'Chờ xác nhận',
  active: 0,
}, {
  id: '1',
  value: 'confirmed',
  label: 'Chờ thanh toán',
  active: 0,
}, {
  id: '2',
  value: 'paid',
  label: 'Đã thanh toán',
  active: 0,
}, {
  id: '3',
  value: 'using',
  label: 'Đang sử dụng',
  active: 0,
}, {
  id: '4',
  value: 'reject-by-host',
  label: 'Chủ nhà huỷ đơn',
  active: -1,
}, {
  id: '5',
  value: 'reject',
  label: 'Huỷ đơn không thanh toán',
  active: -1,
}, {
  id: '6',
  value: 'reject-refund',
  label: 'Huỷ có hoàn tiền',
  active: 1,
}, {
  id: '7',
  value: 'reject-norefund',
  label: 'Huỷ không hoàn tiền',
  active: 1,
}, {
  id: '8',
  value: 'reject-processing',
  label: 'Huỷ chưa hoàn tiền',
  active: 2,
}, {
  id: '9',
  value: 'reject-waiting',
  label: 'Huỷ chờ cung cấp thông tin',
  active: 2,
}, {
  id: '10',
  value: 'completed',
  label: 'Hoàn thành',
  active: 1,
}, {
  id: '11',
  value: 'expired',
  label: 'Hết hạn',
  active: -1,
}, {
  id: '12',
  value: 'lock',
  label: 'Khoá phòng',
  active: 4,
}, {
  id: '13',
  value: 'waiting-refund-in-update',
  label: 'Xác nhận chỉnh sửa chờ hoàn tiền ngân hàng',
  active: 2,
}, {
  id: '14',
  value: 'processing-refund-in-update',
  label: 'Chờ xác nhận chỉnh sửa trước khi hoàn tiền',
  active: 2,
}, {
  id: '15',
  value: 'confirmed-pay-extra',
  label: 'Xác nhận chỉnh sửa chưa thanh toán',
  active: 0,
}, {
  id: '16',
  value: 'processing-pay-extra',
  label: 'Chờ xác nhận chỉnh sửa trước khi thanh toán thêm',
  active: 0,
}, {
  id: '17',
  value: 'add-checkout-processing',
  label: 'Đặt ở thêm chờ xác nhận',
  active: 0,
}]
export const PAYMENT_TYPE = [{
  value: '2home',
  label: 'Hoàn tiền tới tài khoản 2home',
}, {
  value: 'bank-transfer',
  label: 'Hoàn tiền tới tài khoản ngân hàng',
}]
export const REJECT_AS_HOST = 'Khi thực hiện huỷ đơn cho khách thay cho chủ nhà, khách sẽ luôn được hoàn tiền.'
