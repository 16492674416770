import {
  Typography,
  Avatar,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Icon,
  Chip,
  Checkbox,
} from '@material-ui/core'
import { NavLink as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { formatPrice } from 'utils/formatter'
import { Table } from 'components'
import {
  Edit,
  Lock,
  LockOpen,
  Check,
  PanTool,
  OfflineBolt,
  AddToPhotos,
} from '@material-ui/icons'
import { remove, restore, pending } from 'provider/room'
import { getFiltersFromUrl } from 'utils/url'
import AppConfig from 'config'
import { copy } from 'utils/service'
import { useSnackbar } from 'notistack'
import moment from 'moment'

const RoomsTable = ({
  classes,
  rooms,
  meta,
  loading,
  update,
  duplicate,
  find,
  location,
  defaultSort,
  onSortChange,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [action, setAction] = useState(null)
  const [openDialog, setOpenDialog] = useState(false)
  const [data, setData] = useState([])

  useEffect(() => {
    setData(rooms.map((r) => ({ ...r, isSelected: false })))
  }, [rooms])

  const handlePageChange = (page) => {
    onPageChange(page)
  }

  const handleRowsPerPageChange = (limit) => {
    onRowsPerPageChange(limit)
  }

  const handleSortChange = (sortBy, sortDirection) => {
    onSortChange(sortBy, sortDirection)
  }

  const onUpdateStatus = async (room, status) => {
    setAction(status)
    setOpenDialog(true)
    const foundIdx = data.findIndex((r) => r._id === room._id)
    setData([...data.slice(0, foundIdx), ({ ...data[foundIdx], isSelected: true }), ...data.slice(foundIdx + 1, data.length)])
  }

  const updateRoomStatus = async () => {
    const ids = data.filter((r) => r.isSelected).map((r) => r._id)
    switch (action) {
      case 'active':
        await remove(ids)
        break
      case 'inactive':
        await restore(ids)
        break
      case 'pending':
        await pending(ids)
        break
      default:
        return
    }
    const query = getFiltersFromUrl(location.search)
    find(query)
    setOpenDialog(false)
    setAction(null)
  }

  const renderStatus = (status) => {
    switch (status) {
      case 'inactive':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontWeight: '900',
              backgroundColor: '#999999',
            }}
            label="Ẩn"
          />
        )
      case 'pending':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontWeight: '900',
              backgroundColor: '#ffa854',
            }}
            label="Xem xét"
          />
        )
      case 'active':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontWeight: '900',
              backgroundColor: '#00e676',
            }}
            label="Đang hoạt động"
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      {/* Dialog xác nhận khoá phòng */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle disableTypography>
          <h3>Xác nhận</h3>
        </DialogTitle>
        <DialogContent style={{ minWidth: 600, minHeight: 80 }}>
          {action && (
            <div
              dangerouslySetInnerHTML={{
                __html: `Xác nhận ${action === 'inactive'
                  ? 'mở phòng'
                  : action === 'active'
                    ? 'ẩn phòng'
                    : action === 'pending'
                      ? 'chuyển trạng thái phòng tới "Xem xét"'
                      : 'mở phòng'
                  }?`,
              }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="default">
            Thoát
          </Button>
          <Button onClick={updateRoomStatus} color="primary">
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
      {/* Danh sách phòng */}
      <Table
        data={data}
        columns={[
          {
            Head: (
              <Checkbox
                checked={data.every((r) => r.isSelected)}
                onChange={(e) => setData([...data.map((r) => ({ ...r, isSelected: e.target.checked }))])}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            ),
            Cell: (value, origin) => (
              <Checkbox
                checked={origin.isSelected}
                onChange={(e) => {
                  const foundIdx = data.findIndex((r) => r._id === value)
                  setData([...data.slice(0, foundIdx), ({ ...data[foundIdx], isSelected: e.target.checked }), ...data.slice(foundIdx + 1, data.length)])
                }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            ),
            accessor: '_id',
            sortable: false,
          },
          {
            Head: 'Mã',
            Cell: (value, origin) => (
              <RouterLink to={`/room/${origin.code}/update`} className={classes.codeCell}>
                <div>{value}</div>
              </RouterLink>
            ),
            accessor: 'code',
            sortable: true,
          },
          {
            Head: 'Ảnh',
            Cell: (value) => (
              <Avatar variant="square" src={`${AppConfig.BASE_IMG_URL}/${value}`} className={classes.avatar} />
            ),
            accessor: 'thumbnail',
            sortable: false,
          },
          {
            Head: 'Tên phòng',
            Cell: (value, origin) => (
              <>
                <div style={{ display: 'flex' }}>
                  <a
                    href={`${AppConfig.endpoint.customer}/phong-can-ho/${origin.id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.name}
                  >
                    {origin.bookingType === 'instant' ? <OfflineBolt style={{ color: '#ffc107' }} /> : null}
                    <Tooltip title={value}>
                      <Typography variant="body1" className={classes.ellipsisCell}>
                        {value}
                      </Typography>
                    </Tooltip>
                  </a>
                  <Tooltip title="Copy link đặt phòng">
                    <Button
                      onClick={() => copy(`${AppConfig.endpoint.customer}/phong-can-ho/${origin.code}`, () => enqueueSnackbar('Copy link đặt phòng', { variant: 'success' }))}
                    >
                      <Icon
                        fontSize="small"
                        className="far fa-clone"
                        style={{ cursor: 'pointer' }}
                      />
                    </Button>
                  </Tooltip>
                </div>
                {
                  origin.owner
                    ? (
                      <Typography variant="body2">
                        <RouterLink to={`/host/${origin.owner?.code}/update`} target="_blank">
                          <a className={classes.hostCell}>{origin.owner?.name}</a>
                        </RouterLink>
                        <b>{` (${origin.owner?.code})`}</b>
                      </Typography>
                    )
                    : null
                }
              </>
            ),
            accessor: 'name',
            sortable: false,
          },
          {
            Head: 'Địa chỉ',
            Cell: (value, origin) => (
              <div className={classes.address}>
                <Typography variant="body1" className={classes.ellipsisCell}>
                  {`${origin.roomNumber ? `${origin.roomNumber}` : ''}`}
                </Typography>
                <Tooltip title={origin.buildingName}>
                  <Typography variant="body1" className={classes.ellipsisCell}>
                    {origin.buildingName}
                  </Typography>
                </Tooltip>
                <Tooltip title={value}>
                  <Typography variant="body2" className={classes.ellipsisCell}>{value}</Typography>
                </Tooltip>
              </div>
            ),
            accessor: 'address',
            sortable: false,
          },
          {
            Head: 'Số đơn',
            Cell: (value, origin) => (
              <RouterLink to={`/reservations?room=["${origin.id}"]`} target="_blank">
                <a style={{ color: '#3f51b5' }}>{`${value[0]?.total || 0} đơn`}</a>
              </RouterLink>
            ),
            accessor: 'orders',
            sortable: true,
          },
          {
            Head: 'Giá theo giờ',
            Cell: (value, origin) => (
              <Typography variant="body1">
                {
                  origin.price?.hourFirstAmount && origin.price.hourFirstBaseFee
                    ? `${formatPrice(origin.price.hourFirstAmount * origin.price.hourFirstBaseFee)}VND/${origin.price.hourFirstAmount}h`
                    : '-'
                }
              </Typography>
            ),
            accessor: 'hourPrice',
            sortable: true,
          },
          {
            Head: 'Giá ngày',
            Cell: (value, origin) => <Typography variant="body1">{`${formatPrice(origin.price.dayBaseFee)}VND`}</Typography>,
            accessor: 'dayPrice',
            sortable: true,
          },
          {
            Head: 'Trạng thái',
            Cell: (value) => renderStatus(value),
            accessor: 'status',
            sortable: false,
          },
          {
            Head: 'Ngày tạo',
            Cell: (value) => moment(value).format('DD/MM/YYYY'),
            accessor: 'createdAt',
            sortable: true,
          },
          {
            Head: '',
            Cell: (value, origin) => (
              <div className={classes.actionCell}>
                <Tooltip title="Chỉnh sửa thông tin phòng">
                  <IconButton
                    aria-label="edit"
                    className={classes.margin}
                    onClick={() => update(origin.code)}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                {
                  (() => {
                    switch (origin.status) {
                      case 'active':
                        return (
                          <>
                            <Tooltip title="Ẩn phòng">
                              <IconButton
                                aria-label="lock"
                                className={classes.margin}
                                onClick={() => onUpdateStatus(origin, 'active')}
                              >
                                <Lock />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Chuyển tới xem xét">
                              <IconButton
                                aria-label="pantool"
                                className={classes.margin}
                                onClick={() => onUpdateStatus(origin, 'pending')}
                              >
                                <PanTool />
                              </IconButton>
                            </Tooltip>
                          </>
                        )
                      case 'inactive':
                        return (
                          <Tooltip title="Mở lại phòng">
                            <IconButton
                              aria-label="unlock"
                              className={classes.margin}
                              onClick={() => onUpdateStatus(origin, 'inactive')}
                            >
                              <LockOpen />
                            </IconButton>
                          </Tooltip>
                        )
                      case 'pending':
                        return (
                          <Tooltip title="Duyệt phòng">
                            <IconButton
                              aria-label="check"
                              className={classes.margin}
                              onClick={() => onUpdateStatus(origin, 'inactive')}
                            >
                              <Check />
                            </IconButton>
                          </Tooltip>
                        )
                      default:
                        return null
                    }
                  })()
                }
                <Tooltip title="Copy thông tin phòng">
                  <IconButton
                    aria-label="copy"
                    className={classes.margin}
                    onClick={() => duplicate(origin.code)}
                  >
                    <AddToPhotos />
                  </IconButton>
                </Tooltip>
              </div>
            ),
            accessor: '_id',
          },
        ]}
        loading={loading}
        page={meta.page}
        limit={meta.limit}
        total={meta.total}
        defaultSort={defaultSort}
        onSortChange={handleSortChange}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </>
  )
}

RoomsTable.propTypes = {
  classes: PropTypes.shape(),
  rooms: PropTypes.arrayOf(PropTypes.shape()),
  meta: PropTypes.shape(),
  defaultSort: PropTypes.shape(),
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSortChange: PropTypes.func,
  loading: PropTypes.bool,
  update: PropTypes.func,
  duplicate: PropTypes.func,
  find: PropTypes.func,
  location: PropTypes.shape(),
}

export default RoomsTable
