import { fromJS } from 'immutable'
import Favico from 'favico.js'
import { SOCKET_ACTION } from '../type'

window.audio = new Audio('/audio/new-order.mp3')
window.favicon = new Favico({
  position: 'up',
  animation: 'fade',
})
window.count = 0
const onFocus = () => {
  window.favicon.badge(0)
  window.count = 0
}

window.addEventListener('focus', onFocus)

const playSoundNotification = () => {
  if (!document.hasFocus()) {
    window.audio.volume = 1
    window.audio.play()
    window.count += 1
    window.favicon.badge(window.count)
  } else {
    window.favicon.badge(0)
    window.count = 0
  }
}

export default function socket(state, action) {
  const { type: actionType, payload } = action
  let order = {}
  let customer = {}
  let prepaidPackage = {}
  let sidebar = {}
  /**
   * payload:
   * - from: customer | admin | host
   * - to: customer | admin | host
   * - owner (optional): id of owner's message
   * - type: ORDER | CUSTOMER | PREPAID_PACKAGE
   * - message:
   * + ORDER: NEW | UPDATE | REJECT | UNDO | PAY | CONFIRM | RELEASE-DEPOSIT | HOST-COMPLAIN | 2HOME-HANDLE | CUSTOMER-COMPLAIN
   * + CUSTOMER: REGISTER | ONLINE | OFFLINE
   * + PREPAID_PACKAGE: BUY | PAY | REJECT
   */
  switch (actionType) {
    case SOCKET_ACTION.SET_MESSAGE:
      const { type, message } = payload
      sidebar = state.get('sidebar').toJS()
      switch (type) {
        case 'ORDER':
          sidebar.order += 1
          order = state.get('order').toJS()
          switch (message) {
            case 'NEW':
              order.new += 1
              order.active += 1
              break
            case 'UPDATE':
            case 'UNDO':
              order.update += 1
              order.active += 1
              break
            case 'PAY':
              order.pay += 1
              order.active += 1
              break
            case 'REJECT':
              order.reject += 1
              break
            case 'CUSTOMER-COMPLAIN':
            case 'HOST-COMPLAIN':
              order.deposit += 1
              break
            case '2HOME-HANDLE':
            case 'RELEASE-DEPOSIT':
            case 'CONFIRM':
            default:
          }
          playSoundNotification()
          return state.set('order', fromJS(order)).set('sidebar', fromJS(sidebar))
        case 'CUSTOMER':
          sidebar.customer += 1
          customer = state.get('customer').toJS()
          switch (message) {
            case 'REGISTER':
              customer.join += 1
              break
            case 'ONLINE':
              // customer.online += 1
              break
            default:
          }
          return state.set('customer', fromJS(customer)).set('sidebar', fromJS(sidebar))
        case 'PREPAID_PACKAGE':
          sidebar.prepaidPackage += 1
          prepaidPackage = state.get('prepaidPackage').toJS()
          switch (message) {
            case 'BUY':
              prepaidPackage.buy += 1
              break
            case 'PAY':
              prepaidPackage.paid += 1
              break
            case 'REJECT':
              prepaidPackage.reject += 1
              break
            default:
          }
          return state.set('prepaidPackage', fromJS(prepaidPackage)).set('sidebar', fromJS(sidebar))
        default:
          return state
      }

    case SOCKET_ACTION.RESET_ORDER_NEW:
      order = state.get('order').toJS()
      order.new = 0
      sidebar = state.get('sidebar').toJS()
      sidebar.order = 0
      return state.set('order', fromJS(order)).set('sidebar', fromJS(sidebar))

    case SOCKET_ACTION.RESET_ORDER_PAID:
      order = state.get('order').toJS()
      order.paid = 0
      sidebar = state.get('sidebar').toJS()
      sidebar.order = 0
      return state.set('order', fromJS(order)).set('sidebar', fromJS(sidebar))

    case SOCKET_ACTION.RESET_ORDER_UPDATE:
      order = state.get('order').toJS()
      order.update = 0
      sidebar = state.get('sidebar').toJS()
      sidebar.order = 0
      return state.set('order', fromJS(order)).set('sidebar', fromJS(sidebar))

    case SOCKET_ACTION.RESET_ORDER_REJECT:
      order = state.get('order').toJS()
      order.reject = 0
      sidebar = state.get('sidebar').toJS()
      sidebar.order = 0
      return state.set('order', fromJS(order)).set('sidebar', fromJS(sidebar))

    case SOCKET_ACTION.RESET_ORDER_LIST_ACTIVE:
      order = state.get('order').toJS()
      order.active = 0
      sidebar = state.get('sidebar').toJS()
      sidebar.order = 0
      return state.set('order', fromJS(order)).set('sidebar', fromJS(sidebar))

    case SOCKET_ACTION.RESET_ORDER_LIST_REFUND:
      order = state.get('order').toJS()
      order.refund = 0
      sidebar = state.get('sidebar').toJS()
      sidebar.order = 0
      return state.set('order', fromJS(order)).set('sidebar', fromJS(sidebar))

    case SOCKET_ACTION.RESET_ORDER_LIST_DEPOSIT:
      order = state.get('order').toJS()
      order.deposit = 0
      sidebar = state.get('sidebar').toJS()
      sidebar.order = 0
      return state.set('order', fromJS(order)).set('sidebar', fromJS(sidebar))

    case SOCKET_ACTION.RESET_CUSTOMER_JOIN:
      customer = state.get('customer').toJS()
      customer.join = 0
      customer.online = 0
      sidebar = state.get('sidebar').toJS()
      sidebar.customer = 0
      return state.set('customer', fromJS(customer)).set('sidebar', fromJS(sidebar))

    case SOCKET_ACTION.RESET_PREPAID_PACKAGE:
      prepaidPackage = state.get('prepaidPackage').toJS()
      prepaidPackage = { ...payload }
      return state.set('prepaidPackage', fromJS(prepaidPackage))

    case SOCKET_ACTION.RESET_ORDER_SIDEBAR:
      sidebar = state.get('sidebar').toJS()
      sidebar.order = 0
      return state.set('sidebar', fromJS(sidebar))

    case SOCKET_ACTION.RESET_CUSTOMER_SIDEBAR:
      sidebar = state.get('sidebar').toJS()
      sidebar.customer = 0
      return state.set('sidebar', fromJS(sidebar))

    case SOCKET_ACTION.RESET_PACKAGE_SIDEBAR:
      sidebar = state.get('sidebar').toJS()
      sidebar.prepaidPackage = 0
      return state.set('sidebar', fromJS(sidebar))

    default:
      return state
  }
}
