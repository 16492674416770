import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { FormatListBulleted, Close } from '@material-ui/icons'
import { FormControlLabel, Checkbox, Typography } from '@material-ui/core'
import * as commonApi from 'provider/common'

const PrepaidPackage = ({ value, onApply }) => {
  const [prepaidPackage, setPrepaidPackage] = useState([])

  const fetchPrepaidPackage = async () => {
    try {
      const resp = await commonApi.getPackage()
      setPrepaidPackage(
        resp.data.map(item => ({
          value: item.id,
          label: item.name,
        })),
      )
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    try {
      const prevPrepaidPackage = value
      setPrepaidPackage(prepaidPackage.map(item => ({
        ...item,
        checked: prevPrepaidPackage.includes(item.value),
      })))
    } catch (e) {
      // TODO: Do nothing
    }
    return () => {
    }
  }, [value])

  useEffect(() => {
    fetchPrepaidPackage()
    return () => {
    }
  }, [])

  const onSelectPrepaidPackage = (idx, checked) => {
    setPrepaidPackage([
      ...prepaidPackage.slice(0, idx),
      { ...prepaidPackage[idx], checked },
      ...prepaidPackage.slice(idx + 1, prepaidPackage.length),
    ])
  }

  return (
    <PopupState variant="popover" popupId="customer-filter">
      {popupState => (
        <div>
          <Button variant="outlined" {...bindTrigger(popupState)} style={{ backgroundColor: 'white', textTransform: 'none' }}>
            <FormatListBulleted />
            {prepaidPackage.filter(item => item.checked).length
              ? prepaidPackage.filter(item => item.checked).map(item => item.label).join(', ')
              : 'Kiểu gói'}
            {value
              ? (
                <Close onClick={(e) => {
                  e.stopPropagation()
                  setPrepaidPackage(prepaidPackage.map(item => ({ ...item, checked: false })))
                  onApply(undefined)
                }}
                />
              )
              : null}
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box p={2} style={{ width: 200 }}>
              {prepaidPackage.map(
                (item, idx) => (
                  <div>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={item.checked}
                          onChange={e => onSelectPrepaidPackage(idx, e.target.checked)}
                          color="primary"
                        />
                      )}
                      label={<Typography variant="body1">{item.label}</Typography>}
                    />
                  </div>
                ),
              )}
              <Button
                onClick={() => {
                  onApply(JSON.stringify(prepaidPackage.filter(item => item.checked).map(item => item.value)))
                  popupState.close()
                }}
                style={{ float: 'right', marginBottom: 7 }}
              >
              Áp dụng
              </Button>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

PrepaidPackage.propTypes = {
  onApply: PropTypes.func,
  value: PropTypes.string,
}

export default PrepaidPackage
