import React from 'react'
import { connect } from 'react-redux'
import { getSocketData } from 'redux/selector/socket'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Divider, Drawer, Badge } from '@material-ui/core'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PeopleIcon from '@material-ui/icons/People'
import Apartment from '@material-ui/icons/Apartment'
import AssignmentInd from '@material-ui/icons/AssignmentInd'
import ReceiptIcon from '@material-ui/icons/Receipt'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import PostAddIcon from '@material-ui/icons/PostAdd'
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet'
import {
  Description, Redeem, Spa, Storage,
} from '@material-ui/icons'
import { SOCKET_ACTION } from 'redux/type'
import {
  Profile,
  SidebarNav,
  // UpgradePlan
} from './components'

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
    '@media (max-width:1280px)': {
      width: 200,
    },
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  nav: {
    marginBottom: theme.spacing(2),
  },
}))

const Sidebar = (props) => {
  const {
    open,
    variant,
    onClose,
    className,
    user,
    anchor,
    socket,
    resetSocket,
    ...rest
  } = props

  const classes = useStyles()

  const pages = [
    // Dashboard
    {
      title: 'Trang chủ',
      href: '/dashboard',
      icon: <DashboardIcon />,
      isVisible: true,
    },
    // Nhân viên
    {
      title: 'Nhân viên',
      href: '/users',
      icon: <AssignmentInd />,
      isVisible: user.role
        ? user.role
          .map(r => r.name)
          .some(r => ['admin', 'area-manager'].includes(r))
        : false,
    },
    // Chủ nhà
    {
      title: 'Chủ nhà',
      href: '/host',
      icon: <VpnKeyIcon />,
      isVisible: user.role
        ? user.role
          .map(r => r.name)
          .some(r => ['admin', 'sale'].includes(r))
        : false,
    },
    // Khách hàng
    {
      title: 'Khách hàng',
      href: '/customer',
      icon: socket?.sidebar?.customer
        ? (
          <Badge badgeContent={socket?.sidebar?.customer} color="primary">
            <PeopleIcon />
          </Badge>
        )
        : <PeopleIcon />,
      isVisible: user.role
        ? user.role
          .map(r => r.name)
          .some(r => ['admin', 'booking-staff'].includes(r))
        : false,
      onClick: () => resetSocket(SOCKET_ACTION.RESET_CUSTOMER_SIDEBAR),
    },
    // Gói trả trước
    {
      title: 'Gói trả trước',
      href: '/prepaid-history',
      icon: socket?.sidebar?.prepaidPackage
        ? (
          <Badge badgeContent={socket?.sidebar?.prepaidPackage} color="primary">
            <AccountBalanceWalletIcon />
          </Badge>
        )
        : <AccountBalanceWalletIcon />,
      isVisible: user.role
        ? user.role
          .map(r => r.name)
          .some(r => ['admin', 'accountant'].includes(r))
        : false,
      onClick: () => resetSocket(SOCKET_ACTION.RESET_PACKAGE_SIDEBAR),
    },
    // Dịch vụ
    {
      title: 'Dịch vụ',
      href: '/service',
      icon: <Spa />,
      isVisible: user.role
        ? user.role
          .map(r => r.name)
          .some(r => ['admin', 'service-staff'].includes(r))
        : false,
    },
    // Khu vực
    {
      title: 'Khu vực',
      href: '/area',
      icon: <Apartment />,
      isVisible: user.role
        ? user.role
          .map(r => r.name)
          .some(r => ['admin', 'area-manager', 'booking-staff'].includes(r))
        : false,
    },
    // Danh sách Phòng
    {
      title: 'Phòng',
      href: '/room',
      icon: <Apartment />,
      isVisible: user.role
        ? user.role
          .map(r => r.name)
          .some(r => ['admin', 'sale'].includes(r))
        : false,
    },
    // Đơn đặt phòng
    {
      title: 'Đơn đặt phòng',
      href: '/reservations',
      icon: socket?.sidebar?.order
        ? (
          <Badge badgeContent={socket?.sidebar?.order} color="primary">
            <ReceiptIcon />
          </Badge>
        )
        : <ReceiptIcon />,
      isVisible: user.role
        ? user.role
          .map(r => r.name)
          .some(r => ['admin', 'area-manager', 'booking-staff', 'accountant'].includes(r))
        : false,
      onClick: () => resetSocket(SOCKET_ACTION.RESET_ORDER_SIDEBAR),
    },
    // Mã khuyến mại
    {
      title: 'Mã khuyến mại',
      href: '/coupon',
      icon: <Redeem />,
      isVisible: user.role
        ? user.role
          .map(r => r.name)
          .some(r => ['admin', 'area-manager', 'booking-staff', 'accountant'].includes(r))
        : false,
    },
    // Báo cáo
    {
      title: 'Báo cáo',
      href: '/report',
      icon: <PostAddIcon />,
      isVisible: user.role
        ? user.role
          .map(r => r.name)
          .some(r => ['admin', 'area-manager', 'accountant'].includes(r))
        : false,
      subs: [{
        title: 'Đơn đặt phòng',
        href: '/report-order',
        icon: <PostAddIcon />,
        isVisible: user.role
          ? user.role
            .map(r => r.name)
            .some(r => ['admin', 'area-manager', 'accountant'].includes(r))
          : false,
      }, {
        title: 'Phòng',
        href: '/report-room',
        icon: <PostAddIcon />,
        isVisible: user.role
          ? user.role
            .map(r => r.name)
            .some(r => ['admin', 'area-manager', 'accountant'].includes(r))
          : false,
      }, {
        title: 'Dịch vụ',
        href: '/report-service',
        icon: <PostAddIcon />,
        isVisible: user.role
          ? user.role
            .map(r => r.name)
            .some(r => ['admin', 'area-manager', 'accountant'].includes(r))
          : false,
      }, {
        title: 'Khách',
        href: '/report-customer',
        icon: <PostAddIcon />,
        isVisible: user.role
          ? user.role
            .map(r => r.name)
            .some(r => ['admin', 'area-manager', 'accountant'].includes(r))
          : false,
      }, {
        title: 'Chủ nhà',
        href: '/report-host',
        icon: <PostAddIcon />,
        isVisible: user.role
          ? user.role
            .map(r => r.name)
            .some(r => ['admin', 'area-manager', 'accountant'].includes(r))
          : false,
      }],
    },
    // Đối soát
    {
      title: 'Đối soát',
      href: '/invoice',
      icon: <Description />,
      isVisible: user.role
        ? user.role
          .map(r => r.name)
          .some(r => ['admin'].includes(r))
        : false,
    },
    // Tiện ích phòng
    {
      title: 'Tiện ích phòng',
      href: '/amenity',
      icon: <Storage />,
      isVisible: user.role
        ? user.role
          .map(r => r.name)
          .some(r => ['admin'].includes(r))
        : false,
    },
  ]

  return (
    <Drawer
      anchor={anchor}
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile user={user} />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  )
}

Sidebar.propTypes = {
  socket: PropTypes.shape(),
  user: PropTypes.shape(),
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  anchor: PropTypes.string,
  resetSocket: PropTypes.func,
}

export default connect(getSocketData, false)(Sidebar)
