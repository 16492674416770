import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Grid } from '@material-ui/core'
import Profile from './Profile'
import Info from './Info'
import styles from './styled'

const Account = ({
  classes,
  data: user,
  update,
}) => (
  <div className={classes.root}>
    <Grid container spacing={4}>
      <Grid item lg={4} md={6} xl={4} xs={12}>
        <Profile classes={classes} user={user} updateInfo={update} />
      </Grid>
      <Grid item lg={8} md={6} xl={8} xs={12}>
        <Info classes={classes} user={user} updateInfo={update} />
      </Grid>
    </Grid>
  </div>
)

Account.propTypes = {
  classes: PropTypes.shape(),
  data: PropTypes.shape(),
  update: PropTypes.func,
}

export default withStyles(styles)(Account)
