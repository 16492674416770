import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { Apartment, Close, Remove } from '@material-ui/icons'
import { SearchSelectionInput } from 'components'
import * as serviceApi from 'provider/service'
import { Grid, Typography } from '@material-ui/core'
import { nonAccentVietnamese } from 'utils/formatter'

const Service = ({
  values,
  // touched,
  // errors,
  // handleChange,
  setFieldValue,
}) => {
  const [allServices, setAllServices] = useState([])
  const [sltService, setSltService] = useState({})
  const [serviceSuggestion, setServiceSuggestion] = useState([])
  const [services, setServices] = useState([])

  const getSelectedServiceList = async (ids) => {
    setServices(allServices.filter(s => ids.includes(s.id)))
  }

  const fetchAllServices = async () => {
    try {
      const resp = await serviceApi.find({ limit: Number.MAX_SAFE_INTEGER })
      setAllServices(
        resp.data?.map(opt => ({
          id: opt.id,
          title: `${opt.name} (${opt.code})`,
          // subTitle: `${opt.description}`,
        })),
      )
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    fetchAllServices()
    return () => {
    }
  }, [])

  useEffect(() => {
    try {
      if (!values.services) {
        setServices([])
        return () => {}
      }
      const ids = values.services
      if (ids && ids.length) {
        getSelectedServiceList(ids)
      }
    } catch (e) {
      setServices([])
      setFieldValue('services', [])
    }
    return () => {}
  }, [values.services, allServices])

  const onServiceChange = async (str) => {
    if (!str) return
    setServiceSuggestion(allServices.filter(s => nonAccentVietnamese(s.title?.toLowerCase()).includes(str)))
  }

  const onServiceSelect = async (item) => {
    if (!item) return
    setSltService(item)
    if (!services.find(c => item && c.id === item.id)) {
      setServices([...services].concat(item))
    }
  }

  const renderServiceOption = (option) => {
    const { title, subTitle } = option
    return (
      <div>
        <div style={{ fontSize: 16 }}>
          <strong>{title}</strong>
        </div>
        <div>
          <small>{subTitle}</small>
        </div>
      </div>
    )
  }

  return (
    <PopupState variant="popover" popupId="coupon-room-apply">
      {popupState => (
        <div className="w-100">
          <Button className="w-100" variant="outlined" {...bindTrigger(popupState)} style={{ backgroundColor: 'white', textTransform: 'none' }}>
            <Apartment className="mr-2" />
            {services.filter(c => values && (values.services || []).includes(c.id)).length
              ? `Áp dụng cho ${services.filter(c => values && (values.services || []).includes(c.id)).length} dịch vụ`
              : 'Áp dụng cho toàn bộ dịch vụ'}
            {values && (values.services || []).length
              ? (
                <Close onClick={(e) => {
                  e.stopPropagation()
                  setFieldValue('services', [])
                }}
                />
              )
              : null}
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box p={2} style={{ width: 400 }}>
              <SearchSelectionInput
                label="Dịch vụ"
                placeholder="Nhập thông tin dịch vụ"
                value={sltService.title}
                renderOption={renderServiceOption}
                suggestions={serviceSuggestion}
                onChange={str => onServiceChange(str)}
                onSelect={onServiceSelect}
              />
              {/* Selected services */}
              <div>
                {services.length ? <Typography>Danh sách dịch vụ áp dụng:</Typography> : ''}
                {
                  services.map((c, i) => (
                    <Grid container>
                      <Typography variant="body1" style={{ fontWeight: 500 }}>{`${i + 1}. ${c.title}`}</Typography>
                      <Remove style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => setServices([...services].filter(item => item.id !== c.id))} />
                    </Grid>
                  ))
                }
              </div>
              <Button
                onClick={() => {
                  setFieldValue('services', services && services.length ? services.map(c => c.id) : [])
                  popupState.close()
                }}
                style={{ float: 'right', marginBottom: 7 }}
              >
              Áp dụng
              </Button>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

Service.propTypes = {
  values: PropTypes.shape(),
  setFieldValue: PropTypes.func,
}

export default Service
