import React from 'react'
import PropTypes from 'prop-types'
import { withStyles, Grid, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import CustomerInfo from './CustomerInfo'
import ReservationsList from './ReservationsList'
import PrepaidPackageList from './PrepaidPackageList'
import styles from './styled'

const CustomerForm = ({
  classes,
  history,
  id,
  action,
}) => {
  const { enqueueSnackbar } = useSnackbar()

  const onCustomerInfoUpdate = (type) => {
    switch (type) {
      case 'success':
        enqueueSnackbar('Cập nhật thông tin khách hàng thành công!', { variant: type })
        break
      case 'error':
        enqueueSnackbar('Cập nhật thông tin khách hàng không thành công!', { variant: type })
        break
      case 'info':
        enqueueSnackbar('Thêm mới khách hàng thành công!', { variant: type })
        break
      default:
    }
  }

  return (
    <Grid container>
      {/*  Customer info */}
      <Grid item lg={4} md={4} xs={12} style={{ padding: 5 }}>
        <CustomerInfo
          classes={classes}
          action={action}
          history={history}
          id={id}
          onUpdate={onCustomerInfoUpdate}
        />
      </Grid>
      {/* Reservation and Prepaid package */}
      <Grid item lg={8} md={8} xs={12} style={{ padding: 5 }}>
        <Typography variant="h4" className={classes.titleAlign}>
          Đơn đặt phòng
        </Typography>
        <ReservationsList classes={classes} customer={id} />
        <br />
        <Typography variant="h4" className={classes.titleAlign}>
          Gói đã mua
        </Typography>
        <PrepaidPackageList classes={classes} customer={id} />
      </Grid>
    </Grid>
  )
}

CustomerForm.propTypes = {
  classes: PropTypes.shape(),
  history: PropTypes.shape(),
  id: PropTypes.string,
  action: PropTypes.string,
}

export default withStyles(styles)(CustomerForm)
