import { connect } from 'react-redux'
import { CouponList as CouponPage } from 'views'
import { getCouponData } from 'redux/selector/coupon'
import { find } from 'redux/action/coupon'

const mapDispatchToProps = dispatch => ({
  find: query => dispatch(find(query)),
})

export default connect(getCouponData, mapDispatchToProps)(CouponPage)
