/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  withStyles,
  Grid,
  Typography,
  Button,
  Divider,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { findOne, create, update } from 'provider/room'
import BackgroundBackdrop from 'components/Backdrop'
import { useSnackbar } from 'notistack'
import uploadApi from 'provider/upload'
import roomFormStyle from './styled'
import GeneralInfo from './General'
import NormalPrice from './NormalPrice'
import SpecialPrice from './SpecialPrice'
import Promotion from './Promotion'
import Media from './Media'
import Service from './Service'
import Description from './Description'
import Policy from './Policy'
import Instruction from './Instruction'
import Amenity from './Amenity'
import RejectPolicy from './RejectPolicy'

const validateSchema = Yup.object().shape({
  name: Yup.string().required('Thông tin không được để trống'),
  owner: Yup.string().required('Thông tin không được để trống'),
  dayBaseFee: Yup.number().required('Thông tin không được để trống').min(1000, 'Nhập phí tối thiểu 1000đ'),
  dayHourExtraBaseFee: Yup.number().required('Thông tin không được để trống').min(1000, 'Nhập phí tối thiểu 1000đ')
    .when(['dayBaseFee'], (dayBaseFee, schema) => {
      if (dayBaseFee > 0) {
        return schema.max(dayBaseFee, 'Phí không được vượt quá giá theo ngày')
      }
      return schema
    }),
  dayHourExtraMax: Yup.number().required('Thông tin không được để trống')
    .when(['dayBaseFee', 'dayHourExtraBaseFee'], (dayBaseFee, dayHourExtraBaseFee, schema) => {
      if (dayBaseFee > 0 && dayHourExtraBaseFee > 0) {
        const max = Math.floor(dayBaseFee / dayHourExtraBaseFee)
        return schema.max(max, `Tối đa ${max} tiếng`)
      }
      return schema
    }),
  hourFirstBaseFee: Yup.number().when(['hasRentByHour'], (hasRentByHour, schema) => {
    if (hasRentByHour) {
      return schema.required('Thông tin không được để trống').min(1000, 'Nhập phí tối thiểu 1000đ')
    }
    return schema
  }),
  hourFirstAmount: Yup.number().when(['hasRentByHour'], (hasRentByHour, schema) => {
    if (hasRentByHour) {
      return schema.required('Thông tin không được để trống').min(1, 'Tối thiểu 1 tiếng')
    }
    return schema
  }),
  hourExtraBaseFee: Yup.number().when(['hasRentByHour'], (hasRentByHour, schema) => {
    if (hasRentByHour) {
      return schema.required('Thông tin không được để trống').min(1000, 'Nhập phí tối thiểu 1000đ')
    }
    return schema
  }),
  hourExtraMax: Yup.number().when(['hasRentByHour', 'dayBaseFee', 'hourExtraBaseFee', 'hourFirstBaseFee', 'hourFirstAmount'], (hasRentByHour, dayBaseFee, hourExtraBaseFee, hourFirstBaseFee, hourFirstAmount, schema) => {
    if (hasRentByHour) {
      if (dayBaseFee > 0 && hourExtraBaseFee > 0 && hourFirstBaseFee > 0 && hourFirstAmount > 0) {
        const max = Math.floor((dayBaseFee - hourFirstBaseFee) / hourExtraBaseFee) + hourFirstAmount
        return schema.required('Thông tin không được để trống').min(hourFirstAmount + 1, `Tối thiểu ${hourFirstAmount + 1} tiếng`).max(max, `Tối đa ${max} tiếng`)
      }
      return schema.required('Thông tin không được để trống')
    }
    return schema
  }),
  addGuestFeeStartAt: Yup.number().min(0)
    .when(['guestMax'], (guestMax, schema) => {
      if (guestMax > 0) {
        return schema.max(guestMax, `Tối đa ${guestMax} người`)
      }
      return schema
    }),
  overnightFee: Yup.number().when(['hasNightPromote'], (hasNightPromote, schema) => {
    if (hasNightPromote) {
      return schema.required('Thông tin không được để trống').min(1000, 'Nhập phí tối thiểu 1000đ')
    }
    return schema
  }),
  dayRangeFee: Yup.number().when(['isFlexible', 'hasRentByHour', 'applyTimeRange'], (isFlexible, hasRentByHour, applyTimeRange, schema) => {
    if (!isFlexible && hasRentByHour && applyTimeRange) {
      return schema.required('Thông tin không được để trống').min(1000, 'Nhập phí tối thiểu 1000đ')
    }
    return schema
  }),
  cleaningFee: Yup.number().when(['hasCleaningFee'], (hasCleaningFee, schema) => {
    if (hasCleaningFee) {
      return schema.required('Thông tin không được để trống').min(1000, 'Nhập phí tối thiểu 1000đ')
    }
    return schema
  }),
  addGuestBaseFee: Yup.number().when(['addGuestFeeStartAt'], (addGuestFeeStartAt, schema) => {
    if (addGuestFeeStartAt >= 1) {
      return schema.required('Thông tin không được để trống').min(1000, 'Nhập phí tối thiểu 1000đ')
    }
    return schema
  }),
  bedNum: Yup.number().required('Số giường không được để trống').min(1, 'Tối thiểu 1 giường'),
  bathroomNum: Yup.number().required('Số phòng tắm không được để trống').min(1, 'Tối thiểu 1 phòng tắm'),
})

const initRoom = {
  name: '',
  square: 0,
  owner: '',
  roomNumber: '',
  district: '',
  building: '',
  address: '',
  mapLat: 0,
  mapLng: 0,
  roomBookingType: 'instant',
  guestMax: 0,
  bedNum: 0,
  bedroomNum: 0,
  bathroomNum: 0,
  bathroomBox: 0,
  roomType: '',
  has2homeFee: true,
  commission: 20,
  hasRentByHour: false,
  checkin: '14:00',
  checkout: '12:00',
  applyTimeRange: false,
  startTimeRange: '08:00',
  endTimeRange: '18:00',
  thumbnail: [],
  images: [],
  video: '',
  hasService: false,
  serviceNote: '',
  description: '',
  rule: '',
  help: '',
  hourFirstBaseFee: 0,
  hourFirstAmount: 0,
  hourExtraBaseFee: 0,
  hourExtraMax: 0,
  dayBaseFee: 0,
  dayHourExtraMax: 0,
  dayHourExtraBaseFee: 0,
  specialDayFee: {},
  limitRoomBedFee: {},
  overnightFee: 0,
  nightStartAt: '22:00',
  nightEndAt: '07:00',
  dayRangeFee: 0,
  cleaningFee: 0,
  addGuestBaseFee: 0,
  addGuestFeeStartAt: 0,
  discountWeekType: 'percent',
  discountWeek: 0,
  discountMonthType: 'percent',
  discountMonth: 0,
  isFlexible: false,
  hasNightPromote: false,
  hasCleaningFee: false,
  amenities: [],
}

const RoomForm = ({
  id,
  classes,
  action,
  history,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [room, setRoom] = useState(initRoom)
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(0)

  const a11yProps = (index) => ({
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  })

  const formatRoomData = (roomData, mode) => {
    const {
      price,
      location,
      city,
      country,
      district,
      building,
      roomType,
      owner,
      amenities,
      images,
      thumbnail,
      checkin,
      checkout,
      startTimeRange,
      endTimeRange,
      nightStartAt,
      nightEndAt,
      code,
      ...rest
    } = roomData
    setRoom({
      ...rest,
      ...({
        ...price,
        discountWeekType: price?.discountWeekType || 'percent',
        discountMonthType: price?.discountMonthType || 'percent',
        hourFirstBaseFee: price.hourFirstBaseFee * price.hourFirstAmount,
        hourExtraMax: price.hourExtraMax + price.hourFirstAmount,
        specialDayFee: (() => {
          const {
            dayOfWeeks,
            specialDays,
          } = price.specialDayFee
          const convertSpecialDayFee = {
            dayOfWeeks: {},
            specialDays: {},
          }
          Object.keys(dayOfWeeks || {})?.forEach((key) => {
            convertSpecialDayFee.dayOfWeeks[key] = {
              ...dayOfWeeks[key],
              hourFirstBaseFee: dayOfWeeks[key].hourFirstBaseFee * dayOfWeeks[key].hourFirstAmount,
              hourExtraMax: dayOfWeeks[key].hourExtraMax + dayOfWeeks[key].hourFirstAmount,
            }
          })
          Object.keys(specialDays || {})?.forEach((key) => {
            convertSpecialDayFee.specialDays[key] = {
              ...specialDays[key],
              hourFirstBaseFee: specialDays[key].hourFirstBaseFee * specialDays[key].hourFirstAmount,
              hourExtraMax: specialDays[key].hourExtraMax + specialDays[key].hourFirstAmount,
            }
          })
          // console.log(convertSpecialDayFee)
          return convertSpecialDayFee
        })(),
        limitRoomBedFee: {
          ...price.limitRoomBedFee,
          hourFirstBaseFee: price.limitRoomBedFee.hourFirstBaseFee ? (price.limitRoomBedFee.hourFirstBaseFee * price.limitRoomBedFee.hourFirstAmount) : 0,
          hourExtraMax: price.limitRoomBedFee.hourFirstBaseFee ? (price.limitRoomBedFee.hourExtraMax + price.limitRoomBedFee.hourFirstAmount) : 0,
        },
      }),
      id: ['update', 'duplicate'].includes(mode) ? id : undefined,
      code: ['update', 'duplicate'].includes(mode) ? code : undefined,
      mapLat: location?.length ? location[0] : 0,
      mapLng: location?.length ? location[1] : 0,
      country: country?._id || country?.id,
      city: city?._id || city?.id || district?.city,
      district: district?._id || district?.id,
      building: building?._id || building?.id,
      roomType: roomType?._id || roomType?.id,
      owner: owner?._id || owner?.id,
      priceId: price?._id || price?.id,
      amenities: amenities?.map((item) => item?._id || item?.id),
      images: ['update'].includes(mode) ? images.map((url) => ({ isLink: true, file: url })) : [],
      thumbnail: ['update'].includes(mode) ? (thumbnail ? [{ isLink: true, file: thumbnail }] : []) : [],
      checkin: checkin || '14:00',
      checkout: checkout || '12:00',
      startTimeRange: startTimeRange || '08:00',
      endTimeRange: endTimeRange || '18:00',
      nightStartAt: (!nightStartAt || (nightStartAt === '0')) ? '22:00' : nightStartAt,
      nightEndAt: (!nightEndAt || (nightEndAt === '0')) ? '07:00' : nightEndAt,
    })
  }

  const fetchRoom = async (mode) => {
    setLoading(true)
    try {
      const resp = await findOne(id)
      formatRoomData(resp, mode)
      setLoading(false)
    } catch (e) {
      console.log(e)
      enqueueSnackbar(`Máy chủ lỗi (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
      setLoading(false)
    }
  }

  useEffect(() => {
    // Fetch user data if in updating mode
    if (['update', 'duplicate'].includes(action)) {
      fetchRoom(action)
    }
    // eslint-disable-next-line
  }, [])

  const onSubmit = async (data) => {
    const {
      images: allImages,
      thumbnail,
      ...rest
    } = data
    const convertSpecialDayFee = {
      dayOfWeeks: {},
      specialDays: {},
    }
    Object.keys(data.specialDayFee.dayOfWeeks).forEach((key) => {
      convertSpecialDayFee.dayOfWeeks[key] = {
        ...data.specialDayFee.dayOfWeeks[key],
        hourFirstBaseFee: data.specialDayFee.dayOfWeeks[key].hourFirstBaseFee ? data.specialDayFee.dayOfWeeks[key].hourFirstBaseFee / data.specialDayFee.dayOfWeeks[key].hourFirstAmount : 0,
        hourExtraMax: data.specialDayFee.dayOfWeeks[key].hourFirstBaseFee ? data.specialDayFee.dayOfWeeks[key].hourExtraMax - data.specialDayFee.dayOfWeeks[key].hourFirstAmount : 0,
      }
    })
    Object.keys(data.specialDayFee.specialDays).forEach((key) => {
      convertSpecialDayFee.specialDays[key] = {
        ...data.specialDayFee.specialDays[key],
        hourFirstBaseFee: data.specialDayFee.specialDays[key].hourFirstBaseFee ? data.specialDayFee.specialDays[key].hourFirstBaseFee / data.specialDayFee.specialDays[key].hourFirstAmount : 0,
        hourExtraMax: data.specialDayFee.specialDays[key].hourFirstBaseFee ? data.specialDayFee.specialDays[key].hourExtraMax - data.specialDayFee.specialDays[key].hourFirstAmount : 0,
      }
    })
    const convertLimitRoomBedFee = {
      ...data.limitRoomBedFee,
      hourFirstBaseFee: data.limitRoomBedFee.hourFirstBaseFee ? data.limitRoomBedFee.hourFirstBaseFee / data.limitRoomBedFee.hourFirstAmount : 0,
      hourExtraMax: data.limitRoomBedFee.hourFirstBaseFee ? data.limitRoomBedFee.hourExtraMax - data.limitRoomBedFee.hourFirstAmount : 0,
    }
    const requestData = {
      ...rest,
      id: ['duplicate'].includes(action) ? undefined : data.id,
      code: ['duplicate'].includes(action) ? undefined : data?.code,
      roomNumber: data.roomNumber || '',
      checkin: !data.isFlexible ? data.checkin : '',
      checkout: !data.isFlexible ? data.checkout : '',
      nightStartAt: data.hasNightPromote ? data.nightStartAt : '',
      nightEndAt: data.hasNightPromote ? data.nightEndAt : '',
      applyTimeRange: data.hasRentByHour && !data.isFlexible ? data.applyTimeRange : false,
      startTimeRange: data.hasRentByHour && !data.isFlexible ? data.startTimeRange : undefined,
      endTimeRange: data.hasRentByHour && !data.isFlexible ? data.endTimeRange : undefined,
      dayBaseFee: data.dayBaseFee ? data.dayBaseFee : 0,
      hourFirstAmount: data.hasRentByHour ? data.hourFirstAmount : undefined,
      hourExtraBaseFee: data.hasRentByHour ? data.hourExtraBaseFee ? data.hourExtraBaseFee : 0 : undefined,
      hourFirstBaseFee: data.hasRentByHour ? data.hourFirstBaseFee ? data.hourFirstBaseFee / data.hourFirstAmount : 0 : undefined,
      hourExtraMax: data.hasRentByHour ? data.hasRentByHour ? data.hourExtraMax - data.hourFirstAmount : 0 : undefined,
      cleaningFee: data.cleaningFee ? data.cleaningFee : 0,
      addGuestBaseFee: data.addGuestBaseFee ? data.addGuestBaseFee : 0,
      addGuestFeeStartAt: data.addGuestFeeStartAt ? data.addGuestFeeStartAt : 0,
      overnightFee: data.overnightFee ? data.overnightFee : 0,
      dayRangeFee: data.dayRangeFee ? data.dayRangeFee : 0,
      location: [data.mapLat, data.mapLng],
      specialDayFee: convertSpecialDayFee,
      limitRoomBedFee: convertLimitRoomBedFee,
      square: data.square || 0,
      bathroomBox: data.bathroomBox || 0,
    }
    // Upload images to Cloudinary server
    const needToUpload = []
    const noUpload = []
    allImages.forEach((item) => {
      if (!item.isLink) {
        needToUpload.push(item.file)
      } else {
        noUpload.push(item.file)
      }
    })
    let uploadToServer = []
    // Upload thumbnail to Cloudinary server
    const thumbnailToUpload = []
    if (thumbnail && thumbnail.length && !thumbnail[0].isLink) {
      thumbnailToUpload.push(thumbnail[0].file)
    }
    let uploadThumnailToServer = []
    setLoading(true)
    try {
      let resp = null
      switch (action) {
        case 'update':
          if (!id) {
            enqueueSnackbar('Cập nhật thông tin phòng không thành công', { variant: 'error' })
            return
          }
          if (needToUpload.length) {
            uploadToServer = await uploadApi.uploadImage(needToUpload, `Admin/Room/${data?.code}`)
          }
          if (thumbnailToUpload.length) {
            uploadThumnailToServer = await uploadApi.uploadImage(thumbnailToUpload, `Admin/Room/${data?.code}`)
          }
          await update({
            ...requestData,
            thumbnail: uploadThumnailToServer.length ? uploadThumnailToServer[0]?.shortUrl : (thumbnail && thumbnail.length ? thumbnail[0]?.file : 'Unknown'),
            images: [...uploadToServer?.map((item) => item.shortUrl), ...noUpload],
            id,
            priceId: room.priceId,
          })
          // formatRoomData({ ...requestData, images: [...uploadToServer, ...noUpload] })
          fetchRoom('update')
          setLoading(false)
          enqueueSnackbar('Cập nhật thông tin phòng thành công.', { variant: 'success' })
          break
        default:
          resp = await create(requestData)
          if (!resp || !resp.id) {
            enqueueSnackbar('Thêm mới phòng không thành công', { variant: 'error' })
            return
          }
          // Upload images to Cloudinary server
          if (needToUpload.length) {
            uploadToServer = await uploadApi.uploadImage(needToUpload, `Admin/Room/${resp?.code}`)
          }
          if (thumbnailToUpload.length) {
            uploadThumnailToServer = await uploadApi.uploadImage(thumbnailToUpload, `Admin/Room/${data?.code}`)
          }
          await update({
            ...requestData,
            thumbnail: uploadThumnailToServer.length ? uploadThumnailToServer[0]?.shortUrl : (thumbnail && thumbnail.length ? thumbnail[0]?.file : 'Unknown'),
            images: [...uploadToServer?.map((item) => item.shortUrl), ...noUpload],
            id: resp.id,
            priceId: resp.price,
          })
          history.push(`/room/${resp?.code}/update`)
          setLoading(false)
          enqueueSnackbar('Thêm mới phòng thành công.', { variant: 'success' })
      }
    } catch (e) {
      setLoading(false)
      enqueueSnackbar(`Máy chủ lỗi (code: ${e.response ? e.response.status : 'Unknown'}). Không thể cập nhật thông tin phòng.`, { variant: 'error' })
    }
  }

  return (
    <BackgroundBackdrop loading={loading}>
      <Formik
        initialValues={room}
        enableReinitialize
        validationSchema={validateSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          setFieldValue,
        }) => (
          <Form className={classes.root}>
            <Typography variant="h3" className="m-2">
              {action === 'update' ? 'Cập nhật thông tin phòng' : 'Thêm mới phòng'}
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={activeTab} onChange={(e, v) => setActiveTab(v)}>
                <Tab label="Chi tiết căn hộ" {...a11yProps(0)} />
                <Tab label="Tiện ích phòng" {...a11yProps(1)} />
                <Tab label="Thiết lập giá thuê" {...a11yProps(2)} />
                <Button type="submit" variant="contained" color="primary" className="ml-auto mr-4">
                  {action === 'update' ? 'Cập nhật thông tin' : 'Tạo phòng'}
                </Button>
              </Tabs>
            </Box>
            {/* Thông tin phòng */}
            <div role="tabpanel" hidden={activeTab !== 0} id="scrollable-force-tabpanel-0" aria-labelledby="scrollable-force-tab-0">
              <Grid container className="p-2 m-0">
                <Grid item md={6} xs={12} className="pr-2">
                  {/* Thông tin phòng */}
                  <GeneralInfo
                    classes={classes}
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                  <Divider className="w-100 my-4" style={{ backgroundColor: '#d9d9d9' }} />
                  {/* Danh sách ảnh/video */}
                  <Media
                    classes={classes}
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
                <Grid item md={6} xs={12} className="pl-2">
                  {/* Mô tả căn hộ */}
                  <Description
                    classes={classes}
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                  <Divider className="w-100 my-4" style={{ backgroundColor: '#d9d9d9' }} />
                  {/* Quy đinh trong căn hộ */}
                  <Policy
                    classes={classes}
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                  <Divider className="w-100 my-4" style={{ backgroundColor: '#d9d9d9' }} />
                  {/* TODO: Chính sách huỷ và đặt cọc */}
                  <RejectPolicy
                    classes={classes}
                    values={values}
                  />
                  <Divider className="w-100 my-4" style={{ backgroundColor: '#d9d9d9' }} />
                  {/* Hướng dẫn nhận phòng */}
                  <Instruction
                    classes={classes}
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                  <Divider className="w-100 my-4" style={{ backgroundColor: '#d9d9d9' }} />
                  {/* Dịch vụ */}
                  <Service
                    classes={classes}
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
              </Grid>
            </div>
            {/* Tiện ích */}
            <div role="tabpanel" hidden={activeTab !== 1} id="scrollable-force-tabpanel-1" aria-labelledby="scrollable-force-tab-1">
              <Grid className="p-2 m-0">
                <Amenity
                  classes={classes}
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              </Grid>
            </div>
            {/* Chi tiết giá */}
            <div role="tabpanel" hidden={activeTab !== 2} id="scrollable-force-tabpanel-2" aria-labelledby="scrollable-force-tab-2">
              <Grid className="p-2 m-0">
                {/* Giá ngày thường */}
                <NormalPrice
                  classes={classes}
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
                <Divider className="w-100 my-4" style={{ backgroundColor: '#d9d9d9' }} />
                {/* Ưu đãi khi thuê dài hạn */}
                <Promotion
                  classes={classes}
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
                <Divider style={{ margin: '20px auto', backgroundColor: '#d9d9d9' }} />
                {/* Giá ngày đặc biệt */}
                <SpecialPrice
                  classes={classes}
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              </Grid>
            </div>
          </Form>
        )}
      </Formik>
    </BackgroundBackdrop>
  )
}

RoomForm.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.shape(),
  action: PropTypes.string,
  history: PropTypes.shape(),
}

export default withStyles(roomFormStyle)(RoomForm)
