import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import debounce from 'lodash/debounce'
import { makeStyles } from '@material-ui/styles'
import { TextField, InputAdornment } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles({
  root: {
    // minWidth: 300,
    marginTop: -16,
  },
})

const SearchSelectionInput = (props) => {
  const {
    className,
    suggestions,
    value,
    renderOption,
    onChange,
    onSelect,
    label,
    placeholder,
    leftIcon,
  } = props
  const classes = useStyles()

  const onOptionSelected = (newValue) => {
    onSelect(newValue || '')
  }

  const onSearchInputChange = useCallback(
    debounce((searchTxt) => {
      onChange(searchTxt)
    }, 800),
    [],
  )

  return (
    <div className={clsx(classes.root, className)}>
      <Autocomplete
        id="free-solo-demo"
        freeSolo
        value={value || ''}
        options={suggestions}
        renderOption={renderOption}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option
          }
          return option.title
        }}
        onChange={(e, newValue) => onOptionSelected(newValue)}
        renderInput={params => (
          <TextField
            {...params}
            style={{ backgroundColor: 'white' }}
            placeholder={placeholder}
            label={label || 'Tìm kiếm'}
            margin="normal"
            variant="outlined"
            onChange={e => onSearchInputChange(e.target.value)}
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <InputAdornment position="start">{leftIcon}</InputAdornment>
              ),
            }}
          />
        )}
      />
    </div>
  )
}

SearchSelectionInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onSelect: PropTypes.func,
  suggestions: PropTypes.arrayOf(PropTypes.shape()),
  renderOption: PropTypes.func,
  leftIcon: PropTypes.element,
}

export default SearchSelectionInput
