import { connect } from 'react-redux'
import { Dashboard as DashboardPage } from 'views'
import { getDashboardData } from 'redux/selector/common'
import { getDashboard, getLastOrders, getRevenue } from 'redux/action/common'

const mapDispatchToProps = dispatch => ({
  getDashboard: () => dispatch(getDashboard()),
  getLastOrders: () => dispatch(getLastOrders()),
  getRevenue: () => dispatch(getRevenue()),
})

export default connect(getDashboardData, mapDispatchToProps)(DashboardPage)
