import * as userApi from 'provider/user'
import { USER_ACTION } from '../type'

export const login = (email, password) => async (dispatch) => {
  dispatch({
    type: USER_ACTION.LOGIN,
  })
  try {
    const user = await userApi.login(email, password)
    dispatch({
      type: USER_ACTION.LOGIN_OK,
      payload: user,
    })
  } catch (e) {
    dispatch({
      type: USER_ACTION.LOGIN_ERR,
      message: '',
    })
  }
}

export const get = () => async (dispatch) => {
  dispatch({
    type: USER_ACTION.GET_INFO,
  })
  try {
    const user = await userApi.get()
    dispatch({
      type: USER_ACTION.GET_INFO_OK,
      payload: user,
    })
  } catch (e) {
    window.location.replace('/sign-in')
    dispatch({
      type: USER_ACTION.GET_INFO_ERR,
      message: '',
    })
  }
}

export const update = data => async (dispatch) => {
  dispatch({
    type: USER_ACTION.UPDATE_INFO,
  })
  try {
    await userApi.update(data)
    dispatch({
      type: USER_ACTION.UPDATE_INFO_OK,
      payload: data,
    })
  } catch (e) {
    console.log('update info', e)
    dispatch({
      type: USER_ACTION.UPDATE_INFO_ERR,
      message: '',
    })
  }
}

export const signout = () => {
  userApi.signout()
  return {
    type: USER_ACTION.LOGOUT,
  }
}
