import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core'
import { Money, Receipt } from '@material-ui/icons'

const GeneralInfo = ({
  classes,
  data,
}) => {
  const {
    noRevenue,
    hasRevenue,
  } = data

  return (
    <>
      <Typography variant="h3" className="mb-2">Tổng quan</Typography>
      <Grid container spacing={3}>
        {/* Số đơn đặt phòng */}
        <Grid item md={6} xs={12}>
          <Card className="h-100" style={{ minHeight: 130 }}>
            <CardContent>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography className={classes.title} color="textSecondary" gutterBottom variant="h5">
                    KHÔNG CÓ DOANH THU
                  </Typography>
                  <Typography variant="h2">{`${noRevenue || 0} chủ nhà`}</Typography>
                </Grid>
                <Grid item>
                  <Avatar className={classes.avatarMoney}>
                    <Receipt className={classes.icon} />
                  </Avatar>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        {/* Doanh thu 2home */}
        <Grid item md={6} xs={12}>
          <Card className="h-100" style={{ minHeight: 130 }}>
            <CardContent>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography className={classes.title} color="textSecondary" gutterBottom variant="h5">
                    CÓ DOANH THU
                  </Typography>
                  <Typography variant="h2">{`${hasRevenue || 0} chủ nhà`}</Typography>
                </Grid>
                <Grid item>
                  <Avatar className={classes.avatarRevenue}>
                    <Money className={classes.icon} />
                  </Avatar>
                </Grid>
              </Grid>
              {/* {false && (
              <div className={classes.difference}>
                <Typography className={classes.caption} variant="caption">
                  <div style={{ fontSize: 12, marginBottom: 5 }}>Phòng trên 20 đơn: </div>
                  <div>{`${more20OrderRoom || 0} phòng`}</div>
                </Typography>
                <Typography className={classes.caption} variant="caption">
                  <div style={{ fontSize: 12, marginBottom: 5 }}>Phòng dưới 20 đơn: </div>
                  <div>{`${less20OrderRoom || 0} phòng`}</div>
                </Typography>
              </div>
              )} */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

GeneralInfo.propTypes = {
  classes: PropTypes.shape(),
  data: PropTypes.shape(),
}

export default withStyles(theme => ({
  root: {
    padding: theme.spacing(4),
    height: '100%',
  },
  content: {
    alignItems: 'center',
    display: 'flex',
  },
  title: {
    fontWeight: 700,
  },
  avatarMoney: {
    backgroundColor: theme.palette.error.main,
    height: 56,
    width: 56,
  },
  avatarRevenue: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56,
  },
  icon: {
    height: 32,
    width: 32,
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  differenceIcon: {
    color: theme.palette.error.dark,
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: 3,
  },
  caption: {
    fontSize: 14,
    textAlign: 'left',
  },
}))(GeneralInfo)
