import Customer from './Customer'
import Host from './Host'
import Room from './Room'
import Status from './Status'
import Service from './Service'
import BookingDate from './BookingDate'

export default {
  Customer,
  Host,
  Room,
  Status,
  Service,
  BookingDate,
}
