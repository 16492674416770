/* eslint-disable no-useless-catch */
import axios from 'axios'
import { BUILDING_TABLE_LIST } from 'config'
import * as ENDPOINT from './url'

export const find = async (query) => {
  try {
    const resp = await axios.get(ENDPOINT.FETCH_BUILDING({ ...query, page: query?.page || 1, limit: query?.limit || BUILDING_TABLE_LIST }))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const findOne = async (id) => {
  try {
    const resp = await axios.get(ENDPOINT.FETCH_BUILDING_BY_ID(id))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const create = async (data) => {
  try {
    const resp = await axios.post(ENDPOINT.BUILDING, data)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const remove = async (id) => {
  try {
    const resp = await axios.delete(`${ENDPOINT.BUILDING}/${id}`)
    return resp.data
  } catch (e) {
    throw e
  }
}
