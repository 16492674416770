import React from 'react'
import PropTypes from 'prop-types'
import {
  withStyles,
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
} from '@material-ui/core'
import VpnKey from '@material-ui/icons/VpnKey'
import { StatusBullet } from 'components'
import styles from './styled'

const Host = ({
  classes,
  data: {
    total,
    online,
  },
}) => (
  <a href="/host" className={classes.no_link}>
    <Card>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
            CHỦ NHÀ
            </Typography>
            <Typography variant="h3">{total}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <VpnKey className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <div className={classes.difference}>
          {/* <ArrowDownwardIcon className={classes.differenceIcon} /> */}
          <Typography className={classes.differenceValue} variant="body2">
            <StatusBullet color="success" size="sm" />
          </Typography>
          <Typography className={classes.caption} variant="caption">
            {online}
            {' '}
          Online
          </Typography>
        </div>
      </CardContent>
    </Card>
  </a>
)

Host.propTypes = {
  classes: PropTypes.shape(),
  data: PropTypes.shape(),
}

export default withStyles(styles)(Host)
