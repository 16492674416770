import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { Person, Close, Remove } from '@material-ui/icons'
import { SearchSelectionInput } from 'components'
// import * as commonApi from 'provider/common'
import * as customerApi from 'provider/customer'
import { Typography, Grid } from '@material-ui/core'

const Customer = ({ value, onApply }) => {
  const [customerSuggestion, setCustomerSuggestion] = useState([])
  const [sltCustomer, setSltCustomer] = useState({})
  const [customers, setCustomers] = useState([])

  const fetchCustomer = async (ids) => {
    // TODO: Tạm thời sử dụng JSON.stringify() cho api version 1
    const resp = await customerApi.find({ ids })
    setCustomers(
      resp.data.map(opt => ({
        id: opt._id || opt.id,
        title: `${opt.name} (${opt.code})`,
        subTitle: `${opt.email} - ${opt.phone}`,
      })),
    )
  }

  useEffect(() => {
    try {
      if (!value) {
        setCustomers([])
        return () => {}
      }
      const ids = value
      if (ids?.length) {
        fetchCustomer(ids)
      }
    } catch (e) {
      setCustomers([])
      onApply(undefined)
    }
    return () => {}
  }, [value])

  const onCustomerChange = async (str) => {
    if (!str) return
    const resp = await customerApi.find({
      searchTxt: str,
      limit: Number.MAX_SAFE_INTEGER,
    })
    setCustomerSuggestion(
      resp.data?.map(opt => ({
        id: opt._id || opt.id,
        title: `${opt.name} (${opt.code})`,
        subTitle: `${opt.email} - ${opt.phone}`,
      })),
    )
  }

  const onCustomerSelect = async (item) => {
    if (!item) return
    setSltCustomer(item)
    if (!customers.find(c => item && c.id === item.id)) {
      setCustomers([...customers].concat(item))
    }
  }

  const renderCustomerOption = (option) => {
    const { title, subTitle } = option
    return (
      <div>
        <div style={{ fontSize: 16 }}>
          <strong>{title}</strong>
        </div>
        <div>
          <small>{subTitle}</small>
        </div>
      </div>
    )
  }

  return (
    <PopupState variant="popover" popupId="customer-filter">
      {popupState => (
        <div>
          <Button variant="outlined" {...bindTrigger(popupState)} style={{ backgroundColor: 'white', textTransform: 'none' }} className="text-left w-100">
            <Person />
            {customers.filter(c => value?.includes(c.id))?.length
              ? customers.filter(c => value?.includes(c.id))?.map(c => c.title)?.join(', ')
              : 'Chọn khách hàng'}
            {value?.length
              ? (
                <Close onClick={(e) => {
                  e.stopPropagation()
                  onApply(undefined)
                }}
                />
              )
              : null}
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box p={2} style={{ width: 400 }}>
              <SearchSelectionInput
                label="Khách hàng"
                placeholder="Nhập thông tin khách hàng"
                value={sltCustomer.title}
                renderOption={renderCustomerOption}
                suggestions={customerSuggestion}
                onChange={str => onCustomerChange(str)}
                onSelect={onCustomerSelect}
              />
              {/* Selected customers */}
              <div>
                {customers.length ? <Typography>Danh sách khách hàng tìm kiếm:</Typography> : ''}
                {
                  customers.map((c, i) => (
                    <Grid container>
                      <Typography variant="body1" style={{ fontWeight: 500 }}>{`${i + 1}. ${c.title}`}</Typography>
                      <Remove style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => setCustomers([...customers].filter(item => item.id !== c.id))} />
                    </Grid>
                  ))
                }
              </div>
              <Button
                onClick={() => {
                  onApply(customers?.map(c => c.id) || [])
                  popupState.close()
                }}
                style={{ float: 'right', marginBottom: 7 }}
              >
              Áp dụng
              </Button>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

Customer.propTypes = {
  onApply: PropTypes.func,
  value: PropTypes.string,
}

export default Customer
