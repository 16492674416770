import * as prepaidHistoryApi from 'provider/prepaidHistory'
import { PREPAID_HISTORY_ACTION } from '../type'

export const find = (query) => async (dispatch, getState) => {
  const { page: pageProp, limit: limitProp } = getState().getIn(['prepaidHistory', 'meta']).toJS()
  const data = getState().getIn(['prepaidHistory', 'data']).toJS()
  const pageQuery = getState().getIn(['prepaidHistory', 'query']).toJS()
  const convertedQuery = {
    page: pageProp,
    limit: limitProp,
    ...query,
  }
  if (!data || data.length === 0 || JSON.stringify(convertedQuery) !== JSON.stringify(pageQuery)) {
    dispatch({
      type: PREPAID_HISTORY_ACTION.FETCH,
    })
    try {
      const prepaidHistory = await prepaidHistoryApi.find(query)
      dispatch({
        type: PREPAID_HISTORY_ACTION.FETCH_OK,
        payload: { query, ...prepaidHistory },
      })
    } catch (e) {
      dispatch({
        type: PREPAID_HISTORY_ACTION.FETCH_ERR,
        payload: { message: 'Something went wrong' },
      })
    }
  }
}

export const update = (data) => async (dispatch) => {
  dispatch({
    type: PREPAID_HISTORY_ACTION.UPDATE,
  })
  try {
    const prepaidHistory = await prepaidHistoryApi.update(data._id, data)
    dispatch({
      type: PREPAID_HISTORY_ACTION.UPDATE_OK,
      payload: prepaidHistory,
    })
  } catch (e) {
    dispatch({
      type: PREPAID_HISTORY_ACTION.UPDATE_ERR,
      payload: { message: 'Something went wrong' },
    })
  }
}
