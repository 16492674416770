/* eslint-disable no-useless-catch */
import axios from 'axios'
import { HOST_TABLE_LIST } from 'config'
import * as ENDPOINT from './url'

export const find = async (query) => {
  try {
    const resp = await axios.get(ENDPOINT.FETCH_HOST({ ...query, page: query.page || 1, limit: query.limit || HOST_TABLE_LIST }))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const findOne = async (id) => {
  try {
    const resp = await axios.get(ENDPOINT.FETCH_HOST_BY_ID(id))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const create = async (data) => {
  try {
    const resp = await axios.post(ENDPOINT.CREATE_HOST, data)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const update = async (data) => {
  try {
    const resp = await axios.patch(ENDPOINT.UPDATE_HOST(data.id), data)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const remove = async (ids) => {
  try {
    const resp = await axios.delete(ENDPOINT.DELETE_HOST, { data: { ids } })
    return resp.data
  } catch (e) {
    throw e
  }
}

export const deactive = async (hostId) => {
  try {
    const resp = await axios.post(ENDPOINT.DEACTIVE_HOST(hostId))
    return resp.data
  } catch (e) {
    throw e
  }
}
