import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(() => ({
  active: {
    backgroundColor: '#3f51b5',
    textTransform: 'none',
    color: 'white',
  },
  inactive: {
    backgroundColor: 'white',
    textTransform: 'none',
  },
}))

const Service = ({ value, onApply }) => {
  const classes = useStyles()
  const [hasService, setHasService] = useState(false)

  useEffect(() => {
    setHasService(String(value) === 'true')
    return () => {}
  }, [value])

  return (
    <div>
      <Button
        variant="outlined"
        className={hasService ? `${classes.active}` : `${classes.inactive}`}
        onClick={() => onApply(!hasService)}
      >
        <Icon className="fa fa-birthday-cake" style={{ fontSize: 18, marginRight: 10 }} />
      Dịch vụ
      </Button>
    </div>
  )
}

Service.propTypes = {
  onApply: PropTypes.func,
  value: PropTypes.string,
}

export default Service
