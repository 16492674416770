import { fromJS } from 'immutable'
import { COMMON_ACTION } from '../type'

export default function common(state, action) {
  const { type: actionType, payload } = action

  switch (actionType) {
    case COMMON_ACTION.FETCH_DASHBOARD:
      return state.set('loading', true).set('error', null)

    case COMMON_ACTION.FETCH_DASHBOARD_OK:
      return state
        .set('loading', false)
        .setIn(['dashboard', 'status'], fromJS(payload))

    case COMMON_ACTION.FETCH_DASHBOARD_ERR:
      return state
        .set('loading', false)
        .set('error', payload ? payload.message : '')

    case COMMON_ACTION.FETCH_LAST_ORDER:
      return state.set('loading', true).set('error', null)

    case COMMON_ACTION.FETCH_LAST_ORDER_OK:
      return state
        .set('loading', false)
        .setIn(['dashboard', 'data', 'orders'], fromJS(payload))

    case COMMON_ACTION.FETCH_LAST_ORDER_ERR:
      return state
        .set('loading', false)
        .set('error', payload ? payload.message : '')

    case COMMON_ACTION.FETCH_REVENUE:
      return state.set('loading', true).set('error', null)

    case COMMON_ACTION.FETCH_REVENUE_OK:
      return state
        .set('loading', false)
        .setIn(['dashboard', 'data', 'revenue'], fromJS(payload))

    case COMMON_ACTION.FETCH_REVENUE_ERR:
      return state
        .set('loading', false)
        .set('error', payload ? payload.message : '')

    default:
      return state
  }
}
