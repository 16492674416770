import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  withStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import styled from './styled'

const WarningDialog = ({
  visible,
  title,
  content,
  onClose,
  onConfirm,
}) => (
  <Dialog open={visible} onClose={onClose} PaperProps={{ style: { maxWidth: 800, overflowY: 'unset' } }} scroll="body">
    <DialogTitle disableTypography>
      <h3>{title}</h3>
    </DialogTitle>
    <DialogContent style={{ minWidth: 800, minHeight: 80, paddingTop: 0 }}>
      <Grid item xs={6} className="mb-2">
        {content}
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onConfirm} color="primary">
        Xác nhận
      </Button>
    </DialogActions>
  </Dialog>
)

WarningDialog.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default withStyles(styled)(WarningDialog)
