import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { FormatListBulleted, Close } from '@material-ui/icons'
import { FormControlLabel, Checkbox, Typography } from '@material-ui/core'

const Status = ({ data, value, onApply }) => {
  const [statuses, setStatuses] = useState([])
  const [sltStatus, setSltStatus] = useState([])

  useEffect(() => {
    console.log(value)
    if (value) {
      try {
        const parsing = data.map(item => ({
          ...item,
          checked: value.includes(item.value),
        }))
        setStatuses(parsing)
        setSltStatus(parsing.filter(item => item.checked).map(item => item.label))
      } catch (e) {
        setStatuses(data.map(item => ({
          ...item,
          checked: false,
        })))
      }
    } else {
      setStatuses(data.map(item => ({
        ...item,
        checked: false,
      })))
    }
    return () => {
    }
  }, [value, data])

  const onSelectStatus = (idx, checked) => {
    setStatuses([
      ...statuses.slice(0, idx),
      {
        ...statuses[idx],
        checked,
      },
      ...statuses.slice(idx + 1, statuses.length),
    ])
  }

  return (
    <PopupState variant="popover" popupId="customer-filter">
      {popupState => (
        <div>
          <Button variant="outlined" {...bindTrigger(popupState)} style={{ backgroundColor: 'white', textTransform: 'none' }}>
            <FormatListBulleted />
            {sltStatus.length ? sltStatus.join(', ') : 'Chọn trạng thái'}
            {value
              ? (
                <Close onClick={(e) => {
                  e.stopPropagation()
                  setSltStatus([])
                  onApply(undefined)
                }}
                />
              )
              : null}
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box p={2} style={{ width: 200 }}>
              {statuses.map(
                (item, idx) => (
                  <div>
                    <FormControlLabel
                      control={(
                        <Checkbox
                          checked={item.checked}
                          onChange={e => onSelectStatus(idx, e.target.checked)}
                          color="primary"
                        />
                      )}
                      label={<Typography variant="body1">{item.label}</Typography>}
                    />
                  </div>
                ),
              )}
              <Button
                onClick={() => {
                  onApply(statuses.filter(item => item.checked).map(item => item.value))
                  popupState.close()
                }}
                style={{ float: 'right', marginBottom: 7 }}
              >
              Áp dụng
              </Button>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

Status.propTypes = {
  onApply: PropTypes.func,
  value: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.string),
}

export default Status
