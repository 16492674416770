import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'

const MediaUpload = ({
  className, title, multiple, onSuccess, disabled,
}) => {
  const openSelectImage = () => {
    let urls = []
    const cloudinaryWidget = window.cloudinary.createUploadWidget({
      cloudName: 'dx9tmki1j',
      uploadPreset: '2home-admin',
      sources: ['local', 'url'],
      multiple,
      singleUploadAutoClose: false,
      text: {
        en: {
          or: 'Hoặc',
          menu: {
            files: 'Thư mục',
            web: 'Đường link',
          },
          url: {
            main_title: 'Đường link URL',
            inner_title: 'Đường link URL của ảnh muốn upload:',
            input_placeholder: 'http://remote.site.example/images/remote-image.jpg',
          },
          local: {
            browse: 'Chọn ảnh',
            main_title: 'Upload ảnh',
            dd_title_single: 'Kéo và thả ảnh vào đây',
            dd_title_multi: 'Kéo và thả ảnh vào đây',
            drop_title_single: 'Drop a file to upload',
            drop_title_multiple: 'Drop files to upload',
          },
          queue: {
            title: 'Ảnh đã tải lên',
            title_uploading_with_counter: 'Đang tải lên {{num}} ảnh',
            title_uploading: 'Đang tải lên ...',
            mini_title: 'Đã tải lên',
            mini_title_uploading: 'Đang tải lên',
            show_completed: 'Hoàn thành',
            retry_failed: 'Thử lại',
            abort_all: 'Huỷ',
            upload_more: 'Tải lên thêm',
            done: 'Hoàn thành',
            mini_upload_count: '{{num}} ảnh đã tải lên',
            mini_failed: '{{num}} ảnh lỗi',
            statuses: {
              uploading: 'Đang tải lên ...',
              error: 'Lỗi',
              uploaded: 'Hoàn thành',
              aborted: 'Đã huỷ',
            },
          },
        },
      },
    }, (error, result) => {
      if (!error && result) {
        switch (result.event) {
          case 'queues-end':
            urls = urls.concat(result.info.files.map(f => f.uploadInfo.secure_url))
            break
          case 'close':
            if (urls.length) {
              onSuccess(urls)
            }
            break
          default:
        }
      }
    })
    cloudinaryWidget.open()
  }

  if (disabled) return null
  return (
    <Button
      className={{ ...className }}
      color="primary"
      variant="text"
      onClick={openSelectImage}
    >
      {
        title
      }
    </Button>
  )
}

MediaUpload.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  multiple: PropTypes.bool,
  onSuccess: PropTypes.func,
  disabled: PropTypes.bool,
}

export default MediaUpload
