import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  withStyles,
  TextField,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { formatPrice } from 'utils/formatter'
import { last } from 'lodash'
import styled from './styled'
import BlockUI from 'components/BlockUI'

const ConfirmDialog = ({
  // classes,
  reservation,
  open,
  onClose,
  onConfirm,
}) => {
  const [isBusy, setBusy] = useState(false)

  const [extraPaymentInfo, setExtraPaymentInfo] = useState({
    payment: undefined,
  })

  useEffect(() => {
    if (open) {
      setExtraPaymentInfo({
        payment: undefined,
      })
    }
    return () => {
    }
  }, [open])

  const getConfirmOrderHeader = () => {
    if (!reservation) return null
    const {
      status,
      addCheckoutPoint,
    } = reservation
    const lastCheckoutPoint = addCheckoutPoint && addCheckoutPoint.length ? addCheckoutPoint[addCheckoutPoint.length - 1] : null
    switch (status) {
      //! Trạng thái tương ứng với đơn mới đặt chưa thanh toán
      case 'processing':
        return (
          <h3>Xác nhận đơn đặt phòng</h3>
        )
      //! Trạng thái tương ứng với đơn chỉnh sửa được hoàn tiền với phòng cần xác nhận
      case 'processing-refund-in-update':
        return (
          <h3>Xác nhận chỉnh sửa đơn đặt phòng</h3>
        )
      //! Trạng thái tương ứng với đơn chỉnh sửa được hoàn tiền với phòng xác nhận ngay và hoàn tiền vào ngân hàng
      case 'waiting-refund-in-update':
        return (
          <h3>Xác nhận chỉnh sửa đơn đặt phòng</h3>
        )
      case 'host-confirm-refund-update':
        return (
          <h3>Xác nhận hoàn tiền đơn đặt phòng sau chỉnh sửa</h3>
        )
      //! Trạng thái tương ứng với đơn chỉnh sửa cần thanh toán thêm với phòng cần xác nhận
      case 'processing-pay-extra':
        return (
          <h3>Xác nhận chỉnh sửa đơn đặt phòng</h3>
        )
      //! Trạng thái tương ứng với đơn chỉnh sửa cần thanh toán thêm với phòng xác nhận ngay
      case 'confirmed-pay-extra':
        return (
          <h3>Xác nhận chỉnh sửa đơn đặt phòng</h3>
        )
      //! Trạng thái tương ứng với đơn đặt ở thêm, chờ xác nhận thanh toán qua tài khoản ngân hàng
      case 'add-checkout-processing':
        if (lastCheckoutPoint?.status === 'processing') {
          return (
            <h3>Xác nhận đặt thêm</h3>
          )
        }
        if (lastCheckoutPoint?.status === 'confirmed') {
          return (
            <h3>Xác nhận thanh toán đặt thêm</h3>
          )
        }
        return null
      default:
        return null
    }
  }

  const getConfirmOrderContent = () => {
    if (!reservation) return null
    const {
      status,
      // roomPrice,
      // servicePrice,
      prevState,
      addCheckoutPoint,
      extraFund,
    } = reservation
    const {
      // roomPrice: oldRoomPrice,
      // servicePrice: oldServicePrice,
      refundUpdateInfo: {
        paymentType: refundUpdatePayment,
        user,
        name,
        account,
      },
    } = prevState || {
      roomPrice: 0,
      servicePrice: 0,
      refundUpdateInfo: {
        paymentType: '2home',
        user: '',
        name: '',
        account: '',
      },
    }
    const lastCheckoutPoint = last(addCheckoutPoint || [])
    // const extraRoomPrice = roomPrice - oldRoomPrice
    // const extraServicePrice = servicePrice - oldServicePrice
    switch (status) {
      //! Trạng thái tương ứng với đơn mới đặt chưa thanh toán
      case 'processing':
        return (
          <div>
            <span>Xác nhận đơn đặt phòng </span>
            <b>{reservation?.code}</b>
          </div>
        )
      //! Trạng thái tương ứng với đơn chỉnh sửa được hoàn tiền với phòng cần xác nhận
      case 'processing-refund-in-update':
        return (
          <div>
            <div>
              <span>Xác nhận chỉnh sửa đơn đặt phòng </span>
              <b>{reservation?.code}</b>
              <span> và hoàn tiền sau chỉnh sửa.</span>
            </div>
            <div>
              <span>Số tiền hoàn </span>
              <span><b>{`${formatPrice(Math.abs(refundUpdatePayment === '2home' ? extraFund : ((extraFund * 99) / 100)))} VND.`}</b></span>
            </div>
            <span>Hoàn tiền tới tài khoản </span>
            {
              refundUpdatePayment === '2home'
                ? <span>2home, tiền sẽ được hoàn ngay vào tài khoản của khách sau khi ấn &quot;Xác nhận&quot;.</span>
                : (
                  <>
                    <span> ngân hàng, xác nhận đã chuyển khoản tới tài khoản khách hàng cung cấp trước khi ấn &quot;Xác nhận&quot;.</span>
                    <br />
                    <div>
                      <div>Thông tin tài khoản:</div>
                      <div>{`Số tài khoản: ${account}`}</div>
                      <div>{`Chủ tài khoản: ${user}`}</div>
                      <div>{`Ngân hàng: ${name}`}</div>
                    </div>
                  </>
                )
            }
          </div>
        )
      //! Trạng thái tương ứng với đơn chỉnh sửa được hoàn tiền với phòng xác nhận ngay và hoàn tiền vào ngân hàng
      case 'waiting-refund-in-update':
      case 'host-confirm-refund-update':
        return (
          <div>
            <div>
              <span>Xác nhận chỉnh sửa đơn đặt phòng </span>
              <b>{reservation?.code}</b>
              <span> và hoàn tiền sau chỉnh sửa.</span>
            </div>
            <div>
              <span>Số tiền hoàn </span>
              <span><b>{`${formatPrice(Math.abs(extraFund))} VND.`}</b></span>
            </div>
            <span>Hoàn tiền tới tài khoản ngân hàng, xác nhận đã chuyển khoản tới tài khoản khách hàng cung cấp trước khi ấn &quot;Xác nhận&quot;.</span>
            <br />
            <div>
              <div>Thông tin tài khoản:</div>
              <div>{`Số tài khoản: ${account}`}</div>
              <div>{`Chủ tài khoản: ${user}`}</div>
              <div>{`Ngân hàng: ${name}`}</div>
            </div>
          </div>
        )
      //! Trạng thái tương ứng với đơn chỉnh sửa cần thanh toán thêm với phòng cần xác nhận
      case 'processing-pay-extra':
        return (
          <div>
            <div>
              <span>Xác nhận chỉnh sửa đơn đặt phòng </span>
              <b>{reservation?.code}</b>
            </div>
            <span>Sau khi đơn đặt phòng được xác nhận, khách hàng sẽ phải thanh toán thêm số tiền </span>
            <span><b>{`${formatPrice(Math.abs(extraFund))} VND.`}</b></span>
          </div>
        )
      //! Trạng thái tương ứng với đơn chỉnh sửa cần thanh toán thêm với phòng xác nhận ngay
      case 'confirmed-pay-extra':
        return null
      //! Trạng thái tương ứng với đơn đặt ở thêm, chờ xác nhận thanh toán qua tài khoản ngân hàng
      case 'add-checkout-processing':
        if (lastCheckoutPoint?.status === 'processing') {
          return (
            <div>
              <div>
                <span>Xác nhận thanh toán đặt thêm cho đơn đặt phòng </span>
                <b>{reservation?.code}</b>
              </div>
              <div>
                <span>Thời gian </span>
                <span><b>{`${addCheckoutPoint[addCheckoutPoint?.length - 1]?.amount} tiếng.`}</b></span>
              </div>
              <div>
                <span>Số tiền </span>
                <span><b>{`${formatPrice(addCheckoutPoint[addCheckoutPoint?.length - 1]?.value)} VND.`}</b></span>
              </div>
              {
                addCheckoutPoint[addCheckoutPoint?.length - 1]?.payment === 'bank-transfer'
                  ? (
                    <>
                      <br />
                      <div> Xác nhận khách hàng đã thanh toán đặt thêm trước khi ấn &quot;Xác nhận&quot;.</div>
                      <div>
                        <div>Tài khoản chuyển đến: </div>
                        <div>{`Ngân hàng: ${addCheckoutPoint[addCheckoutPoint?.length - 1]?.bankName}`}</div>
                      </div>
                    </>
                  )
                  : null
              }
            </div>
          )
        }
        if (lastCheckoutPoint?.status === 'confirmed') {
          return (
            <div>
              <div>
                <span>Xác nhận thanh toán đặt thêm cho đơn đặt phòng </span>
                <b>{reservation?.code}</b>
              </div>
              <div>
                <span>Thời gian </span>
                <span><b>{`${lastCheckoutPoint?.amount} tiếng.`}</b></span>
              </div>
              <div>
                <span>Số tiền </span>
                <span><b>{`${formatPrice(lastCheckoutPoint?.value)} VND.`}</b></span>
              </div>
              <Grid container spacing={3} className="mt-2">
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color={extraPaymentInfo.payment === '2home' ? 'primary' : 'default'}
                    onClick={() => setExtraPaymentInfo({ ...extraPaymentInfo, payment: '2home' })}
                  >
                    Số dư 2home
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color={extraPaymentInfo.payment === 'bank-transfer' ? 'primary' : 'default'}
                    onClick={() => setExtraPaymentInfo({ ...extraPaymentInfo, payment: 'bank-transfer' })}
                  >
                    Ngân hàng
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color={extraPaymentInfo.payment === 'host' ? 'primary' : 'default'}
                    onClick={() => setExtraPaymentInfo({ ...extraPaymentInfo, payment: 'host' })}
                  >
                    Chủ nhà thu hộ
                  </Button>
                </Grid>
              </Grid>
              {
                extraPaymentInfo?.payment === 'bank-transfer'
                  ? (
                    <TextField
                      className="mt-2"
                      label="Ngân hàng"
                      variant="outlined"
                      fullWidth
                      value={extraPaymentInfo?.bankName}
                      onChange={e => setExtraPaymentInfo({ ...extraPaymentInfo, bankName: e.target.value })}
                    />
                  )
                  : null
              }
            </div>
          )
        }
        return null
      default:
        return null
    }
  }

  const confirmReservation = async () => {
    setBusy(true)

    try {
      await onConfirm(extraPaymentInfo)
    } finally {
      setBusy(false)
    }
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <BlockUI isLoading={isBusy}>
        <DialogTitle disableTypography>
          <h3>{getConfirmOrderHeader()}</h3>
        </DialogTitle>
        <DialogContent style={{ minWidth: 600, minHeight: 80, paddingTop: 0 }}>
          <div className="mb-2">{getConfirmOrderContent()}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={confirmReservation} color="primary">
            Xác nhận
          </Button>
        </DialogActions>
      </BlockUI>
    </Dialog>
  )
}

ConfirmDialog.propTypes = {
  // classes: PropTypes.shape(),
  reservation: PropTypes.shape(),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default withStyles(styled)(ConfirmDialog)
