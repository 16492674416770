import React from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  TextField,
} from '@material-ui/core'

const Value = ({
  // classes,
  errors,
  touched,
  values,
  handleChange,
  // setFieldValue,
}) => (
  <>
    <Typography variant="h6" className="mb-3">Thông tin khuyến mãi</Typography>
    <TextField
      type="number"
      style={{ backgroundColor: 'white' }}
      label="Số lần sử dụng"
      variant="outlined"
      name="count"
      fullWidth
      value={values.count}
      onChange={handleChange}
      error={errors.count && touched.count}
      helperText={errors.count && touched.count && errors.count}
      InputLabelProps={{ shrink: true }}
      size="small"
    />
  </>
)

Value.propTypes = {
  // classes: PropTypes.shape(),
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  handleChange: PropTypes.func,
  // setFieldValue: PropTypes.func,
}

export default Value
