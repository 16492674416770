import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { Person, Close } from '@material-ui/icons'
import { SearchSelectionInput } from 'components'
import * as commonApi from 'provider/common'
import * as customerApi from 'provider/customer'

const Customer = ({ value, onApply }) => {
  const [customerSuggestion, setCustomerSuggestion] = useState([])
  const [sltCustomer, setSltCustomer] = useState({})
  const [customer, setCustomer] = useState(null)

  const fetchCustomer = async (id) => {
    const resp = await customerApi.findOne(id)
    setCustomer(`${resp.name} (${resp.code})`)
    setSltCustomer({
      id: resp.id,
      title: `${resp.name} (${resp.code})`,
      subTitle: `${resp.email} - ${resp.phone}`,
    })
    setCustomerSuggestion(
      [resp].map(opt => ({
        id: opt.id,
        title: `${opt.name} (${opt.code})`,
        subTitle: `${opt.email} - ${opt.phone}`,
      })),
    )
  }

  useEffect(() => {
    if (!value) {
      setCustomer(null)
      return () => {}
    }
    if (sltCustomer.id !== value) {
      fetchCustomer(value)
    }
    return () => {}
  }, [value])

  const onCustomerChange = async (str) => {
    const suggestions = await commonApi.getCustomerSuggestion(str)
    setCustomerSuggestion(
      suggestions.map(opt => ({
        id: opt.id,
        title: `${opt.display} (${opt.code})`,
        subTitle: `${opt.email} - ${opt.phone}`,
      })),
    )
  }

  const onCustomerSelect = async (item) => {
    setSltCustomer(item)
  }

  const renderCustomerOption = (option) => {
    const { title, subTitle } = option
    return (
      <div>
        <div style={{ fontSize: 16 }}>
          <strong>{title}</strong>
        </div>
        <div>
          <small>{subTitle}</small>
        </div>
      </div>
    )
  }

  return (
    <PopupState variant="popover" popupId="customer-filter">
      {popupState => (
        <div>
          <Button variant="outlined" {...bindTrigger(popupState)} style={{ backgroundColor: 'white', textTransform: 'none' }}>
            <Person />
            {customer || 'Khách hàng'}
            {value
              ? (
                <Close onClick={(e) => {
                  e.stopPropagation()
                  onApply(undefined)
                }}
                />
              )
              : null}
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box p={2} style={{ width: 400 }}>
              <SearchSelectionInput
                label="Khách hàng"
                placeholder="Nhập thông tin khách hàng"
                value={sltCustomer.title}
                renderOption={renderCustomerOption}
                suggestions={customerSuggestion}
                onChange={str => onCustomerChange(str)}
                onSelect={onCustomerSelect}
              />
              <Button
                onClick={() => {
                  onApply(sltCustomer.id)
                  setCustomer(sltCustomer.title)
                  popupState.close()
                }}
                style={{ float: 'right', marginBottom: 7 }}
              >
              Áp dụng
              </Button>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

Customer.propTypes = {
  onApply: PropTypes.func,
  value: PropTypes.string,
}

export default Customer
