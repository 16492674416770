import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink as RouterLink } from 'react-router-dom'
import {
  Fab,
  withStyles,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { applyFiltersToUrl, getFiltersFromUrl } from 'utils/url'
import { EnhancedEncryption } from '@material-ui/icons'
import * as orderApi from 'provider/reservation'
// import { BOOKING_STATUS } from 'contants'
import Toolbar from './Toolbar'
import Table from './Table'
import reservationListStyle from './styled'
import LockingRoomDialog from './Dialog/LockingRoom'

const ReservationList = ({
  classes,
  reservation,
  location,
  history,
  find,
  pay,
  reject,
  confirm,
  undo,
  refund,
  addCheckout,
  rejectCheckout,
  resetSocket,
  socket,
}) => {
  const [openLockDialog, setOpenLockDialog] = useState(false)

  const updateUrl = (query) => {
    if (query.code) {
      // eslint-disable-next-line no-param-reassign
      delete query.code
    }
    const url = `${location.pathname}${applyFiltersToUrl(query)}`
    history.push(url, { replace: false })
    find({
      ...query,
      active: query.active || 0,
    })
  }

  useEffect(() => {
    const query = getFiltersFromUrl(location.search)
    try {
      find({
        ...query,
        active: query.active || 0,
      })
    } catch (e) {
      delete query.status
      find({
        ...query,
        active: query.active || 0,
      })
    }
  }, [])

  const applyFilter = (fltData) => {
    const query = {
      ...reservation.query,
      page: 1,
      ...fltData,
    }
    updateUrl(query)
    // find(query)
  }

  const changePage = (page) => {
    const query = {
      ...reservation.query,
      page,
    }
    updateUrl(query)
    // find(query)
  }

  const changeRowsPerPage = (limit) => {
    const query = {
      ...reservation.query,
      page: 1,
      limit,
    }
    updateUrl(query)
    // find(query)
  }

  const sort = (sortBy, sortDirection) => {
    const query = {
      ...reservation.query,
      sortBy,
      sortDirection,
    }
    updateUrl(query)
    // find(query)
  }

  const sendEmail = async (id) => {
    try {
      const resp = await orderApi.sendEmail(id)
      console.log(resp)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className={classes.root}>
      {/* Locking room */}
      <LockingRoomDialog
        open={openLockDialog}
        onClose={() => setOpenLockDialog(false)}
        onConfirm={() => applyFilter({})}
      />
      {/* Filter options */}
      <Toolbar
        classes={classes}
        location={location}
        applyFilter={applyFilter}
        socket={socket}
        resetSocket={resetSocket}
      />
      {/* Reservation table */}
      <div className={classes.content}>
        <Table
          classes={classes}
          loading={reservation.loading}
          reservations={reservation.data}
          meta={reservation.meta}
          defaultSort={
            reservation.query
              ? {
                sortBy: reservation.query.sortBy,
                sortDirection: reservation.query.sortDirection,
              }
              : {}
          }
          onPageChange={changePage}
          onRowsPerPageChange={changeRowsPerPage}
          onSortChange={sort}
          applyFilter={applyFilter}
          pay={pay}
          confirm={confirm}
          reject={reject}
          undo={undo}
          refund={refund}
          addCheckout={addCheckout}
          rejectCheckout={rejectCheckout}
          edit={(id) => history.push(`/reservation/${id}/update`)}
          view={(id) => history.push(`/reservation/${id}/view`)}
          sendEmail={sendEmail}
        />
      </div>
      {/* Create/Lock button */}
      <Fab color="primary" aria-label="add" className={classes.lockBtn} onClick={() => setOpenLockDialog(true)}>
        <EnhancedEncryption />
      </Fab>
      <RouterLink to="/reservation/create">
        <Fab color="primary" aria-label="add" className={classes.createBtn}>
          <AddIcon />
        </Fab>
      </RouterLink>
    </div>
  )
}

ReservationList.propTypes = {
  classes: PropTypes.shape(),
  reservation: PropTypes.arrayOf(PropTypes.shape()),
  find: PropTypes.func,
  pay: PropTypes.func,
  reject: PropTypes.func,
  confirm: PropTypes.func,
  undo: PropTypes.func,
  refund: PropTypes.func,
  addCheckout: PropTypes.func,
  rejectCheckout: PropTypes.func,
  history: PropTypes.shape(),
  location: PropTypes.shape(),
  socket: PropTypes.shape(),
  resetSocket: PropTypes.func,
}

export default withStyles(reservationListStyle)(ReservationList)
