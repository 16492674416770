import React from 'react'
import PropTypes from 'prop-types'
import {
  withStyles,
  Grid,
  InputLabel,
  Divider,
  Typography,
} from '@material-ui/core'
import { formatPrice } from 'utils/formatter'
import styled from './styled'

const OrderRevenue = ({
  // classes,
  data,
}) => {
  const {
    // code,
    host: {
      prev: prevHost,
      current: currentHost,
    },
    system: {
      prev: prevSystem,
      current: currentSystem,
    },
    customer: {
      prev: prevCustomer,
      current: currentCustomer,
    },
  } = data || {
    host: {},
    system: {},
    customer: {},
  }

  const getRoomRevenue = info => info?.roomRevenue + info?.guestRevenue

  const getRoomPrice = info => info?.roomPrice + info?.guestPrice

  const getRemainingPrice = () => {
    const remainingRoom = prevCustomer.roomPrice - (currentCustomer.punishRoomPrice - prevCustomer.punishRoomPrice)
    const remainingGuest = prevCustomer.guestPrice - (currentCustomer.punishGuestPrice - prevCustomer.punishGuestPrice)
    const remainingService = prevCustomer.servicePrice - currentCustomer.punishServicePrice
    return remainingGuest + remainingRoom + remainingService
  }

  const getMissingPrice = () => currentCustomer?.punishRoomPrice + currentCustomer?.punishGuestPrice + currentCustomer?.punishServicePrice

  const getExtraPrice = () => (currentCustomer.roomPrice + currentCustomer.guestPrice + currentCustomer.servicePrice) - getRemainingPrice()

  const getPayOnBehaftTotal = info => info?.payOnBehaft?.map(item => item.value)?.reduce((a, b) => a + b, 0)

  // TODO: Tính toán thêm mã giảm giá của chủ nhà
  const getHostRevenueNet = (info, system) => getRoomRevenue(info) + info?.punishDeposit - getPayOnBehaftTotal(info) + (system?.setupFee || 0)

  // TODO: Tính toán thêm mã giảm giá của 2home
  const getCustomerPaidTotal = info => getRoomPrice(info) + info?.servicePrice - info?.punishDeposit - (currentCustomer?.couponInfo?.value || 0)

  return (
    <Grid container style={{ width: '90vw' }}>
      {/* Title */}
      <Grid container item lg={12} md={12}>
        <Grid item lg={4} md={4}>
          <h6 className="mb-2 font-weight-bold text-center">Chủ nhà</h6>
        </Grid>
        <Grid item lg={4} md={4}>
          <h6 className="mb-2 font-weight-bold text-center">Khách</h6>
        </Grid>
        <Grid item lg={4} md={4}>
          <h6 className="mb-2 font-weight-bold text-center">2home</h6>
        </Grid>
      </Grid>
      {/* Subtitle */}
      {prevHost
        ? (
          <Grid container item lg={12} md={12}>
            {
              prevHost
                ? (
                  <Grid item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
                    <InputLabel className="mb-2 font-weight-bold">Đơn cũ</InputLabel>
                  </Grid>
                )
                : null
            }
            <Grid item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
              <InputLabel className="mb-2 font-weight-bold">Đơn mới</InputLabel>
            </Grid>
            {
              prevHost
                ? (
                  <Grid item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
                    <InputLabel className="mb-2 font-weight-bold">Đơn cũ</InputLabel>
                  </Grid>
                )
                : null
            }
            <Grid item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
              <InputLabel className="mb-2 font-weight-bold">Đơn mới</InputLabel>
            </Grid>
            {
              prevHost
                ? (
                  <Grid item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
                    <InputLabel className="mb-2 font-weight-bold">Đơn cũ</InputLabel>
                  </Grid>
                )
                : null
            }
            <Grid item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
              <InputLabel className="mb-2 font-weight-bold">Đơn mới</InputLabel>
            </Grid>
          </Grid>
        )
        : null}
      <div className="w-100" style={{ maxHeight: '50vh', overflowY: 'auto' }}>
        {/* Doanh thu phòng */}
        <Grid container item lg={12} md={12}>
          {/* Chủ nhà */}
          {
            prevHost
              ? (
                <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
                  <Grid item lg={8} md={8}>
                    <InputLabel>{`Doanh thu phòng (${prevHost?.commission}%):`}</InputLabel>
                  </Grid>
                  <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
                    <InputLabel>{`${formatPrice(getRoomRevenue(prevHost))}`}</InputLabel>
                  </Grid>
                </Grid>
              )
              : null
          }
          <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
            <Grid item lg={8} md={8}>
              <InputLabel>{`Doanh thu phòng (${currentHost?.commission}%):`}</InputLabel>
            </Grid>
            <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
              <InputLabel>{`${formatPrice(getRoomRevenue(currentHost))}`}</InputLabel>
            </Grid>
          </Grid>
          {/* Khách */}
          {
            prevHost
              ? (
                <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
                  <Grid item lg={8} md={8}>
                    <InputLabel>Tiền phòng (100%):</InputLabel>
                  </Grid>
                  <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
                    <InputLabel>{`${formatPrice(getRoomPrice(prevCustomer))}`}</InputLabel>
                  </Grid>
                </Grid>
              )
              : null
          }
          <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
            <Grid item lg={8} md={8}>
              <InputLabel>Tiền phòng (100%):</InputLabel>
            </Grid>
            <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
              <InputLabel>{`${formatPrice(getRoomPrice(currentCustomer))}`}</InputLabel>
            </Grid>
          </Grid>
          {/* 2home */}
          {
            prevHost
              ? (
                <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
                  <Grid item lg={8} md={8}>
                    <InputLabel>{`Doanh thu phòng (${prevSystem?.commission}%):`}</InputLabel>
                  </Grid>
                  <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
                    <InputLabel>{`${formatPrice(getRoomRevenue(prevSystem))}`}</InputLabel>
                  </Grid>
                </Grid>
              )
              : null
          }
          <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
            <Grid item lg={8} md={8}>
              <InputLabel>{`Doanh thu phòng (${currentSystem?.commission}%):`}</InputLabel>
            </Grid>
            <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
              <InputLabel>{`${formatPrice(getRoomRevenue(currentSystem))}`}</InputLabel>
            </Grid>
          </Grid>
        </Grid>
        {/* Doanh thu dịch vụ */}
        <Grid container item lg={12} md={12}>
          {/* Chủ nhà */}
          {
            prevHost
              ? (
                <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
                  &nbsp;
                </Grid>
              )
              : null
          }
          <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
            &nbsp;
          </Grid>
          {/* Khách */}
          {
            prevHost
              ? (
                <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
                  <Grid item lg={8} md={8}>
                    <InputLabel>Tiền dịch vụ (100%):</InputLabel>
                  </Grid>
                  <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
                    <InputLabel>{`${formatPrice(prevCustomer?.servicePrice)}`}</InputLabel>
                  </Grid>
                </Grid>
              )
              : null
          }
          <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
            <Grid item lg={8} md={8}>
              <InputLabel>Tiền dịch vụ (100%):</InputLabel>
            </Grid>
            <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
              <InputLabel>{`${formatPrice(currentCustomer?.servicePrice)}`}</InputLabel>
            </Grid>
          </Grid>
          {/* 2home */}
          {
            prevHost
              ? (
                <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
                  <Grid item lg={8} md={8}>
                    <InputLabel>{`Doanh thu dịch vụ (${prevSystem?.commission}%):`}</InputLabel>
                  </Grid>
                  <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
                    <InputLabel>{`${formatPrice(prevSystem?.serviceRevenue)}`}</InputLabel>
                  </Grid>
                </Grid>
              )
              : null
          }
          <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
            <Grid item lg={8} md={8}>
              <InputLabel>{`Doanh thu dịch vụ (${currentSystem?.commission}%):`}</InputLabel>
            </Grid>
            <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
              <InputLabel>{`${formatPrice(currentSystem?.serviceRevenue)}`}</InputLabel>
            </Grid>
          </Grid>
        </Grid>
        <Divider className="w-100 my-3" />
        {/* Số dư, Thanh toán thêm/hoàn tiền */}
        {prevHost
          ? (
            <Grid container item lg={12} md={12}>
              {/* Chủ nhà */}
              <Grid className="pr-4" container item lg={4} md={4}>
            &nbsp;
              </Grid>
              {/* Khách */}
              <Grid className="pr-4" container item lg={4} md={4}>
                {/* Số dư khả dụng */}
                <Grid container item lg={12} md={12}>
                  <Grid item lg={8} md={8}>
                    <InputLabel>Số dư khả dụng:</InputLabel>
                  </Grid>
                  <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
                    <InputLabel>{`${formatPrice(getRemainingPrice())}`}</InputLabel>
                  </Grid>
                </Grid>
                {/* Số tiền khách không được hoàn */}
                <Grid container item lg={12} md={12}>
                  <Grid item lg={8} md={8}>
                    <InputLabel>Số tiền khách không được hoàn lại:</InputLabel>
                  </Grid>
                  <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
                    <InputLabel>{`${formatPrice(getMissingPrice())}`}</InputLabel>
                  </Grid>
                </Grid>
                {/* Thanh toán thêm/hoàn tiền */}
                <Grid container item lg={12} md={12}>
                  <Grid item lg={8} md={8}>
                    <InputLabel>{getExtraPrice() >= 0 ? 'Thanh toán thêm' : 'Hoàn tiền'}</InputLabel>
                  </Grid>
                  <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
                    <InputLabel>{`${formatPrice(Math.abs(getExtraPrice()))}`}</InputLabel>
                  </Grid>
                </Grid>
              </Grid>
              {/* 2home */}
              <Grid className="pr-4" container item lg={4} md={4}>
                &nbsp;
              </Grid>
            </Grid>
          )
          : null
        }
        {prevHost ? <Divider className="w-100 my-3" /> : null}
        {/* Mã giảm giá */}
        <Grid container item lg={12} md={12}>
          {/* Chủ nhà */}
          <Grid className="pr-4" container item lg={4} md={4}>
            &nbsp;
          </Grid>
          {/* Khách */}
          <Grid className="pr-4" container item lg={4} md={4}>
            {['customer', 'system'].includes(currentCustomer?.couponInfo?.creator)
              ? (
                <Grid className="mb-2" container item lg={12}>
                  <Grid item lg={6} md={6}>
                    <Typography>Mã giảm giá 2home (7):</Typography>
                    <Typography variant="body1">{currentCustomer.couponInfo?.code || 'Không sử dụng'}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} className="text-right">
                    <Typography variant="body1">{`-${formatPrice(currentCustomer.couponInfo?.value || 0)}`}</Typography>
                  </Grid>
                </Grid>
              )
              : null}
          </Grid>
          {/* 2home */}
          <Grid className="pr-4" container item lg={4} md={4}>
            {['customer', 'system'].includes(currentSystem?.couponInfo?.creator)
              ? (
                <Grid container item lg={12}>
                  <Grid item lg={6} md={6}>
                    <Typography>Mã giảm giá 2home (7):</Typography>
                    <Typography variant="body1">{currentSystem.couponInfo?.code || 'Không sử dụng'}</Typography>
                  </Grid>
                  <Grid item lg={6} md={6} className="text-right">
                    <Typography variant="body1">{`-${formatPrice(currentSystem.couponInfo?.value || 0)}`}</Typography>
                  </Grid>
                </Grid>
              )
              : null}
          </Grid>
        </Grid>
        <Divider className="w-100 my-3" />
        {/* Cọc và chi hộ phát sinh */}
        <Grid container item lg={12} md={12}>
          {/* Chủ nhà */}
          <Grid className="pr-4" container item lg={4} md={4}>
            {/* Phạt cọc */}
            <Grid container lg={12} md={12}>
              <Grid item lg={8} md={8}>
                <InputLabel>Phạt cọc:</InputLabel>
              </Grid>
              <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
                <InputLabel>{`${formatPrice(prevHost?.punishDeposit)}`}</InputLabel>
              </Grid>
            </Grid>
            {/* 2home chi hộ phát sinh */}
            <Grid container lg={12} md={12}>
              <Grid item lg={8} md={8}>
                <InputLabel>2home chi hộ phát sinh:</InputLabel>
              </Grid>
              <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
                <InputLabel>{`${formatPrice(getPayOnBehaftTotal(currentHost))}`}</InputLabel>
              </Grid>
            </Grid>
            {
              currentHost?.payOnBehaft?.map(item => (
                <Grid container lg={12} md={12}>
                  <Grid item lg={8} md={8}>
                    <InputLabel style={{ fontSize: '0.8rem' }}>{`- ${item?.content}`}</InputLabel>
                  </Grid>
                  <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
                    <InputLabel style={{ fontSize: '0.8rem' }}>{`${formatPrice(item?.value)}`}</InputLabel>
                  </Grid>
                </Grid>
              ))
            }
          </Grid>
          {/* Khách */}
          <Grid className="pr-4" container item lg={4} md={4}>
            {/* Phạt cọc */}
            <Grid container lg={12} md={12}>
              <Grid item lg={8} md={8}>
                <InputLabel>Phạt cọc:</InputLabel>
              </Grid>
              <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
                <InputLabel>{`${formatPrice(prevCustomer?.punishDeposit)}`}</InputLabel>
              </Grid>
            </Grid>
          </Grid>
          {/* 2home */}
          <Grid className="pr-4" container item lg={4} md={4}>
            <Grid container lg={12} md={12}>&nbsp;</Grid>
            {/* 2home chi hộ phát sinh */}
            <Grid container lg={12} md={12}>
              <Grid item lg={8} md={8}>
                <InputLabel>2home chi hộ phát sinh:</InputLabel>
              </Grid>
              <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
                <InputLabel>{`${formatPrice(getPayOnBehaftTotal(currentSystem))}`}</InputLabel>
              </Grid>
            </Grid>
            {
              currentSystem?.payOnBehaft?.map(item => (
                <Grid container lg={12} md={12}>
                  <Grid item lg={8} md={8}>
                    <InputLabel style={{ fontSize: '0.8rem' }}>{`- ${item?.content}`}</InputLabel>
                  </Grid>
                  <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
                    <InputLabel style={{ fontSize: '0.8rem' }}>{`${formatPrice(item?.value)}`}</InputLabel>
                  </Grid>
                </Grid>
              ))
            }
          </Grid>
        </Grid>
        <Divider className="w-100 my-3" />
        {/* Doanh thu net */}
        <Grid container item lg={12} md={12}>
          {/* Chủ nhà */}
          {
            prevHost
              ? (
                <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
                  <Grid item lg={8} md={8}>
                    <InputLabel>Doanh thu net:</InputLabel>
                  </Grid>
                  <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
                    <InputLabel>{`${formatPrice(getHostRevenueNet(prevHost, prevSystem))}`}</InputLabel>
                  </Grid>
                </Grid>
              )
              : null
          }
          <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
            <Grid item lg={8} md={8}>
              <InputLabel>Doanh thu net:</InputLabel>
            </Grid>
            <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
              <InputLabel>{`${formatPrice(getHostRevenueNet(currentHost, currentSystem))}`}</InputLabel>
            </Grid>
          </Grid>
          {/* Khách */}
          {
            prevHost
              ? (
                <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
                  <Grid item lg={8} md={8}>
                    <InputLabel>Tổng tiền thanh toán:</InputLabel>
                  </Grid>
                  <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
                    <InputLabel>{`${formatPrice(getCustomerPaidTotal(prevCustomer))}`}</InputLabel>
                  </Grid>
                </Grid>
              )
              : null
          }
          <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
            <Grid item lg={8} md={8}>
              <InputLabel>Tổng tiền thanh toán:</InputLabel>
            </Grid>
            <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
              <InputLabel>{`${formatPrice(getCustomerPaidTotal(currentCustomer))}`}</InputLabel>
            </Grid>
          </Grid>
          {/* 2home */}
          {
            prevHost
              ? (
                <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
                  <Grid item lg={8} md={8}>
                    <InputLabel>Chi phí 2home thuê vào sớm setup:</InputLabel>
                  </Grid>
                  <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
                    <InputLabel>{`${formatPrice(prevSystem?.setupFee || 0)}`}</InputLabel>
                  </Grid>
                </Grid>
              )
              : null
          }
          <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
            <Grid item lg={8} md={8}>
              <InputLabel>Chi phí 2home thuê vào sớm setup:</InputLabel>
            </Grid>
            <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
              <InputLabel>{`${formatPrice(currentSystem?.setupFee || 0)}`}</InputLabel>
            </Grid>
          </Grid>
        </Grid>
        {/* Tình trạng giao dịch */}
        <Grid container item lg={12} md={12}>
          {/* Chủ nhà */}
          {
            prevHost
              ? (
                <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
                  <Grid item lg={8} md={8}>
                    <InputLabel>Tình trạng giao dịch giữa 2home và chủ nhà:</InputLabel>
                  </Grid>
                  <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
                    <InputLabel>TBD</InputLabel>
                  </Grid>
                </Grid>
              )
              : null
          }
          <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
            <Grid item lg={8} md={8}>
              <InputLabel>Tình trạng giao dịch giữa 2home và chủ nhà:</InputLabel>
            </Grid>
            <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
              <InputLabel>TBD</InputLabel>
            </Grid>
          </Grid>
          {/* Khách */}
          {
            prevHost
              ? (
                <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
                  <Grid item lg={8} md={8}>
                    <InputLabel>Tình trạng thanh toán:</InputLabel>
                  </Grid>
                  <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
                    <InputLabel>TBD</InputLabel>
                  </Grid>
                </Grid>
              )
              : null
          }
          <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
            <Grid item lg={8} md={8}>
              <InputLabel>Tình trạng thanh toán:</InputLabel>
            </Grid>
            <Grid item lg={4} md={4} style={{ textAlign: 'right' }}>
              <InputLabel>TBD</InputLabel>
            </Grid>
          </Grid>
          {/* 2home */}
          {
            prevHost
              ? (
                <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
                  &nbsp;
                </Grid>
              )
              : null
          }
          <Grid className="pr-4" container item lg={prevHost ? 2 : 4} md={prevHost ? 2 : 4}>
          &nbsp;
          </Grid>
        </Grid>
      </div>
    </Grid>
  )
}

OrderRevenue.propTypes = {
  // classes: PropTypes.shape(),
  data: PropTypes.shape(),
}

export default withStyles(styled)(OrderRevenue)
