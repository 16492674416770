import { connect } from 'react-redux'
import { ServiceList as ServicePage } from 'views'
import { getServiceData } from 'redux/selector/service'
import { find } from 'redux/action/service'

const mapDispatchToProps = dispatch => ({
  find: query => dispatch(find(query)),
})

export default connect(getServiceData, mapDispatchToProps)(ServicePage)
