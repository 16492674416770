import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  Button,
  Divider,
  withStyles,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core'
import {
  find,
  create,
  remove,
  update,
} from 'provider/amenity'
import { useSnackbar } from 'notistack'
import BackgroundBackdrop from 'components/Backdrop'
import { chain, uniq } from 'underscore'
import styles from './styled'

const AmenityForm = ({
  // action,
  classes,
  // history,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [openCreateDialog, setOpenCreateDialog] = useState(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [amenities, setAmenities] = useState([])
  const [amenityTypes, setAmenityTypes] = useState([])
  const [loading, setLoading] = useState(false)
  const [sltAmenityType, setSltAmenityType] = useState(null)
  const [sltAmenityIcon, setSltAmenityIcon] = useState(null)
  const [sltAmenityTitle, setSltAmenityTitle] = useState(null)
  const [sltAmenityId, setSltAmenityId] = useState(null)

  const fetchAmenities = async () => {
    try {
      setLoading(true)
      const resp = await find()
      setAmenities(chain(resp.data.map(item => ({ ...item, type: item.type.title, typeId: item.type.id }))).groupBy('type').map((value, key) => ({ type: key, amenities: value })).value())
      setAmenityTypes(uniq(resp.data.map(item => item.type), x => x.id))
      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchAmenities()
    // eslint-disable-next-line
  }, [])

  const onOpenCreateAmenity = () => {
    setOpenCreateDialog('create')
  }

  const onCloseCreateAmenity = () => {
    setOpenCreateDialog(null)
    setSltAmenityId(null)
    setSltAmenityTitle(null)
    setSltAmenityType(null)
    setSltAmenityIcon(null)
  }

  const handleCreateAmenity = async () => {
    setLoading(true)
    try {
      switch (openCreateDialog) {
        case 'create':
          await create({
            icon: sltAmenityIcon,
            title: sltAmenityTitle,
            type: sltAmenityType,
          })
          break
        case 'edit':
          await update({
            id: sltAmenityId,
            icon: sltAmenityIcon,
            title: sltAmenityTitle,
            type: sltAmenityType,
          })
          break
        default:
          break
      }
      await fetchAmenities()
      onCloseCreateAmenity()
      setLoading(false)
    } catch (e) {
      setLoading(false)
      enqueueSnackbar(`Máy chủ lỗi (code: ${e.response ? e.response.status : 'Unknown'}). Không thể tạo tiện ích mới.`, { variant: 'error' })
    }
  }

  const onOpenDeleteAmenity = (id) => {
    setOpenDeleteDialog(true)
    setSltAmenityId(id)
  }

  const onCloseDeleteAmenity = () => {
    setOpenDeleteDialog(false)
  }

  const handleDeleteAmenity = async () => {
    try {
      setLoading(true)
      await remove(sltAmenityId)
      onCloseDeleteAmenity()
      setLoading(false)
      fetchAmenities()
      setSltAmenityId(null)
    } catch (e) {
      setLoading(false)
    }
  }

  const openEditAmenityDialog = (item) => {
    setOpenCreateDialog('edit')
    setSltAmenityId(item.id)
    setSltAmenityTitle(item.title)
    setSltAmenityType(item.typeId)
    setSltAmenityIcon(item.icon)
  }

  return (
    <BackgroundBackdrop loading={loading}>
      {/* Edit/Create amenity dialog */}
      <Dialog
        open={openCreateDialog}
        onClose={onCloseCreateAmenity}
        PaperProps={{ style: { maxWidth: 800, overflowY: 'unset' } }}
        scroll="body"
      >
        <DialogTitle disableTypography>
          <h3>Thêm tiện ích phòng</h3>
        </DialogTitle>
        <DialogContent style={{ minWidth: 800, minHeight: 80, paddingTop: 0 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl
                className="mt-2"
                variant="outlined"
                fullWidth
                style={{ backgroundColor: 'white' }}
              >
                <InputLabel style={{ backgroundColor: 'white', padding: '0px 5px' }} id="amenity-group">
                  Nhóm tiện ích
                </InputLabel>
                <Select
                  labelId="amenity-group"
                  id="amenity-group"
                  variant="outlined"
                  fullWidth
                  value={sltAmenityType}
                  onChange={e => setSltAmenityType(e.target.value)}
                >
                  {
                    amenityTypes.map(item => <MenuItem value={item.id}>{item.title}</MenuItem>)
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="mt-2"
                label="Tên Icon"
                variant="outlined"
                fullWidth
                value={sltAmenityIcon}
                onChange={e => setSltAmenityIcon(e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                className="mt-2"
                label="Tên tiện ích"
                variant="outlined"
                fullWidth
                value={sltAmenityTitle}
                onChange={e => setSltAmenityTitle(e.target.value)}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCreateAmenity} color="primary">
          Thêm tiện ích
          </Button>
        </DialogActions>
      </Dialog>
      {/* Delete amenity dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={onCloseDeleteAmenity}
        PaperProps={{ style: { maxWidth: 800, overflowY: 'unset' } }}
        scroll="body"
      >
        <DialogTitle disableTypography>
          <h3>Xóa tiện ích phòng</h3>
        </DialogTitle>
        <DialogContent style={{ minWidth: 800, minHeight: 80 }}>
          <Grid container>Xác nhận xóa tiện ích phòng?</Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteAmenity} color="primary">
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
      {/* List of amenities */}
      <div className={classes.root}>
        <Typography variant="h3">
          Danh sách tiện ích phòng
        </Typography>
        {amenities.map(group => (
          <div className="mt-2">
            <Typography variant="h4">{group.type}</Typography>
            <div>
              {group.amenities.map(item => (
                <Chip
                  className="m-1"
                  label={item.title}
                  onClick={() => openEditAmenityDialog(item)}
                  onDelete={() => onOpenDeleteAmenity(item.id)}
                />
              ))}
            </div>
          </div>
        ))}
        <Divider style={{ margin: '20px auto', backgroundColor: '#d9d9d9' }} />
        {/* Create button */}
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={onOpenCreateAmenity}
        >
          Thêm tiện ích phòng
        </Button>
      </div>
    </BackgroundBackdrop>
  )
}

AmenityForm.propTypes = {
  classes: PropTypes.shape(),
  // action: PropTypes.string,
  // history: PropTypes.shape(),
}

export default withStyles(styles)(AmenityForm)
