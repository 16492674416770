import React, { useState } from 'react'
import {
  Avatar,
  Typography,
  IconButton,
  Tooltip,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Dialog,
} from '@material-ui/core'
import { NavLink as RouterLink } from 'react-router-dom'
import { formatPrice } from 'utils/formatter'
import PropTypes from 'prop-types'
import { Table } from 'components'
import { Edit, Delete } from '@material-ui/icons'
import { remove } from 'provider/service'
import { getFiltersFromUrl } from 'utils/url'
import Config from 'config'

const ServiceTable = ({
  classes,
  loading,
  services,
  meta,
  find,
  update,
  defaultSort,
  onSortChange,
  onPageChange,
  onRowsPerPageChange,
  location,
}) => {
  const [sltService, setSltService] = useState(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const handlePageChange = (page) => {
    onPageChange(page)
  }

  const handleRowsPerPageChange = (limit) => {
    onRowsPerPageChange(limit)
  }

  const handleSortChange = (sortBy, sortDirection) => {
    onSortChange(sortBy, sortDirection)
  }

  const onRemoveService = async (service) => {
    setOpenDeleteDialog(true)
    setSltService(service)
  }

  const removeService = async () => {
    await remove([sltService.id])
    const query = getFiltersFromUrl(location.search)
    find(query)
    setOpenDeleteDialog(false)
    setSltService(null)
  }

  return (
    <>
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false)
          setSltService(null)
        }}
      >
        <DialogTitle disableTypography>
          <h3>Xoá thông tin dịch vụ</h3>
        </DialogTitle>
        <DialogContent style={{ minWidth: 600, minHeight: 80 }}>
          <div dangerouslySetInnerHTML={{ __html: `Xác nhận xoá dịch vụ <b>${sltService ? sltService.name : ''}</b>?` }} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteDialog(false)
              setSltService(null)
            }}
            color="default"
          >
            Thoát
          </Button>
          <Button
            onClick={removeService}
            color="primary"
          >
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
      <Table
        data={services}
        columns={[
          {
            Head: 'Mã',
            Cell: (value, origin) => (
              <RouterLink
                to={`/service/${origin.code}/update`}
                className={classes.codeCell}
              >
                {value}
              </RouterLink>
            ),
            accessor: 'code',
            sortable: true,
          },
          {
            Head: 'Ảnh',
            Cell: (value) => (
              <Avatar
                variant="square"
                src={`${Config.BASE_IMG_URL}/${value}`}
                className={classes.avatar}
              />
            ),
            accessor: 'image',
          },
          {
            Head: 'Tên',
            Cell: (value, origin) => (
              <>
                <Typography variant="body1">{value}</Typography>
                <Tooltip
                  title={(
                    <div style={{ fontSize: '0.8rem' }}>
                      {origin.description}
                    </div>
                  )}
                  placement="top"
                >
                  <Typography
                    variant="body2"
                    className={classes.serviceDescription}
                  >
                    <div dangerouslySetInnerHTML={{ __html: origin?.description }} />
                  </Typography>
                </Tooltip>
              </>
            ),
            accessor: 'name',
            sortable: true,
          },
          {
            Head: 'Thành phần',
            Cell: (value, origin) => (
              <Typography variant="body1" style={{ maxWidth: 150 }}>
                {origin.components ? origin.components.map((c) => c.name).join(', ') : ''}
              </Typography>
            ),
            accessor: 'components',
          },
          {
            Head: 'Chuẩn bị',
            Cell: (value) => value ? `${value} phút` : 'Có ngay',
            accessor: 'prepareTime',
          },
          {
            Head: 'Khoá phòng',
            Cell: (value) => value ? `${value} phút` : 'Không',
            accessor: 'lockTime',
          },
          {
            Head: 'Giá',
            Cell: (value) => (
              <Typography variant="body1">
                {`${formatPrice(value)}VND`}
              </Typography>
            ),
            accessor: 'price',
            sortable: true,
          },
          {
            Head: 'Sử dụng',
            Cell: (value) => `${value} đơn`,
            accessor: 'reservations',
            sortable: true,
          },
          {
            Head: '',
            Cell: (value, origin) => {
              if (origin.trash) {
                return null
              }
              return (
                <>
                  <IconButton
                    aria-label="delete"
                    className={classes.margin}
                    onClick={() => update(value)}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    className={classes.margin}
                    onClick={() => onRemoveService(origin)}
                  >
                    <Delete />
                  </IconButton>
                </>
              )
            },
            accessor: 'id',
          },
        ]}
        loading={loading}
        page={meta.page}
        limit={meta.limit}
        total={meta.total}
        defaultSort={defaultSort}
        onSortChange={handleSortChange}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </>
  )
}

ServiceTable.propTypes = {
  classes: PropTypes.shape(),
  loading: PropTypes.bool,
  services: PropTypes.arrayOf(PropTypes.shape()),
  meta: PropTypes.shape(),
  find: PropTypes.func,
  update: PropTypes.func,
  defaultSort: PropTypes.shape(),
  onSortChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  location: PropTypes.shape(),
}

export default ServiceTable
