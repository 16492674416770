import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
  Typography,
  // Chip,
  // Tooltip,
} from '@material-ui/core'
import { NavLink as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { formatPrice, convertTime } from 'utils/formatter'
import { Table } from 'components'

const Orders = ({
  // classes,
  loading,
  data,
  setting,
  meta,
  info,
  onSortChange,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const [sortBy, setSortBy] = useState(null)
  const [sortDirection, setSortDirection] = useState(null)

  useEffect(() => {
    console.log('here')
    return () => {
    }
  }, [])

  const handlePageChange = (page) => {
    onPageChange(page)
  }

  const handleRowsPerPageChange = (limit) => {
    onRowsPerPageChange(limit)
  }

  const handleSortChange = (_sortBy, _sortDirection) => {
    onSortChange(_sortBy, _sortDirection)
    setSortBy(_sortBy)
    setSortDirection(_sortDirection)
  }

  return (
    <>
      {/* Reservation list */}
      <Table
        data={data}
        columns={[
          {
            Head: 'Mã đơn',
            Cell: (value, origin) => (
              <div
                className="d-flex align-items-center"
                style={{
                  position: 'sticky',
                  background: '#fff',
                  left: 0,
                  zIndex: 1,
                }}
              >
                <RouterLink to={`/reservation/${value}/view`} target="_blank">
                  <Typography variant="body1">{value}</Typography>
                  <Typography variant="body2">
                    {moment(origin.createdAt).format('DD/MM/YYYY HH:mm')}
                  </Typography>
                </RouterLink>
              </div>
            ),
            accessor: 'code',
            sortable: false,
            hidden: !setting['Mã đơn'],
          },
          {
            Head: (
              <div>
                <div>Đặt phòng</div>
                <div>{`(${Number((info?.orderTime / 60) / (meta?.total || 1)).toFixed(2)} giờ)`}</div>
              </div>
            ),
            Cell: value => (<Typography variant="div">{value ? convertTime(value) : 0}</Typography>),
            accessor: 'orderTime',
            sortable: true,
            hidden: !setting['Đặt phòng'],
          },
          {
            Head: (
              <div>
                <div>Doanh thu phòng</div>
                <div>{`(${formatPrice((info?.room2homeRevenue || 0) + (info?.roomHostRevenue || 0))}đ)`}</div>
              </div>
            ),
            Cell: value => (<Typography variant="div">{formatPrice(value)}</Typography>),
            accessor: 'roomTotalRevenue',
            sortable: true,
            hidden: !setting['Doanh thu phòng'],
          },
          {
            Head: (
              <div>
                <div>Phòng (2home)</div>
                <div>{`(${formatPrice(info?.room2homeRevenue || 0)}đ)`}</div>
              </div>
            ),
            Cell: value => (<Typography variant="div">{formatPrice(value || 0)}</Typography>),
            accessor: 'room2homeRevenue',
            sortable: true,
            hidden: !setting['Doanh thu phòng (2home)'],
          },
          {
            Head: (
              <div>
                <div>Phòng (Chủ nhà)</div>
                <div>{`(${formatPrice(info?.roomHostRevenue || 0)}đ)`}</div>
              </div>
            ),
            Cell: value => (<Typography variant="div">{formatPrice(value || 0)}</Typography>),
            accessor: 'roomHostRevenue',
            sortable: true,
            hidden: !setting['Doanh thu phòng (Chủ nhà)'],
          },
          {
            Head: (
              <div>
                <div>Dịch vụ</div>
                <div>{`(${formatPrice(info?.serviceRevenue || 0)}đ)`}</div>
              </div>
            ),
            Cell: value => (<Typography variant="div">{formatPrice(value || 0)}</Typography>),
            accessor: 'serviceRevenue',
            sortable: true,
            hidden: !setting['Dịch vụ'],
          },
          {
            Head: (
              <div>
                <div>Phí huỷ</div>
                <div>{`(${formatPrice(info?.rejectRevenue || 0)}đ)`}</div>
              </div>
            ),
            Cell: value => (<Typography variant="div">{formatPrice(value || 0)}</Typography>),
            accessor: 'rejectRevenue',
            sortable: true,
            hidden: !setting['Phí huỷ'],
          },
          {
            Head: (
              <div>
                <div>Phạt cọc</div>
                <div>{`(${formatPrice(info?.punishRevenue || 0)}đ)`}</div>
              </div>
            ),
            Cell: value => (<Typography variant="div">{formatPrice(value || 0)}</Typography>),
            accessor: 'punishRevenue',
            sortable: true,
            hidden: !setting['Phạt cọc'],
          },
          {
            Head: (
              <div>
                <div>2home setup</div>
                <div>{`(${Number((info?.setupTime / 60) / (meta?.total || 1)).toFixed(2)} giờ)`}</div>
              </div>
            ),
            Cell: value => (<Typography variant="div">{`${value} phút`}</Typography>),
            accessor: 'setupTime',
            sortable: true,
            hidden: !setting['2home setup'],
          },
          {
            Head: (
              <div>
                <div>Chi hộ phát sinh</div>
                <div>{`(${formatPrice(info?.payOnBehaft || 0)}đ)`}</div>
              </div>
            ),
            Cell: value => (<Typography variant="div">{formatPrice(value || 0)}</Typography>),
            accessor: 'payOnBehaft',
            sortable: true,
            hidden: !setting['Chi hộ phát sinh'],
          },
          {
            Head: (
              <div>
                <div>2home thuê phòng</div>
                <div>{`(${formatPrice(info?.setupFee || 0)}đ)`}</div>
              </div>
            ),
            Cell: value => (<Typography variant="div">{formatPrice(value || 0)}</Typography>),
            accessor: 'setupFee',
            sortable: true,
            hidden: !setting['2home thuê phòng'],
          },
          {
            Head: (
              <div>
                <div>Doanh thu net (Chủ nhà)</div>
                <div>{`(${formatPrice((info?.roomHostRevenue || 0) + (info?.setupFee || 0) - (info?.payOnBehaft || 0))}đ)`}</div>
              </div>
            ),
            Cell: value => (<Typography variant="div">{formatPrice(value)}</Typography>),
            accessor: 'roomHostNet',
            sortable: true,
            hidden: !setting['Doanh thu net (Chủ nhà)'],
          },
          {
            Head: 'Giảm giá',
            Cell: (coupon, origin) => {
              if (coupon?.creator === 'host') return 0
              switch (coupon?.useFor) {
                case 'room':
                  if (coupon.valueType === 'money') {
                    return <Typography variant="div">{formatPrice(coupon.value || 0)}</Typography>
                  }
                  return <Typography variant="div">{formatPrice((origin.room2homeRevenue + origin.roomHostRevenue) * ((coupon.value || 0) / 100))}</Typography>
                case 'service':
                  if (coupon.valueType === 'money') {
                    return <Typography variant="div">{formatPrice(coupon.value || 0)}</Typography>
                  }
                  return <Typography variant="div">{formatPrice((origin.serviceRevenue) * ((coupon.value || 0) / 100))}</Typography>
                default:
                  return 0
              }
            },
            accessor: 'coupon',
            sortable: false,
            hidden: !setting['Giảm giá'],
          },
        ]}
        loading={loading}
        page={meta?.page || 1}
        limit={meta?.limit || 10}
        total={meta?.total || 0}
        defaultSort={{ sortBy, sortDirection }}
        onSortChange={handleSortChange}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </>
  )
}

Orders.propTypes = {
  // classes: PropTypes.shape(),
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape()),
  setting: PropTypes.shape(),
  meta: PropTypes.shape(),
  info: PropTypes.shape(),
  onSortChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
}

export default Orders
