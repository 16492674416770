import * as serviceApi from 'provider/service'
import { SERVICE_ACTION } from '../type'

export const find = query => async (dispatch, getState) => {
  const { page: pageProp, limit: limitProp } = getState().getIn(['service', 'meta']).toJS()
  const data = getState().getIn(['service', 'data']).toJS()
  const pageQuery = getState().getIn(['service', 'query']).toJS()
  const convertedQuery = {
    page: pageProp,
    limit: limitProp,
    ...query,
  }
  if (!data || data.length === 0 || JSON.stringify(convertedQuery) !== JSON.stringify(pageQuery)) {
    dispatch({
      type: SERVICE_ACTION.FETCH,
    })
    try {
      const service = await serviceApi.find(query)
      dispatch({
        type: SERVICE_ACTION.FETCH_OK,
        payload: { query, ...service },
      })
    } catch (e) {
      dispatch({
        type: SERVICE_ACTION.FETCH_ERR,
        payload: { message: 'Something went wrong' },
      })
    }
  }
}
