import * as roomApi from 'provider/room'
import { ROOM_ACTION } from '../type'

export const find = query => async (dispatch, getState) => {
  const { page: pageProp, limit: limitProp } = getState().getIn(['room', 'meta']).toJS()
  const data = getState().getIn(['room', 'data']).toJS()
  const pageQuery = getState().getIn(['room', 'query']).toJS()
  const convertedQuery = {
    page: pageProp,
    limit: limitProp,
    ...query,
  }
  if (!data || data.length === 0 || JSON.stringify(convertedQuery) !== JSON.stringify(pageQuery)) {
    dispatch({
      type: ROOM_ACTION.FETCH,
    })
    try {
      const room = await roomApi.find(query)
      dispatch({
        type: ROOM_ACTION.FETCH_OK,
        payload: { query, ...room },
      })
    } catch (e) {
      dispatch({
        type: ROOM_ACTION.FETCH_ERR,
        payload: { message: 'Something went wrong' },
      })
    }
  }
}
