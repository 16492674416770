import React from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  InputLabel,
  Typography,
} from '@material-ui/core'
import Upload from 'components/Upload'
import { formatPrice } from 'utils/formatter'

const PaymentInfo = ({
  values,
  classes,
}) => {
  const {
    rejectRoomRevenue,
    rejectServiceRevenue,
    refundRoom,
    refundService,
    roomPrice,
    servicePrice,
    refundPaymentType,
    refundBankName,
    refundUser,
    refundBankAccount,
    rejectImage,
    rejectNote,
    bankNote,
    paymentType,
    status,
    couponInfo,
    depositWith,
    depositPunishAmount,
    depositAmount,
  } = values

  const renderTitle = () => {
    switch (status) {
      case 'paid':
      case 'using':
        return <Typography className={`${classes.subTitle} mb-2`}>Thông tin thanh toán:</Typography>
      case 'reject-refund':
      case 'reject-processing':
        return <Typography className={`${classes.subTitle} mb-2`}>Thông tin hoàn tiền:</Typography>
      default:
        return null
    }
  }

  const getRefundTotal = () => {
    let refundRoomTotal = refundRoom
    let refundServiceTotal = refundService
    let refundDepositTotal = 0
    const {
      valueType,
      value,
      useFor,
    } = couponInfo || {}
    switch (valueType) {
      case 'money':
        if (couponInfo.useFor === 'room') {
          refundRoomTotal -= value * (refundRoom / roomPrice)
        } else {
          refundServiceTotal -= value * (servicePrice ? refundService / servicePrice : 0)
        }
        break
      case 'percent':
        if (useFor === 'room') {
          refundRoomTotal -= refundRoomTotal * (((value) / 100) * (refundRoom / roomPrice))
        } else {
          refundServiceTotal -= refundServiceTotal * (((value) / 100) * (servicePrice ? refundService / servicePrice : 0))
        }
        break
      default:
        break
    }
    if (depositWith === '2home') {
      refundDepositTotal += depositAmount - depositPunishAmount
    }
    return (
      <Grid container lg={12} md={12} className="my-2">
        <Grid item lg={4} md={4}>
          <InputLabel style={{ fontSize: '1rem' }}>Số tiền hoàn: </InputLabel>
        </Grid>
        <Grid item lg={8} md={8} style={{ textAlign: 'right' }}>
          <InputLabel className="font-weight-bold" style={{ fontSize: '1rem' }}>
            {`${formatPrice(Math.floor((refundRoomTotal * (100 - rejectRoomRevenue) / 100) + (refundServiceTotal * (100 - rejectServiceRevenue) / 100)) + refundDepositTotal)}VND`}
          </InputLabel>
        </Grid>
      </Grid>
    )
  }

  const renderPaymentInfo = () => {
    switch (status) {
      case 'paid':
      case 'using':
        switch (paymentType) {
          case '2home':
            return <Grid container lg={12} md={12} className="my-2">Thanh toán sử dụng tài khoản 2home</Grid>
          case 'bank-transfer':
            return <Grid container lg={12} md={12} className="my-2">Thanh toán sử dụng tài khoản ngân hàng</Grid>
          default:
            return null
        }
      case 'reject-refund':
      case 'reject-processing':
        switch (refundPaymentType) {
          case '2home':
            return <Grid container lg={12} md={12} className="my-2">Hoàn tiền vào tài khoản 2home</Grid>
          case 'bank-transfer':
            return (
              <>
                <Grid container lg={12} md={12} className="my-2">
                  Hoàn tiền vào tài khoản ngân hàng
                </Grid>
                <Grid container lg={12} md={12} className="my-2">
                  <Grid item lg={4} md={4}>
                    <InputLabel>Chủ tài khoản:</InputLabel>
                  </Grid>
                  <Grid item lg={8} md={8} style={{ textAlign: 'right' }}>
                    <InputLabel className="font-weight-bold">{refundUser}</InputLabel>
                  </Grid>
                </Grid>
                <Grid container lg={12} md={12} className="my-2">
                  <Grid item lg={4} md={4}>
                    <InputLabel>Số tài khoản:</InputLabel>
                  </Grid>
                  <Grid item lg={8} md={8} style={{ textAlign: 'right' }}>
                    <InputLabel className="font-weight-bold">{refundBankAccount}</InputLabel>
                  </Grid>
                </Grid>
                <Grid container lg={12} md={12} className="my-2">
                  <Grid item lg={4} md={4}>
                    <InputLabel>Ngân hàng:</InputLabel>
                  </Grid>
                  <Grid item lg={8} md={8} style={{ textAlign: 'right' }}>
                    <InputLabel className="font-weight-bold">{refundBankName}</InputLabel>
                  </Grid>
                </Grid>
                {rejectNote
                  ? (
                    <Grid container lg={12} md={12} className="my-2">
                      <Grid item lg={4} md={4}>
                        <InputLabel>Lý do huỷ:</InputLabel>
                      </Grid>
                      <Grid item lg={8} md={8} style={{ textAlign: 'right' }}>
                        <InputLabel className="font-weight-bold">{rejectNote}</InputLabel>
                      </Grid>
                    </Grid>
                  )
                  : null}
                {bankNote
                  ? (
                    <Grid container lg={12} md={12} className="my-2">
                      <Grid item lg={4} md={4}>
                        <InputLabel>Mã bút toán:</InputLabel>
                      </Grid>
                      <Grid item lg={8} md={8} style={{ textAlign: 'right' }}>
                        <InputLabel className="font-weight-bold">{bankNote}</InputLabel>
                      </Grid>
                    </Grid>
                  ) : null}
              </>
            )
          default:
            return null
        }
      default:
        return null
    }
  }

  if (['lock', 'reject-lock'].includes(values.status)) {
    return null
  }

  return (
    <>
      {renderTitle()}
      {renderPaymentInfo()}
      {['reject-refund', 'reject-processing'].includes(status) ? getRefundTotal() : null}
      <Upload files={(rejectImage || []).map(item => ({ isLink: true, file: item }))} readOnly />
    </>
  )
}

PaymentInfo.propTypes = {
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
}

export default PaymentInfo
