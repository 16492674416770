import React from 'react'
import PropTypes from 'prop-types'
import {
  withStyles,
  Card,
  CardContent,
  Grid,
  Typography,
  Avatar,
} from '@material-ui/core'
// import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import Receipt from '@material-ui/icons/Receipt'
import { StatusBullet } from 'components'
import styles from './styled'

const Budget = ({
  classes,
  data: {
    total,
    pending,
    paid,
  },
}) => (
  <a href="/reservations" className={classes.no_link}>
    <Card style={{ minHeight: 130 }}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography className={classes.title} color="textSecondary" gutterBottom variant="body2">
            ĐƠN ĐẶT PHÒNG
            </Typography>
            <Typography variant="h3">{total}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <Receipt className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        {/* <ArrowDownwardIcon className={classes.differenceIcon} /> */}
        <div className={classes.difference}>
          {pending
            ? (
              <div className="d-flex mr-3 align-items-center">
                <Typography className={classes.differenceValue} variant="body2">
                  <StatusBullet color="warning" size="sm" />
                </Typography>
                <Typography className={classes.caption} variant="caption">
                  {pending}
                  {' '}
                đang chờ
                </Typography>
              </div>
            )
            : null}
          {paid
            ? (
              <div className="d-flex align-items-center">
                <Typography className={classes.differenceValue} variant="body2">
                  <StatusBullet color="success" size="sm" />
                </Typography>
                <Typography className={classes.caption} variant="caption">
                  {paid}
                  {' '}
                thanh toán
                </Typography>
              </div>
            )
            : null}
          {!pending && !paid
            ? (
              <Typography className={classes.caption} variant="caption">
              Không có đơn đặt phòng mới
              </Typography>
            )
            : null}
        </div>
      </CardContent>
    </Card>
  </a>
)

Budget.propTypes = {
  classes: PropTypes.shape(),
  data: PropTypes.shape(),
}

export default withStyles(styles)(Budget)
