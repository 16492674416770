function supportsLocalStorage() {
  return typeof Storage !== 'undefined'
}

export const set = (key, value) => {
  if (supportsLocalStorage()) {
    localStorage.setItem(key, JSON.stringify(value))
  }
}

export const remove = (key) => {
  if (supportsLocalStorage()) {
    localStorage.removeItem(key)
  }
}

export const get = (key) => {
  if (supportsLocalStorage()) {
    try {
      const value = JSON.parse(localStorage.getItem(key))
      return value
    } catch (e) {
      localStorage.setItem(key, null)
      return null
    }
  }
  return undefined
}

export const KEYS = {
  SEARCH_DISTRICT_NAME: 'SEARCH_DISTRICT_NAME',
  ORDER: 'ORDER',
  TOKEN: 'TOKEN',
  ORDER_ROOM: 'ORDER_ROOM',
  ORDER_ROOM_ID: 'ORDER_ROOM_ID',
  REDIRECT_URL: 'REDIRECT_URL',
}
