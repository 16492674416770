import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Typography,
  withStyles,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { getFiltersFromUrl } from 'utils/url'
import AppConfig from 'config'
import { Add, Close } from '@material-ui/icons'
import * as commonApi from 'provider/common'
import BuildingDialog from './Dialog/Building'
import areaStyle from './styled'
import DistrictDialog from './Dialog/District'

const Area = ({
  classes,
  location,
  find,
  area,
  // history,
  // remove,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [sltDistrict, setSltDistrict] = useState({})
  const [sltCity, setSltCity] = useState(null)

  useEffect(() => {
    const query = getFiltersFromUrl(location.search)
    find({
      ...query,
      active: query.active === undefined ? 0 : Number(query.active),
    })
    return () => { }
    // eslint-disable-next-line
  }, [location.search])

  const onRemoveDistrict = async (id) => {
    try {
      await commonApi.removeDistrict(id)
      find()
    } catch (e) {
      console.log(e)
      enqueueSnackbar(`${e.response.data} (code: ${e.response ? e.response.status : 'Unknown'})`, { variant: 'error' })
    }
  }

  return (
    <>
      <BuildingDialog data={sltDistrict} open={sltDistrict?._id} onClose={() => setSltDistrict({})} />
      <DistrictDialog
        open={sltCity}
        city={sltCity}
        onClose={() => setSltCity(null)}
        onConfirm={() => {
          setSltCity(null)
          find()
        }}
      />
      <div className={classes.root}>
        <Typography variant="h3">Danh sách khu vực:</Typography>
        <div className="my-2">
          {
            area?.data?.map(item => (
              <div className="mb-4" key={item._id}>
                <Typography variant="h3">{item?.name}</Typography>
                <div className="d-flex flex-wrap">
                  {
                    item.districts?.map(district => (
                      <div key={district._id} onClick={() => setSltDistrict(district)} tabIndex="-1" role="button" onKeyDown={() => { }}>
                        <div
                          style={{
                            marginRight: 16,
                            marginTop: 32,
                            width: 210,
                            height: 160,
                            border: 'solid 1px #d5d5d5',
                            borderRadius: 8,
                            position: 'relative',
                          }}
                        >
                          <img src={`${AppConfig.BASE_IMG_URL}/${district.thumbnail}`} alt="district" width="100%" height="100%" style={{ objectFit: 'cover', borderRadius: 8 }} />
                          <Close
                            style={{
                              position: 'absolute',
                              top: 5,
                              right: 5,
                              backgroundColor: 'red',
                              color: 'white',
                            }}
                            onClick={(e) => {
                              e.stopPropagation()
                              onRemoveDistrict(district._id)
                            }}
                          />
                        </div>
                        <div className="d-flex align-items-center" style={{ marginTop: 12 }}>
                          <Typography variant="h5">{district?.name}</Typography>
                        </div>
                      </div>
                    ))
                  }
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginRight: 16,
                      marginTop: 32,
                      width: 210,
                      height: 160,
                      border: 'solid 1px #d5d5d5',
                      borderRadius: 8,
                    }}
                  >
                    <Add />
                    <Button onClick={() => setSltCity(item._id)}>Thêm quận mới</Button>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </>
  )
}

Area.propTypes = {
  classes: PropTypes.shape(),
  find: PropTypes.func,
  location: PropTypes.shape(),
  area: PropTypes.arrayOf(PropTypes.shape()),
  // remove: PropTypes.func,
  // history: PropTypes.shape(),
}

export default withStyles(areaStyle)(Area)
