import * as hostApi from 'provider/host'
import { HOST_ACTION } from '../type'

export const find = query => async (dispatch, getState) => {
  const { page: pageProp, limit: limitProp } = getState().getIn(['host', 'meta']).toJS()
  const data = getState().getIn(['host', 'data']).toJS()
  const pageQuery = getState().getIn(['host', 'query']).toJS()
  const convertedQuery = {
    page: pageProp,
    limit: limitProp,
    ...query,
  }
  if (!data || data.length === 0 || JSON.stringify(convertedQuery) !== pageQuery) {
    dispatch({
      type: HOST_ACTION.FETCH,
    })
    try {
      const host = await hostApi.find(query)
      dispatch({
        type: HOST_ACTION.FETCH_OK,
        payload: { query, ...host },
      })
    } catch (e) {
      dispatch({
        type: HOST_ACTION.FETCH_ERR,
        payload: { message: 'Something went wrong' },
      })
    }
  }
}
