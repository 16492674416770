import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox,
  Grid,
  Typography,
  Divider,
} from '@material-ui/core'
import * as commonApi from 'provider/common'
import { groupBy } from 'underscore'

const Amenity = ({
  classes,
  // errors,
  // touched,
  values,
  setFieldValue,
  // handleChange,
}) => {
  const [sltAmenities, setSltAmenities] = useState([])
  const [amenities, setAmenities] = useState(null)

  const fetch = async () => {
    try {
      const resp = await commonApi.getAmenity()
      const data = resp.data.map(item => ({
        value: item.id,
        title: item.title,
        type: item.type?.title,
      }))
      const groupData = groupBy(data, 'type')
      setAmenities(groupData)
    } catch (e) {
      // TODO
      console.log(e)
    }
  }

  useEffect(() => {
    fetch()
    return () => {
    }
  }, [])

  useEffect(() => {
    if (values.amenities) {
      setSltAmenities(values.amenities)
    }
    return () => {
    }
  }, [values])

  const onItemSelected = (id) => {
    let selected = []
    if (sltAmenities.includes(id)) {
      selected = sltAmenities.filter(item => item !== id)
    } else {
      selected = [...sltAmenities, id]
    }
    setSltAmenities(selected)
    setFieldValue('amenities', selected)
  }

  const onGroupItemSelected = (ids) => {
    let selected = []
    if (ids.every(item => values.amenities.includes(item))) {
      selected = sltAmenities.filter(item => !ids.includes(item))
    } else {
      selected = [...sltAmenities, ...ids]
    }
    setSltAmenities(selected)
    setFieldValue('amenities', selected)
  }

  return (
    <>
      <Typography className={classes.subTitle}>Tiện ích phòng:</Typography>
      <Grid container item lg={8} md={8} className={`${classes.rowAlign}`}>
        {
          amenities && Object.keys(amenities).map(key => (
            <>
              <Grid container spacing={2}>
                <Grid container item lg={12} md={12} className="align-items-center">
                  <Checkbox
                    checked={amenities[key].map(item => item.value).every(item => values.amenities.includes(item))}
                    onChange={() => onGroupItemSelected(amenities[key].map(item => item.value))}
                  />
                  <div className={classes.subTitle} style={{ marginBottom: 0, fontSize: '1.1rem' }}>{`- ${key}`}</div>
                </Grid>
                {
                  amenities[key].map(item => (
                    <Grid container item lg={3} md={3} style={{ padding: 5 }}>
                      <Checkbox
                        checked={sltAmenities.includes(item.value)}
                        onChange={() => onItemSelected(item.value)}
                      />
                      <Typography variant="h6" style={{ margin: 'auto 0px' }}>
                        {item.title}
                      </Typography>
                    </Grid>
                  ))
                }
              </Grid>
              <Divider className="w-100 my-2" style={{ backgroundColor: '#d9d9d9' }} />
            </>
          ))
        }
      </Grid>
    </>
  )
}

Amenity.propTypes = {
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
  // errors: PropTypes.shape(),
  // touched: PropTypes.shape(),
  // handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
}

export default Amenity
