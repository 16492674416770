import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  withStyles,
  Select,
  MenuItem,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Icon,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import PropTypes from 'prop-types'
import Upload from 'components/Upload'
import CurrencyInput from 'components/CurrencyInput'
import { Add } from '@material-ui/icons'
import { copy } from 'utils/service'
import { formatPrice } from 'utils/formatter'
import AppConfig from 'config'
import { isNull, isUndefined } from 'underscore'
import styled from './styled'

const PunishDepositDialog = ({
  classes,
  reservation,
  open,
  hasDepositPunish,
  onClose,
  onConfirm,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [punishDepositInfo, setPunishDepositInfo] = useState({
    amount: 0,
    description: '',
    images: [], // {isLink, file}
    paymentType: '2home',
    accountName: '',
    bankAccount: '',
    bankName: '',
  })
  const [agreeWithCustomer, setAgreeWithCustomer] = useState('1')
  const [agreeWithHost, setAgreeWithHost] = useState('1')
  const [refundBankNote, setRefundBankNote] = useState(undefined)

  useEffect(() => {
    if (open) {
      setAgreeWithCustomer('1')
      setAgreeWithHost('1')
      setRefundBankNote(undefined)
      setPunishDepositInfo({
        amount: 0,
        description: '',
        images: [], // {isLink, file}
        paymentType: '2home',
        accountName: '',
        bankAccount: '',
        bankName: '',
      })
    }
    return () => {
    }
  }, [open])

  const disabledPunishDepositBtn = () => {
    switch (reservation?.depositStatus) {
      case 'hold':
        if (hasDepositPunish) {
          return isNull(punishDepositInfo.amount) || isUndefined(punishDepositInfo.amount) || !punishDepositInfo.description || !punishDepositInfo.images?.length
        }
        return false
      case 'host-complain':
        if (agreeWithHost === '2') {
          return isNull(punishDepositInfo.amount) || isUndefined(punishDepositInfo.amount) || !punishDepositInfo.description || !punishDepositInfo.images?.length
        }
        if (agreeWithHost === '1' && punishDepositInfo.paymentType === 'bank-transfer') {
          return !punishDepositInfo.accountName || !punishDepositInfo.bankAccount || !punishDepositInfo.bankName
        }
        return false
      case 'customer-complain':
        return false
      case '2home-handle':
        return isNull(punishDepositInfo.amount) || isUndefined(punishDepositInfo.amount)
      case 'processing-release':
        return !refundBankNote
      case 'waiting-release':
        if (punishDepositInfo.paymentType === 'bank-transfer') {
          return !punishDepositInfo.accountName || !punishDepositInfo.bankAccount || !punishDepositInfo.bankName
        }
        return false
      default:
        return false
    }
  }

  const getPunishDepositInfoFromHost = () => {
    const {
      depositPunishInfo,
    } = reservation
    const amount = depositPunishInfo?.find(d => d.from === 'host')?.amount || 0
    const description = depositPunishInfo?.find(d => d.from === 'host')?.description || ''
    return `Chủ nhà đã đưa ra số tiền phạt trước đó là ${formatPrice(amount)}VND với lý do "${description}"".`
  }

  const getPunishDepositInfoFromCustomer = () => {
    const {
      depositPunishInfo,
    } = reservation
    const amount = depositPunishInfo?.find(d => d.from === 'customer')?.amount || 0
    const description = depositPunishInfo?.find(d => d.from === 'customer')?.description || ''
    return `Khách hàng không đồng ý với mức phạt trên và đề xuất mức phạt mới: ${formatPrice(amount)}VND với lý do "${description}".`
  }

  const getRefundDeposit = (order) => {
    if (!order) return null
    const {
      depositAmount,
      depositPunishAmount,
    } = order
    return `Số tiền hoàn: ${formatPrice(depositAmount - depositPunishAmount)}VND`
  }

  const confirmPunishDeposit = () => {
    onConfirm(agreeWithHost, agreeWithCustomer, punishDepositInfo, refundBankNote)
  }

  const getPunishDepositTitle = () => {
    switch (reservation?.depositStatus) {
      case 'hold':
        return 'Yêu cầu phạt cọc'
      case 'host-complain':
        return 'Đề xuất phạt cọc cho khách hàng'
      case 'customer-complain':
        return 'Xác nhận phạt cọc cho chủ nhà'
      case '2home-handle':
        return 'Xử lý phạt cọc'
      case 'processing-release':
        return 'Xác nhận hoàn cọc'
      case 'waiting-release':
        return 'Cung cấp thông tin hoàn cọc'
      default:
        return null
    }
  }

  // Cân nhắc đang cho phép huỷ đơn đã hoàn thành và đang sử dụng
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          overflowY: 'unset',
          maxWidth: reservation?.depositStatus === 'hold' ? '100%' : 'auto',
        },
      }}
    >
      <DialogTitle disableTypography>
        <h4>{getPunishDepositTitle()}</h4>
      </DialogTitle>
      <DialogContent style={{ minHeight: 80, overflowY: 'auto' }}>
        {
          reservation?.depositStatus === 'hold'
            ? hasDepositPunish
              ? (
                <>
                  {/* Thông tin phạt cọc */}
                  <Grid container spacing={3}>
                    <Grid item md={4}>
                      <Typography variant="body1" className="mb-2">Số tiền phạt yêu cầu</Typography>
                      <CurrencyInput
                        size="small"
                        variant="outlined"
                        fullWidth
                        value={null}
                        onChange={v => setPunishDepositInfo({ ...punishDepositInfo, amount: v })}
                        placeholder="Nhập số tiền"
                      />
                    </Grid>
                    <Grid item md={8}>
                      <Typography variant="body1">Lý do phạt</Typography>
                      <TextField
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={3}
                        value={null}
                        onChange={e => setPunishDepositInfo({ ...punishDepositInfo, description: e.target.value })}
                        className={classes.formControl}
                      />
                    </Grid>
                  </Grid>
                  {/* Ảnh chứng minh */}
                  <Grid container spacing={3}>
                    <Grid item md={4}>
                      <Typography variant="body1">Tải lên ảnh bằng chứng</Typography>
                      <Upload
                        files={[]}
                        multiple
                        onChange={files => setPunishDepositInfo({ ...punishDepositInfo, images: [...files] })}
                        uploadComponent={(
                          <div className="w-100 border d-flex" style={{ height: 120, borderStyle: 'dashed !important' }}>
                            <Add fontSize="large" className="m-auto" />
                          </div>
                      )}
                      />
                    </Grid>
                    <Grid item md={8} className="mt-2">
                      <Typography variant="body1">Ảnh bằng chứng</Typography>
                      <Upload
                        files={punishDepositInfo.images}
                        onChange={files => setPunishDepositInfo({ ...punishDepositInfo, images: [...files] })}
                        multiple
                        readOnly
                      />
                    </Grid>
                  </Grid>
                  {/* Mô tả */}
                  <Grid container spacing={3}>
                    <Grid item md={12}>
                      <Typography variant="body1">
                        Nếu khách hàng đồng ý với số tiền bạn đưa ra hoặc không phản hồi sau 24 giờ, chung tôi sẽ thông báo và chuyển số tiền này vào tài khoản của bạn.
                      </Typography>
                      <Typography variant="body1">
                        Nếu khách hàng không đồng ý và đưa ra một số tiền đề xuất khác (trong vòng 24 giờ).
                        <ul className="ml-4">
                          <li>Bạn đồng ý với số tiền khách hàng đề xuất, số tiền này sẽ được chuyển vào tài khoản của bạn.</li>
                          <li>Bạn không đồng ý với đề xuất, 2home sẽ can thiệp giải quyết với các bên (thời hạn giải quyết 5 ngày).</li>
                        </ul>
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )
              : (
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    <Typography variant="body1">Xác nhận hoàn 100% tiền cọc cho khách hàng</Typography>
                  </Grid>
                </Grid>
              )
            : null
        }
        {
          reservation?.depositStatus === 'customer-complain'
            ? (
              <>
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    <Typography variant="body1">
                      {getPunishDepositInfoFromHost(reservation)}
                    </Typography>
                  </Grid>
                  {
                    reservation?.depositPunishInfo?.find(d => d.from === 'host')?.images?.map(image => (
                      <img src={`${AppConfig.BASE_IMG_URL}/${image}`} width={120} alt="host-prove" />
                    ))
                  }
                  <Grid item md={12}>
                    <Typography variant="body1">
                      {getPunishDepositInfoFromCustomer(reservation)}
                    </Typography>
                  </Grid>
                  {
                    reservation?.depositPunishInfo?.find(d => d.from === 'customer')?.images?.map(image => (
                      <img src={`${AppConfig.BASE_IMG_URL}/${image}`} width={120} alt="customer-prove" />
                    ))
                  }
                  <Grid item md={12}>
                    <RadioGroup name="isAgree" value={agreeWithCustomer} onChange={e => setAgreeWithCustomer(e.target.value)}>
                      <FormControlLabel value="1" control={<Radio />} label="Chủ nhà đồng ý số tiền mà khách hàng đề xuất" />
                      <FormControlLabel value="2" control={<Radio />} label="Chủ nhà không đồng ý, cần có sự can thiệp của 2home" />
                    </RadioGroup>
                  </Grid>
                </Grid>
              </>
            )
            : null
        }
        {
          reservation?.depositStatus === 'host-complain'
            ? (
              <Grid container spacing={3}>
                <Grid item md={12}>
                  <Typography variant="body1">
                    {getPunishDepositInfoFromHost(reservation)}
                  </Typography>
                </Grid>
                {
                  reservation?.depositPunishInfo?.find(d => d.from === 'host')?.images?.map(image => (
                    <img src={`${AppConfig.BASE_IMG_URL}/${image}`} width={120} alt="host-prove" />
                  ))
                }
                <Grid item md={12}>
                  <RadioGroup name="isAgree" value={agreeWithHost} onChange={e => setAgreeWithHost(e.target.value)}>
                    <FormControlLabel value="1" control={<Radio />} label="Khách đồng ý số tiền mà chủ nhà đề xuất" />
                    <FormControlLabel value="2" control={<Radio />} label="Khách không đồng ý, đề xuất số tiền phạt khác" />
                  </RadioGroup>
                </Grid>
                <Grid item md={12}>
                  {
                    agreeWithHost === '2'
                      ? (
                        <>
                          {/* Số tiền */}
                          <Typography variant="body1" className="mb-2">Số tiền mong muốn</Typography>
                          <CurrencyInput
                            size="small"
                            className="w-50"
                            variant="outlined"
                            fullWidth
                            value={null}
                            onChange={v => setPunishDepositInfo({ ...punishDepositInfo, amount: v })}
                            placeholder="Nhập số tiền"
                          />
                          {/* Lý do */}
                          <Typography variant="body1" className="mb-2">Lý do</Typography>
                          <TextField
                            label=""
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={3}
                            value={punishDepositInfo.description}
                            onChange={e => setPunishDepositInfo({ ...punishDepositInfo, description: e.target.value })}
                            className={classes.formControl}
                            placeholder="Điền lý do bạn không đồng ý với mức phạt chủ nhà đưa ra"
                          />
                          {/* Ảnh chứng minh */}
                          <Grid container spacing={3}>
                            <Grid item md={4}>
                              <Typography variant="body1">Tải lên ảnh bằng chứng</Typography>
                              <Upload
                                files={[]}
                                multiple
                                onChange={files => setPunishDepositInfo({ ...punishDepositInfo, images: [...files] })}
                                uploadComponent={(
                                  <div className="w-100 border d-flex" style={{ height: 120, borderStyle: 'dashed !important' }}>
                                    <Add fontSize="large" className="m-auto" />
                                  </div>
                            )}
                              />
                            </Grid>
                            <Grid item md={8} className="mt-2">
                              <Typography variant="body1">Ảnh bằng chứng</Typography>
                              <Upload
                                files={punishDepositInfo.images}
                                onChange={files => setPunishDepositInfo({ ...punishDepositInfo, images: [...files] })}
                                multiple
                                readOnly
                              />
                            </Grid>
                          </Grid>
                        </>
                      )
                      : (
                        <>
                          <Typography variant="body1" className="mb-2">Chọn hình thức hoàn tiền</Typography>
                          <Select
                            labelId="deposit-punish-info"
                            id="deposit-punish-info"
                            variant="outlined"
                            fullWidth
                            value={punishDepositInfo?.paymentType}
                            onChange={e => setPunishDepositInfo({ ...punishDepositInfo, paymentType: e.target.value })}
                          >
                            <MenuItem value="2home">Tài khoản 2home</MenuItem>
                            {reservation?.depositPayment === 'bank-transfer' ? <MenuItem value="bank-transfer">Chuyển khoản ngân hàng</MenuItem> : null}
                          </Select>
                          {
                            punishDepositInfo?.paymentType === '2home'
                              ? null
                              : (
                                <>
                                  <br />
                                  <br />
                                  <Typography variant="body1" className="mb-2">Nhập thông tin để 2home hoàn tiền cọc cho khách</Typography>
                                  <TextField
                                    label="Chủ tài khoản"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    value={punishDepositInfo.accountName}
                                    onChange={e => setPunishDepositInfo({ ...punishDepositInfo, accountName: e.target.value })}
                                    className={classes.formControl}
                                    placeholder=""
                                  />
                                  <TextField
                                    label="Số tài khoản"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    value={punishDepositInfo.bankAccount}
                                    onChange={e => setPunishDepositInfo({ ...punishDepositInfo, bankAccount: e.target.value })}
                                    className={classes.formControl}
                                    placeholder=""
                                  />
                                  <TextField
                                    label="Ngân hàng"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    value={punishDepositInfo.bankName}
                                    onChange={e => setPunishDepositInfo({ ...punishDepositInfo, bankName: e.target.value })}
                                    className={classes.formControl}
                                    placeholder=""
                                  />
                                </>
                              )
                          }
                        </>
                      )
                  }
                </Grid>
              </Grid>
            )
            : null
        }
        {
          reservation?.depositStatus === '2home-handle'
            ? (
              <>
                <Grid container spacing={3}>
                  <Grid item md={12}>
                    <Typography variant="body1">
                      {getPunishDepositInfoFromHost(reservation)}
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Typography variant="body1">
                      {getPunishDepositInfoFromCustomer(reservation)}
                    </Typography>
                  </Grid>
                  <Grid item md={12}>
                    <Typography variant="body1" className="mb-2">2home xác định số tiền phạt cọc</Typography>
                    <CurrencyInput
                      size="small"
                      className="w-50"
                      variant="outlined"
                      fullWidth
                      value={null}
                      onChange={v => setPunishDepositInfo({ ...punishDepositInfo, amount: v })}
                      placeholder="Nhập số tiền"
                    />
                  </Grid>
                </Grid>
              </>
          )
            : null
        }
        {
          reservation?.depositStatus === 'processing-release'
            ? (
              <>
                <div className="mb-2">
                  Xác nhận hoàn tiền cọc cho đơn đặt phòng
                  {' '}
                  <b>{reservation?.code}</b>
                </div>
                <div>{`Khách yêu cầu hoàn tiền cọc tới tài khoản ${!reservation?.refundDepositInfo ? '2home' : 'ngân hàng'}`}</div>
                {
                  reservation?.refundDepositInfo
                    ? (
                      <>
                        <div>{`Chủ tài khoản: ${reservation?.refundDepositInfo?.accountName}`}</div>
                        <div>
                          {`Số tài khoản: ${reservation?.refundDepositInfo?.bankAccount}`}
                          <Button
                            onClick={() => {
                              copy(`${reservation?.refundDepositInfo?.bankAccount}`, () => enqueueSnackbar('Copy số tài khoản khách hàng', { variant: 'success' }))
                            }}
                          >
                            <Icon
                              fontSize="small"
                              className="far fa-clone"
                              style={{ cursor: 'pointer' }}
                            />
                          </Button>
                        </div>
                        <div className="mb-2">{`Ngân hàng: ${reservation?.refundDepositInfo?.bankName}`}</div>
                        <div className="mt-2 mb-2 font-weight-bold">{getRefundDeposit(reservation)}</div>
                        <TextField
                          label="Mã bút toán"
                          variant="outlined"
                          fullWidth
                          value={refundBankNote}
                          onChange={e => setRefundBankNote(e.target.value)}
                          className={classes.formControl}
                        />
                      </>
                  )
                    : null
                }
              </>
            )
            : null
        }
        {
          reservation?.depositStatus === 'waiting-release'
            ? (
              <>
                <div className="mb-2">
                  Xác nhận hoàn tiền cọc cho đơn đặt phòng
                  {' '}
                  <b>{reservation?.code}</b>
                </div>
                <>
                  <Typography variant="body1" className="mb-2">Chọn hình thức hoàn tiền</Typography>
                  <Select
                    labelId="deposit-punish-info"
                    id="deposit-punish-info"
                    variant="outlined"
                    fullWidth
                    value={punishDepositInfo?.paymentType}
                    onChange={e => setPunishDepositInfo({ ...punishDepositInfo, paymentType: e.target.value })}
                  >
                    <MenuItem value="2home">Tài khoản 2home</MenuItem>
                    {reservation?.depositPayment === 'bank-transfer' ? <MenuItem value="bank-transfer">Chuyển khoản ngân hàng</MenuItem> : null}
                  </Select>
                  {
                    punishDepositInfo?.paymentType === '2home'
                      ? null
                      : (
                        <>
                          <br />
                          <br />
                          <Typography variant="body1" className="mb-2">Nhập thông tin để 2home hoàn tiền cọc cho khách</Typography>
                          <TextField
                            label="Chủ tài khoản"
                            variant="outlined"
                            fullWidth
                            multiline
                            value={punishDepositInfo.accountName}
                            onChange={e => setPunishDepositInfo({ ...punishDepositInfo, accountName: e.target.value })}
                            className={classes.formControl}
                            placeholder=""
                          />
                          <TextField
                            label="Số tài khoản"
                            variant="outlined"
                            fullWidth
                            multiline
                            value={punishDepositInfo.bankAccount}
                            onChange={e => setPunishDepositInfo({ ...punishDepositInfo, bankAccount: e.target.value })}
                            className={classes.formControl}
                            placeholder=""
                          />
                          <TextField
                            label="Ngân hàng"
                            variant="outlined"
                            fullWidth
                            multiline
                            value={punishDepositInfo.bankName}
                            onChange={e => setPunishDepositInfo({ ...punishDepositInfo, bankName: e.target.value })}
                            className={classes.formControl}
                            placeholder=""
                          />
                        </>
                      )
                  }
                </>
              </>
            )
            : null
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={confirmPunishDeposit} color="primary" disabled={disabledPunishDepositBtn(reservation)}>
          Xác nhận
        </Button>
      </DialogActions>
    </Dialog>
  )
}

PunishDepositDialog.propTypes = {
  classes: PropTypes.shape(),
  reservation: PropTypes.shape(),
  open: PropTypes.bool,
  hasDepositPunish: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default withStyles(styled)(PunishDepositDialog)
