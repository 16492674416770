/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef, useState } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  List,
  ListItem,
  Button,
  colors,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1),
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main,
    },
  },
}))

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
))

const SidebarNav = (props) => {
  const { pages, className, ...rest } = props
  const classes = useStyles()
  const [showSub] = useState(true)

  const onItemClick = (page) => {
    if (page.onClick && typeof (page.onClick) === 'function') {
      page.onClick()
    }
  }

  return (
    <List
      {...rest}
      className={clsx(classes.root, className)}
    >
      {pages.map((page) => {
        if (page.isVisible) {
          return (
            <>
              <ListItem
                key={page.title}
                className={classes.item}
                disableGutters
              >
                <Button
                  activeClassName={classes.active}
                  className={classes.button}
                  component={CustomRouterLink}
                  to={page.href}
                  onClick={() => {
                    // setShowSub(!showSub)
                    onItemClick(page)
                  }}
                >
                  <div className={classes.icon}>{page.icon}</div>
                  {page.title}
                </Button>
              </ListItem>
              {
                showSub && page.subs && page.subs.length
                  ? (
                    page.subs.map(sub => (
                      <Button
                        activeClassName={classes.active}
                        className={`ml-4 ${classes.button}`}
                        component={CustomRouterLink}
                        to={sub.href}
                        onClick={() => onItemClick(sub)}
                      >
                        <div className={classes.icon}>{sub.icon}</div>
                        {sub.title}
                      </Button>
                    ))
                  )
                  : null
              }
            </>
          )
        }
        return null
      })}
    </List>
  )
}

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.arrayOf(PropTypes.shape()),
}

export default SidebarNav
