import moment from 'moment'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  TextField,
  Typography,
  InputLabel,
  Checkbox,
  Select,
  FormControl,
  MenuItem,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Chip,
} from '@material-ui/core'
import { Field } from 'formik'
import DatePicker from 'components/DatePicker2'
import CheckinIcon from 'assets/icons/icon-date-in.png'
import CheckoutIcon from 'assets/icons/icon-date-out.png'
import Config, {
  DATETIME_FORMAT,
  TIME_FORMAT,
  DATE_FORMAT,
  REFUND_100_2HOME,
} from 'config'
import { formatPrice } from 'utils/formatter'
import Upload from 'components/Upload'
import { isArray, last } from 'underscore'
import { download } from 'utils/download'
import { diffTime } from 'utils/service'


const PURPOSE = [{
  value: 'Tổ chức tiệc',
  label: 'Tổ chức tiệc',
}, {
  value: 'Nghỉ ngơi',
  label: 'Nghỉ ngơi',
}, {
  value: 'Công tác',
  label: 'Công tác',
}, {
  value: 'Chụp ảnh/ quay phim',
  label: 'Chụp ảnh/ quay phim',
}, {
  value: 'Du lịch',
  label: 'Du lịch',
}, {
  value: 'Khác',
  label: 'Khác',
}]


const BookingInfo = ({
  editable,
  classes,
  values,
  touched,
  errors,
  handleChange,
  setFieldValue,
  onRoomPriceRecalculate,
  sltRoom,
  services,
  allServices,
  order,
}) => {
  const [prepareTime, setPrepareTime] = useState(0)

  useEffect(() => {
    if (editable && values.room && values.checkin && values.checkout && values.guest) {
      onRoomPriceRecalculate(values.room, values.checkin, values.checkout, values.guest, values.limitRoom)
    }
    return () => {
    }
  }, [editable, values.checkin, values.checkout, values.guest, values.limitRoom])

  useEffect(() => {
    if (values.services?.length) {
      const sltServices = allServices?.filter(s => values.services.includes(s.id))
      let time = sltServices?.map(s => s.prepareTime).reduce((a, b) => a + b, 0)
      if (time > 3 * 60) {
        time = 3 * 60
      }
      setPrepareTime(time)
    }
    return () => {
    }
  }, [values.services, allServices])

  useEffect(() => {
    if (!sltRoom) {
      return () => {

      }
    }
    if (values.depositWith === '2home') {
      setFieldValue('depositAmount', sltRoom.depositInfo?.amount || 0)
    } else {
      setFieldValue('depositAmount', 0)
    }
    return () => {

    }
  }, [values.depositWith])

  const getCheckout = () => {
    let time = values.checkout
    const lastPaidCheckout = last(values?.addCheckoutPoint?.filter(item => item.status !== 'reject'))
    if (lastPaidCheckout) {
      time = lastPaidCheckout.checkout
    }
    return time
  }

  const onDownloadServiceImage = (files, serviceName) => {
    if (!files || !files.length) return
    const urls = files.map(f => f.file)
    if (!urls || !urls.length) return
    download(urls.map(url => `${Config.BASE_IMG_URL}/${url}`), `${values.code}_${moment().format('DDMMYY')}_${serviceName}`)
  }

  const getAddCheckoutPointStatus = (status) => {
    switch (status) {
      case 'processing':
        return 'Chờ xác nhận'
      case 'confirmed':
        return 'Chờ thanh toán'
      case 'paid':
        return 'Đã thanh toán'
      case 'reject':
        return 'Đã huỷ'
      default:
        return null
    }
  }

  const disableSelectTimeInUpdate = (currentOrder) => {
    // console.log(moment(currentOrder.checkin).format('DD/MM HH:mm'))
    const {
      roomPolicy,
      roomPolicyDay,
      checkin: currentCheckin,
    } = currentOrder || { roomPolicy: '2home', roomPolicyDay: 0 }
    if (!currentCheckin) return false
    const diffToNow = diffTime(moment().valueOf(), currentCheckin, 'minute')
    //! Chính sách huỷ 2home
    if (!roomPolicy || roomPolicy === '2home') {
      if (diffToNow < REFUND_100_2HOME * 60) {
        return true
      }
      return false
    }
    //! Chính sách huỷ chủ nhà
    const policyHours = roomPolicyDay * 24
    // 1. Không hoàn tiền phòng trong mọi trường hợp nếu roomPolicyDay = -1
    // 2. Luôn hoàn tiền nếu roomPolicyDay = 0
    // 3. Hoàn tiền theo policy là số ngày roomPolicyDay mà host đã cài đặt
    if (diffToNow < policyHours * 60) {
      return true
    }
    return false
  }

  const getDisabledCheckinTime = () => {
    const {
      reservations: orders,
    } = sltRoom || {
      reservations: [],
    }
    let disableTimeInUpdate = []
    if (order?.code && order?.status === 'paid') {
      if (disableSelectTimeInUpdate(order)) {
        disableTimeInUpdate = [{ start: order.checkin, end: order.checkout }]
      }
    }
    return [...(orders || []).filter(o => o.id !== order?.id)?.map(r => ({ start: r.checkin, end: r.checkout })), ...disableTimeInUpdate]
  }

  const getDisabledCheckoutTime = () => {
    const {
      reservations: orders,
    } = sltRoom || {
      reservations: [],
    }
    let disableTimeInUpdate = []
    if (order?.code && order?.status === 'paid') {
      if (disableSelectTimeInUpdate(order)) {
        disableTimeInUpdate = [{ start: order.checkin, end: order.checkout }]
      }
    }
    // TODO: Tính toán thời gian dọn phòng (đơn > 5 tiếng thì dọn 1 tiếng, < 5 tiếng thì dọn 30ph)
    return [...((orders || []).filter(o => o.id !== order?.id)?.map(r => ({ start: r.checkin, end: r.checkout }))), ...disableTimeInUpdate]
  }

  return (
    <>
      <Typography className={classes.subTitle}>
        {['lock', 'reject-lock'].includes(values.status) ? 'Thời gian khoá phòng:' : 'Thông tin đặt phòng:'}
      </Typography>
      {/* Checkin/Checkout */}
      <Grid container spacing={3}>
        {/* Checkin - Checkout */}
        {editable
          ? (
            <>
              <Grid item lg={6} md={6} style={{ paddingRight: 10 }}>
                <InputLabel className={classes.itemTitle}>{['lock', 'reject-lock'].includes(values.status) ? 'Bắt đầu khoá:' : 'Nhận phòng:'}</InputLabel>
                <DatePicker
                  // backward
                  type="datetime"
                  fullWidth
                  name="checkin"
                  icon={<img src={CheckinIcon} style={{ width: 20, marginRight: 10 }} alt="checkin-icon" />}
                  label={['lock', 'reject-lock'].includes(values.status) ? 'Bắt đầu khoá:' : 'Nhận phòng'}
                  value={values.checkin}
                  onSelect={value => setFieldValue('checkin', value)}
                  size="small"
                  picked={getDisabledCheckinTime()}
                  disabled={['completed', 'using'].includes(values.status)}
                />
                {errors.checkin ? <small style={{ color: 'red' }}>{errors.checkin}</small> : null}
              </Grid>
              <Grid item lg={6} md={6} style={{ paddingLeft: 10 }}>
                <InputLabel className={classes.itemTitle}>{['lock', 'reject-lock'].includes(values.status) ? 'Kết thúc khoá:' : 'Trả phòng:'}</InputLabel>
                <DatePicker
                  type="datetime"
                  fullWidth
                  name="checkout"
                  icon={<img src={CheckoutIcon} style={{ width: 20, marginRight: 10 }} alt="checkout-icon" />}
                  label={['lock', 'reject-lock'].includes(values.status) ? 'Kết thúc khoá:' : 'Trả phòng'}
                  value={values.checkout}
                  onSelect={value => setFieldValue('checkout', value)}
                  size="small"
                  min={values.checkin}
                  picked={getDisabledCheckoutTime()}
                  disabled={['completed', 'using'].includes(values.status)}
                />
                {errors.checkout ? <small style={{ color: 'red' }}>{errors.checkout}</small> : null}
              </Grid>
            </>
          )
          : (
            <Grid item lg={12} md={12}>
              <InputLabel className={classes.itemTitle}>
                {['lock', 'reject-lock'].includes(values.status) ? 'Bắt đầu khoá - Kết thúc khoá:' : 'Nhận phòng - Trả phòng'}
              </InputLabel>
              <Grid container>
                {
                  values?.prevState
                    ? (
                      <>
                        <Grid item lg={5} md={5} className="d-flex align-items-center">
                          <div>
                            <Typography variant="body1" style={{ fontWeight: 500 }}>
                              {moment(values?.prevState?.checkin).format(TIME_FORMAT)}
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 500 }}>
                              {moment(values?.prevState?.checkin).format(DATE_FORMAT)}
                            </Typography>
                          </div>
                          <span className="mx-4">&#8208;</span>
                          <div>
                            <Typography variant="body1" style={{ fontWeight: 500 }}>
                              {moment(values?.prevState?.checkout).format(TIME_FORMAT)}
                            </Typography>
                            <Typography variant="body1" style={{ fontWeight: 500 }}>
                              {moment(values?.prevState?.checkout).format(DATE_FORMAT)}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid item lg={1} md={1}>&rarr;</Grid>
                      </>
                    )
                    : null
                }
                <Grid item lg={6} md={6} className="d-flex align-items-center">
                  <div>
                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                      {moment(values?.checkin).format(TIME_FORMAT)}
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                      {moment(values?.checkin).format(DATE_FORMAT)}
                    </Typography>
                  </div>
                  <span style={{ margin: '0px 30px' }}>&#8208;</span>
                  <div>
                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                      {moment(getCheckout()).format(TIME_FORMAT)}
                    </Typography>
                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                      {moment(getCheckout()).format(DATE_FORMAT)}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
      </Grid>
      {/* Coupon/Guest/Setup time/Checkout point */}
      {
        ['lock', 'reject-lock'].includes(values.status)
          ? null
          : (
            <>
              {/* Coupon and Guest */}
              <Grid container spacing={3}>
                {/* Guest */}
                {editable
                  ? (
                    <Grid item lg={6} md={6}>
                      <TextField
                        label="Số khách"
                        variant="outlined"
                        name="guest"
                        fullWidth
                        value={values.guest}
                        onChange={handleChange}
                        error={errors.guest && touched.guest}
                        helperText={errors.guest && touched.guest}
                        className={classes.formControl}
                        size="small"
                        placeholder="Nhập số khách"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disabled={['completed', 'using'].includes(values.status)}
                      />
                    </Grid>
                  )
                  : (
                    <Grid item lg={12} md={12}>
                      <InputLabel className={classes.itemTitle}>Số khách:</InputLabel>
                      <Grid container>
                        {
                          values?.prevState
                            ? (
                              <>
                                <Grid item lg={5} md={5}>
                                  <Typography variant="body1" style={{ fontWeight: 500 }}>
                                    {`${values?.prevState?.guest} khách`}
                                  </Typography>
                                </Grid>
                                <Grid item lg={1} md={1}>&rarr;</Grid>
                              </>
                            )
                            : null
                        }
                        <Grid item lg={6} md={6}>
                          <Typography variant="body1" style={{ fontWeight: 500 }}>
                            {`${values.guest} khách`}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                {/* Coupon */}
                {false && (
                <Grid item lg={6} md={6}>
                  <TextField
                    label="Mã khuyến mại"
                    variant="outlined"
                    name="coupon"
                    fullWidth
                    value={values.coupon}
                    onChange={handleChange}
                    error={errors.coupon && touched.coupon}
                    helperText={errors.coupon && touched.coupon && errors.coupon}
                    disabled={values.id}
                    className={classes.formControl}
                    size="small"
                    placeholder="Nhập mã khuyến mãi"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                )}
              </Grid>
              {/* Thời gian setup */}
              {values.services?.length
                ? (
                  <Grid container spacing={3}>
                    {/* Thời điểm sử dụng dịch vụ */}
                    {editable
                      ? (
                        <Grid item lg={6} md={6} style={{ paddingRight: 10 }}>
                          <InputLabel className={classes.itemTitle}>Thời điểm sử dụng dịch vụ:</InputLabel>
                          <DatePicker
                            type="datetime"
                            fullWidth
                            name="useServiceAt"
                            onSelect={(value) => {
                              const useServiceAt = value < values.checkin ? values.checkin : value
                              setFieldValue('useServiceAt', useServiceAt)
                              setFieldValue('roomCheckin', useServiceAt - prepareTime * 60 * 1000)
                            }}
                            icon={<img src={CheckinIcon} style={{ width: 20, marginRight: 10 }} alt="checkin-icon" />}
                            label="Thời điểm sử dụng dịch vụ"
                            value={values.useServiceAt || values.checkin}
                            size="small"
                            min={moment(values.checkin).valueOf()}
                            max={moment(values.checkout).valueOf()}
                            disabled={['completed', 'using'].includes(values.status)}
                          />
                          {errors.useServiceAt ? <small style={{ color: 'red' }}>{errors.useServiceAt}</small> : null}
                        </Grid>
                      )
                      : (
                        <Grid item lg={12} md={12}>
                          <InputLabel className={classes.itemTitle}>Thời điểm sử dụng dịch vụ:</InputLabel>
                          <Grid container>
                            {
                              values?.prevState
                                ? (
                                  <>
                                    <Grid item lg={5} md={5}>
                                      <Typography variant="body1" style={{ fontWeight: 500 }}>
                                        {moment(values?.prevState?.useServiceAt).format(DATETIME_FORMAT)}
                                      </Typography>
                                    </Grid>
                                    <Grid item lg={1} md={1}>&rarr;</Grid>
                                  </>
                                )
                                : null
                            }
                            <Grid item lg={6} md={6}>
                              <Typography variant="body1" style={{ fontWeight: 500 }}>
                                {moment(values.useServiceAt || values.checkin).format(DATETIME_FORMAT)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    {/* 2home nhận phòng */}
                    {editable
                      ? values.useServiceAt
                        ? (
                          <Grid item lg={6} md={6} style={{ paddingRight: 10 }}>
                            <InputLabel className={classes.itemTitle}>2home nhận phòng:</InputLabel>
                            <DatePicker
                              type="datetime"
                              fullWidth
                              name="roomCheckin"
                              icon={<img src={CheckinIcon} style={{ width: 20, marginRight: 10 }} alt="checkin-icon" />}
                              label="2home nhận phòng"
                              value={values.roomCheckin}
                              onSelect={value => setFieldValue('roomCheckin', value)}
                              size="small"
                              max={moment(values.useServiceAt).valueOf()}
                              backward
                              disabled={['completed', 'using'].includes(values.status)}
                            />
                            {errors.roomCheckin ? <small style={{ color: 'red' }}>{errors.roomCheckin}</small> : null}
                          </Grid>
                        )
                        : null
                      : (
                        <Grid item lg={12} md={12}>
                          <InputLabel className={classes.itemTitle}>2home nhận phòng:</InputLabel>
                          <Grid container>
                            {
                              values?.prevState
                                ? (
                                  <>
                                    <Grid item lg={5} md={5}>
                                      <Typography variant="body1" style={{ fontWeight: 500 }}>
                                        {moment(values?.prevState?.roomCheckin).format(DATETIME_FORMAT)}
                                      </Typography>
                                    </Grid>
                                    <Grid item lg={1} md={1}>&rarr;</Grid>
                                  </>
                                )
                                : null
                            }
                            <Grid item lg={6} md={6}>
                              <Typography variant="body1" style={{ fontWeight: 500 }}>
                                {moment(values.roomCheckin).format(DATETIME_FORMAT)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                  </Grid>
                )
                : null}
              <br />
              {/* Thuê thêm giờ */}
              {values.addCheckoutPoint?.filter(item => item.status !== 'reject')?.length
                ? (
                  <>
                    <Typography className={classes.subTitle}>Giờ trả phòng đầu tiên:</Typography>
                    <Grid container spacing={3}>
                      <Grid item lg={6} md={6}>
                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                          {`- ${moment(values.checkout).format(DATETIME_FORMAT)}: ${formatPrice(values.roomPrice + values.guestPrice)} VND`}
                        </Typography>
                      </Grid>
                    </Grid>
                    <br />
                    <Typography className={classes.subTitle}>Đặt ở thêm:</Typography>
                    {
                      values.addCheckoutPoint?.filter(item => item.status !== 'reject')?.map(item => (
                        <Grid container spacing={3}>
                          <Grid item lg={6} md={6}>
                            <Typography variant="body1" style={{ fontWeight: 500 }}>
                              {`- ${moment(item.checkout).format(DATETIME_FORMAT)}: ${formatPrice(item.value)} VND`}
                            </Typography>
                          </Grid>
                          <Grid item lg={2} md={2}>
                            <Chip
                              label={getAddCheckoutPointStatus(item.status)}
                              style={{ backgroundColor: item.status === 'paid' ? '#2979ff' : '#ffc107', color: 'white' }}
                              size="small"
                            />
                          </Grid>
                        </Grid>
                      ))
                    }
                    <br />
                  </>
                )
                : null}
            </>
          )
      }
      {/* 1 phòng ngủ */}
      {editable
        ? sltRoom?.limitRoom
          ? (
            <Grid item lg={12} md={12} className="d-flex">
              <Checkbox
                checked={values.limitRoom}
                onChange={e => setFieldValue('limitRoom', e.target.checked)}
                disabled={['completed', 'using'].includes(values.status)}
              />
              <Typography variant="h6" style={{ margin: 'auto 0px' }}>
                <span>Chỉ thuê 1 phòng ngủ</span>
              </Typography>
            </Grid>
          )
          : null
        : values.limitRoom
          ? (
            <Grid container item lg={12} md={12}>
              <Grid container>
                {
                  values?.prevState
                    ? (
                      <>
                        <Grid item lg={5} md={5}>
                          <Typography variant="body1" style={{ fontWeight: 500 }}>
                            {values?.prevState?.limitRoom ? 'Chỉ thuê 1 phòng ngủ' : 'Thuê toàn bộ'}
                          </Typography>
                        </Grid>
                        <Grid item lg={1} md={1}>&rarr;</Grid>
                      </>
                    )
                    : null
                }
                <Grid item lg={6} md={6}>
                  <Typography variant="body1" style={{ fontWeight: 500 }}>
                    Chỉ thuê 1 phòng ngủ
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          )
          : null}
      {/* Bổ sung thông tin dịch vụ */}
      {services.some(s => s.options && s.options.length)
        ? <Typography className={`${classes.subTitle} mt-3`}>Thông tin bổ sung dịch vụ:</Typography>
        : null}
      <Grid container>
        {/* Dịch vụ cũ */}
        {
          values.prevState?.serviceInfo && values.prevState?.serviceInfo.length
            ? (
              <>
                <Grid item lg={5} md={5}>
                  {
                    values.prevState?.serviceInfo?.map((service) => {
                      if (!service.options || !service.options.length) {
                        return null
                      }
                      return (
                        <>
                          <InputLabel className="mt-2 font-weight-bold">
                            {`${service.name}`}
                          </InputLabel>
                          {service.options.map((opt) => {
                            switch (opt.name) {
                              case 'text':
                                return (
                                  <InputLabel className="mt-2" style={{ lineHeight: '1.2rem' }}>
                                    {values.prevExtraInfo[service.id] ? `${`- ${opt.title}: ${values.prevExtraInfo[service.id][opt.id]}`}` : 'Không có yêu cầu'}
                                  </InputLabel>
                                )

                              case 'option':
                                return (
                                  <InputLabel className="mt-2" style={{ lineHeight: '1.2rem' }}>
                                    {values.prevExtraInfo[service.id] ? `${`- ${opt.title}: ${values.prevExtraInfo[service.id][opt.id]?.join(', ')}`}` : 'Không có yêu cầu'}
                                  </InputLabel>
                                )

                              case 'attach':
                                return (
                                  values.prevExtraInfo[service.id]
                                    ? (
                                      <div className="mt-2">
                                        <InputLabel className="mb-2 mr-2">{`- ${opt.title}: `}</InputLabel>
                                        <Upload
                                          files={values.prevExtraInfo[service.id][opt.id] || []}
                                          multiple
                                          readOnly
                                        />
                                        {
                                          values.prevExtraInfo[service.id][opt.id] && values.prevExtraInfo[service.id][opt.id].length
                                            ? (
                                              <Button onClick={() => onDownloadServiceImage(values.prevExtraInfo[service.id][opt.id], service.name)}>Tải xuống</Button>
                                            )
                                            : null
                                        }
                                      </div>
                                    )
                                    : null
                                )

                              case 'combo':
                                return (
                                  values.prevExtraInfo[service.id]
                                    ? isArray(values.prevExtraInfo[service.id][opt.id])
                                      ? (
                                        <div className="mt-2">
                                          <InputLabel className="mb-2 mr-2">{`- ${opt.title}: `}</InputLabel>
                                          <Upload
                                            files={values.prevExtraInfo[service.id][opt.id] || []}
                                            multiple
                                            readOnly
                                          />
                                          {
                                            values.prevExtraInfo[service.id][opt.id] && values.prevExtraInfo[service.id][opt.id].length
                                              ? (
                                                <Button onClick={() => onDownloadServiceImage(values.prevExtraInfo[service.id][opt.id], service.name)}>Tải xuống</Button>
                                              )
                                              : null
                                          }
                                        </div>
                                      )
                                      : (
                                        <InputLabel className="mt-2" style={{ lineHeight: '1.2rem' }}>
                                          {values.prevExtraInfo[service.id] ? `${`- ${opt.title}: ${values.prevExtraInfo[service.id][opt.id]}`}` : 'Không có yêu cầu'}
                                        </InputLabel>
                                      )
                                    : null
                                )

                              default:
                                return null
                            }
                          })}
                        </>
                      )
                    })
                  }
                </Grid>
                <Grid item lg={1} md={1}>&rarr;</Grid>
              </>
            )
            : null
        }
        {/* Dịch vụ hiện tại */}
        <Grid item lg={values.prevState ? 6 : 12} md={values.prevState ? 6 : 12}>
          {services.map((service) => {
            if (!service.options || !service.options.length) {
              return null
            }
            return (
              <>
                <InputLabel className="mt-2 font-weight-bold">
                  {`${service.name}`}
                </InputLabel>
                {service.options.map((opt) => {
                  switch (opt.name) {
                    case 'text':
                      if (editable && !['completed', 'using'].includes(values.status)) {
                        return (
                          <div className="mt-2">
                            <InputLabel className="font-weight-bold">{`- ${opt.title}`}</InputLabel>
                            <Field name={`extraInfo[${service.id}][${opt.id}]`}>
                              {({ field }) => (
                                <TextField
                                  style={{ marginTop: 10 }}
                                  placeholder={opt.placeholder}
                                  variant="outlined"
                                  fullWidth
                                  value={field.value}
                                  onChange={e => setFieldValue(`extraInfo[${service.id}][${opt.id}]`, e.target.value)}
                                  size="small"
                                />
                              )}
                            </Field>
                          </div>
                        )
                      }
                      return (
                        <InputLabel className="mb-2" style={{ lineHeight: '1.2rem' }}>
                          {values.extraInfo[service.id] ? `${`- ${opt.title}: ${values.extraInfo[service.id][opt.id]}`}` : 'Không có yêu cầu'}
                        </InputLabel>
                      )

                    case 'option':
                      if (editable && !['completed', 'using'].includes(values.status)) {
                        return (
                          <div className="mt-2" style={{ lineHeight: '1.2rem' }}>
                            <InputLabel>{`- ${opt.title}: (Chọn ${opt.max} trong danh sách)`}</InputLabel>
                            <Field name={`extraInfo[${service.id}][${opt.id}]`}>
                              {({ field }) => opt.values.map((item) => {
                                const disabled = (field.value && !field.value.includes(item) && field.value.length === opt.max)
                                return (
                                  <FormControlLabel
                                    disabled={disabled}
                                    control={(
                                      <Checkbox
                                        checked={field.value && field.value.includes(item)}
                                        onChange={(e) => {
                                          let sltOpts = field.value || []
                                          if (e.target.checked) {
                                            sltOpts.push(item)
                                          } else {
                                            sltOpts = sltOpts.filter(o => o !== item)
                                          }
                                          setFieldValue(`extraInfo[${service.id}][${opt.id}]`, sltOpts)
                                        }}
                                        name={item}
                                        size="small"
                                        style={{ padding: 5, paddingLeft: 9 }}
                                      />
                                  )}
                                    label={item}
                                    size="small"
                                  />
                                )
                              })}
                            </Field>
                          </div>
                        )
                      }
                      return (
                        <InputLabel className="mb-2" style={{ lineHeight: '1.2rem' }}>
                          {values.extraInfo[service.id] ? `${`- ${opt.title}: ${values.extraInfo[service.id][opt.id]?.join(', ')}`}` : 'Không có yêu cầu'}
                        </InputLabel>
                      )

                    case 'attach':
                      if (editable && !['completed', 'using'].includes(values.status)) {
                        return (
                          <div className="mt-2" style={{ lineHeight: '1.2rem' }}>
                            <InputLabel>{`- ${opt.title}: (Tối đa ${opt.max} ảnh)`}</InputLabel>
                            <div className="mt-2">
                              <Upload
                                files={values.extraInfo[service.id] ? values.extraInfo[service.id][opt.id] : []}
                                multiple
                                onChange={files => setFieldValue(`extraInfo[${service.id}][${opt.id}]`, files)}
                              />
                            </div>
                          </div>
                        )
                      }
                      return (
                        values.extraInfo[service.id]
                          ? (
                            <div className="mb-2">
                              <InputLabel className="mb-2 mr-2">{`- ${opt.title}: `}</InputLabel>
                              <Upload
                                files={values.extraInfo[service.id][opt.id] || []}
                                multiple
                                readOnly
                              />
                              {
                                values.extraInfo[service.id][opt.id] && values.extraInfo[service.id][opt.id].length
                                  ? (
                                    <Button onClick={() => onDownloadServiceImage(values.extraInfo[service.id][opt.id], service.name)}>Tải xuống</Button>
                                  )
                                  : null
                              }
                            </div>
                          )
                          : null
                      )

                    case 'combo':
                      if (editable && !['completed', 'using'].includes(values.status)) {
                        return (
                          <>
                            <div className="mt-2">
                              <InputLabel className="mb-2 mr-2">{`- ${opt.title}`}</InputLabel>
                              <FormControl component="fieldset">
                                <RadioGroup
                                  onChange={(e) => {
                                    setFieldValue(`extraInfo[${service.id}][type]`, e.target.value)
                                    setFieldValue(`extraInfo[${service.id}][${opt.id}]`, e.target.value === 'attach' ? [] : '')
                                  }}
                                >
                                  {
                                    opt.options.map(o => (
                                      <FormControlLabel value={o.name} control={<Radio />} label={o.title} />
                                    ))
                                  }
                                </RadioGroup>
                              </FormControl>
                            </div>
                            <div className="w-100 mt-1 mb-3">
                              {
                                opt.options.map((subOpt) => {
                                  if (!values.extraInfo[service.id] || subOpt.name !== values.extraInfo[service.id]?.type) {
                                    return null
                                  }
                                  switch (values.extraInfo[service.id]?.type) {
                                    case 'text':
                                      return (
                                        <div className="mt-2" style={{ lineHeight: '1.2rem' }}>
                                          <InputLabel>{`- ${subOpt.title}`}</InputLabel>
                                          <Field name={`extraInfo[${service.id}][${opt.id}]`}>
                                            {({ field }) => (
                                              <TextField
                                                style={{ marginTop: 10 }}
                                                placeholder={subOpt.placeholder}
                                                variant="outlined"
                                                fullWidth
                                                value={field.value}
                                                onChange={e => setFieldValue(`extraInfo[${service.id}][${opt.id}]`, e.target.value)}
                                                size="small"
                                              />
                                            )}
                                          </Field>
                                        </div>
                                      )
                                    case 'attach':
                                      return (
                                        <div className="mt-2" style={{ lineHeight: '1.2rem' }}>
                                          <InputLabel>{`- ${subOpt.title}: (Tối đa ${subOpt.max} ảnh)`}</InputLabel>
                                          <div className="mt-2">
                                            <Upload
                                              files={values.extraInfo[service.id] ? values.extraInfo[service.id][opt.id] : []}
                                              multiple
                                              onChange={files => setFieldValue(`extraInfo[${service.id}][${opt.id}]`, files)}
                                            />
                                          </div>
                                        </div>
                                      )
                                    default:
                                      return null
                                  }
                                })
                              }
                            </div>
                          </>
                        )
                      }
                      return (
                        values.extraInfo[service.id]
                          ? isArray(values.extraInfo[service.id][opt.id])
                            ? (
                              <div className="mt-2" style={{ lineHeight: '1.2rem' }}>
                                <InputLabel className="mb-2 mr-2">{`- ${opt.title}: `}</InputLabel>
                                <Upload
                                  files={values.extraInfo[service.id][opt.id] || []}
                                  multiple
                                  readOnly
                                />
                                {
                                  values.extraInfo[service.id][opt.id] && values.extraInfo[service.id][opt.id].length
                                    ? (
                                      <Button onClick={() => onDownloadServiceImage(values.extraInfo[service.id][opt.id], service.name)}>Tải xuống</Button>
                                    )
                                    : null
                                }
                              </div>
                            )
                            : (
                              <InputLabel className="mb-2">
                                {values.extraInfo[service.id] ? `${`- ${opt.title}: ${values.extraInfo[service.id][opt.id]}`}` : 'Không có yêu cầu'}
                              </InputLabel>
                            )
                          : null
                      )

                    default:
                      return null
                  }
                })}
              </>
            )
          })}
        </Grid>
      </Grid>
      {/* Mục đích đặt phòng */}
      {
      ['lock', 'reject-lock'].includes(values.status)
        ? null
        : (
          <>
            <Typography className={`${classes.subTitle} mt-3`}>
              Mục đích đặt phòng
            </Typography>
            <Grid container spacing={3}>
              {editable
                ? (
                  <Grid item lg={6} md={6} style={{ paddingRight: 10 }}>
                    <FormControl
                      variant="outlined"
                      className={classes.formControl}
                      fullWidth
                      error={errors.services && touched.services}
                      disabled={!editable}
                      size="small"
                    >
                      <InputLabel style={{ backgroundColor: 'white', padding: '0px 5px' }} id="reservation-purpose">
                      Mục đích đặt phòng
                      </InputLabel>
                      <Select
                        labelId="reservation-purpose"
                        id="reservation-purpose-label"
                        name="purpose"
                        value={values.purpose}
                        onChange={handleChange}
                        disabled={!editable}
                      >
                        {PURPOSE.map(item => (
                          <MenuItem value={item.value}>{item.label}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )
                : (
                  <Grid item lg={6} md={6}>
                    <Typography variant="body1">
                      {values.purpose || <i>Không có thông tin</i>}
                    </Typography>
                  </Grid>
                )}
            </Grid>
          </>
        )
}
      {/* Đặt cọc */}
      {
        sltRoom?.depositInfo
          ? (
            <Grid item lg={6} md={6}>
              <Typography className={`${classes.subTitle} mt-3`}>Đặt cọc:</Typography>
              {
                editable && (!values.code || ['processing', 'confirmed'].includes(values.status))
                  ? (
                    <RadioGroup className="d-flex flex-row" aria-label="useFor" name="depositWith" value={values.depositWith} onChange={handleChange}>
                      <FormControlLabel value="none" control={<Radio />} label="Không thuộc trường hợp phải đặt cọc" />
                      <FormControlLabel value="2home" control={<Radio />} label="Đặt cọc an toàn qua 2home" />
                      <FormControlLabel value="host" control={<Radio />} label="Đặt cọc trực tiếp với chủ nhà" />
                    </RadioGroup>
                  )
                  : values.depositWith === '2home'
                    ? (
                      <div dangerouslySetInnerHTML={{
                        __html:
                          `<i style="font-size: 0.9rem">Khách hàng <b>đã có khoản đặt cọc ${formatPrice(values.depositAmount)} VND.</b> 
                          Nếu yêu cầu bổ sung tiền cọc, bạn vui lòng liên hệ với khách hàng bằng số điện thoại đã gửi trong "Hướng dẫn nhận phòng". 
                          Nên lưu lại các bằng chứng và giao dịch (nếu có)</i>`,
                      }}
                      />
                    )
                    : values.depositWith === 'host'
                      ? (
                        <div dangerouslySetInnerHTML={{
                          __html:
                          `<i style="font-size: 0.9rem">Khách hàng sẽ đặt cọc <b>${formatPrice(values.depositAmount)} VND </b> trực tiếp khi nhận phòng.</i>`,
                        }}
                        />
                      )
                      : (
                        <div dangerouslySetInnerHTML={{
                          __html:
                          '<i style="font-size: 0.9rem">Không cọc</i>',
                        }}
                        />
                      )
                }
            </Grid>
        )
          : null
      }
    </>
  )
}

BookingInfo.propTypes = {
  editable: PropTypes.bool,
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
  touched: PropTypes.shape(),
  errors: PropTypes.shape(),
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  onRoomPriceRecalculate: PropTypes.func,
  sltRoom: PropTypes.shape(),
  services: PropTypes.arrayOf(PropTypes.shape()),
  allServices: PropTypes.arrayOf(PropTypes.shape()),
  order: PropTypes.shape(),
}

export default BookingInfo
