import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { NavLink as RouterLink } from 'react-router-dom'
import {
  FormControl,
  Grid,
  TextField,
  Typography,
  FormHelperText,
  Tooltip,
  InputLabel,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import * as customerApi from 'provider/customer'
import { debounce } from 'underscore'

const CustomerInfo = ({
  editable,
  classes,
  values,
  touched,
  errors,
  handleChange,
  setFieldValue,
}) => {
  const [customers, setCustomers] = useState([])
  const [sltCustomer, setSltCustomer] = useState(null)

  // Fetch customer
  const fetchCustomer = async (searchTxt) => {
    try {
      const resp = await customerApi.find({
        searchTxt,
        status: [1], // Chỉ lấy khách hàng đang hoạt động
        limit: Number.MAX_SAFE_INTEGER,
      })
      setCustomers(
        resp.data.map(item => ({
          id: item._id || item.id,
          name: item.name,
          code: item.code,
          phone: item.phone,
          email: item.email,
        })),
      )
    } catch (e) {
      // console.log(e)
    }
  }

  const onSearchCustomer = debounce((inputText) => fetchCustomer(inputText), 800)

  useEffect(() => {
    fetchCustomer()
  }, [])

  useEffect(() => {
    if (values.customer && customers.length) {
      setSltCustomer(customers.find(h => h.id === values.customer))
    }
    return () => {
    }
  }, [values.customer, customers])

  return (
    <>
      <Typography className={classes.subTitle}>
        {['lock', 'reject-lock'].includes(values.status) ? 'Người khoá:' : 'Thông tin khách hàng:'}
      </Typography>
      {/* Customer and Receiver information */}
      {['lock', 'reject-lock'].includes(values.status)
        ? (
          <Grid container spacing={3} className="mb-3">
            <Grid item lg={6} md={6}>
              <InputLabel className={classes.itemTitle}>Tài khoản hệ thống:</InputLabel>
              <Typography variant="body1" style={{ fontWeight: 500 }}>
                <RouterLink to={`/customer/${sltCustomer?.id}/update`} target="_blank">{`${sltCustomer?.name} (${sltCustomer?.code})`}</RouterLink>
              </Typography>
            </Grid>
          </Grid>
        )
        : (
          <Grid container spacing={3}>
            <Grid item lg={6} md={6}>
              {editable
                ? (
                  <>
                    <Grid item lg={12} md={12}>
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                        fullWidth
                        error={errors.customer && touched.customer}
                        size="small"
                      >
                        <Autocomplete
                          value={sltCustomer}
                          options={customers}
                          getOptionLabel={opt => `${opt.name} (${opt.code})`}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Khách hàng"
                              variant="outlined"
                              placeholder="Chọn khách hàng"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              size="small"
                              onChange={(e) => onSearchCustomer(e.target.value)}
                            />
                          )}
                          onChange={(e, sltItem) => {
                            setSltCustomer(sltItem)
                            setFieldValue('customerName', sltItem ? sltItem.name : '')
                            setFieldValue('customerPhone', sltItem ? sltItem.phone : '')
                            setFieldValue('customerEmail', sltItem ? sltItem.email : '')
                            setFieldValue('receiverName', sltItem ? sltItem.name : '')
                            setFieldValue('receiverPhone', sltItem ? sltItem.phone : '')
                            setFieldValue('receiverEmail', sltItem ? sltItem.email : '')
                            setFieldValue('customer', sltItem ? sltItem.id : '')
                          }}
                          disabled={values.id}
                        />
                      </FormControl>
                      <FormHelperText style={{ color: 'red' }}>
                        {errors.gender && touched.gender && errors.gender}
                      </FormHelperText>
                    </Grid>
                    {
                  sltCustomer
                    ? (
                      <Grid container item lg={12} md={12}>
                        <Typography variant="body1">Người đặt phòng:</Typography>
                        <RouterLink to={`/customer/${sltCustomer.id}/update`} className={classes.customerInfo} target="_blank">
                          <Tooltip title="Đi tới chi tiết thông tin khách hàng">
                            <Typography style={{ marginLeft: 10 }} variant="body1">{`${sltCustomer.name} (${sltCustomer.code})`}</Typography>
                          </Tooltip>
                        </RouterLink>
                      </Grid>
                    )
                    : (
                      <Grid container item lg={12} md={12}>
                        <Typography variant="body1">Người đặt phòng:</Typography>
                        <Typography style={{ marginLeft: 10 }} variant="body1">Khách vãng lai</Typography>
                      </Grid>
                    )
                }
                  </>
                )
                : (
                  <Grid item lg={6} md={6}>
                    <InputLabel className={classes.itemTitle}>Khách hàng:</InputLabel>
                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                      <RouterLink to={`/customer/${sltCustomer?.id}/update`} target="_blank">{`${sltCustomer?.name} (${sltCustomer?.code})`}</RouterLink>
                    </Typography>
                  </Grid>
                )}
            </Grid>
            <Grid item lg={6} md={6}>
              {/* Người nhận phòng */}
              {editable
                ? (
                  <Grid item lg={12} md={12}>
                    <TextField
                      label="Người nhận phòng"
                      variant="outlined"
                      name="receiverName"
                      placeholder="Nhập tên người nhận phòng"
                      fullWidth
                      defaultValue={values.receiverName || values.customerName}
                      onChange={handleChange}
                      className={classes.formControl}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                    />
                  </Grid>
                )
                : (
                  <Grid item lg={6} md={6}>
                    <InputLabel className={classes.itemTitle}>Người nhận phòng:</InputLabel>
                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                      {values.receiverName || values.customerName}
                    </Typography>
                  </Grid>
                )}
              {/* Số điện thoại */}
              {editable
                ? (
                  <Grid item lg={12} md={12} className="my-3">
                    <TextField
                      label="Số điện thoại"
                      variant="outlined"
                      name="receiverPhone"
                      placeholder="Nhập SĐT người nhận phòng"
                      fullWidth
                      defaultValue={values.receiverPhone || values.customerPhone}
                      onChange={handleChange}
                      className={classes.formControl}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                    />
                  </Grid>
                )
                : (
                  <Grid item lg={6} md={6} style={{ marginTop: 15 }}>
                    <InputLabel className={classes.itemTitle}>Số điện thoại:</InputLabel>
                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                      {values.receiverPhone || values.customerPhone}
                    </Typography>
                  </Grid>
                )}
              {/* Địa chỉ email */}
              {editable
                ? (
                  <Grid item lg={12} md={12}>
                    <TextField
                      label="Địa chỉ email"
                      variant="outlined"
                      name="receiverEmail"
                      placeholder="Nhập email người nhận phòng"
                      fullWidth
                      defaultValue={values.receiverEmail || values.customerEmail}
                      onChange={handleChange}
                      className={classes.formControl}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                    />
                  </Grid>
                )
                : (
                  <Grid item lg={6} md={6} style={{ marginTop: 15 }}>
                    <InputLabel className={classes.itemTitle}>Địa chỉ email:</InputLabel>
                    <Typography variant="body1" style={{ fontWeight: 500 }}>
                      {values.receiverEmail || values.customerEmail}
                    </Typography>
                  </Grid>
                )}
            </Grid>
          </Grid>
        )}
    </>
  )
}

CustomerInfo.propTypes = {
  editable: PropTypes.bool,
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
  touched: PropTypes.shape(),
  errors: PropTypes.shape(),
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
}

export default CustomerInfo
