/* eslint-disable no-useless-catch */
import axios from 'axios'
import * as ENDPOINT from './url'

export const find = async () => {
  try {
    const resp = await axios.get(ENDPOINT.FETCH_AREA())
    return resp.data
  } catch (e) {
    throw e
  }
}
