import axios from 'axios'
import Config from 'config'
import * as ENDPOINT from './url'

// eslint-disable-next-line no-unused-vars
const uploadImageToCloudinary = async (files, folder) => {
  try {
    // Get Cloudinary signature
    const signParams = {}
    if (folder) {
      signParams.folder = folder
    }
    const signData = await axios.post(ENDPOINT.CLOUDINARY_SIGN_UPLOAD_IMAGE(), signParams)
    const { signature, timestamp } = signData.data

    // Upload image to Cloudinary server
    const request = []
    for (let i = 0; i < files.length; i += 1) {
      const data = new FormData()
      data.append('file', files[i])
      data.append('api_key', Config.CLOUDINARY_API_KEY)
      data.append('timestamp', timestamp)
      data.append('folder', folder)
      data.append('signature', signature)
      request.push(
        axios.post(Config.CLOUDINARY_API, data, {
          transformRequest: (params, headers) => {
            // eslint-disable-next-line no-param-reassign
            delete headers.common.Authorization
            return params
          },
        }),
      )
    }
    const resp = await axios.all(request)
    return (resp || []).map((item) => ({
      shortUrl: `v${item.data.version}/${item.data.public_id}.${item.data.format}`,
      fullUrl: item.data.secure_url,
    }))
  } catch (e) {
    console.log(e)
    throw e.response ? e.response.data : 'Lỗi không xác định'
  }
}

// eslint-disable-next-line no-unused-vars
const putObjectToS3 = async (folder, file) => {
  const signedUrl = await axios.get(ENDPOINT.S3_SIGN_UPLOAD_IMAGE(), {
    params: {
      fileDir: folder,
      fileName: file.name,
    },
  })
  return axios.put(signedUrl.data, file, {
    transformRequest: (params, headers) => {
      // eslint-disable-next-line no-param-reassign
      delete headers.common.Authorization
      return params
    },
  })
}

// eslint-disable-next-line no-unused-vars
const uploadImageToS3 = async (files, folder) => {
  try {
    // Upload image to Cloudinary server
    const request = []
    for (let i = 0; i < files.length; i += 1) {
      request.push(putObjectToS3(folder, files[i]))
    }
    const resp = await axios.all(request)
    return (resp || []).map((item) => ({
      shortUrl: item.config.url?.replace(`${Config.BASE_IMG_URL}/`, '')?.split('?')[0],
      fullUrl: item.config.url?.split('?')[0],
    }))
  } catch (e) {
    console.log(e.response)
    throw e.response ? e.response.data : 'Lỗi không xác định'
  }
}

const putObjectToR2 = async (folder, file) => {
  const signedUrl = await axios.get(ENDPOINT.R2_SIGN_UPLOAD_IMAGE(), {
    params: {
      fileDir: folder,
      fileName: file.name,
    },
  })
  return axios.put(signedUrl.data, file, {
    transformRequest: (params, headers) => {
      // eslint-disable-next-line no-param-reassign
      delete headers.common.Authorization
      // eslint-disable-next-line no-param-reassign
      headers['Content-Type'] = file.type
      return params
    },
  })
}

const uploadImageToR2 = async (files, folder) => {
  try {
    // Upload image to Cloudinary server
    const request = []
    for (let i = 0; i < files.length; i += 1) {
      request.push(putObjectToR2(folder, files[i]))
    }
    const resp = await Promise.all(request)
    return (resp || []).map((item) => ({
      shortUrl: item?.config?.url?.replace(`${Config.R2_UPLOAD_URL}/`, '')?.split('?')[0],
      fullUrl: item?.config?.url?.split('?')[0],
    }))
  } catch (e) {
    console.log(e)
    throw e.response ? e.response.data : 'Lỗi không xác định'
  }
}

export default {
  uploadImage: uploadImageToR2,
}
