import {
  Avatar,
  Typography,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core'
import { Table } from 'components'
import { makeStyles } from '@material-ui/styles'
import { getInitials } from 'helpers'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Delete, Edit, Restore } from '@material-ui/icons'
import { remove, restore } from 'provider/staff'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 10,
  },
  content: {
    padding: 0,
  },
  inner: {
    // minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  deleteBtn: {
    backgroundColor: 'red',
    color: 'white',
    marginLeft: 15,
  },
}))

const UsersTable = (props) => {
  const {
    active,
    users,
    meta,
    find,
    update,
    loading,
  } = props
  const classes = useStyles()
  const [sltUser, setSltUser] = useState(null)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [openRestoreDialog, setOpenRestoreDialog] = useState(false)

  const handlePageChange = (p) => {
    find({ ...meta, active, page: p })
  }

  const handleRowsPerPageChange = (l) => {
    find({ page: 1, active, limit: l })
  }

  const onRemoveUser = async (user) => {
    setOpenDeleteDialog(true)
    setSltUser(user)
  }

  const onRestoreUser = async (user) => {
    setOpenRestoreDialog(true)
    setSltUser(user)
  }

  const deleteUser = async () => {
    await remove([sltUser.id])
    find({ ...meta, active })
    setOpenDeleteDialog(false)
    setSltUser(null)
  }

  const restoreUser = async () => {
    await restore([sltUser.id])
    find({ ...meta, active })
    setOpenRestoreDialog(false)
    setSltUser(null)
  }

  return (
    <>
      {/* Suspend user */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => {
          setOpenDeleteDialog(false)
          setSltUser(null)
        }}
      >
        <DialogTitle disableTypography>
          <h3>Xoá tài khoản nhân viên</h3>
        </DialogTitle>
        <DialogContent style={{ minWidth: 600, minHeight: 80 }}>
          <div dangerouslySetInnerHTML={{ __html: `Xác nhận xoá tài khoản nhân viên <b>${sltUser ? sltUser.name : ''}</b>?` }} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDeleteDialog(false)
              setSltUser(null)
            }}
            color="default"
          >
            Thoát
          </Button>
          <Button
            onClick={deleteUser}
            color="primary"
          >
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
      {/* Restore user */}
      <Dialog
        open={openRestoreDialog}
        onClose={() => {
          setOpenRestoreDialog(false)
          setSltUser(null)
        }}
      >
        <DialogTitle disableTypography>
          <h3>Khôi phục tài khoản nhân viên</h3>
        </DialogTitle>
        <DialogContent style={{ minWidth: 600, minHeight: 80 }}>
          <div dangerouslySetInnerHTML={{ __html: `Xác nhận khôi phục tài khoản nhân viên <b>${sltUser ? sltUser.name : ''}</b>?` }} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenRestoreDialog(false)
              setSltUser(null)
            }}
            color="default"
          >
            Thoát
          </Button>
          <Button
            onClick={restoreUser}
            color="primary"
          >
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
      {/* Danh sách */}
      <Table
        data={users}
        columns={[
          {
            Head: 'Họ tên',
            Cell: (value, origin) => (
              <div className={classes.nameContainer}>
                <Avatar className={classes.avatar} src={origin.avatar}>
                  {getInitials(value)}
                </Avatar>
                <Typography variant="body1">
                  <div style={{ fontSize: '1rem' }}>{value}</div>
                  <p style={{ fontSize: '0.6rem', marginTop: 10 }}>
                    {origin?.role?.map(r => (
                      <Chip
                        size="small"
                        variant="outlined"
                        label={r.title}
                        style={{ marginRight: 5 }}
                      />
                    ))}
                  </p>
                </Typography>
              </div>
            ),
            accessor: 'name',
            sortable: false,
          },
          {
            Head: 'Email',
            Cell: value => value,
            accessor: 'email',
            sortable: false,
          },
          {
            Head: 'Địa chỉ',
            Cell: value => value,
            accessor: 'address',
            sortable: false,
          },
          {
            Head: 'Số điện thoại',
            Cell: value => value,
            accessor: 'phone',
            sortable: false,
          },
          {
            Head: 'Ngày sinh',
            Cell: value => value ? moment(value).format('DD/MM/YYYY') : '',
            accessor: 'birthday',
            sortable: false,
          },
          {
            Head: 'Ngày đăng ký',
            Cell: value => moment(value).format('DD/MM/YYYY'),
            accessor: 'createdAt',
            sortable: false,
          },
          {
            Head: '',
            Cell: (value, origin) => (
              <>
                <IconButton
                  aria-label="update"
                  className={classes.margin}
                  onClick={() => update(value)}
                >
                  <Edit />
                </IconButton>
                {
                  origin.status === 1
                    ? (
                      <IconButton
                        aria-label="delete"
                        className={classes.margin}
                        onClick={() => onRemoveUser(origin)}
                      >
                        <Delete />
                      </IconButton>
                    )
                    : (
                      <IconButton
                        aria-label="active"
                        className={classes.margin}
                        onClick={() => onRestoreUser(origin)}
                      >
                        <Restore />
                      </IconButton>
                    )
                }
              </>
            ),
            accessor: 'id',
          },
        ]}
        loading={loading}
        page={meta.page}
        limit={meta.limit}
        total={meta.total}
        // defaultSort={defaultSort}
        // onSortChange={handleSortChange}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </>
  )
}

UsersTable.propTypes = {
  active: PropTypes.number,
  users: PropTypes.arrayOf(PropTypes.shape()),
  meta: PropTypes.shape(),
  find: PropTypes.func,
  update: PropTypes.func,
  loading: PropTypes.bool,
}

export default UsersTable
