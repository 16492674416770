import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Button,
  FormHelperText,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Link,
  Divider,
  Checkbox,
  Tooltip,
} from '@material-ui/core'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import DatePicker from 'components/DatePicker'
import moment from 'moment'
import BackgroundBackdrop from 'components/Backdrop'
import CurrencyInput from 'components/CurrencyInput'
import {
  Edit,
  AccountBox,
  LockOpen,
  CreditCard,
  Gavel,
  Help,
  Add,
  Delete,
} from '@material-ui/icons'
import { formatPrice } from 'utils/formatter'
import RichEditor from 'components/RichEditor'
import DepositRoom from './DepositRoom'

const depositTooltip = (
  <>
    <div>
      Tiền cọc là tiền do chủ nhà tự thu của khách nhằm đảm bảo khách không làm hư hại,
      làm mất, vi phạm quy định trong căn hộ. Khoản tiền này sẽ do chủ nhà hoàn trả lại toàn bộ cho khách nếu không
      có thiệt hại nào từ căn hộ và khách không vi phạm quy định của căn hộ. Ngược lại, nếu khách hàng gây thiệt hại
      tài sản hoặc vi phạm quy định của căn hộ, chủ nhà có thể giữ lại 1 phần hoặc toàn bộ số tiền cọc này của khách
      hàng tuỳ vào mức độ thiệt hại của căn hộ do 2 bên thoả thuận.
    </div>
    <div>
      Khách hàng là thành viên hạng Vàng và Kim Cương, hoặc có số dư 2home từ 3 triệu trở lên sẽ không phải chuyển tiền cọc,
      nhưng vẫn phải đền bù thiệt hại cho chủ nhà nếu khách hàng gây ra.
    </div>
  </>
)

const validateSchema = action => Yup.object().shape({
  password:
    action === 'update'
      ? Yup.string()
      : Yup.string().required('* Mật khẩu không được để trống'),
  name: Yup.string().required('* Họ tên không được để trống'),
  email: Yup.string()
    .email()
    .required('* Địa chỉ email không được để trống'),
  phone: Yup.string(),
  birthday: Yup.number(),
  address: Yup.string(),
  bankAccount: Yup.string(),
  bankName: Yup.string(),
  bankBranch: Yup.string(),
  policy: Yup.string(),
  policyDay: Yup.number(),
  role: Yup.string(),
  owner: Yup.string().nullable(),
})

const HostInfo = ({
  classes,
  action,
  host,
  hosts,
  fetchHosts,
  loading,
  onSubmit,
  isEdit,
  setIsEdit,
}) => {
  const [depositInfo, setDepositInfo] = useState([])

  useEffect(() => {
    setDepositInfo(host?.depositInfo || [])
    return () => {
    }
  }, [host?.depositInfo])

  const onUpdateDepositInfo = (data, setFieldValue) => {
    setFieldValue('depositInfo', data)
  }

  const onAddDepositInfo = (setFieldValue) => {
    const clone = [...depositInfo]
    clone.push({
      amount: 0,
      description: '',
      price: '',
      rooms: [],
    })
    setDepositInfo(clone)
    setFieldValue('depositInfo', clone)
  }

  const onDeleteDepositInfo = (index, setFieldValue) => {
    const clone = [...depositInfo]
    setDepositInfo(clone.filter((obj, i) => i !== index))
    setFieldValue('depositInfo', clone)
  }

  return (
    <BackgroundBackdrop loading={loading}>
      <Typography variant="h4" className={classes.title}>
        {action === 'update' ? 'Thông tin chủ nhà' : 'Thêm mới chủ nhà'}
      </Typography>
      <Formik
        initialValues={host}
        enableReinitialize
        validationSchema={validateSchema(action)}
        onSubmit={onSubmit}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          setFieldValue,
        }) => (
          <Form className={classes.root}>
            <Typography className={classes.subTitle} style={{ marginTop: 0 }}>
              <LockOpen />
                Thông tin đăng nhập:
            </Typography>
            {/* Email and Password */}
            <Grid container spacing={2}>
              <Grid item lg={6} md={6}>
                {
                    isEdit
                      ? (
                        <TextField
                          size="small"
                          className={classes.formControl}
                          label="Email"
                          variant="outlined"
                          name="email"
                          fullWidth
                          value={values.email}
                          onChange={handleChange}
                          error={errors.email && touched.email}
                          helperText={errors.email && touched.email && errors.email}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="Nhập địa chỉ email"
                        />
                      )
                      : (
                        <>
                          <InputLabel className={classes.itemTitle}>Email:</InputLabel>
                          <Typography
                            variant="body1"
                            style={{ fontWeight: 500 }}
                          >
                            {values.email}
                          </Typography>
                        </>
                      )
                  }
              </Grid>
              <Grid item lg={6} md={6}>
                {
                    isEdit
                      ? (
                        <TextField
                          size="small"
                          className={classes.formControl}
                          label="Mật khẩu"
                          variant="outlined"
                          type="password"
                          name="password"
                          disabled={action === 'update'}
                          fullWidth
                          value={values.password || '*******'}
                          onChange={handleChange}
                          error={errors.password && touched.password}
                          helperText={errors.password && touched.password && errors.password}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="Nhập mật khẩu"
                        />
                      )
                      : null
                  }
              </Grid>
            </Grid>
            <br />
            <Divider />
            <Typography className={classes.subTitle}>
              <AccountBox />
                Thông tin cá nhân:
            </Typography>
            {/* Name and phone */}
            <Grid container spacing={2}>
              <Grid item lg={6} md={6}>
                {
                    isEdit
                      ? (
                        <TextField
                          size="small"
                          className={classes.formControl}
                          label="Họ tên"
                          variant="outlined"
                          name="name"
                          fullWidth
                          value={values.name}
                          onChange={handleChange}
                          error={errors.name && touched.name}
                          helperText={errors.name && touched.name && errors.name}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="Nhập họ tên chủ nhà"
                        />
                      )
                      : (
                        <>
                          <InputLabel className={classes.itemTitle}>Họ tên:</InputLabel>
                          <Typography variant="body1" style={{ fontWeight: 500 }}>{values.name}</Typography>
                        </>
                      )
                  }
              </Grid>
              <Grid item lg={6} md={6}>
                {
                    isEdit
                      ? (
                        <TextField
                          size="small"
                          className={classes.formControl}
                          label="Số điện thoại"
                          variant="outlined"
                          name="phone"
                          fullWidth
                          value={values.phone}
                          onChange={handleChange}
                          error={errors.phone && touched.phone}
                          helperText={errors.phone && touched.phone && errors.phone}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="Nhập số điện thoại"
                        />
                      )
                      : (
                        <>
                          <InputLabel className={classes.itemTitle}>Điện thoại:</InputLabel>
                          <Typography
                            variant="body1"
                            style={{ fontWeight: 500 }}
                          >
                            {values.phone}
                          </Typography>
                        </>
                      )
                  }
              </Grid>
            </Grid>
            <br />
            {/* Birthday and Address */}
            <Grid container spacing={2}>
              <Grid item lg={6} md={6}>
                {
                    isEdit
                      ? (
                        <DatePicker
                          size="small"
                          fullWidth
                          name="birthday"
                          label="Ngày sinh"
                          value={values.birthday}
                          onChange={value => setFieldValue('birthday', value)}
                        />
                      )
                      : (
                        <>
                          <InputLabel className={classes.itemTitle}>Ngày sinh:</InputLabel>
                          <Typography
                            variant="body1"
                            style={{ fontWeight: 500 }}
                          >
                            {values.birthday
                              ? moment(values.birthday).format('DD/MM/YYYY')
                              : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                          </Typography>
                        </>
                      )
                  }
              </Grid>
              <Grid item lg={6} md={6}>
                {
                    isEdit
                      ? (
                        <TextField
                          size="small"
                          className={classes.formControl}
                          label="Địa chỉ"
                          variant="outlined"
                          name="address"
                          fullWidth
                          value={values.address}
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          placeholder="Nhập địa chỉ"
                        />
                      )
                      : (
                        <>
                          <InputLabel className={classes.itemTitle}>Địa chỉ:</InputLabel>
                          <Typography
                            variant="body1"
                            style={{ fontWeight: 500 }}
                          >
                            {values.address}
                          </Typography>
                        </>
                      )
                  }
              </Grid>
            </Grid>
            <br />
            <Divider />
            <Typography className={classes.subTitle}>
              <CreditCard />
                Tài khoản ngân hàng:
            </Typography>
            {/* Bank information (owner, number) */}
            <Grid container spacing={2}>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <TextField
                        size="small"
                        className={classes.formControl}
                        label="Chủ tài khoản"
                        variant="outlined"
                        name="bankAccountName"
                        fullWidth
                        value={values.bankAccountName}
                        onChange={handleChange}
                        error={errors.bankAccountName && touched.bankAccountName}
                        helperText={errors.bankAccountName && touched.bankAccountName && errors.bankAccountName}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Nhập tài khoản ngân hàng"
                      />
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Chủ tài khoản:</InputLabel>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 500 }}
                        >
                          {values.bankAccountName
                            ? values.bankAccountName
                            : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                        </Typography>
                      </>
                    )
                }
              </Grid>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <TextField
                        size="small"
                        className={classes.formControl}
                        label="Số tài khoản"
                        variant="outlined"
                        name="bankAccountNumber"
                        fullWidth
                        value={values.bankAccountNumber}
                        onChange={handleChange}
                        error={errors.bankAccountNumber && touched.bankAccountNumber}
                        helperText={errors.bankAccountNumber && touched.bankAccountNumber && errors.bankAccountNumber}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Nhập số tài khoản"
                      />
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Số tài khoản:</InputLabel>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 500 }}
                        >
                          {values.bankAccountNumber
                            ? values.bankAccountNumber
                            : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                        </Typography>
                      </>
                    )
                }
              </Grid>
            </Grid>
            <br />
            {/* Bank information (bank name, bank branch) */}
            <Grid container spacing={2}>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <TextField
                        size="small"
                        className={classes.formControl}
                        label="Tên ngân hàng"
                        variant="outlined"
                        name="bankName"
                        fullWidth
                        value={values.bankName}
                        onChange={handleChange}
                        error={errors.bankName && touched.bankName}
                        helperText={errors.bankName && touched.bankName && errors.bankName}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Nhập tên ngân hàng"
                      />
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Tên ngân hàng:</InputLabel>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 500 }}
                        >
                          {values.bankName
                            ? values.bankName
                            : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                        </Typography>
                      </>
                    )
                }
              </Grid>
              <Grid item lg={6} md={6}>
                {
                  isEdit
                    ? (
                      <TextField
                        size="small"
                        className={classes.formControl}
                        label="Chi nhánh "
                        variant="outlined"
                        name="bankBranch"
                        fullWidth
                        value={values.bankBranch}
                        onChange={handleChange}
                        error={errors.bankBranch && touched.bankBranch}
                        helperText={errors.bankBranch && touched.bankBranch && errors.bankBranch}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        placeholder="Nhập tên chi nhánh"
                      />
                    )
                    : (
                      <>
                        <InputLabel className={classes.itemTitle}>Chi nhánh:</InputLabel>
                        <Typography
                          variant="body1"
                          style={{ fontWeight: 500 }}
                        >
                          {values.bankBranch
                            ? values.bankBranch
                            : (<span style={{ fontWeight: 300, fontStyle: 'italic' }}>Chưa có</span>)}
                        </Typography>
                      </>
                    )
                }
              </Grid>
            </Grid>
            <br />
            <Divider />
            <Typography className={classes.subTitle}>
              <Gavel />
                Chính sách:
            </Typography>
            {/* Policy */}
            <Grid container spacing={2}>
              {
                isEdit
                  ? (
                    <>
                      <Grid item lg={6} md={6}>
                        <FormControl component="fieldset">
                          <FormLabel component="legend" style={{ fontSize: 10 }}>
                          Chính sách huỷ
                          </FormLabel>
                          <RadioGroup
                            aria-label="policy"
                            name="policy"
                            value={values.policy}
                            onChange={handleChange}
                          >
                            <FormControlLabel
                              value="2home"
                              control={<Radio />}
                              label="Chính sách huỷ của 2home"
                            />
                            <FormControlLabel
                              value="owner"
                              control={<Radio />}
                              label="Chính sách huỷ riêng"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item lg={6} md={6}>
                        {values.policy === '2home' ? (
                          <Link style={{ fontSize: 14, cursor: 'pointer' }}>
                          Thông tin về chính sách huỷ của 2home
                          </Link>
                        ) : (
                          <>
                            <p style={{ fontSize: 14 }}>
                              Hoàn lại 100% tiền thuê căn hộ khi báo trước ít nhất
                              <input
                                style={{ width: 30, margin: 5 }}
                                name="policyDay"
                                value={values.policyDay}
                                onChange={handleChange}
                              />
                              ngày nhận phòng đã đặt. Không hoàn lại tiền nếu báo muộn hơn
                              số ngày này.
                            </p>
                            <div style={{ fontSize: 14 }}>
                              <div><b>Lưu ý:</b></div>
                              <ul>
                                <li>Điền 0 nếu LUÔN HOÀN TIỀN khi khách huỷ đơn</li>
                                <li>Điền -1 nếu KHÔNG HOÀN TIỀN khi khách huỷ đơn</li>
                              </ul>
                            </div>
                          </>
                        )}
                      </Grid>
                    </>
                  )
                  : (
                    <Grid item lg={12} md={12}>
                      <InputLabel className={classes.itemTitle}>Chính sách huỷ:</InputLabel>
                      <Typography variant="body1" style={{ fontWeight: 500 }}>
                        {values.policy === '2home'
                          ? (
                            <Link style={{ cursor: 'pointer' }}>Chính sách huỷ của 2home</Link>
                          )
                          : values.policyDay > 0
                            ? `Hoàn lại 100% tiền thuê căn hộ khi báo trước ít nhất ${values.policyDay} ngày nhận phòng đã đặt. Không hoàn lại tiền nếu báo muộn hơn số ngày này.`
                            : values.policyDay === 0
                              ? 'Luôn hoàn tiền khi khách báo huỷ đơn đặt phòng'
                              : 'Không hoàn tiền khi khách báo huỷ đơn đặt phòng'}
                      </Typography>
                    </Grid>
                  )
              }
            </Grid>
            {/* Đặt cọc */}
            <Grid container spacing={2}>
              {
                isEdit
                  ? (
                    <>
                      <Grid container item lg={12} md={12}>
                        <Checkbox
                          checked={values.deposit}
                          onChange={e => setFieldValue('deposit', e.target.checked)}
                          style={{ padding: 0 }}
                        />
                        <Typography variant="h6" style={{ marginLeft: 10 }}>
                          Có thể thu tiền cọc từ khách hàng?
                          <Tooltip title={depositTooltip}><Help fontSize="small" /></Tooltip>
                        </Typography>
                      </Grid>
                      {
                        values.deposit
                          ? (
                            <>
                              {
                                depositInfo.map((info, index) => (
                                  <Grid item lg={12} md={12} className="p-3 border">
                                    <Grid container item lg={12} md={12} className="mb-4">
                                      {/* Số tiền thu cọc */}
                                      <Grid item lg={3} md={3} className="pr-2">
                                        <CurrencyInput
                                          size="small"
                                          label="Số tiền"
                                          variant="outlined"
                                          fullWidth
                                          value={info.amount}
                                          onChange={(v) => {
                                            const clone = [...depositInfo]
                                            clone[index].amount = v
                                            onUpdateDepositInfo(clone, setFieldValue)
                                          }}
                                          placeholder="Nhập số tiền"
                                        />
                                      </Grid>
                                      {/* Mô tả cho lý do đặt cọc */}
                                      <Grid item lg={9} md={9}>
                                        <TextField
                                          size="small"
                                          label="Thu cọc trong các trường hợp"
                                          variant="outlined"
                                          fullWidth
                                          value={info.description}
                                          onChange={(e) => {
                                            const clone = [...depositInfo]
                                            clone[index].description = e.target.value
                                            onUpdateDepositInfo(clone, setFieldValue)
                                          }}
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          placeholder="Số tiền cọc có thể thu, khách thuê qua đêm, khách ở dài ngày,..."
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid container item lg={12} md={12} className="mb-4">
                                      <div>{`Căn hộ này yêu cầu đặt cọc: ${formatPrice(info.amount)} VND ${info.description}`}</div>
                                    </Grid>
                                    {/* Mô tả chi tiết phạt cọc */}
                                    <Grid item lg={12} md={12} className="mb-4">
                                      <RichEditor
                                        style={{ backgroundColor: 'white' }}
                                        label="Bảng giá quy định phạt các vi phạm"
                                        fullWidth
                                        value={info.price}
                                        onChange={(value) => {
                                          const clone = [...depositInfo]
                                          clone[index].price = value
                                          onUpdateDepositInfo(clone, setFieldValue)
                                        }}
                                        placeholder="Ví dụ: Hút thuốc trong căn hộ: 100.000VND, ..."
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                      />
                                    </Grid>
                                    {/* Phòng áp dụng */}
                                    <Grid item lg={12} md={12} className="mb-4">
                                      <DepositRoom
                                        rooms={host.rooms}
                                        defaultValue={info.rooms}
                                        onApplyRoom={(sltRooms) => {
                                          const clone = [...depositInfo]
                                          clone[index].rooms = sltRooms
                                          onUpdateDepositInfo(clone, setFieldValue)
                                        }}
                                      />
                                    </Grid>
                                    {/* Remove button */}
                                    <Grid item lg={12} md={12}>
                                      <Button style={{ float: 'right' }} variant="link" onClick={() => onDeleteDepositInfo(index, setFieldValue)}>
                                        <Delete />
                                        Xoá điều kiện
                                      </Button>
                                    </Grid>
                                  </Grid>
                                ))
                              }
                              <Grid item lg={12} md={12}>
                                <Button variant="contained" onClick={() => onAddDepositInfo(setFieldValue)}>
                                  <Add />
                                  Thêm điều kiện cọc
                                </Button>
                              </Grid>
                            </>
                          )
                          : null
                      }
                    </>
                  )
                  : (
                    <Grid item lg={12} md={12}>
                      <InputLabel className={classes.itemTitle}>Đặt cọc:</InputLabel>
                      <Typography variant="h6">
                        {values.deposit
                          ? 'Thu tiền cọc từ khách hàng khi nhận phòng'
                          : 'Không thu tiền cọc từ khách hàng khi nhận phòng'}
                      </Typography>
                      {
                        values.deposit
                          ? depositInfo.map((info, index) => (
                            <div className="p-2">
                              <Typography>{`${index + 1}. Điều kiện cọc ${index + 1}:`}</Typography>
                              <Grid item lg={12} md={12}>
                                <Typography>{` - Tiền cọc: ${formatPrice(info.amount)} VND`}</Typography>
                                <Typography>{` - Điều kiện phạt: ${info.description}`}</Typography>
                                <Typography>{' - Chi tiết phạt:'}</Typography>
                                <Typography variant="body2" className="pl-4" style={{ fontSize: 14 }} dangerouslySetInnerHTML={{ __html: info.price }} />
                                <Typography>{` - Áp dụng cho phòng: ${host.rooms?.filter(r => info.rooms.includes(r._id))?.map(r => `${r.name} (${r.code})`)?.join(', ')}`}</Typography>
                              </Grid>
                            </div>
                          ))
                          : null
                      }
                    </Grid>
                  )
              }
            </Grid>
            <br />
            <Divider />
            <Typography className={classes.subTitle}>Quyền hạn:</Typography>
            {/* Role and owner */}
            <Grid container spacing={2}>
              {
                isEdit
                  ? (
                    <>
                      <Grid item lg={6} md={6}>
                        <FormControl
                          size="small"
                          variant="outlined"
                          className={classes.formControl}
                          fullWidth
                          error={errors.role && touched.role}
                        >
                          <InputLabel style={{ backgroundColor: 'white', padding: '0px 5px' }} id="user-role">
                          Quyền
                          </InputLabel>
                          <Select
                            labelId="user-role"
                            id="user-role-label"
                            name="role"
                            value={values.role}
                            onChange={(e) => {
                              fetchHosts(e.target.value)
                              handleChange(e)
                            }}
                          >
                            {['owner', 'manager'].map(item => (
                              <MenuItem value={item}>
                                {item === 'owner' ? 'Chủ nhà' : 'Quản lý'}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormHelperText style={{ color: 'red' }}>
                          {errors.role && touched.role && errors.role}
                        </FormHelperText>
                      </Grid>
                      <Grid item lg={6} md={6}>
                        {values.role === 'owner' ? null : (
                          <>
                            <FormControl
                              size="small"
                              variant="outlined"
                              className={classes.formControl}
                              fullWidth
                              error={errors.owner && touched.owner}
                            >
                              <InputLabel
                                style={{ backgroundColor: 'white', padding: '0px 5px' }}
                                id="host-owner"
                              >
                              Chủ nhà
                              </InputLabel>
                              <Select
                                labelId="host-owner"
                                id="host-owner-label"
                                name="owner"
                                value={values.owner}
                                onChange={handleChange}
                              >
                                {hosts.map(({ value, label }) => (
                                  <MenuItem value={value}>{label}</MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <FormHelperText style={{ color: 'red' }}>
                              {errors.owner && touched.owner && errors.owner}
                            </FormHelperText>
                          </>
                        )}
                      </Grid>
                    </>
                  )
                  : (
                    <Grid item lg={12} md={12}>
                      <Typography variant="body1" style={{ fontWeight: 500 }}>
                        {values.role === 'owner' ? 'Chủ nhà' : 'Quản lý'}
                      </Typography>
                    </Grid>
                  )
              }
            </Grid>
            {/* Button */}
            <br />
            <Divider />
            <br />
            {
              action === 'update'
                ? isEdit
                  ? (
                    <>
                      <Button type="submit" variant="contained" color="primary">Cập nhật</Button>
                      <Button variant="contained" onClick={() => setIsEdit(false)} style={{ marginLeft: 20 }}>Thoát</Button>
                    </>
                  )
                  : (
                    <Button color="primary" variant="contained" onClick={() => setIsEdit(true)}>
                      <Edit style={{ fontSize: 16 }} />
                      Chỉnh sửa
                    </Button>
                  )
                : (
                  <Button type="submit" variant="contained" color="primary" size="large">Tạo tài khoản</Button>
                )
            }
          </Form>
        )}
      </Formik>
    </BackgroundBackdrop>
  )
}

HostInfo.propTypes = {
  classes: PropTypes.shape(),
  host: PropTypes.shape(),
  hosts: PropTypes.arrayOf(PropTypes.shape()),
  fetchHosts: PropTypes.func,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  isEdit: PropTypes.bool,
  setIsEdit: PropTypes.func,
  action: PropTypes.string,
}

export default HostInfo
