import axios from 'axios'
import * as ENDPOINT from './url'

export const schedule = async () => {
  try {
    const resp = await axios.get(ENDPOINT.FETCH_SCHEDULE())
    return resp.data
  } catch (e) {
    throw e
  }
}

export const find = async ({ host: hostId, page, limit }) => {
  try {
    const resp = await axios.get(ENDPOINT.FETCH_INVOICE({ host: hostId, page, limit }))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const create = async (data) => {
  try {
    const resp = await axios.post(ENDPOINT.CREATE_INVOICE, data)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const reject = async (id) => {
  try {
    const resp = await axios.post(ENDPOINT.REJECT_INVOICE(id))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const paid = async (id, { bankNote }) => {
  try {
    const resp = await axios.post(ENDPOINT.PAID_INVOICE(id), { bankNote })
    return resp.data
  } catch (e) {
    throw e
  }
}
