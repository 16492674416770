import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  withStyles,
  Grid,
  Typography,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import {
  create,
  update,
  findOne,
  find,
} from 'provider/host'
import HostInfo from './HostInfo'
import RoomList from './RoomList'
import ReservationList from './ReservationList'
import styles from './styled'

const initHost = {
  account: '',
  password: '',
  name: '',
  email: '',
  phone: '',
  birthday: undefined,
  address: '',
  bankAccountName: '',
  bankAccountNumber: '',
  bankName: '',
  bankBranch: '',
  policy: '2home',
  policyDay: 0,
  role: 'owner',
  owner: '',
  deposit: false,
  depositInfo: [],
}

const HostForm = ({
  classes,
  history,
  id,
  action,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [host, setHost] = useState(initHost)
  const [hosts, setHosts] = useState([])
  const [loading, setLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [activeTab, setActiveTab] = useState(0)

  const a11yProps = index => ({
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  })

  const fetchHosts = async (role) => {
    if (role === 'manager' && !hosts.length) {
      try {
        const resp = await find({ limit: Number.MAX_SAFE_INTEGER })
        setHosts(
          resp.data.map(item => ({
            value: item.id,
            label: item.name,
          })),
        )
      } catch (e) {
        enqueueSnackbar(
          `Máy chủ lỗi (code: ${e.response ? e.response.status : 'Unknown'}).`,
          { variant: 'error' },
        )
      }
    }
  }

  // Fetch user data if in updating mode
  const fetchHost = async () => {
    setLoading(true)
    try {
      const resp = await findOne(id)
      setHost({
        ...resp,
        id: resp._id || resp.id,
        owner: resp.owner ? resp.owner.id : '',
      })
      if (resp.role === 'manager') {
        fetchHosts('manager')
      }
      setLoading(false)
    } catch (e) {
      setLoading(false)
      enqueueSnackbar(
        `Máy chủ lỗi (code: ${e.response ? e.response.status : 'Unknown'}).`,
        { variant: 'error' },
      )
    }
  }

  useEffect(() => {
    if (action === 'update') {
      fetchHost()
    }
  }, [])

  useEffect(() => {
    if (action !== 'update') {
      setIsEdit(true)
    }
    return () => {
    }
  }, [action])

  const onHostInfoUpdate = (type, message) => {
    switch (type) {
      case 'success':
        enqueueSnackbar('Cập nhật thông tin chủ nhà thành công!', {
          variant: type,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
        break
      case 'error':
        enqueueSnackbar(message, {
          variant: type,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
        break
      case 'info':
        enqueueSnackbar('Thêm mới chủ nhà thành công!', {
          variant: type,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
        break
      default:
    }
  }

  const onSubmit = async (data) => {
    try {
      let resp = null
      switch (action) {
        case 'update':
          resp = await update({ ...data, owner: data.owner || undefined })
          setHost(resp)
          onHostInfoUpdate('success')
          break
        default:
          resp = await create({ ...data, owner: data.owner || undefined })
          history.push(`/host/${resp.id}/update`)
          onHostInfoUpdate('info')
      }
      setIsEdit(false)
    } catch (e) {
      onHostInfoUpdate('error', e.response.data)
    }
  }

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={activeTab} onChange={(e, v) => setActiveTab(v)}>
          <Tab label="Chi tiết chủ nhà" {...a11yProps(0)} />
          <Tab label="Phòng và đơn đặt phòng" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <div role="tabpanel" hidden={activeTab !== 0} id="scrollable-force-tabpanel-0" aria-labelledby="scrollable-force-tab-0">
        <Grid container className="p-2 m-0">
          <Grid item lg={8} md={8} xs={12}>
            <HostInfo
              classes={classes}
              action={action}
              loading={loading}
              host={host}
              hosts={hosts}
              fetchHosts={fetchHosts}
              isEdit={isEdit}
              setIsEdit={setIsEdit}
              onSubmit={onSubmit}
            />
          </Grid>
        </Grid>
      </div>
      <div role="tabpanel" hidden={activeTab !== 1} id="scrollable-force-tabpanel-0" aria-labelledby="scrollable-force-tab-0">
        <Grid container className="p-2 m-0">
          {/* Room and Reservation */}
          <Grid item lg={12} md={12} xs={12}>
            <Typography variant="h4" className={classes.titleAlign}>
              Danh sách phòng
            </Typography>
            <RoomList classes={classes} owner={host._id} />
            <br />
            <Typography variant="h4" className={classes.titleAlign}>
              Đơn đặt phòng
            </Typography>
            <ReservationList classes={classes} host={host._id} />
          </Grid>
        </Grid>
      </div>
    </>
  )
}

HostForm.propTypes = {
  classes: PropTypes.shape(),
  history: PropTypes.shape(),
  id: PropTypes.string,
  action: PropTypes.string,
}

export default withStyles(styles)(HostForm)
