import React from 'react'
import PropTypes from 'prop-types'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const RichEditor = ({ style, ...props }) => (
  <ReactQuill theme="snow" style={{ ...style, width: '100%' }} {...props} />
)

RichEditor.propTypes = {
  style: PropTypes.shape(),
}

export default RichEditor
