import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Typography,
  Button,
  Divider,
  withStyles,
} from '@material-ui/core'
import * as Yup from 'yup'
import { Formik, Form } from 'formik'
import { findOne, create, update } from 'provider/service'
import uploadApi from 'provider/upload'
import { useSnackbar } from 'notistack'
import BackgroundBackdrop from 'components/Backdrop'
import serviceFormStyle from './styled'
import General from './General'
import Media from './Media'
import Description from './Description'

const validateSchema = Yup.object().shape({})

const initService = {
  name: '',
  description: '',
  price: 0,
  prepareTime: 0,
  lockTime: 0,
  thumbnail: [],
  images: [],
  video: '',
  type: '',
  options: [],
  components: [],
  services: [],
}

const ServiceForm = ({
  classes,
  id,
  action,
  history,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [service, setService] = useState(initService)
  const [loading, setLoading] = useState(false)

  const fetchService = async () => {
    try {
      const resp = await findOne(id)
      setService({
        ...resp,
        components: resp.components.map((item) => item.id),
        options: resp.options.map((item) => item.id),
        images: resp.images.map((url) => ({ isLink: true, file: url })),
        thumbnail: resp.thumbnail ? [{ isLink: true, file: resp.thumbnail }] : [],
      })
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (action === 'update') {
      fetchService()
    }
    // eslint-disable-next-line
  }, [])

  const onSubmit = async (data) => {
    setLoading(true)
    const {
      images: allImages,
      thumbnail,
      ...rest
    } = data
    const requestData = {
      ...rest,
    }
    // Upload images to Cloudinary server
    const needToUpload = []
    const noUpload = []
    allImages.forEach((item) => {
      if (!item.isLink) {
        needToUpload.push(item.file)
      } else {
        noUpload.push(item.file)
      }
    })
    let uploadToServer = []
    // Upload thumbnail to Cloudinary server
    const thumbnailToUpload = []
    if (thumbnail && thumbnail.length && !thumbnail[0].isLink) {
      thumbnailToUpload.push(thumbnail[0].file)
    }
    let uploadThumnailToServer = []
    try {
      let resp = null
      switch (action) {
        case 'update':
          if (!id) {
            enqueueSnackbar('Tạo mới dịch vụ không thành công', { variant: 'error' })
            return
          }
          if (needToUpload.length) {
            uploadToServer = await uploadApi.uploadImage(needToUpload, `Admin/Service/${data.code}`)
          }
          if (thumbnailToUpload.length) {
            uploadThumnailToServer = await uploadApi.uploadImage(thumbnailToUpload, `Admin/Service/${data.code}`)
          }
          await update({
            ...requestData,
            id,
            thumbnail: uploadThumnailToServer.length ? uploadThumnailToServer[0].shortUrl : thumbnail[0].file,
            images: [...uploadToServer.map((item) => item.shortUrl), ...noUpload],
            price: parseInt(data.price.toString().replace(/,/g, ''), 2),
          })
          setService(data)
          setLoading(false)
          enqueueSnackbar('Cập nhật thông tin dịch vụ thành công.', { variant: 'success' })
          break
        default:
          resp = await create({
            ...requestData,
            price: parseInt(data.price.toString().replace(/,/g, ''), 2),
          })
          if (!resp || !resp.id) {
            enqueueSnackbar('Tạo mới dịch vụ không thành công', { variant: 'error' })
            return
          }
          if (needToUpload.length) {
            uploadToServer = await uploadApi.uploadImage(needToUpload, `Admin/Service/${resp.code}`)
          }
          if (thumbnailToUpload.length) {
            uploadThumnailToServer = await uploadApi.uploadImage(thumbnailToUpload, `Admin/Service/${resp.code}`)
          }
          await update({
            ...requestData,
            thumbnail: uploadThumnailToServer.length ? uploadThumnailToServer[0].shortUrl : thumbnail[0].file,
            images: [...uploadToServer.map((item) => item.shortUrl), ...noUpload],
            id: resp.id,
          })
          history.push(`/service/${resp.id}/update`)
          setLoading(false)
          enqueueSnackbar('Thêm mới dịch vụ thành công.', { variant: 'success' })
      }
    } catch (e) {
      setLoading(false)
      enqueueSnackbar(`Máy chủ lỗi (code: ${e.response ? e.response.status : 'Unknown'}). Không thể cập nhật thông tin dịch vụ.`, { variant: 'error' })
    }
  }

  return (
    <BackgroundBackdrop loading={loading}>
      <Formik
        initialValues={service}
        enableReinitialize
        validationSchema={validateSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          setFieldValue,
        }) => (
          <Form className={classes.root}>
            <Typography variant="h3">
              {action === 'update'
                ? 'Cập nhật thông tin dịch vụ'
                : 'Thêm mới dịch vụ'}
            </Typography>
            <General
              classes={classes}
              values={values}
              touched={touched}
              errors={errors}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
            <Divider style={{ margin: '20px auto', backgroundColor: '#d9d9d9' }} />
            {/* Danh sách ảnh/video */}
            <Media
              classes={classes}
              values={values}
              touched={touched}
              errors={errors}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
            <Divider style={{ margin: '20px auto', backgroundColor: '#d9d9d9' }} />
            {/* Mô tả dịch vụ */}
            <Description
              classes={classes}
              values={values}
              touched={touched}
              errors={errors}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
            <Divider style={{ margin: '20px auto', backgroundColor: '#d9d9d9' }} />
            {/* Create button */}
            <br />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              {action === 'update' ? 'Cập nhật thông tin' : 'Tạo dịch vụ'}
            </Button>
          </Form>
        )}
      </Formik>
    </BackgroundBackdrop>
  )
}

ServiceForm.propTypes = {
  classes: PropTypes.shape(),
  id: PropTypes.string,
  action: PropTypes.string,
  history: PropTypes.shape(),
}

export default withStyles(serviceFormStyle)(ServiceForm)
