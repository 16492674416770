import Host from './Host'
import Building from './Building'
import Status from './Status'
import Price from './Price'
import Service from './Service'

export default {
  Host,
  Building,
  Status,
  Price,
  Service,
}
