import React from 'react'
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  isSameDay,
} from 'date-fns'
import PropTypes from 'prop-types'

const defineds = {
  theFirstestDay: startOfDay(new Date(0)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOf3Days: startOfDay(addDays(new Date(), -2)),
  startOf7Days: startOfDay(addDays(new Date(), -6)),
  startOf14Days: startOfDay(addDays(new Date(), -13)),
  startOf30Days: startOfDay(addDays(new Date(), -29)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
}

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range()
    if (!definedRange) return false
    return (isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate))
  },
  hasCustomRendering: true,
}

const createStaticRanges = ranges => ranges.map(range => ({ ...staticRangeHandler, ...range }))

export const staticRanges = createStaticRanges([
  {
    label: 'Tất cả',
    range: () => ({
      startDate: null,
      endDate: null,
    }),
  },
  {
    label: 'Hôm nay',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Hôm qua',
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
  },

  {
    label: '7 ngày trước',
    range: () => ({
      startDate: defineds.startOf7Days,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: '14 ngày trước',
    range: () => ({
      startDate: defineds.startOf14Days,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: '30 ngày trước',
    range: () => ({
      startDate: defineds.startOf30Days,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Tháng này',
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
  {
    label: 'Tháng trước',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    label: 'Tuỳ chọn',
    range: () => ({
      startDate: defineds.startOf3Days,
      endDate: defineds.endOfToday,
    }),
  },
])

export const CustomStaticRangeLabelContent = ({ dataRange, setDisabledPreview, ...definedRange }) => {
  const start = dataRange[0].startDate
  const end = dataRange[0].endDate

  const isSelectAll = definedRange.label === 'All' && start === null && end === null
  let isRangeExisted = staticRanges.filter(s => ((isSameDay(s.range().startDate, start) || s.range().startDate === start) && (isSameDay(s.range().endDate, end) || s.range().endDate === end)))

  isRangeExisted = isRangeExisted.length

  const customSelected = isSelectAll || (definedRange.label === 'Custom' && !isRangeExisted)

  return (
    // eslint-disable-next-line
    <div
      role="button"
      onMouseOver={() => setDisabledPreview(true)}
      onMouseLeave={() => setDisabledPreview(false)}
      className={customSelected ? 'customSelected' : ''}
    >
      {definedRange.label}
    </div>
  )
}

CustomStaticRangeLabelContent.propTypes = {
  dataRange: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setDisabledPreview: PropTypes.func.isRequired,
}
