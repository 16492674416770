const styles = theme => ({
  root: {},
  content: {
    padding: 0,
    '& :last-child': {
      paddingBottom: 0,
    },
  },
  inner: {
    // minWidth: 800,
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    marginRight: theme.spacing(1),
  },
  actions: {
    justifyContent: 'flex-end',
  },
})

export default styles
