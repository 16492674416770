import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  withStyles,
  Grid,
  Typography,
  InputLabel,
  Divider,
  Collapse,
  Button,
} from '@material-ui/core'
import { NavLink as RouterLink } from 'react-router-dom'
import { getCalculatedArray } from 'utils/service'
import { formatPrice } from 'utils/formatter'
import moment from 'moment'
import { DATE_FORMAT, TIME_FORMAT } from 'config'
import { reduce } from 'underscore'
import styled from './styled'

const OrderInfo = ({
  classes,
  remainingRoomPrice,
  remainingServicePrice,
  reservation,
}) => {
  const [showMoreRoom, setShowMoreRoom] = useState(false)
  const [showMoreService, setShowMoreService] = useState(false)

  const renderPriceItem = (title, price) => (
    <Grid container lg={12} md={12} className="my-2">
      <Grid item lg={6} md={6}>
        <InputLabel>{title}</InputLabel>
      </Grid>
      <Grid item lg={6} md={6} className="text-right">
        <InputLabel>{`${price} VND`}</InputLabel>
      </Grid>
    </Grid>
  )

  const getTotalRoomPrice = () => {
    if (!reservation || !reservation.id) return 0
    const {
      paymentHistory,
      roomPrice,
      guestPrice,
      couponInfo,
      addCheckoutPoint,
    } = reservation
    let total = roomPrice + guestPrice
    if (addCheckoutPoint?.length) {
      // Thời gian checkout thực tế
      const allAddCheckouts = addCheckoutPoint.filter(item => item.status === 'paid')
      total += (allAddCheckouts && allAddCheckouts.length ? reduce(allAddCheckouts.map(c => c.value), (a, b) => a + b) : 0)
    }
    if (couponInfo && couponInfo?.creator === 'host' && couponInfo.useFor === 'room') {
      switch (couponInfo.valueType) {
        case 'money':
          total -= couponInfo.value
          break
        case 'percent':
          total -= couponInfo.value * (paymentHistory?.length ? paymentHistory[0]?.roomPrice + paymentHistory[0]?.guestPrice : 0) / 100
          break
        default:
          break
      }
    }
    return total
  }

  const getPrevRoomTotalRevenue = () => {
    if (!reservation || !reservation.id) return 0
    const {
      prevState,
      paymentHistory,
      couponInfo,
    } = reservation
    if (!prevState) return 0
    let total = 0
    const {
      roomPrice,
      guestPrice,
    } = prevState
    total = roomPrice + guestPrice
    total += paymentHistory?.map(p => p.punishRoomPrice)?.reduce((a, b) => a + b, 0)
    total += paymentHistory?.map(p => p.punishGuestPrice)?.reduce((a, b) => a + b, 0)
    if (couponInfo && couponInfo?.creator === 'host' && couponInfo.useFor === 'room') {
      switch (couponInfo.valueType) {
        case 'money':
          total -= couponInfo.value
          break
        case 'percent':
          total -= couponInfo.value * (paymentHistory?.length ? paymentHistory[0]?.roomPrice + paymentHistory[0]?.guestPrice : 0) / 100
          break
        default:
          break
      }
    }
    return total
  }

  const getCurrentRoomTotalRevenue = () => {
    if (!reservation || !reservation.id) return 0
    const {
      prevState,
      paymentHistory,
      roomPrice,
      guestPrice,
      couponInfo,
      addCheckoutPoint,
    } = reservation
    let total = roomPrice + guestPrice
    if (addCheckoutPoint?.length) {
      // Thời gian checkout thực tế
      const allAddCheckouts = addCheckoutPoint.filter(item => item.status === 'paid')
      total += (allAddCheckouts && allAddCheckouts.length ? reduce(allAddCheckouts.map(c => c.value), (a, b) => a + b) : 0)
    }
    total += paymentHistory?.map(p => p.punishRoomPrice || 0)?.reduce((a, b) => a + b, 0)
    total += paymentHistory?.map(p => p.punishGuestPrice || 0)?.reduce((a, b) => a + b, 0)
    if (prevState) {
      total += prevState.roomPrice - remainingRoomPrice.remainingRoom
      total += prevState.guestPrice - remainingRoomPrice.remainingGuest
    }
    if (couponInfo && couponInfo?.creator === 'host' && couponInfo.useFor === 'room') {
      switch (couponInfo.valueType) {
        case 'money':
          total -= couponInfo.value
          break
        case 'percent':
          total -= couponInfo.value * (paymentHistory?.length ? paymentHistory[0]?.roomPrice + paymentHistory[0]?.guestPrice : 0) / 100
          break
        default:
          break
      }
    }
    return total
  }

  const getPrevServiceTotalRevenue = () => {
    if (!reservation || !reservation.id) return 0
    const {
      prevState,
      paymentHistory,
      couponInfo,
    } = reservation
    if (!prevState) return 0
    let total = 0
    const {
      servicePrice,
    } = prevState
    total = servicePrice
    total += paymentHistory?.map(p => p.punishServicePrice)?.reduce((a, b) => a + b, 0)
    if (couponInfo && couponInfo.useFor === 'service') {
      switch (couponInfo.valueType) {
        case 'money':
          total -= couponInfo.value
          break
        case 'percent':
          total -= couponInfo.value * paymentHistory[0]?.servicePrice / 100
          break
        default:
          break
      }
    }
    return total
  }

  const getCurrentServiceTotalRevenue = () => {
    if (!reservation || !reservation.id) return 0
    const {
      prevState,
      paymentHistory,
      servicePrice,
      couponInfo,
    } = reservation
    let total = servicePrice
    total += paymentHistory?.map(p => p.punishServicePrice)?.reduce((a, b) => a + b, 0)
    if (prevState) {
      total += prevState.servicePrice - remainingServicePrice
    }
    if (couponInfo && couponInfo.useFor === 'service') {
      switch (couponInfo.valueType) {
        case 'money':
          total -= couponInfo.value
          break
        case 'percent':
          total -= couponInfo.value * paymentHistory[0]?.servicePrice / 100
          break
        default:
          break
      }
    }
    return total
  }

  const getCouponValue = (couponData, price) => {
    switch (couponData.valueType) {
      case 'money':
        return couponData.value

      case 'percent':
        return price * couponData

      default:
        return 0
    }
  }

  if (!reservation || !reservation.id) return null

  const {
    checkin,
    checkout,
    guest,
    limitRoom,
    serviceInfo: services,
    roomPriceInfo,
    roomPrice,
    guestPrice,
    servicePrice,
    prevState,
    roomInfo: room,
    couponInfo,
    paymentHistory,
  } = reservation
  const {
    checkin: prevCheckin,
    checkout: prevCheckout,
    guest: prevGuest,
    limitRoom: prevLimitRoom,
    services: prevServices,
    roomPriceInfo: prevRoomPriceInfo,
    roomPrice: prevRoomPrice,
    guestPrice: prevGuestPrice,
    servicePrice: prevServicePrice,
  } = prevState || {}

  return (
    <Grid container style={{ maxWidth: '60vw', padding: 15 }}>
      {/* Phòng */}
      <Grid className="mb-2" container item lg={12} spacing={3}>
        <Grid item lg={10} md={10}>
          <InputLabel className={classes.itemTitle}>Thông tin đặt phòng:</InputLabel>
          <Typography variant="body1">
            <RouterLink to={`/room/${room?.id}/update`} target="_blank">{`${room?.name} (${room?.code})`}</RouterLink>
          </Typography>
        </Grid>
        <Grid item lg={2} md={2} className="text-right mt-auto">
          <Button
            variant="text"
            style={{ textTransform: 'none' }}
            onClick={() => setShowMoreRoom(!showMoreRoom)}
          >
            {showMoreRoom ? 'Thu gọn' : 'Chi tiết'}
          </Button>
        </Grid>
      </Grid>
      {/* Chi tiết giá phòng */}
      <Collapse in={showMoreRoom} timeout="auto" unmountOnExit className="w-100">
        {/* Checkin - Checkout */}
        <Grid className="mb-2" container item lg={12} spacing={3}>
          <Grid item lg={12} md={12}>
            <InputLabel className={classes.itemTitle}>Nhận phòng - Trả phòng</InputLabel>
            <Grid container>
              {
                  reservation?.prevState
                    ? (
                      <>
                        <Grid item lg={5} md={5} className="d-flex align-items-center">
                          <div>
                            <Typography variant="body1">
                              {moment(prevCheckin).format(TIME_FORMAT)}
                            </Typography>
                            <Typography variant="body1">
                              {moment(prevCheckin).format(DATE_FORMAT)}
                            </Typography>
                          </div>
                          <span className="mx-4">&#8208;</span>
                          <div>
                            <Typography variant="body1">
                              {moment(prevCheckout).format(TIME_FORMAT)}
                            </Typography>
                            <Typography variant="body1">
                              {moment(prevCheckout).format(DATE_FORMAT)}
                            </Typography>
                          </div>
                        </Grid>
                        <Grid className="text-center m-auto" item lg={1} md={1}>&rarr;</Grid>
                      </>
                    )
                    : null
                }
              <Grid item lg={reservation?.prevState ? 6 : 8} md={reservation?.prevState ? 6 : 8} className="d-flex align-items-center">
                <div>
                  <Typography variant="body1">
                    {moment(checkin).format(TIME_FORMAT)}
                  </Typography>
                  <Typography variant="body1">
                    {moment(checkin).format(DATE_FORMAT)}
                  </Typography>
                </div>
                <span className="mx-4">&#8208;</span>
                <div>
                  <Typography variant="body1">
                    {moment(checkout).format(TIME_FORMAT)}
                  </Typography>
                  <Typography variant="body1">
                    {moment(checkout).format(DATE_FORMAT)}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Số khách */}
        <Grid className="mb-2" container item lg={12} spacing={3}>
          <Grid item lg={12} md={12}>
            <InputLabel className={classes.itemTitle}>Số khách:</InputLabel>
            <Grid container>
              {
                  reservation?.prevState
                    ? (
                      <>
                        <Grid item lg={5} md={5}>
                          <Typography variant="body1">
                            {`${prevGuest} khách`}
                          </Typography>
                        </Grid>
                        <Grid className="text-center m-auto" item lg={1} md={1}>&rarr;</Grid>
                      </>
                    )
                    : null
                }
              <Grid item lg={6} md={6}>
                <Typography variant="body1">
                  {`${guest} khách`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Số phòng ngủ */}
        <Grid className="mb-2" container item lg={12} spacing={3}>
          <Grid item lg={12} md={12}>
            <InputLabel className={classes.itemTitle}>Phòng ngủ:</InputLabel>
            <Grid container>
              {
                  reservation?.prevState
                    ? (
                      <>
                        <Grid item lg={5} md={5}>
                          <Typography variant="body1">
                            {prevLimitRoom ? 'Chỉ thuê 1 phòng ngủ' : 'Thuê toàn bộ'}
                          </Typography>
                        </Grid>
                        <Grid className="text-center m-auto" item lg={1} md={1}>&rarr;</Grid>
                      </>
                    )
                    : null
                }
              <Grid item lg={6} md={6}>
                <Typography variant="body1">
                  {limitRoom ? 'Chỉ thuê 1 phòng ngủ' : 'Thuê toàn bộ'}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* Chi tiết giá phòng */}
        <Grid className="mb-2" container item lg={12} spacing={3}>
          <Grid item lg={12} md={12}>
            <InputLabel className={classes.itemTitle}>Chi tiết giá phòng:</InputLabel>
            <Grid container>
              {
                reservation?.prevState
                  ? (
                    <>
                      <Grid item lg={5} md={5}>
                        {getCalculatedArray(prevRoomPriceInfo.data)?.map(item => renderPriceItem(item.title, formatPrice(item.price)))}
                      </Grid>
                      <Grid className="text-center m-auto" item lg={1} md={1}>&rarr;</Grid>
                    </>
                  )
                  : null
              }
              <Grid item lg={reservation?.prevState ? 6 : 12} md={reservation?.prevState ? 6 : 12}>
                {getCalculatedArray(roomPriceInfo.data)?.map(item => renderPriceItem(item.title, formatPrice(item.price)))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
      {/* Tổng tiền phòng */}
      <Grid className="mb-2" container item lg={12} spacing={3}>
        <Divider component="div" className="w-100" />
        <Grid item lg={12} md={12}>
          <Grid container>
            {
              reservation?.prevState
                ? (
                  <>
                    <Grid item lg={5} md={5}>
                      <Grid container lg={12} md={12} className="my-2">
                        <Grid item lg={6} md={6}>
                          <InputLabel>Tổng tiền phòng (1)</InputLabel>
                        </Grid>
                        <Grid item lg={6} md={6} className="text-right">
                          <InputLabel>{`${formatPrice(prevRoomPrice + prevGuestPrice)} VND`}</InputLabel>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className="text-center m-auto" item lg={1} md={1}>&rarr;</Grid>
                  </>
                )
                : null
            }
            <Grid item lg={reservation?.prevState ? 6 : 12} md={reservation?.prevState ? 6 : 12}>
              <Grid container lg={12} md={12} className="my-2">
                <Grid item lg={6} md={6}>
                  <InputLabel>{`Tổng tiền phòng ${prevState ? '' : '(1)'}`}</InputLabel>
                </Grid>
                <Grid item lg={6} md={6} className="text-right">
                  <InputLabel>{`${formatPrice(getTotalRoomPrice())} VND`}</InputLabel>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Số dư khả dụng khi chỉnh sửa đơn */}
      <Grid className="mb-2" container item lg={12} spacing={3}>
        <Divider component="div" className="w-100" />
        <Grid item lg={12} md={12}>
          <Grid container>
            {
              reservation?.prevState
                ? (
                  <Grid item lg={6} md={6} className="ml-auto">
                    <Grid container lg={12} md={12} className="my-3">
                      <Grid item lg={6} md={6}>
                        <InputLabel>Số dư phòng khả dụng của khách từ đơn cũ (3)</InputLabel>
                      </Grid>
                      <Grid item lg={6} md={6} style={{ textAlign: 'right' }}>
                        <InputLabel>{`${formatPrice(remainingRoomPrice.remainingRoom + remainingRoomPrice.remainingGuest)} VND`}</InputLabel>
                      </Grid>
                    </Grid>
                    <Grid container lg={12} md={12} className="my-3">
                      <Grid item lg={6} md={6}>
                        <InputLabel>Số tiền phòng khách không được hoàn lại (4)</InputLabel>
                      </Grid>
                      <Grid item lg={6} md={6} style={{ textAlign: 'right' }}>
                        <InputLabel>{`${formatPrice((prevRoomPrice + prevGuestPrice) - (remainingRoomPrice.remainingRoom + remainingRoomPrice.remainingGuest))} VND`}</InputLabel>
                      </Grid>
                    </Grid>
                    <Divider component="div" className="my-2 w-100" />
                    <Grid container lg={12} md={12} className="my-2">
                      <Grid item lg={6} md={6}>
                        <InputLabel style={{ color: 'inherit' }}>
                          {remainingRoomPrice.remainingRoom + remainingRoomPrice.remainingGuest <= roomPrice + guestPrice
                            ? 'Số tiền phòng khách thanh toán thêm (5)'
                            : 'Số tiền phòng khách được hoàn lại (5)'}
                        </InputLabel>
                      </Grid>
                      <Grid item lg={6} md={6} style={{ textAlign: 'right' }}>
                        <InputLabel style={{ color: 'inherit' }}>
                          {`${formatPrice(Math.abs((roomPrice + guestPrice) - (remainingRoomPrice.remainingRoom + remainingRoomPrice.remainingGuest)))} VND`}
                        </InputLabel>
                      </Grid>
                    </Grid>
                  </Grid>
                )
                : null
            }
          </Grid>
        </Grid>
      </Grid>
      {/* Mã giảm giá chủ nhà */}
      {couponInfo && couponInfo.creator === 'host'
        ? (
          <Grid className="mb-2" container item lg={12} spacing={3}>
            <Grid item lg={6} md={6}>
              <Grid container lg={12} md={12} className="my-2">
                <Grid item lg={6} md={6}>
                  <Typography>Mã giảm giá chủ nhà (2):</Typography>
                  <Typography variant="body1">{couponInfo?.code}</Typography>
                </Grid>
                <Grid item lg={6} md={6} className="text-right">
                  <Typography variant="body1">{`${formatPrice(couponInfo?.value || 0)} VND`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )
        : null}
      {/* Tổng doanh thu phòng */}
      <Grid className="mb-2" container item lg={12} spacing={3}>
        <Grid item lg={12} md={12}>
          <Grid container>
            {
              reservation?.prevState
                ? (
                  <>
                    <Grid item lg={5} md={5}>
                      <Grid container lg={12} md={12} className="my-2">
                        <Grid item lg={6} md={6}>
                          <Typography>Tổng doanh thu phòng (6):</Typography>
                          <Typography variant="body1">
                            {`= (1) ${couponInfo && couponInfo?.creator === 'host' ? '- (2)' : ''}`}
                          </Typography>
                        </Grid>
                        <Grid item lg={6} md={6} className="text-right">
                          <Typography variant="body1">{`${formatPrice(getPrevRoomTotalRevenue())} VND`}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid className="text-center m-auto" item lg={1} md={1}>&rarr;</Grid>
                  </>
                )
                : null
            }
            <Grid item lg={reservation?.prevState ? 6 : 12} md={reservation?.prevState ? 6 : 12}>
              <Grid container lg={12} md={12} className="my-2">
                <Grid item lg={6} md={6}>
                  <Typography>Tổng doanh thu phòng:</Typography>
                  <Typography variant="body1">
                    {`${prevState
                      ? `= (3) + (4) + (5) ${couponInfo && couponInfo?.creator === 'host' ? '- (2)' : ''}`
                      : `= (1) ${couponInfo && couponInfo?.creator === 'host' ? '- (2)' : ''}`}`}
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} className="text-right">
                  <Typography variant="body1">{`${formatPrice(getCurrentRoomTotalRevenue())} VND`}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Dịch vụ */}
      {room && room.hasService && (services?.length || prevServices?.length)
        ? (
          <>
            <Grid className="mb-2" container item lg={12} spacing={3}>
              <Grid item lg={12} md={12}>
                <Grid container>
                  <Grid item lg={10} md={10}>
                    <InputLabel className={classes.itemTitle}>Thông tin dịch vụ:</InputLabel>
                  </Grid>
                  <Grid item lg={2} md={2} className="text-right">
                    <Button
                      variant="text"
                      style={{ textTransform: 'none' }}
                      onClick={() => setShowMoreService(!showMoreService)}
                    >
                      {showMoreService ? 'Thu gọn' : 'Chi tiết'}
                    </Button>
                  </Grid>
                </Grid>
                <Collapse in={showMoreService} timeout="auto" unmountOnExit className="w-100">
                  <Grid container>
                    {
                      reservation?.prevState
                        ? (
                          <Grid item lg={5} md={5} style={{ paddingTop: 3 }}>
                            {
                              prevServices.map(item => (
                                <Grid container lg={12} md={12} className="my-2">
                                  <Grid item lg={6} md={6}>
                                    <InputLabel>{item.name}</InputLabel>
                                  </Grid>
                                  <Grid item lg={6} md={6} className="text-right">
                                    <InputLabel>{`${formatPrice(item.price)} VND`}</InputLabel>
                                  </Grid>
                                </Grid>
                              ))
                            }
                          </Grid>
                        )
                        : null
                    }
                    {reservation?.prevState ? <Grid className="text-center m-auto" item lg={1} md={1}>&rarr;</Grid> : ''}
                    <Grid item lg={reservation?.prevState ? 6 : 12} md={reservation?.prevState ? 6 : 12}>
                      {
                        services.map(item => (
                          <Grid container lg={12} md={12} className="my-2">
                            <Grid item lg={6} md={6}>
                              <Typography variant="body1">{item.name}</Typography>
                            </Grid>
                            <Grid item lg={6} md={6} className="text-right">
                              <InputLabel>{`${formatPrice(item.price)} VND`}</InputLabel>
                            </Grid>
                          </Grid>
                        ))
                      }
                    </Grid>
                  </Grid>
                </Collapse>
              </Grid>
            </Grid>
            {/* Tổng tiền dịch vụ */}
            <Grid className="mb-2" container item lg={12} spacing={3}>
              <Divider component="div" className="w-100" />
              <Grid item lg={12} md={12}>
                <Grid container>
                  {
                    reservation?.prevState
                      ? (
                        <>
                          <Grid item lg={5} md={5}>
                            <Grid container lg={12} md={12} className="my-2">
                              <Grid item lg={6} md={6}>
                                <InputLabel>Tổng tiền dịch vụ (A)</InputLabel>
                              </Grid>
                              <Grid item lg={6} md={6} className="text-right">
                                <InputLabel>{`${formatPrice(prevServicePrice)} VND`}</InputLabel>
                              </Grid>
                            </Grid>
                            {/* Mã giảm giá 2home */}
                            {couponInfo && ['customer', 'system'].includes(couponInfo.creator) && couponInfo.useFor === 'service'
                              ? (
                                <Grid container lg={12} md={12} className="my-2">
                                  <Grid item lg={6} md={6}>
                                    <Typography>Mã giảm giá 2home (E):</Typography>
                                    <Typography variant="body1">{couponInfo?.code}</Typography>
                                  </Grid>
                                  <Grid item lg={6} md={6} className="text-right">
                                    <Typography variant="body1">
                                      {`-${formatPrice(getCouponValue(couponInfo, paymentHistory && paymentHistory.length ? paymentHistory[0].servicePrice : 0))} VND`}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              )
                              : null}
                          </Grid>
                          <Grid className="text-center m-auto" item lg={1} md={1}>&rarr;</Grid>
                        </>
                      )
                      : null
                  }
                  <Grid item lg={reservation?.prevState ? 6 : 12} md={reservation?.prevState ? 6 : 12}>
                    <Grid container lg={12} md={12} className="my-2">
                      <Grid item lg={6} md={6}>
                        <InputLabel>Tổng tiền dịch vụ (A)</InputLabel>
                      </Grid>
                      <Grid item lg={6} md={6} className="text-right">
                        <InputLabel>{`${formatPrice(servicePrice)} VND`}</InputLabel>
                      </Grid>
                    </Grid>
                    {/* Mã giảm giá 2home */}
                    {couponInfo && ['customer', 'system'].includes(couponInfo.creator) && couponInfo.useFor === 'service'
                      ? (
                        <Grid container lg={12} md={12} className="my-2">
                          <Grid item lg={6} md={6}>
                            <Typography>Mã giảm giá 2home (E):</Typography>
                            <Typography variant="body1">{couponInfo?.code}</Typography>
                          </Grid>
                          <Grid item lg={6} md={6} className="text-right">
                            <Typography variant="body1">
                              {`-${formatPrice(getCouponValue(couponInfo, paymentHistory && paymentHistory.length ? paymentHistory[0].servicePrice : 0))} VND`}
                            </Typography>
                          </Grid>
                        </Grid>
                      )
                      : null}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* Số dư khả dụng khi chỉnh sửa dịch vụ */}
            <Grid className="mb-2" container item lg={12} spacing={3}>
              <Divider component="div" className="w-100" />
              <Grid item lg={6} md={6} className="ml-auto">
                <Grid container>
                  {
                    reservation?.prevState
                      ? (
                        <Grid item lg={12} md={12} className="ml-auto">
                          <Grid container lg={12} md={12} className="my-3">
                            <Grid item lg={6} md={6}>
                              <InputLabel>Số dư dịch vụ khả dụng của khách từ đơn cũ (B)</InputLabel>
                            </Grid>
                            <Grid item lg={6} md={6} style={{ textAlign: 'right' }}>
                              <InputLabel>{`${formatPrice(remainingServicePrice)} VND`}</InputLabel>
                            </Grid>
                          </Grid>
                          <Grid container lg={12} md={12} className="my-3">
                            <Grid item lg={6} md={6}>
                              <InputLabel>Số tiền dịch vụ khách không được hoàn lại (C)</InputLabel>
                            </Grid>
                            <Grid item lg={6} md={6} style={{ textAlign: 'right' }}>
                              <InputLabel>{`${formatPrice(prevServicePrice - remainingServicePrice)} VND`}</InputLabel>
                            </Grid>
                          </Grid>
                          <Divider component="div" className="my-2 w-100" />
                          <Grid container lg={12} md={12} className="my-2">
                            <Grid item lg={6} md={6}>
                              <InputLabel style={{ color: 'inherit' }}>
                                {(servicePrice - remainingServicePrice) >= 0
                                  ? 'Số tiền dịch vụ khách thanh toán thêm (D)'
                                  : 'Số tiền dịch vụ khách được hoàn lại (D)'}
                              </InputLabel>
                            </Grid>
                            <Grid item lg={6} md={6} style={{ textAlign: 'right' }}>
                              <InputLabel style={{ color: 'inherit' }}>
                                {`${formatPrice(Math.abs(servicePrice - remainingServicePrice))} VND`}
                              </InputLabel>
                            </Grid>
                          </Grid>
                        </Grid>
                      )
                      : null
                  }
                </Grid>
              </Grid>
            </Grid>
            {/* Tổng doanh thu dịch vụ */}
            <Grid className="mb-2" container item lg={12} spacing={3}>
              <Grid item lg={12} md={12}>
                <Grid container>
                  {
                    reservation?.prevState
                      ? (
                        <>
                          <Grid item lg={5} md={5}>
                            <Grid container lg={12} md={12} className="my-2">
                              <Grid item lg={6} md={6}>
                                <Typography>Tổng doanh thu dịch vụ (A)</Typography>
                              </Grid>
                              <Grid item lg={6} md={6} className="text-right">
                                <Typography variant="body1">{`${formatPrice(getPrevServiceTotalRevenue())} VND`}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid className="text-center m-auto" item lg={1} md={1}>&rarr;</Grid>
                        </>
                      )
                      : null
                  }
                  <Grid item lg={reservation?.prevState ? 6 : 12} md={reservation?.prevState ? 6 : 12}>
                    <Grid container lg={12} md={12} className="my-2">
                      <Grid item lg={6} md={6}>
                        <Typography>{reservation?.prevState ? 'Tổng doanh thu dịch vụ sau chỉnh sửa (A\'):' : 'Tổng doanh thu dịch vụ'}</Typography>
                        {reservation?.prevState
                          ? <Typography variant="body1">= (B) + (C) + (D)</Typography>
                          : <Typography variant="body1">= (A)</Typography>}
                      </Grid>
                      <Grid item lg={6} md={6} className="text-right">
                        <Typography variant="body1">{`${formatPrice(getCurrentServiceTotalRevenue())} VND`}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )
        : null}
    </Grid>
  )
}

OrderInfo.propTypes = {
  classes: PropTypes.shape(),
  reservation: PropTypes.shape(),
  remainingRoomPrice: PropTypes.shape(),
  remainingServicePrice: PropTypes.number,
}

export default withStyles(styled)(OrderInfo)
