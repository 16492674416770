import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/styles'
import {
  Grid,
  Typography,
  Popover,
  Button,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core'
import { Toc, Search } from '@material-ui/icons'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import * as reportApi from 'provider/report'
import { useSnackbar } from 'notistack'
import TimeRange from 'components/TimeRange'
import moment from 'moment'
import { exportExcelFile } from 'utils/download'
import { formatPrice } from 'utils/formatter'
import { SearchTextInput } from 'components'
import Filters from './Filters'
import GeneralInfo from './GeneralInfo'
import Orders from './List'

const OrderReport = ({
  classes,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [filter, setFilter] = useState({
    startdate: moment().startOf('day').valueOf(),
    enddate: moment().endOf('day').valueOf(),
    sortBy: undefined,
    sortDirection: undefined,
    searchTxt: undefined,
    building: undefined,
    host: undefined,
    room: [],
  })
  const [info, setInfo] = useState({})
  const [list, setList] = useState([])
  const [meta, setMeta] = useState({
    page: 1,
    total: 0,
    limit: 10,
  })
  const [sltColumns, setSltColumns] = useState({
    'Mã đơn': true,
    'Đặt phòng': true,
    'Doanh thu phòng': true,
    'Doanh thu phòng (2home)': true,
    'Doanh thu phòng (Chủ nhà)': true,
    'Doanh thu net (Chủ nhà)': true,
    'Dịch vụ': true,
    'Phí huỷ': true,
    'Phạt cọc': true,
    '2home setup': true,
    'Chi hộ phát sinh': true,
    '2home thuê phòng': true,
    'Giảm giá': true,
  })

  const fetchOrderList = async (query) => {
    try {
      const resp = await reportApi.fetchOrderList({ ...query })
      const {
        data,
        ...rest
      } = resp
      setMeta(rest)
      setList(JSON.parse(JSON.stringify(data)))
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi, không lấy được thông tin doanh thu (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  const fetchOrderAverage = async (query) => {
    try {
      const resp = await reportApi.fetchOrderAverage({ ...query })
      setInfo(resp)
    } catch (e) {
      enqueueSnackbar(`Máy chủ lỗi, không lấy được thông tin doanh thu (code: ${e.response ? e.response.status : 'Unknown'}).`, { variant: 'error' })
    }
  }

  useEffect(() => {
    fetchOrderList({ ...filter })
    fetchOrderAverage({ ...filter })
    return () => {
    }
  }, [])

  const applyFilter = (fltData) => {
    setFilter(fltData)
    fetchOrderList({
      ...fltData,
      ...meta,
      page: 1,
    })
    fetchOrderAverage({
      ...fltData,
      ...meta,
      page: 1,
    })
  }

  const onSearch = (searchData) => {
    setFilter({
      ...filter,
      ...searchData,
    })
    fetchOrderList({
      ...filter,
      ...searchData,
      ...meta,
      page: 1,
    })
    fetchOrderAverage({
      ...filter,
      ...searchData,
      ...meta,
      page: 1,
    })
  }

  const onSortChange = (sortBy, sortDirection) => {
    setFilter({
      ...filter,
      sortBy,
      sortDirection,
    })
    fetchOrderList({
      ...filter,
      sortBy,
      sortDirection,
    })
    fetchOrderAverage({
      ...filter,
      sortBy,
      sortDirection,
    })
  }

  const onPageChange = (page) => {
    setMeta({
      ...meta,
      page,
    })
    fetchOrderList({
      ...filter,
      ...meta,
      page,
    })
    fetchOrderAverage({
      ...filter,
      ...meta,
      page,
    })
  }

  const onRowsPerPageChange = (limit) => {
    setMeta({
      ...meta,
      limit,
    })
    fetchOrderList({
      ...filter,
      ...meta,
      limit,
    })
    fetchOrderAverage({
      ...filter,
      ...meta,
      limit,
    })
  }

  const downloadReportAsExcel = async () => {
    try {
      const resp = await reportApi.fetchOrderList({ ...filter, limit: 10000, page: 1 })
      if (resp.data?.length) {
        exportExcelFile(resp.data?.sort((a, b) => a.checkout - b.checkout)?.map(item => ({
          'Ngày checkout': moment(item.checkout).format('DD/MM/YYYY'),
          'Mã đơn': item.code,
          'Số giờ đặt phòng': item.orderTime,
          'Doanh thu phòng': formatPrice(item?.roomTotalRevenue || 0),
          'Doanh thu phòng (2home)': formatPrice(item?.room2homeRevenue || 0),
          'Doanh thu phòng (Chủ nhà)': formatPrice(item?.roomHostRevenue || 0),
          'Dịch vụ': formatPrice(item?.serviceRevenue || 0),
          'Phí huỷ': formatPrice(item?.rejectRevenue || 0),
          '2home setup': item.setupTime,
          'Chi hộ phát sinh': formatPrice(item?.payOnBehaft || 0),
          '2home thuê phòng': formatPrice(item?.setupFee || 0),
          'Doanh thu net chủ nhà': formatPrice(item?.roomHostNet || 0),
          'Giảm giá': (() => {
            if (!item.coupon) return 0
            if (item.coupon?.creator === 'host') return 0
            switch (item.coupon?.useFor) {
              case 'room':
                if (item.coupon.valueType === 'money') {
                  return formatPrice(item.coupon.value || 0)
                }
                return formatPrice((item.room2homeRevenue + item.roomHostRevenue) * ((item.coupon.value || 0) / 100))
              case 'service':
                if (item.coupon.valueType === 'money') {
                  return formatPrice(item.coupon.value || 0)
                }
                return formatPrice((item.serviceRevenue) * ((item.coupon.value || 0) / 100))
              default:
                return 0
            }
          })(),
        })))
      }
    } catch (e) {
      console.log(e)
      enqueueSnackbar('Đã có lỗi, không download được thông tin doanh thu')
    }
  }

  return (
    <div className={classes.root}>
      {/* Date filter and info */}
      <Grid container spacing={1}>
        {/* Time range */}
        <Grid item md={5} xs={12}>
          <div className="d-flex flex-column border p-2 h-100 w-100" style={{ backgroundColor: 'white' }}>
            <div>
              <Typography variant="h4" className="mb-3">Thống kê đơn đặt phòng </Typography>
              <TimeRange
                value={{ startDate: filter.startdate, endDate: filter.enddate }}
                onApply={searchDate => onSearch({ ...filter, startdate: searchDate.startDate, enddate: searchDate.endDate })}
                maxDate={moment().valueOf()}
              />
            </div>
            <Button variant="outlined" className="mt-auto w-50" onClick={downloadReportAsExcel}>Download Excel</Button>
          </div>
        </Grid>
        {/* Information */}
        <Grid item md={7} xs={12}>
          <div className="border p-2 h-100 w-100" style={{ backgroundColor: 'white' }}>
            <GeneralInfo data={info} />
          </div>
        </Grid>
      </Grid>
      {/* Filter */}
      <Grid container spacing={1} className={`${classes.filter} mt-4`}>
        {/* Cột thông tin */}
        <PopupState variant="popover" popupId="customer-filter">
          {popupState => (
            <div>
              <Button variant="outlined" {...bindTrigger(popupState)} style={{ backgroundColor: 'white', textTransform: 'none' }}>
                <Toc />
                Cột thông tin danh sách
              </Button>
              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Box p={2} style={{ width: 600 }}>
                  <Grid container>
                    {
                      Object.keys(sltColumns).map(item => (
                        <Grid item md={4}>
                          <FormControlLabel
                            control={(
                              <Checkbox
                                checked={sltColumns[item]}
                                onChange={() => {
                                  const clone = { ...sltColumns }
                                  clone[item] = !clone[item]
                                  setSltColumns(clone)
                                }}
                                name={item}
                              />
                          )}
                            label={item}
                          />
                        </Grid>
                      ))
                    }
                  </Grid>
                </Box>
              </Popover>
            </div>
          )}
        </PopupState>
        {/* Toà nhà */}
        <Filters.Building
          value={filter.building}
          onApply={building => applyFilter({ ...filter, building })}
        />
        {/* Chủ nhà */}
        <Filters.Host
          value={filter.host}
          onApply={host => applyFilter({ ...filter, host })}
        />
        {/* Phòng/Căn hộ */}
        <Filters.Room
          value={filter.room}
          building={filter.building}
          host={filter.host}
          onApply={room => applyFilter({ ...filter, room })}
        />
      </Grid>
      {/* Tìm kiếm */}
      <Grid container spacing={1}>
        <Grid item md={4} className="ml-auto">
          <SearchTextInput
            fullWidth
            placeholder="Tìm kiếm theo mã đặt phòng"
            debounceTime={800}
            onChange={searchTxt => onSearch({ ...filter, searchTxt })}
            leftIcon={<Search />}
            size="small"
            style={{ marginBottom: 0 }}
          />
        </Grid>
      </Grid>
      {/* Order table */}
      <Grid container className="mt-4">
        <Grid item md={12} xs={12}>
          <Orders
            setting={sltColumns}
            data={list}
            info={info}
            meta={meta}
            onSortChange={onSortChange}
            onPageChange={onPageChange}
            onRowsPerPageChange={onRowsPerPageChange}
          />
        </Grid>
      </Grid>
    </div>
  )
}

OrderReport.propTypes = {
  classes: PropTypes.shape(),
}

export default withStyles(theme => ({
  root: {
    padding: theme.spacing(3),
  },
  filter: {
    '& > div': {
      margin: theme.spacing(1),
    },
  },
}))(OrderReport)
