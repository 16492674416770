export default {
  label: {
    '@media (max-width:1440px)': {
      fontSize: 10,
    },
  },
  sizeSmall: {
    '@media (max-width:1440px)': {
      height: 20,
    },
  },
}
