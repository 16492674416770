/* eslint-disable no-useless-catch */
import axios from 'axios'
import { RESERVATION_TABLE_LIST } from 'config'
import * as ENDPOINT from './url'

export const find = async (query) => {
  try {
    const resp = await axios.get(ENDPOINT.FETCH_RESERVATION, {
        params: {
          ...query,
          status: query?.status ? (typeof query.status === 'string' ? [query.status] : query.status) : undefined,
          page: query.page || 1,
          limit: query.limit || RESERVATION_TABLE_LIST,
        },
      })
    return resp.data
  } catch (e) {
    throw e
  }
}

export const findOne = async (id) => {
  try {
    const resp = await axios.get(ENDPOINT.FETCH_RESERVATION_BY_ID(id))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const update = async (id, data) => {
  try {
    const resp = await axios.patch(ENDPOINT.UPDATE_RESERVATION(id), { ...data })
    return resp.data
  } catch (e) {
    throw e
  }
}

export const create = async (data) => {
  try {
    const resp = await axios.post(ENDPOINT.CREATE_RESERVATION, data)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const recalculate = async (data) => {
  try {
    const resp = await axios.post(ENDPOINT.RECALCULATE_RESERVATION, data)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const updateStatus = async (id, data) => {
  try {
    const resp = await axios.post(ENDPOINT.UPDATE_RESERVATION_STATUS(id), data)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const pay = async (id, paymentType, paidRequestImages) => {
  try {
    const resp = await axios.post(ENDPOINT.PAY_RESERVATION(id), {
      paymentType,
      paidRequestImages,
    })
    return resp.data
  } catch (e) {
    throw e
  }
}

export const reject = async (id, rejectAs, data) => {
  try {
    const resp = await axios.post(ENDPOINT.REJECT_RESERVATION(id), {
      rejectAs,
      ...data,
    })
    return resp.data
  } catch (e) {
    throw e
  }
}

export const confirm = async (id) => {
  try {
    const resp = await axios.post(ENDPOINT.CONFIRM_RESERVATION(id))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const undo = async (id) => {
  try {
    const resp = await axios.post(ENDPOINT.UNDO_RESERVATION(id))
    return resp.data
  } catch (e) {
    throw e
  }
}

export const warning = async (id, mode, data) => {
  try {
    const resp = await axios.get(ENDPOINT.WARNING(id, mode), {
      params: { ...data, isAdmin: true } || {},
    })
    return resp.data
  } catch (e) {
    throw e
  }
}

export const refund = async (id, bankNote) => {
  try {
    const resp = await axios.post(ENDPOINT.REFUND_RESERVATION(id), { bankNote })
    return resp.data
  } catch (e) {
    throw e
  }
}

export const addCheckout = async (id, data) => {
  try {
    const resp = await axios.post(ENDPOINT.ADD_CHECKOUT(id), data)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const rejectCheckout = async (id) => {
  try {
    const resp = await axios.post(ENDPOINT.REJECT_CHECKOUT(id), {})
    return resp.data
  } catch (e) {
    throw e
  }
}

export const confirmCheckoutPoint = async (id) => {
  try {
    const resp = await axios.post(ENDPOINT.CONFIRM_CHECKOUT_POINT(id), {})
    return resp.data
  } catch (e) {
    throw e
  }
}

export const payCheckoutPoint = async (id, paymentInfo) => {
  try {
    const resp = await axios.post(ENDPOINT.PAY_CHECKOUT_POINT(id), paymentInfo)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const getRemainingRoomPrice = async ({ id, ...data }) => {
  try {
    const resp = await axios.get(ENDPOINT.GET_REMAINING_ROOM_PRICE(id), {
      params: data,
    })
    return resp.data
  } catch (e) {
    throw e.response ? e.response.data : 'Lỗi không xác định'
  }
}

export const getRemainingServicePrice = async (orderId) => {
  try {
    const resp = await axios.get(ENDPOINT.GET_REMAINING_SERVICE_PRICE(orderId))
    return resp.data
  } catch (e) {
    throw e.response ? e.response.data : 'Lỗi không xác định'
  }
}

export const getRevenueInfo = async (id) => {
  try {
    const resp = await axios.get(ENDPOINT.GET_REVENUE_INFO(id))
    return resp.data
  } catch (e) {
    throw e.response ? e.response.data : 'Lỗi không xác định'
  }
}

export const punishDepositByHost = async (id, info) => {
  try {
    const resp = await axios.post(ENDPOINT.HOST_PUNISH_DEPOSIT_RESERVATION(id), info)
    return resp.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const punishDepositByCustomer = async (id, info) => {
  try {
    const resp = await axios.post(ENDPOINT.CUSTOMER_PUNISH_DEPOSIT_RESERVATION(id), info)
    return resp.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const agreePunishDeposit = async (id, isAgree) => {
  try {
    const resp = await axios.post(ENDPOINT.AGREE_PUNISH_DEPOSIT(id), { isAgree })
    return resp.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const handlePunishDeposit = async (id, info) => {
  try {
    const resp = await axios.post(ENDPOINT.CONFIRM_PUNISH_DEPOSIT_RESERVATION(id), info)
    return resp.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const releaseDeposit = async (id) => {
  try {
    const resp = await axios.post(ENDPOINT.RELEASE_DEPOSIT(id), {})
    return resp.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const provideCustomerRefundInfo = async (id, data) => {
  try {
    const resp = await axios.post(ENDPOINT.PROVIDE_CUSTOMER_REFUND_INFO(id), data)
    return resp.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const provideCustomerDepositInfo = async (id, data) => {
  try {
    const resp = await axios.post(ENDPOINT.PROVIDE_CUSTOMER_DEPOSIT_INFO(id), data)
    return resp.data
  } catch (e) {
    console.log(e)
    throw e
  }
}

export const sendEmail = async (id) => {
  try {
    const resp = await axios.get(ENDPOINT.SEND_ORDER_EMAIL(id))
    return resp.data
  } catch (e) {
    console.log(e)
    throw e
  }
}
