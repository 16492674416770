import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { NavLink as RouterLink } from 'react-router-dom'
import { withStyles, Fab } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { applyFiltersToUrl, getFiltersFromUrl } from 'utils/url'
import Table from './Table'
import Toolbar from './Toolbar'
import styles from './styled'

const HostList = ({
  classes,
  host,
  find,
  location,
  history,
}) => {
  const updateUrl = (query) => {
    const url = `${location.pathname}${applyFiltersToUrl(query)}`
    history.push(url, { replace: false })
  }

  useEffect(() => {
    const query = getFiltersFromUrl(location.search)
    find({ ...query, active: query.active || 1 })
    return () => {}
    // eslint-disable-next-line
  }, [location.search])

  const applyFilter = (fltData) => {
    const query = { ...host.query, ...fltData, page: 1 }
    updateUrl(query)
  }

  const changePage = (page) => {
    const query = { ...host.query, page }
    updateUrl(query)
  }

  const changeRowsPerPage = (limit) => {
    const query = { ...host.query, page: 1, limit }
    updateUrl(query)
  }

  const sort = (sortBy, sortDirection) => {
    const query = { ...host.query, sortBy, sortDirection }
    updateUrl(query)
  }

  const update = (id) => {
    history.push(`/host/${id}/update`)
  }

  return (
    <div className={classes.root}>
      <Toolbar
        classes={classes}
        applyFilter={applyFilter}
        location={location}
      />
      <div className={classes.content}>
        <Table
          classes={classes}
          location={location}
          loading={host.loading}
          hosts={host.data}
          meta={host.meta}
          defaultSort={
            host.query
              ? {
                sortBy: host.query.sortBy,
                sortDirection: host.query.sortDirection,
              }
              : {}
          }
          onPageChange={changePage}
          onRowsPerPageChange={changeRowsPerPage}
          onSortChange={sort}
          find={find}
          update={update}
        />
      </div>
      <RouterLink to="/host/create">
        <Fab color="primary" aria-label="add" className={classes.fab}>
          <AddIcon />
        </Fab>
      </RouterLink>
    </div>
  )
}

HostList.propTypes = {
  classes: PropTypes.shape(),
  host: PropTypes.arrayOf(PropTypes.shape()),
  find: PropTypes.func,
  history: PropTypes.shape(),
  location: PropTypes.shape(),
}

export default withStyles(styles)(HostList)
