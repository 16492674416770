import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  withStyles,
  TextField,
  Icon,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import { copy } from 'utils/service'
import { useSnackbar } from 'notistack'
import { formatPrice } from 'utils/formatter'
import styled from './styled'
import BlockUI from 'components/BlockUI'

const RefundDialog = ({
  classes,
  reservation,
  open,
  onClose,
  onConfirm,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [refundBankNote, setRefundBankNote] = useState(undefined)
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    if (open) {
      setRefundBankNote(undefined)
    }
    return () => {
    }
  }, [open])

  const getRefundTotal = (order) => {
    if (!order) return null
    const {
      coupon,
      roomPrice: orderRoomPrice,
      servicePrice,
      refundRoom,
      refundService,
      rejectRoomRevenue,
      rejectServiceRevenue,
      depositAmount,
    } = order
    let refundRoomTotal = refundRoom
    let refundServiceTotal = refundService
    switch (coupon?.valueType) {
      case 'money':
        if (coupon.useFor === 'room') {
          refundRoomTotal -= coupon.value * (refundRoom / orderRoomPrice)
        } else {
          refundServiceTotal -= coupon.value * (servicePrice ? refundService / servicePrice : 0)
        }
        break
      case 'percent':
        if (coupon.useFor === 'room') {
          refundRoomTotal -= refundRoomTotal * (((coupon.value) / 100) * (refundRoom / orderRoomPrice))
        } else {
          refundServiceTotal -= refundServiceTotal * (((coupon.value) / 100) * (servicePrice ? refundService / servicePrice : 0))
        }
        break
      default:
        break
    }
    return `Số tiền hoàn: ${formatPrice(Math.floor((refundRoomTotal * (100 - rejectRoomRevenue) / 100) + (refundServiceTotal * (100 - rejectServiceRevenue) / 100)) + depositAmount)}VND`
  }

  const refundReservation = async () => {
    setIsBusy(true)

    try {
      await onConfirm(refundBankNote)
    } finally {
      setIsBusy(false)
    }
  }

  // Cân nhắc đang cho phép huỷ đơn đã hoàn thành và đang sử dụng
  return (
    <Dialog open={open} onClose={onClose}>
      <BlockUI isLoading={isBusy}>
      <DialogTitle disableTypography>
        <h3>Hoàn tiền đơn đặt phòng</h3>
      </DialogTitle>
      <DialogContent style={{ minWidth: 600, minHeight: 80, paddingTop: 0 }}>
        <div className="mb-2">
          Xác nhận hoàn tiền cho đơn đặt phòng
          {' '}
          <b>{reservation?.code}</b>
        </div>
        <div>{`Khách yêu cầu hoàn tiền tới tài khoản ${reservation?.refundPaymentType === '2home' ? '2home' : 'ngân hàng'}`}</div>
        {
        reservation?.refundPaymentType === 'bank-transfer'
          ? (
            <>
              <div>{`Chủ tài khoản: ${reservation.refundUser}`}</div>
              <div>
                {`Số tài khoản: ${reservation.refundBankAccount}`}
                <Button
                  onClick={() => copy(`${reservation.refundBankAccount}`, () => enqueueSnackbar('Copy số tài khoản khách hàng', { variant: 'success' }))}
                >
                  <Icon
                    fontSize="small"
                    className="far fa-clone"
                    style={{ cursor: 'pointer' }}
                  />
                </Button>
              </div>
              <div className="mb-2">{`Ngân hàng: ${reservation.refundBankName}`}</div>
            </>
          )
          : null
        }
        <div className="my-2">{getRefundTotal(reservation)}</div>
        <TextField
          label="Mã bút toán"
          variant="outlined"
          fullWidth
          value={refundBankNote}
          onChange={e => setRefundBankNote(e.target.value)}
          className={classes.formControl}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={refundReservation} color="primary" disabled={reservation?.refundPaymentType === 'bank-transfer' && !refundBankNote}>
        Xác nhận
        </Button>
      </DialogActions>
      </BlockUI>
    </Dialog>
  )
}

RefundDialog.propTypes = {
  classes: PropTypes.shape(),
  reservation: PropTypes.shape(),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default withStyles(styled)(RefundDialog)
