import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { applyFiltersToUrl, getFiltersFromUrl } from 'utils/url'
import { Fab, withStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import Table from './Table'
import Toolbar from './Toolbar'
import styles from './styled'

const PrepaidList = ({
  classes,
  prepaidHistory,
  location,
  history,
  update,
  find,
}) => {
  useEffect(() => {
    const query = getFiltersFromUrl(location.search)
    find(query)
    return () => {}
    // eslint-disable-next-line
  }, [])

  const updateUrl = (query) => {
    if (query.code) {
      // eslint-disable-next-line no-param-reassign
      delete query.code
    }
    const url = `${location.pathname}${applyFiltersToUrl(query)}`
    history.push(url, { replace: false })
  }

  const applyFilter = (fltData) => {
    const query = { ...prepaidHistory.query, ...fltData }
    updateUrl(query)
    find(query)
  }

  const changePage = (page) => {
    const query = { ...prepaidHistory.query, page }
    updateUrl(query)
    find(query)
  }

  const changeRowsPerPage = (limit) => {
    const query = { ...prepaidHistory.query, page: 1, limit }
    updateUrl(query)
    find(query)
  }

  return (
    <div className={classes.root}>
      <Toolbar
        classes={classes}
        applyFilter={applyFilter}
        location={location}
      />
      <div className={classes.content}>
        <Table
          classes={classes}
          prepaidHistory={prepaidHistory.data}
          meta={prepaidHistory.meta}
          find={find}
          update={update}
          onPageChange={changePage}
          onRowsPerPageChange={changeRowsPerPage}
        />
      </div>
      <Fab color="primary" aria-label="add" className={classes.fab}>
        <AddIcon />
      </Fab>
    </div>
  )
}

PrepaidList.propTypes = {
  classes: PropTypes.shape(),
  prepaidHistory: PropTypes.arrayOf(PropTypes.shape()),
  find: PropTypes.func,
  update: PropTypes.func,
  location: PropTypes.shape(),
  history: PropTypes.shape(),
}

export default withStyles(styles)(PrepaidList)
