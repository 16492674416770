import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getFiltersFromUrl } from 'utils/url'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { SearchTextInput } from 'components'
import {
  Grid,
  Typography,
  Button,
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import Filter from './Filters'

const useStyles = makeStyles(theme => ({
  root: {},
  filter: {
    '& > div': {
      margin: theme.spacing(1),
    },
  },
}))

const UsersToolbar = (props) => {
  const {
    className,
    location,
    applyFilter,
    ...rest
  } = props
  const classes = useStyles()
  const [fltData, setFltData] = useState({})

  useEffect(() => {
    const query = getFiltersFromUrl(location.search)
    if (!query.active) {
      query.active = 1
    } else {
      // eslint-disable-next-line no-restricted-globals
      query.active = !isNaN(query.active) ? Number(query.active) : 1
    }
    setFltData(query)
    return () => {}
  }, [location.search])

  const filter = (data) => {
    setFltData(data)
    const {
      active,
      searchTxt,
      startdate,
      enddate,
      role,
    } = data
    applyFilter({
      active,
      searchTxt,
      startdate,
      enddate,
      role,
    })
  }

  const onSearch = async (textStr) => {
    const query = getFiltersFromUrl(location.search)
    filter({ ...query, searchTxt: textStr })
  }

  const groupByStatus = (data) => {
    filter(data)
  }

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Grid container spacing={1}>
        <Typography variant="h3" gutterBottom>Danh sách nhân viên</Typography>
      </Grid>
      <Grid container spacing={1} className={classes.filter}>
        {/* Role */}
        <Grid item>
          <Typography variant="body2">Chọn quyền hạn</Typography>
          <Filter.Role
            value={fltData.role}
            onApply={role => filter({ ...fltData, role })}
          />
        </Grid>
        {/* Date */}
        <Grid item>
          <Typography variant="body2">Chọn ngày tạo</Typography>
          <Filter.Date
            startdate={Number(fltData.startdate)}
            enddate={Number(fltData.enddate)}
            onApply={({ startdate, enddate }) => filter({ ...fltData, startdate, enddate })}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item lg={2} style={{ marginTop: 'auto' }}>
          <Button
            fullWidth
            size="small"
            variant={`${fltData.active === 1 ? 'contained' : 'default'}`}
            color={`${fltData.active === 1 ? 'primary' : 'default'}`}
            onClick={() => groupByStatus({ ...fltData, active: 1 })}
          >
            Hoạt động
          </Button>
        </Grid>
        <Grid item lg={2} style={{ marginTop: 'auto' }}>
          <Button
            fullWidth
            size="small"
            variant={`${fltData.active === 0 ? 'contained' : 'default'}`}
            color={`${fltData.active === 0 ? 'primary' : 'default'}`}
            onClick={() => groupByStatus({ ...fltData, active: 0 })}
          >
            Dừng hoạt động
          </Button>
        </Grid>
        <Grid item lg={3} md={4} style={{ marginLeft: 'auto' }}>
          <SearchTextInput
            size="small"
            fullWidth
            className={classes.searchInput}
            placeholder="Tìm kiếm (theo mã, tên, địa chỉ, ...)"
            initValue={getFiltersFromUrl(location.search)?.searchTxt}
            debounceTime={800}
            onChange={str => onSearch(str)}
            leftIcon={<Search />}
            style={{ marginBottom: 0 }}
          />
        </Grid>
      </Grid>
    </div>
  )
}

UsersToolbar.propTypes = {
  className: PropTypes.string,
  applyFilter: PropTypes.func,
  location: PropTypes.shape(),
}

export default UsersToolbar
