import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  withStyles,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import Upload from 'components/Upload'
import styled from './styled'
import BlockUI from 'components/BlockUI'

const PaymentDialog = ({
  // classes,
  reservation,
  open,
  onClose,
  onConfirm,
}) => {
  const [paymentType, setPaymentType] = useState('2home')
  const [paidRequestImage, setPaidRequestImage] = useState([])
  const [isBusy, setIsBusy] = useState(false)

  useEffect(() => {
    if (open) {
      setPaymentType('2home')
      setPaidRequestImage([])
      if (reservation?.customer?.email === 'customer@2home.vn') {
        setPaymentType('bank-transfer')
      }
    }
    return () => {
    }
  }, [open])

  const payReservation = async () => {
    setIsBusy(true)

    try {
      await onConfirm(paymentType, paidRequestImage)
    } finally {
      setIsBusy(false)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setPaymentType('2home')
        onClose()
      }}
    >
      <BlockUI isLoading={isBusy}>
      <DialogTitle disableTypography>
        <h3>Thanh toán đơn đặt phòng</h3>
      </DialogTitle>
      <DialogContent style={{ minWidth: 600, minHeight: 80 }}>
        <Grid container spacing={3}>
          {/* Thanh toán bằng tk 2home */}
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              color={paymentType === '2home' ? 'primary' : 'default'}
              onClick={() => setPaymentType('2home')}
            >
                Tài khoản 2home
            </Button>
          </Grid>
          {/* Thanh toán bằng tk ngân hàng */}
          <Grid item xs={6}>
            <Button
              fullWidth
              variant="contained"
              color={paymentType === 'bank-transfer' ? 'primary' : 'default'}
              onClick={() => setPaymentType('bank-transfer')}
            >
                Tài khoản ngân hàng
            </Button>
          </Grid>
        </Grid>
        {paymentType === '2home'
          ? (
            <Grid container className="mt-4">
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Upload files={paidRequestImage} multiple onChange={files => setPaidRequestImage(files)} />
              </Grid>
            </Grid>
          )
          : null}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={payReservation}
          color="primary"
          disabled={!paymentType}
        >
            Thanh toán
        </Button>
      </DialogActions>
      </BlockUI>
    </Dialog>
  )
}

PaymentDialog.propTypes = {
  // classes: PropTypes.shape(),
  reservation: PropTypes.shape(),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
}

export default withStyles(styled)(PaymentDialog)
