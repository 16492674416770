export default {
  root: {
    '@media (max-width:1280px)': {
      fontSize: '1.2rem',
    },
    '@media (min-width:1281px) and (max-width:1440px)': {
      fontSize: '1.3rem',
    },
  },
}
