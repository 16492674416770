import axios from 'axios'
import * as ENDPOINT from './url'

export const find = async () => {
  try {
    const resp = await axios.get(ENDPOINT.AMENITY())
    return resp.data
  } catch (e) {
    throw e
  }
}

export const create = async (data) => {
  try {
    const resp = await axios.post(ENDPOINT.AMENITY(), data)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const update = async (data) => {
  const {
    id,
    ...rest
  } = data
  try {
    const resp = await axios.patch(ENDPOINT.AMENITY(id), rest)
    return resp.data
  } catch (e) {
    throw e
  }
}

export const remove = async (id) => {
  try {
    const resp = await axios.delete(ENDPOINT.AMENITY(id))
    return resp.data
  } catch (e) {
    throw e
  }
}
