import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  Radio,
  TextField,
  Typography,
} from '@material-ui/core'
import CurrencyInput from 'components/CurrencyInput'
import { formatPrice } from 'utils/formatter'

const Promotion = ({
  classes,
  errors,
  touched,
  values,
  handleChange,
  setFieldValue,
}) => {
  const [rate, setRate] = useState(1.25)

  useEffect(() => {
    setRate(1 / ((100 - values.commission) / 100))
  }, [values.commission])

  return (
    <>
      <div className={classes.subTitle}>Ưu đãi:</div>
      <Grid container item lg={8} md={8} className={`${classes.rowAlign} ${classes.priceBox}`}>
        {/* Ưu đãi theo tuần */}
        <Grid item lg={6} md={6} className="pr-2" style={{ borderRight: 'solid 1px #a1a1a1' }}>
          <Typography className="font-weight-bold">Theo tuần</Typography>
          <Grid container item lg={12} md={12}>
            <Grid item lg={6} md={6}>
              <Radio
                size="small"
                name="discountWeekType"
                checked={values.discountWeekType === 'percent'}
                onChange={() => setFieldValue('discountWeekType', 'percent')}
              />
              <span className="mr-2">Giảm giá theo %</span>
            </Grid>
            <Grid item lg={6} md={6}>
              <Radio
                size="small"
                name="discountWeekType"
                checked={values.discountWeekType === 'money'}
                onChange={() => setFieldValue('discountWeekType', 'money')}
              />
              <span className="mr-2">Giảm giá theo ngày</span>
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} className="mt-2">
            {
              values.discountWeekType === 'percent'
                ? (
                  <TextField
                    type="number"
                    style={{ backgroundColor: 'white' }}
                    label="Giảm giá nếu khách hàng đặt 7 ngày trở lên (%)"
                    variant="outlined"
                    name="discountWeek"
                    fullWidth
                    value={values.discountWeek}
                    onChange={handleChange}
                    error={errors.discountWeek && touched.discountWeek}
                    helperText={errors.discountWeek && touched.discountWeek && errors.discountWeek}
                    size="small"
                    placeholder="Nhập % giảm giá"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )
                : (
                  <>
                    <CurrencyInput
                      style={{ backgroundColor: 'white' }}
                      label="Giá ưu đãi ngày"
                      variant="outlined"
                      name="discountWeek"
                      fullWidth
                      value={values.discountWeek / (values.has2homeFee ? 1 : rate)}
                      onChange={(value) => {
                        if (!value) {
                          setFieldValue('discountWeek', '')
                          return
                        }
                        const discountWeek = value * (values.has2homeFee ? 1 : rate)
                        setFieldValue('discountWeek', discountWeek)
                      }}
                      error={errors.discountWeek && touched.discountWeek}
                      helperText={errors.discountWeek}
                      size="small"
                      placeholder="Nhập số tiền"
                    />
                    {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(values.discountWeek)} VND`}</Typography> : null}
                  </>
                )
            }
          </Grid>
        </Grid>
        {/* Ưu đãi theo tháng */}
        <Grid item lg={6} md={6} className="pl-2">
          <Typography className="font-weight-bold">Theo tháng</Typography>
          <Grid container item lg={12} md={12}>
            <Grid item lg={6} md={6}>
              <Radio
                size="small"
                name="discountMonthType"
                checked={values.discountMonthType === 'percent'}
                onChange={() => setFieldValue('discountMonthType', 'percent')}
              />
              <span className="mr-2">Giảm giá theo %</span>
            </Grid>
            <Grid item lg={6} md={6}>
              <Radio
                size="small"
                name="discountMonthType"
                checked={values.discountMonthType === 'money'}
                onChange={() => setFieldValue('discountMonthType', 'money')}
              />
              <span className="mr-2">Giảm giá theo ngày</span>
            </Grid>
          </Grid>
          <Grid container item lg={12} md={12} className="mt-2">
            {
              values.discountMonthType === 'percent'
                ? (
                  <TextField
                    type="number"
                    style={{ backgroundColor: 'white' }}
                    label="Giảm giá nếu khách hàng đặt 30 ngày trở lên (%)"
                    variant="outlined"
                    name="discountMonth"
                    fullWidth
                    value={values.discountMonth}
                    onChange={handleChange}
                    error={errors.discountMonth && touched.discountMonth}
                    helperText={errors.discountMonth && touched.discountMonth && errors.discountMonth}
                    size="small"
                    placeholder="Nhập % giảm giá"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )
                : (
                  <>
                    <CurrencyInput
                      style={{ backgroundColor: 'white' }}
                      label="Giá ưu đãi ngày"
                      variant="outlined"
                      name="discountMonth"
                      fullWidth
                      value={values.discountMonth / (values.has2homeFee ? 1 : rate)}
                      onChange={(value) => {
                        if (!value) {
                          setFieldValue('discountMonth', '')
                          return
                        }
                        const discountMonth = value * (values.has2homeFee ? 1 : rate)
                        setFieldValue('discountMonth', discountMonth)
                      }}
                      error={errors.discountMonth && touched.discountMonth}
                      helperText={errors.discountMonth}
                      size="small"
                      placeholder="Nhập số tiền"
                    />
                    {!values.has2homeFee ? <Typography variant="body2">{`Giá 2home: ${formatPrice(values.discountMonth)} VND`}</Typography> : null}
                  </>
                )
        }
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

Promotion.propTypes = {
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
}

export default Promotion
