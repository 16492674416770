import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { getFiltersFromUrl } from 'utils/url'
import {
  Grid,
  Typography,
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { SearchTextInput } from 'components'
import Filter from './Filters'

// ['pending', 'paid', 'reject']
const STATUS = [
  {
    value: 'pending',
    label: 'Chờ thanh toán',
  },
  {
    value: 'paid',
    label: 'Đã thanh toán',
  },
  {
    value: 'reject',
    label: 'Đã huỷ',
  },
]

const UsersToolbar = ({
  classes,
  applyFilter,
  location,
}) => {
  const [fltData, setFltData] = useState({})

  useEffect(() => {
    const query = getFiltersFromUrl(location.search)
    if (!query.active) {
      query.active = 0
    } else {
      // eslint-disable-next-line no-restricted-globals
      query.active = !isNaN(query.active) ? Number(query.active) : 0
    }
    setFltData(query || {})
    return () => {}
  }, [])

  const filter = (data) => {
    setFltData(data)
    const {
      searchTxt,
      status,
      startdate,
      enddate,
      prepaidPackage,
      customer,
    } = data
    applyFilter({
      searchTxt,
      status,
      startdate: startdate || undefined,
      enddate: enddate || undefined,
      prepaidPackage,
      customer,
    })
  }

  const onSearch = async (data) => {
    filter(data)
  }

  return (
    <>
      <Grid container spacing={1}>
        <Typography variant="h3" gutterBottom>Lịch sử mua gói trả trước</Typography>
      </Grid>
      <Grid container spacing={1} className={classes.filter}>
        {/* Customer */}
        <Grid item>
          <Typography variant="body2">Khách hàng</Typography>
          <Filter.Customer
            value={fltData.customer}
            onApply={customer => filter({ ...fltData, customer })}
          />
        </Grid>
        {/* Package */}
        <Grid item>
          <Typography variant="body2">Kiểu gói</Typography>
          <Filter.PrepaidPackage
            value={fltData.prepaidPackage}
            onApply={prepaidPackage => filter({ ...fltData, prepaidPackage })}
          />
        </Grid>
        {/* Status */}
        <Grid item>
          <Typography variant="body2">Trạng thái</Typography>
          <Filter.Status
            data={STATUS}
            value={fltData.status}
            onApply={status => filter({ ...fltData, status })}
          />
        </Grid>
        {/* Date */}
        <Grid item>
          <Typography variant="body2">Ngày mua</Typography>
          <Filter.BookingDate
            startdate={Number(fltData?.startdate)}
            enddate={Number(fltData?.enddate)}
            onApply={({ startdate, enddate }) => filter({ ...fltData, startdate, enddate })}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item lg={3} md={4} style={{ marginLeft: 'auto' }}>
          <SearchTextInput
            size="small"
            fullWidth
            className={classes.searchInput}
            placeholder="Tìm kiếm (theo mã, theo khách hàng, theo phòng, ...)"
            initValue={fltData.searchTxt}
            debounceTime={800}
            onChange={searchTxt => onSearch({ ...fltData, searchTxt })}
            leftIcon={<Search />}
            style={{ margin: 0 }}
          />
        </Grid>
      </Grid>
    </>
  )
}

UsersToolbar.propTypes = {
  classes: PropTypes.shape(),
  applyFilter: PropTypes.func,
  location: PropTypes.shape(),
}

export default UsersToolbar
