import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import debounce from 'lodash/debounce'
import { makeStyles } from '@material-ui/styles'
import { TextField, InputAdornment } from '@material-ui/core'

const useStyles = makeStyles({
  root: {
    // minWidth: 300,
  },
})

const SearchTextInput = ({
  className,
  initValue,
  fullWidth,
  debounceTime,
  onChange,
  label,
  placeholder,
  leftIcon,
  style,
  ...rest
}) => {
  const classes = useStyles()
  const onInputChange = debounce(str => onChange(str), debounceTime || 800)

  return (
    <div className={clsx(classes.root, className)}>
      <TextField
        style={{ backgroundColor: 'white', ...style }}
        fullWidth={fullWidth || false}
        placeholder={placeholder}
        label={label || 'Tìm kiếm'}
        margin="normal"
        variant="outlined"
        defaultValue={initValue}
        onChange={e => onInputChange(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              {leftIcon}
            </InputAdornment>
          ),
        }}
        {...rest}
      />
    </div>
  )
}

SearchTextInput.propTypes = {
  className: PropTypes.string,
  initValue: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  fullWidth: PropTypes.bool,
  debounceTime: PropTypes.number,
  leftIcon: PropTypes.element,
  style: PropTypes.shape(),
}

export default SearchTextInput
