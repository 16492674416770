import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state'
import { Apartment, Close, Remove } from '@material-ui/icons'
import { SearchSelectionInput } from 'components'
import * as commonApi from 'provider/common'
import * as roomApi from 'provider/room'
import { Grid, Typography } from '@material-ui/core'

const Room = ({
  values,
  // touched,
  // errors,
  // handleChange,
  setFieldValue,
}) => {
  const [sltRoom, setSltRoom] = useState({})
  const [roomSuggestion, setRoomSuggestion] = useState([])
  const [rooms, setRooms] = useState([])

  const fetchRoom = async (ids) => {
    const resp = await roomApi.find({ ids })
    setRooms(
      resp.data.map(opt => ({
        id: opt.id,
        title: `${opt.name} (${opt.code})`,
        subTitle: `${opt.address}`,
      })),
    )
  }

  useEffect(() => {
    try {
      if (!values.rooms) {
        setRooms([])
        return () => {}
      }
      const ids = values.rooms
      if (ids && ids.length) {
        fetchRoom(JSON.stringify(ids))
      }
    } catch (e) {
      setRooms([])
      setFieldValue('rooms', [])
    }
    return () => {}
  }, [values.rooms])

  const onRoomChange = async (str) => {
    if (!str) return
    const suggestions = await commonApi.getRoomSuggestion(str)
    setRoomSuggestion(
      suggestions.map(opt => ({
        id: opt.id,
        title: `${opt.display} (${opt.code})`,
        subTitle: `${opt.address}`,
      })),
    )
  }

  const onRoomSelect = async (item) => {
    if (!item) return
    setSltRoom(item)
    if (!rooms.find(c => item && c.id === item.id)) {
      setRooms([...rooms].concat(item))
    }
  }

  const renderRoomOption = (option) => {
    const { title, subTitle } = option
    return (
      <div>
        <div style={{ fontSize: 16 }}>
          <strong>{title}</strong>
        </div>
        <div>
          <small>{subTitle}</small>
        </div>
      </div>
    )
  }

  return (
    <PopupState variant="popover" popupId="coupon-room-apply">
      {popupState => (
        <div className="w-100">
          <Button className="w-100" variant="outlined" {...bindTrigger(popupState)} style={{ backgroundColor: 'white', textTransform: 'none' }}>
            <Apartment className="mr-2" />
            {rooms.filter(c => values && (values.rooms || []).includes(c.id)).length
              ? `Áp dụng cho ${rooms.filter(c => values && (values.rooms || []).includes(c.id)).length} phòng`
              : 'Áp dụng cho toàn bộ phòng'}
            {values && (values.rooms || []).length
              ? (
                <Close onClick={(e) => {
                  e.stopPropagation()
                  setFieldValue('rooms', [])
                }}
                />
              )
              : null}
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Box p={2} style={{ width: 400 }}>
              <SearchSelectionInput
                label="Phòng"
                placeholder="Nhập thông tin phòng"
                value={sltRoom.title}
                renderOption={renderRoomOption}
                suggestions={roomSuggestion}
                onChange={str => onRoomChange(str)}
                onSelect={onRoomSelect}
              />
              {/* Selected rooms */}
              <div>
                {rooms.length ? <Typography>Danh sách phòng áp dụng:</Typography> : ''}
                {
                  rooms.map((c, i) => (
                    <Grid container>
                      <Typography variant="body1" style={{ fontWeight: 500 }}>{`${i + 1}. ${c.title}`}</Typography>
                      <Remove style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={() => setRooms([...rooms].filter(item => item.id !== c.id))} />
                    </Grid>
                  ))
                }
              </div>
              <Button
                onClick={() => {
                  setFieldValue('rooms', rooms?.map(c => c.id) || [])
                  popupState.close()
                }}
                style={{ float: 'right', marginBottom: 7 }}
              >
              Áp dụng
              </Button>
            </Box>
          </Popover>
        </div>
      )}
    </PopupState>
  )
}

Room.propTypes = {
  values: PropTypes.shape(),
  setFieldValue: PropTypes.func,
}

export default Room
