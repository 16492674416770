import { COLORS } from 'theme/common'

const style = {
  container: {
    display: 'flex',
    padding: 0,
    '&>div': {
      paddingTop: 0,
    },
    '& input.MuiInputBase-input': {
      paddingRight: 0,
    },
  },
  formControl: {
    margin: 0,
    width: '100%',
    position: 'relative',
    verticalAlign: 'unset',
    '& input': {
      paddingRight: 20,
    },
    '& svg': {
      top: 'auto',
      bottom: 4,
      right: 24,
    },
  },
  customDateRangePicker: {
    '& .rdrDateDisplayWrapper': {
      display: 'none !important',
    },
    '& .rdrMonth': {
      padding: '0 20px 20px',
    },
    '& .rdrMonthName': {
      fontSize: 14,
      color: COLORS.palette.primaryBlue,
      textAlign: 'center',
      fontWeight: 'bold',
      padding: '19px 10px 15px',
    },
    '& .rdrWeekDay': {
      fontSize: 13,
      color: COLORS.gray.darkGray,
      textTransform: 'uppercase',
      borderBottomWidth: 1,
      borderBottomColor: '#EAF0F4',
      borderBottomStyle: 'solid',
    },
    '& .rdrDayNumber': {
      zIndex: 9,
      color: COLORS.gray.textGray2,
      fontWeight: 500,
    },
    '& .rdrDayNumber span': {
    },
    '& .rdrDayPassive .rdrDayNumber span': {
      color: '#C2C2C2',
    },
    '& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span, & .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span, & .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span, & .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span': {
      color: COLORS.palette.primaryBlue,
    },
    '& .rdrSelected, & .rdrInRange, & .rdrStartEdge, & .rdrEndEdge': {
      background: COLORS.palette.secondaryBlue3,
    },
    '& .rdrStartEdge, & .rdrEndEdge': {
      background: 'transparent',
      zIndex: 6,

      '&:before': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        display: 'inline-block',
        width: '50%',
        height: '100%',
        background: COLORS.palette.secondaryBlue3,
      },

      '&:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'inline-block',
        width: 25,
        height: 25,
        background: '#91B1EA',
        borderRadius: '50%',
      },

      '& ~.rdrDayNumber span': {
        color: `${COLORS.palette.primaryBlue} !important`,
      },

    },
    '& .rdrStartEdge': {
      '&:before': {
        left: '50% !important',
      },

      '&.rdrEndEdge': {
        '&:before': {
          display: 'none',
        },
      },
    },
    '& .rdrDayStartOfWeek .rdrEndEdge:before': {
      borderTopLeftRadius: ' 1.042em',
      borderBottomLeftRadius: ' 1.042em',
    },
    '& .rdrDayEndOfWeek .rdrStartEdge:before': {
      borderTopRightRadius: '1.042em',
      borderBottomRightRadius: ' 1.042em',
    },
    '& .rdrDayStartOfWeek .rdrDayEndPreview:before': {
      borderTopLeftRadius: ' 1.042em',
      borderBottomLeftRadius: ' 1.042em',
    },
    '& .rdrDayEndOfWeek .rdrDayStartPreview:before': {
      borderTopRightRadius: '1.042em',
      borderBottomRightRadius: ' 1.042em',
    },

    '& .rdrDayInPreview': {
      border: 'none',
      background: COLORS.palette.secondaryBlue3,
      zIndex: 3,
    },
    '& .rdrDayStartPreview, .rdrDayEndPreview': {
      border: 'none',

      '&:before': {
        content: '""',
        position: 'absolute',
        top: '0',
        left: '0',
        display: 'inline-block',
        width: '50%',
        height: '100%',
        background: COLORS.palette.secondaryBlue3,
      },

      '&:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'inline-block',
        width: 25,
        height: 25,
        background: '#91B1EA',
        borderRadius: '50%',
      },

      '& ~.rdrDayNumber span': {
        color: `${COLORS.palette.primaryBlue} !important`,
      },
    },
    '& .rdrDayStartPreview': {
      '&:before': {
        left: '50% !important',
      },

      '& .rdrDayNumber span': {
        color: `${COLORS.palette.primaryBlue} !important`,
      },

      '&.rdrDayEndPreview': {
        '&:before': {
          display: 'none',
        },
      },
    },
    '& .rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after, & .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after, & .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after, & .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after': {
      background: COLORS.palette.primaryBlue,
    },
    '& .rdrDayToday .rdrDayNumber span:after': {
      background: COLORS.gray.textGray,
    },
    '& .rdrMonthAndYearPickers': {
      display: 'none',
    },

    '& .rdrCalendarWrapper': {
      position: 'relative',
      padding: '0 10px',
    },
    '& .rdrMonthAndYearWrapper': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      padding: '0 26px',
    },
    '& .rdrNextPrevButton': {
      position: 'relative',
      borderRadius: '50%',
      background: 'transparent',
      transition: 'all .3s ease-in-out',
      '&:before': {
        content: '""',
        display: 'inline-block',
        width: 8,
        height: 2,
        background: COLORS.palette.primaryBlue,
        transform: 'translateX(-50%) rotate(40deg)',
        position: 'absolute',
        top: 9,
        left: '50%',
      },
      '&:after': {
        content: '""',
        display: 'inline-block',
        width: 8,
        height: 2,
        background: COLORS.palette.primaryBlue,
        transform: 'translateX(-50%) rotate(-40deg)',
        position: 'absolute',
        bottom: 9,
        left: '50%',
      },
      '& i': {
        display: 'none',
      },
      '&:hover': {
        background: '#EFF2F7',
      },
      '&.rdrPprevButton': {
        '&:before': {
          transform: 'translateX(-50%) rotate(-40deg)',
        },
        '&:after': {
          transform: 'translateX(-50%) rotate(40deg)',
        },
      },
    },
    '& .rdrStaticRange': {
      border: 'none',
      color: '#3B4757',
      borderRadius: 3,
      margin: '3px 0',
      position: 'relative',

      '&:nth-child(1), &:nth-child(3), &:nth-child(7)': {
        marginBottom: 20,

        '&:after': {
          content: '""',
          display: 'block',
          position: 'absolute',
          bottom: -10,
          left: 5,
          right: 5,
          height: 1,
          background: '#eee',
        },
      },
    },
    '& .rdrStaticRangeLabel': {
      padding: 0,
      fontSize: 14,
      borderRadius: 3,
      overflow: 'hidden',

      '& > div': {
        padding: '10px 15px',
      },

    },
    '& .rdrStaticRange:hover .rdrStaticRangeLabel, & .rdrStaticRange:focus .rdrStaticRangeLabel': {
      background: COLORS.palette.secondaryBlue3,
    },
    '& .rdrDefinedRangesWrapper .rdrStaticRangeSelected': {
      color: COLORS.gray.darkGray,
      fontWeight: 400,
      borderRight: '1px solid #eee',

      '& .rdrStaticRangeLabel': {
        background: COLORS.palette.secondaryBlue3,
      },
    },
    '& .rdrDefinedRangesWrapper': {
      padding: '10px 5px',
      width: '180px !important',
    },
    '&.disablePreview': {
      '& .rdrDayStartPreview, .rdrDayEndPreview, .rdrDayInPreview ': {
        display: 'none',
      },
    },

    '& .customSelected': {
      background: COLORS.palette.secondaryBlue3,
      color: COLORS.gray.darkGray,
    },
    '& .rdrInputRanges': {
      display: 'none',
    },
    '& .rdrDayDisabled': {
      background: 'transparent',
      '& .rdrDayNumber': {
        background: 'transparent',
      },
      '& .rdrDayNumber span': {
        background: 'transparent',
        color: '#aeb9bf !important',
      },
      '& .rdrInRange': {
        background: 'transparent',
      },
    },
  },
  // custom picker
  popover: {
    marginTop: 10,
    '& .MuiPaper-rounded': {
      borderRadius: 5,
    },
    '& .MuiPaper-elevation8': {
      boxShadow: '0px 2px 10px #D6DEF2',
      overflowX: 'unset',
      overflowY: 'unset',
    },

  },
  paper: {
    position: 'relative',
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 191,
    right: 0,
    padding: '0 10px 13px',
  },
  fieldGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  fieldControl: {
    display: 'inline-flex',
    width: '50%',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 38px',
  },
  fieldLabel: {
    color: COLORS.gray.textGray1,
    fontSize: 14,
    minWidth: 50,
    '&+div': {
      paddingTop: 0,
    },
  },
  applyBtnWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
  },
  applyBtn: {
    color: COLORS.palette.white,
    background: COLORS.palette.primaryBlue,
  },
  adornedEnd: {
    paddingRight: 7,
  },
}

export default style
