import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { NavLink as RouterLink } from 'react-router-dom'
import { withStyles } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import AddIcon from '@material-ui/icons/Add'
import { applyFiltersToUrl, getFiltersFromUrl } from 'utils/url'
import Toolbar from './Toolbar'
import Table from './Table'
import roomListStyle from './styled'

const RoomList = ({
  classes,
  room,
  location,
  history,
  find,
  remove,
}) => {
  const updateUrl = (query) => {
    const url = `${location.pathname}${applyFiltersToUrl(query)}`
    history.push(url, { replace: false })
  }

  useEffect(() => {
    const query = getFiltersFromUrl(location.search)
    find({
      ...query,
      active: query.active === undefined ? 0 : Number(query.active),
    })
    return () => { }
    // eslint-disable-next-line
  }, [location.search])

  const applyFilter = (fltData) => {
    const query = {
      ...room.query,
      ...fltData,
      active: fltData.active === undefined ? 0 : Number(fltData.active),
      page: 1,
    }
    updateUrl(query)
  }

  const changePage = (page) => {
    const query = { ...room.query, page }
    updateUrl(query)
  }

  const changeRowsPerPage = (limit) => {
    const query = { ...room.query, page: 1, limit }
    updateUrl(query)
  }

  const sort = (sortBy, sortDirection) => {
    const query = { ...room.query, sortBy, sortDirection }
    updateUrl(query)
  }

  const update = (id) => {
    history.push(`/room/${id}/update`)
  }

  const duplicate = (id) => {
    window.open(`/room/${id}/duplicate`, '_blank')
  }

  return (
    <div className={classes.root}>
      <Toolbar classes={classes} location={location} applyFilter={applyFilter} meta={room.meta} />
      <div className={classes.content}>
        <Table
          classes={classes}
          loading={room.loading}
          rooms={room.data}
          meta={room.meta}
          defaultSort={
            room.query
              ? {
                sortBy: room.query.sortBy,
                sortDirection: room.query.sortDirection,
              }
              : {}
          }
          onPageChange={changePage}
          onRowsPerPageChange={changeRowsPerPage}
          onSortChange={sort}
          update={update}
          duplicate={duplicate}
          find={find}
          remove={remove}
          location={location}
        />
      </div>
      <RouterLink to="/room/create">
        <Fab color="primary" aria-label="add" className={classes.fab}>
          <AddIcon />
        </Fab>
      </RouterLink>
    </div>
  )
}

RoomList.propTypes = {
  classes: PropTypes.shape(),
  room: PropTypes.arrayOf(PropTypes.shape()),
  find: PropTypes.func,
  remove: PropTypes.func,
  location: PropTypes.shape(),
  history: PropTypes.shape(),
}

export default withStyles(roomListStyle)(RoomList)
