import React from 'react'
import PropTypes from 'prop-types'
import {
  Checkbox,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core'

const Service = ({
  classes,
  errors,
  touched,
  values,
  handleChange,
  setFieldValue,
}) => (
  <>
    <Typography className={classes.subTitle}>Dịch vụ:</Typography>
    <Grid container spacing={2} className={`${classes.rowAlign}`}>
      {/* Có hỗ trơ dịch vụ? */}
      <Grid container item lg={12} md={12} style={{ padding: 5 }}>
        <Checkbox
          checked={values.hasService}
          onChange={e => setFieldValue('hasService', e.target.checked)}
        />
        <Typography variant="h6" style={{ margin: 'auto 0px' }}>
          Phòng có hỗ trợ setup dịch vụ
        </Typography>
      </Grid>
      {/* Ghi chú */}
      {values.hasService ? (
        <Grid container item lg={12} md={12}>
          <TextField
            style={{ backgroundColor: 'white' }}
            label="Chú ý khi setup dịch vụ"
            variant="outlined"
            name="serviceNote"
            fullWidth
            multiline
            rows={5}
            value={values.serviceNote}
            onChange={handleChange}
            error={errors.serviceNote && touched.serviceNote}
            helperText={
              errors.serviceNote
              && touched.serviceNote
              && errors.serviceNote
            }
            placeholder="Nhập lưu ý khi setup dịch vụ"
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      ) : null}
    </Grid>
  </>
)

Service.propTypes = {
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
  errors: PropTypes.shape(),
  touched: PropTypes.shape(),
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
}

export default Service
