const styles = theme => ({
  root: {
    padding: theme.spacing(2),
  },
  content: {
    marginTop: theme.spacing(2),
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(3),
    right: theme.spacing(3),
  },
  inner: {
    // minWidth: 1050,
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginRight: theme.spacing(2),
  },
  actions: {
    justifyContent: 'flex-end',
  },
  confirmBtn: {
    margin: 5,
  },
  rejectBtn: {
    backgroundColor: 'red',
    color: 'white',
    margin: 5,
  },
  filter: {
    '& > div': {
      margin: theme.spacing(1),
    },
  },
})

export default styles
