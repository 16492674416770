import React from 'react'
import PropTypes from 'prop-types'
import CurrencyTextField from '@unicef/material-ui-currency-textfield'

const CurrencyInput = ({
  classes,
  onChange,
  value,
  ...props
}) => {
  let timeout
  const handleChange = (v) => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      onChange(parseInt(v?.replace(/,/g, ''), 0))
      timeout = null
    }, 750)
  }

  return (
    <CurrencyTextField
      currencySymbol="VND"
      outputFormat="number"
      decimalPlaces={0}
      {...props}
      value={value || 0}
      onChange={e => handleChange(e.target.value)}
    />
  )
}

CurrencyInput.propTypes = {
  classes: PropTypes.shape(),
  onChange: PropTypes.func,
  value: PropTypes.number,
}

export default CurrencyInput
