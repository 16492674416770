import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { NavLink as RouterLink } from 'react-router-dom'
import { applyFiltersToUrl, getFiltersFromUrl } from 'utils/url'
import { Fab, withStyles } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import * as couponApi from 'provider/coupon'
import WarningDialog from 'views/Dialog/WarningDialog'
import { useSnackbar } from 'notistack'
import Table from './Table'
import Toolbar from './Toolbar'
import styles from './styled'

const CouponList = ({
  classes,
  coupon,
  location,
  history,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const [coupons, setCoupons] = useState([])
  const [meta, setMeta] = useState({})
  const [sltCoupon, setSltCoupon] = useState(null)
  const [showWarningDialog, setShowWarningDialog] = useState(false)

  const fetchCoupons = async (query) => {
    setLoading(true)
    try {
      const resp = await couponApi.find(query)
      const {
        data,
        ...rest
      } = resp
      setCoupons(data)
      setMeta(rest)
      setLoading(false)
    } catch (e) {
      setLoading(false)
      enqueueSnackbar(e?.response?.mesage || `Máy chủ lỗi (code: ${e.response ? e.response.mesage : 'Unknown'}).`, { variant: 'error' })
    }
  }

  useEffect(() => {
    const query = getFiltersFromUrl(location.search)
    fetchCoupons({
      ...query,
      creator: query.creator || 'system',
      status: query.status || 'valid',
    })
    return () => {}
    // eslint-disable-next-line
  }, [location.search])

  const updateUrl = (query) => {
    if (query.code) {
      // eslint-disable-next-line no-param-reassign
      delete query.code
    }
    const url = `${location.pathname}${applyFiltersToUrl(query)}`
    history.push(url, { replace: false })
  }

  const applyFilter = (fltData) => {
    const query = {
      ...coupon.query,
      ...fltData,
      page: 1,
    }
    updateUrl(query)
  }

  const reject = async () => {
    try {
      await couponApi.reject(sltCoupon)
      setSltCoupon(null)
      setShowWarningDialog(false)
      applyFilter()
    } catch (e) {
      enqueueSnackbar(e?.response?.mesage || `Máy chủ lỗi (code: ${e.response ? e.response.mesage : 'Unknown'}).`, { variant: 'error' })
    }
  }

  const changePage = (page) => {
    const query = { ...coupon.query, page }
    updateUrl(query)
  }

  const changeRowsPerPage = (limit) => {
    const query = { ...coupon.query, page: 1, limit }
    updateUrl(query)
  }

  const onRejectClick = (code) => {
    setSltCoupon(code)
    setShowWarningDialog(true)
  }

  return (
    <div className={classes.root}>
      <WarningDialog
        title="Huỷ mã khuyến mại"
        content="Bạn có chắc muốn huỷ mã khuyến mại này?"
        visible={showWarningDialog}
        onClose={() => {
          setSltCoupon(null)
          setShowWarningDialog(false)
        }}
        onConfirm={reject}
      />
      <Toolbar
        classes={classes}
        applyFilter={applyFilter}
        location={location}
      />
      <div className={classes.content}>
        <Table
          classes={classes}
          coupon={coupons}
          meta={meta}
          loading={loading}
          onRejectClick={onRejectClick}
          onPageChange={changePage}
          onRowsPerPageChange={changeRowsPerPage}
          mode={getFiltersFromUrl(location?.search)?.status}
        />
      </div>
      <RouterLink to="/coupon/create">
        <Fab color="primary" aria-label="add" className={classes.fab}>
          <AddIcon />
        </Fab>
      </RouterLink>
    </div>
  )
}

CouponList.propTypes = {
  classes: PropTypes.shape(),
  coupon: PropTypes.arrayOf(PropTypes.shape()),
  location: PropTypes.shape(),
  history: PropTypes.shape(),
}

export default withStyles(styles)(CouponList)
