import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Grid,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  FormHelperText,
} from '@material-ui/core'
import * as commonApi from 'provider/common'
import CurrencyInput from 'components/CurrencyInput'

const General = ({
  classes,
  values,
  touched,
  errors,
  handleChange,
  setFieldValue,
}) => {
  const [types, setTypes] = useState([])
  const [options, setOptions] = useState([])
  const [components, setComponents] = useState([])

  const fetchType = async () => {
    try {
      const resp = await commonApi.getServiceType()
      setTypes(
        resp.data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      )
    } catch (e) {
      console.log(e)
    }
  }

  const fetchOption = async () => {
    try {
      const resp = await commonApi.getServiceOption()
      setOptions(
        resp.data.map((item) => ({
          value: item.id,
          label: item.title,
        })),
      )
    } catch (e) {
      console.log(e)
    }
  }

  const fetchComponent = async () => {
    try {
      const resp = await commonApi.getServiceComponent()
      console.log(resp.data)
      setComponents(
        resp.data.map((item) => ({
          value: item.id,
          label: item.name,
        })),
      )
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    // Fetch user data if in updating mode
    fetchType()
    fetchOption()
    fetchComponent()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Typography className={classes.subTitle}>Thông tin dịch vụ:</Typography>
      {/* Name and price */}
      <Grid container spacing={4} className={classes.rowAlign}>
        {/* Name */}
        <Grid item lg={4} md={4}>
          <TextField
            style={{ backgroundColor: 'white' }}
            label="Tên dịch vụ"
            variant="outlined"
            name="name"
            fullWidth
            value={values.name}
            onChange={handleChange}
            error={errors.name && touched.name}
            helperText={errors.name && touched.name && errors.name}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Nhập tên dịch vụ"
          />
        </Grid>
        {/* Type */}
        <Grid item lg={4} md={4}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            error={errors.components && touched.components}
            size="small"
          >
            <InputLabel
              style={{ backgroundColor: 'white', padding: '0px 5px' }}
              id="user-role"
            >
              Kiểu dịch vụ
            </InputLabel>
            <Select
              labelId="user-role"
              id="user-role-label"
              name="type"
              value={values.type}
              onChange={handleChange}
            >
              {types.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormHelperText style={{ color: 'red' }}>
            {errors.role && touched.role && errors.role}
          </FormHelperText>
        </Grid>
        {/* Price */}
        <Grid item lg={4} md={4}>
          <CurrencyInput
            style={{ backgroundColor: 'white' }}
            label="Giá dịch vụ"
            variant="outlined"
            name="price"
            fullWidth
            value={values?.price}
            onChange={(value) => setFieldValue('price', value)}
            error={errors.price && touched.price}
            helperText={errors.price && touched.price && errors.price}
            size="small"
            placeholder="Nhập số tiền"
          />
        </Grid>
      </Grid>
      {/* Prepare time and locking time */}
      <Grid container spacing={4} className={classes.rowAlign}>
        {/* Prepare time */}
        <Grid item lg={6} md={6}>
          <TextField
            style={{ backgroundColor: 'white' }}
            label="Thời gian chuẩn bị"
            variant="outlined"
            name="prepareTime"
            fullWidth
            value={values.prepareTime}
            onChange={handleChange}
            error={errors.prepateTime && touched.prepateTime}
            helperText={errors.prepateTime && touched.prepateTime && errors.prepateTime}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Nhập số phút (30, 60, 90, ...)"
          />
        </Grid>
        {/* Locking time */}
        <Grid item lg={6} md={6}>
          <TextField
            style={{ backgroundColor: 'white' }}
            label="Thời gian khoá"
            variant="outlined"
            name="lockTime"
            fullWidth
            value={values.lockTime}
            onChange={handleChange}
            error={errors.lockTime && touched.lockTime}
            helperText={errors.lockTime && touched.lockTime && errors.lockTime}
            size="small"
            InputLabelProps={{
              shrink: true,
            }}
            placeholder="Nhập số phút (30, 60, 90, ...)"
          />
        </Grid>
      </Grid>
      {/* Components and Extra info */}
      <Grid container spacing={4}>
        {/* Components */}
        <Grid item lg={6} md={6}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            error={errors.components && touched.components}
            size="small"
          >
            <InputLabel style={{ backgroundColor: 'white', padding: '0px 5px' }} id="user-role">
              Thành phần
            </InputLabel>
            <Select
              labelId="user-role"
              id="user-role-label"
              name="components"
              value={values.components}
              onChange={handleChange}
              multiple
            >
              {components.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormHelperText style={{ color: 'red' }}>
            {errors.role && touched.role && errors.role}
          </FormHelperText>
        </Grid>
        {/* Extra info */}
        <Grid item lg={6} md={6}>
          <FormControl
            variant="outlined"
            className={classes.formControl}
            fullWidth
            error={errors.options && touched.options}
            size="small"
          >
            <InputLabel style={{ backgroundColor: 'white', padding: '0px 5px' }} id="user-role">
              Thông tin bổ sung
            </InputLabel>
            <Select
              labelId="user-role"
              id="user-role-label"
              name="options"
              value={values.options}
              onChange={handleChange}
              multiple
            >
              {options.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormHelperText style={{ color: 'red' }}>
            {errors.options && touched.options && errors.options}
          </FormHelperText>
        </Grid>
      </Grid>
    </>
  )
}

General.propTypes = {
  classes: PropTypes.shape(),
  values: PropTypes.shape(),
  touched: PropTypes.shape(),
  errors: PropTypes.shape(),
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
}

export default General
