import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
  IconButton,
  Link,
  Typography,
  // Chip,
  // Tooltip,
} from '@material-ui/core'
import { NavLink as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { formatPrice } from 'utils/formatter'
import { Table } from 'components'
import { Close, Done } from '@material-ui/icons'

const Invoices = ({
  // classes,
  loading,
  data,
  meta,
  setting,
  onSortChange,
  onPageChange,
  onRowsPerPageChange,
  onPaid,
  onReject,
  onInvoiceSelect,
}) => {
  const [sortBy, setSortBy] = useState(null)
  const [sortDirection, setSortDirection] = useState(null)

  useEffect(() => {
    console.log('here')
    return () => {
    }
  }, [])

  const handlePageChange = (page) => {
    onPageChange(page)
  }

  const handleRowsPerPageChange = (limit) => {
    onRowsPerPageChange(limit)
  }

  const handleSortChange = (_sortBy, _sortDirection) => {
    onSortChange(_sortBy, _sortDirection)
    setSortBy(_sortBy)
    setSortDirection(_sortDirection)
  }

  const renderStatus = (status) => {
    switch (status) {
      case 'pending':
        return 'Đang xử lý'

      case 'paid':
        return 'Đã thanh toán'

      case 'confirmed':
        return 'Chờ thanh toán'

      default:
        return 'Đã huỷ'
    }
  }

  const paidInvoice = (id) => {
    // Xác nhận thanh toán cho chủ nhà
    onPaid(id)
  }

  const rejectInvoice = (id) => {
    // Xác nhận huỷ đối soát của chủ nhà
    onReject(id)
  }

  return (
    <>
      {/* Reservation list */}
      <Table
        data={data}
        columns={[
          {
            Head: 'Chủ nhà',
            Cell: (value, origin) => (
              <div className="d-flex flex-column">
                <RouterLink to={`/host/${origin?.host?.code}/view`} target="_blank">
                  <Typography variant="body1">{`${origin?.host?.name} (${origin?.host?.code})`}</Typography>
                </RouterLink>
              </div>
            ),
            accessor: 'name',
            sortable: false,
            hidden: !setting['Chủ nhà'],
          },
          {
            Head: 'Kỳ đối soát',
            Cell: (value, origin) => (<Typography variant="div">{`${moment(origin.startdate).format('DD/MM')} - ${moment(origin.enddate).format('DD/MM')}`}</Typography>),
            accessor: 'startdate',
            sortable: true,
            hidden: false,
          },
          {
            Head: 'Số đơn',
            Cell: (value, origin) => (
              <Typography variant="div">
                <Link href="#" onClick={() => onInvoiceSelect(value)}>{`${origin?.orders?.length} đơn`}</Link>
              </Typography>
            ),
            accessor: 'id',
            sortable: true,
            hidden: !setting['Số đơn'],
          },
          {
            Head: 'Doanh thu phòng',
            Cell: value => (<Typography variant="div">{`${formatPrice(value || 0)}`}</Typography>),
            accessor: 'roomRevenue',
            sortable: true,
            hidden: !setting['Doanh thu phòng'],
          },
          {
            Head: 'Doanh thu host',
            Cell: value => (<Typography variant="div">{`${formatPrice(value || 0)}`}</Typography>),
            accessor: 'hostRevenue',
            sortable: true,
            hidden: !setting['Doanh thu host'],
          },
          {
            Head: 'Chi hộ',
            Cell: value => (<Typography variant="div">{`${formatPrice(value)}`}</Typography>),
            accessor: 'payOnBehaft',
            sortable: true,
            hidden: !setting['Chi hộ'],
          },
          {
            Head: 'Doanh thu net chủ nhà',
            Cell: (value, origin) => (<Typography variant="div">{`${formatPrice((value || 0) - (origin.payOnBehaft || 0))}`}</Typography>),
            accessor: 'hostRevenue',
            sortable: true,
            hidden: !setting['Doanh thu net'],
          },
          {
            Head: 'Tiền cọc',
            Cell: value => (<Typography variant="div">{`${formatPrice(value || 0)}`}</Typography>),
            accessor: 'depositRevenue',
            sortable: true,
            hidden: !setting['Tiền cọc'],
          },
          {
            Head: 'Tình trạng',
            Cell: value => (<Typography variant="div">{renderStatus(value)}</Typography>),
            accessor: 'status',
            sortable: true,
            hidden: !setting['Tình trạng'],
          },
          {
            Head: 'Mã bút toán',
            Cell: value => (<Typography variant="div">{value}</Typography>),
            accessor: 'bankNote',
            sortable: true,
            hidden: !setting['Mã bút toán'],
          },
          {
            Head: 'Thao tác',
            Cell: (value, origin) => origin.status === 'confirmed' ? (
              <>
                <IconButton onClick={() => paidInvoice(value)} size="small">
                  <Done />
                </IconButton>
                <IconButton onClick={() => rejectInvoice(value)} size="small" className="ml-3">
                  <Close />
                </IconButton>
              </>
            ) : null,
            accessor: 'id',
            sortable: true,
            hidden: false,
          },
        ]}
        loading={loading}
        page={meta?.page || 1}
        limit={meta?.limit || 10}
        total={meta?.total || 0}
        defaultSort={{ sortBy, sortDirection }}
        onSortChange={handleSortChange}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </>
  )
}

Invoices.propTypes = {
  // classes: PropTypes.shape(),
  loading: PropTypes.bool,
  data: PropTypes.arrayOf(PropTypes.shape()),
  meta: PropTypes.shape(),
  setting: PropTypes.shape(),
  onSortChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onPaid: PropTypes.func,
  onReject: PropTypes.func,
  onInvoiceSelect: PropTypes.func,
}

export default Invoices
