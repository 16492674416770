import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Chip,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { NavLink as RouterLink } from 'react-router-dom'
import moment from 'moment'
import PropTypes from 'prop-types'
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { formatPrice } from 'utils/formatter'
import { Delete, Done, SwapHoriz } from '@material-ui/icons'

const UsersTable = ({
  classes,
  prepaidHistory,
  meta,
  find,
  update,
}) => {
  const handlePageChange = (e, p) => {
    find({ ...meta, page: p + 1 })
  }

  const handleRowsPerPageChange = (e) => {
    find({ page: 1, limit: e.target.value })
  }

  const updateStatus = (index, status) => {
    update({ ...prepaidHistory[index], status })
  }

  const renderStatus = (index) => {
    const content = {
      pending: 'Chờ thanh toán',
      paid: 'Đã thanh toán',
      reject: 'Đã huỷ',
    }
    const color = {
      pending: '#ffa854',
      paid: '#00e676',
      reject: '#ff1744',
    }
    return (
      <Chip
        size="small"
        style={{
          color: 'white',
          fontSize: 12,
          fontWeight: '900',
          backgroundColor: color[prepaidHistory[index].status],
        }}
        label={content[prepaidHistory[index].status]}
      />
    )
  }

  const renderAction = (index, status) => {
    switch (status) {
      case 'pending':
        return (
          <>
            <Tooltip
              title="Xác nhận thanh toán"
              aria-label="Xác nhận thanh toán"
            >
              <IconButton
                aria-label="paid"
                className={classes.margin}
                onClick={() => updateStatus(index, 'paid')}
              >
                <Done />
              </IconButton>
            </Tooltip>
            <Tooltip title="Huỷ" aria-label="Huỷ">
              <IconButton
                aria-label="reject"
                className={classes.margin}
                onClick={() => updateStatus(index, 'reject')}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </>
        )
      case 'paid':
        return (
          <Tooltip title="Thu hồi" aria-label="Thu hồi">
            <IconButton
              aria-label="paid"
              className={classes.margin}
              onClick={() => {}}
            >
              <SwapHoriz />
            </IconButton>
          </Tooltip>
        )
      default:
        return null
    }
  }

  return (
    <Card>
      <CardContent style={{ padding: 0 }}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Mã giao dịch</TableCell>
                  <TableCell>Khách hàng</TableCell>
                  <TableCell>Tên gói</TableCell>
                  <TableCell>Số tiền</TableCell>
                  <TableCell>Ngày mua</TableCell>
                  <TableCell>Trạng thái</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {prepaidHistory.map((prepaidPkg, index) => (
                  <TableRow
                    key={prepaidPkg._id}
                    className={classes.tableRow}
                    hover
                  >
                    {/* Code */}
                    <TableCell>{prepaidPkg.code || 'Không xác định'}</TableCell>
                    {/* Customer */}
                    <TableCell>
                      {prepaidPkg.customer ? (
                        <>
                          <RouterLink
                            to={`/customer/${prepaidPkg.customer._id}/update`}
                            target="_blank"
                          >
                            <a style={{ color: '#3f51b5', fontSize: '1rem' }}>
                              {prepaidPkg.customer.name}
                            </a>
                          </RouterLink>
                          <Typography variant="body2">
                            {'Mã: '}
                            <b>{prepaidPkg.customer.code}</b>
                          </Typography>
                        </>
                      ) : null}
                    </TableCell>
                    {/* Email */}
                    <TableCell>
                      {prepaidPkg.package ? prepaidPkg.package.name : null}
                    </TableCell>
                    {/* Address */}
                    <TableCell>
                      {prepaidPkg.package
                        ? `${formatPrice(prepaidPkg.package.value)} VND`
                        : null}
                    </TableCell>
                    {/* Created Date */}
                    <TableCell>
                      {moment(prepaidPkg.createdAt).format('DD/MM/YYYY')}
                    </TableCell>
                    {/* Status */}
                    <TableCell>{renderStatus(index)}</TableCell>
                    {/* Actions */}
                    <TableCell>
                      {renderAction(index, prepaidPkg.status)}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      {/* Pagination */}
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={meta.total}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={meta.page - 1}
          rowsPerPage={meta.limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  )
}

UsersTable.propTypes = {
  classes: PropTypes.shape(),
  prepaidHistory: PropTypes.arrayOf(PropTypes.shape()),
  meta: PropTypes.shape(),
  find: PropTypes.func,
  update: PropTypes.func,
}

export default UsersTable
