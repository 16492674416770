/* eslint-disable no-param-reassign */
import moment from 'moment'
import { useState } from 'react'
import {
  Typography,
  Chip,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Button,
  Grid,
  TextField,
} from '@material-ui/core'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import { useSnackbar } from 'notistack'
import { NavLink as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { formatPrice } from 'utils/formatter'
import { Table } from 'components'
import PaymentDialog from 'views/ReservationForm/Dialog/Payment'
import ConfirmDialog from 'views/ReservationForm/Dialog/Confirm'
import RefundDialog from 'views/ReservationForm/Dialog/Refund'
import RejectDialog from 'views/ReservationForm/Dialog/Reject'
import PunishDepositDialog from 'views/ReservationForm/Dialog/PunishDeposit'
import UndoDialog from 'views/ReservationForm/Dialog/Undo'
import {
  Delete,
  Done,
  AttachMoney,
  Edit,
  Visibility,
  Info,
  Undo,
  SwapHorizontalCircle,
  Add,
  Remove,
  AssignmentTurnedIn,
  ArrowDownward,
  BorderColor,
  Send,
} from '@material-ui/icons'
import AppConfig from 'config'
import {
  pay,
  confirmCheckoutPoint,
  punishDepositByHost,
  punishDepositByCustomer,
  agreePunishDeposit,
  handlePunishDeposit,
  releaseDeposit,
  confirm,
  payCheckoutPoint,
  provideCustomerRefundInfo,
  provideCustomerDepositInfo,
} from 'provider/reservation'
import uploadApi from 'provider/upload'
import { last } from 'underscore'
import AddCheckoutPointDialog from '../Dialog/AddCheckoutPoint'

const ReservationsTable = ({
  classes,
  loading,
  reservations,
  meta,
  applyFilter,
  // pay,
  // confirm,
  reject,
  refund,
  edit,
  view,
  undo,
  addCheckout,
  rejectCheckout,
  sendEmail,
  defaultSort,
  onSortChange,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const { enqueueSnackbar } = useSnackbar()
  const [services, setServices] = useState([])
  const [sltReservation, setSltReservation] = useState(undefined)
  const [openServiceDialog, setOpenServiceDialog] = useState(false)
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false)
  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const [refundUser, setRefundUser] = useState(undefined)
  const [refundBankAccount, setRefundBankAccount] = useState(undefined)
  const [refundBankName, setRefundBankName] = useState(undefined)
  const [openRefundDialog, setOpenRefundDialog] = useState(false)
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [openUndoDialog, setOpenUndoDialog] = useState(false)
  const [openAddTimeDialog, setOpenAddTimeDialog] = useState(false)
  const [openDepositDialog, setOpenDepositDialog] = useState(false)
  const [hasDepositPunish, setHasDepositPunish] = useState(true)
  const [openProvideInfoDialog, setOpenProvideInfoDialog] = useState(false)

  const handlePageChange = (page) => {
    onPageChange(page)
  }

  const handleRowsPerPageChange = (limit) => {
    onRowsPerPageChange(limit)
  }

  const handleSortChange = (sortBy, sortDirection) => {
    onSortChange(sortBy, sortDirection)
  }

  const renderRoomType = (type) => {
    switch (type) {
      case 'request':
        return 'Yêu cầu xác nhận'
      case 'instant':
        return 'Đặt nhanh'
      default:
        return ''
    }
  }

  const renderStatus = (status, origin) => {
    const { checkin, checkout, refundRoom, refundService, paymentHistory, addCheckoutPoint, depositAmount, depositWith, depositStatus } = origin
    const lastCheckoutPoint = last(addCheckoutPoint || [])
    switch (status) {
      case 'reject':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#999999',
            }}
            label="Huỷ"
          />
        )
      case 'reject-by-host':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#999999',
            }}
            label="Hết phòng"
          />
        )
      case 'reject-waiting':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#999999',
            }}
            label="Chờ cung cấp thông tin"
          />
        )
      case 'reject-norefund':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#999999',
            }}
            label="Huỷ không hoàn tiền"
          />
        )
      case 'reject-refund':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#35a211',
            }}
            label="Huỷ hoàn tiền"
          />
        )
      case 'reject-processing':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#ffc107',
            }}
            label="Huỷ chờ hoàn tiền"
          />
        )
      case 'processing':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#ffa854',
            }}
            label="Chờ xác nhận"
          />
        )
      case 'processing-pay-extra':
        return (
          <>
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#00b0ff',
              }}
              label="Đã thanh toán"
            />
            &nbsp;
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#ffa854',
              }}
              label="Chờ xác nhận sau chỉnh sửa"
            />
          </>
        )
      case 'confirmed-pay-extra':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#ff1744',
            }}
            label="Chờ thanh toán chỉnh sửa"
          />
        )
      case 'processing-refund-in-update':
        if (moment().valueOf() > checkout) {
          return (
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#52c41a',
              }}
              label={refundRoom + refundService > 0 ? 'Chờ hoàn tiền chỉnh sửa' : 'Chờ xác nhận'}
            />
          )
        }
        if (moment().valueOf() >= checkin) {
          return (
            <>
              <Chip
                size="small"
                style={{
                  color: 'white',
                  fontSize: 12,
                  fontWeight: '900',
                  backgroundColor: origin.addCheckoutPoint && origin.addCheckoutPoint.length ? '#0052cc' : '#00e676',
                }}
                label={origin.addCheckoutPoint && origin.addCheckoutPoint.length ? 'Đang ở thêm' : 'Đang sử dụng'}
              />
              &nbsp;
              <Chip
                size="small"
                style={{
                  color: 'white',
                  fontSize: 12,
                  fontWeight: '900',
                  backgroundColor: '#52c41a',
                }}
                label={refundRoom + refundService > 0 ? 'Chờ hoàn tiền chỉnh sửa' : 'Chờ xác nhận'}
              />
            </>
          )
        }
        return (
          <>
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#00b0ff',
              }}
              label="Đã thanh toán"
            />
            &nbsp;
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#52c41a',
              }}
              label={refundRoom + refundService > 0 ? 'Chờ hoàn tiền chỉnh sửa' : 'Chờ xác nhận'}
            />
          </>
        )
      case 'waiting-refund-in-update':
      case 'host-confirm-refund-update':
        if (moment().valueOf() > checkout) {
          return (
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#52c41a',
              }}
              label="Chờ hoàn tiền chỉnh sửa"
            />
          )
        }
        if (moment().valueOf() >= checkin) {
          return (
            <>
              <Chip
                size="small"
                style={{
                  color: 'white',
                  fontSize: 12,
                  fontWeight: '900',
                  backgroundColor: origin.addCheckoutPoint && origin.addCheckoutPoint.length ? '#0052cc' : '#00e676',
                }}
                label={origin.addCheckoutPoint && origin.addCheckoutPoint.length ? 'Đang ở thêm' : 'Đang sử dụng'}
              />
              &nbsp;
              <Chip
                size="small"
                style={{
                  color: 'white',
                  fontSize: 12,
                  fontWeight: '900',
                  backgroundColor: '#52c41a',
                }}
                label="Chờ hoàn tiền chỉnh sửa"
              />
            </>
          )
        }
        return (
          <>
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#00b0ff',
              }}
              label="Đã thanh toán"
            />
            &nbsp;
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#52c41a',
              }}
              label="Chờ hoàn tiền chỉnh sửa"
            />
          </>
        )
      case 'add-checkout-processing':
        if (lastCheckoutPoint?.checkout < moment().valueOf()) {
          return (
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#3e51b5',
              }}
              label="Hoàn thành"
            />
          )
        }
        return (
          <>
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#00e676',
              }}
              label="Đang sử dụng"
            />
            {lastCheckoutPoint?.status === 'processing' && (
              <Chip
                size="small"
                style={{
                  color: 'white',
                  fontSize: 12,
                  fontWeight: '900',
                  backgroundColor: '#41CC78',
                }}
                label="Chờ xác nhận ở thêm"
              />
            )}
            {lastCheckoutPoint?.status === 'confirmed' && (
              <Chip
                size="small"
                style={{
                  color: 'white',
                  fontSize: 12,
                  fontWeight: '900',
                  backgroundColor: '#41CC78',
                }}
                label="Chờ thanh toán ở thêm"
              />
            )}
          </>
        )
      case 'confirmed':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#ff1744',
            }}
            label="Chờ thanh toán"
          />
        )
      case 'paid':
        if (paymentHistory?.length > 1 && refundRoom + refundService <= 0) {
          return (
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#00b0ff',
              }}
              label="Đã thanh toán chỉnh sửa"
            />
          )
        }
        if (paymentHistory?.length > 1 && refundRoom + refundService > 0) {
          return (
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor: '#00b0ff',
              }}
              label="Đã hoàn tiền chỉnh sửa"
            />
          )
        }
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#00b0ff',
            }}
            label="Đã thanh toán"
          />
        )
      case 'using':
        return (
          <>
            <Chip
              size="small"
              style={{
                color: 'white',
                fontSize: 12,
                fontWeight: '900',
                backgroundColor:
                  addCheckoutPoint && addCheckoutPoint.length && addCheckoutPoint.find((item) => item.status === 'paid') ? '#0052cc' : '#00e676',
              }}
              label={origin.addCheckoutPoint?.filter((item) => item.status === 'paid')?.length ? 'Đang ở thêm' : 'Đang sử dụng'}
            />
            &nbsp;
            {origin.addCheckoutPoint?.filter((item) => item.status === 'paid')?.length ? (
              <Chip
                size="small"
                style={{
                  color: 'white',
                  fontSize: 12,
                  fontWeight: '900',
                  backgroundColor: '#0052cc',
                }}
                label="Đã thanh toán chỉnh sửa"
              />
            ) : null}
          </>
        )
      case 'completed':
        return depositWith === '2home' && depositAmount > 0 && checkout < moment().valueOf() && !['none', 'release'].includes(depositStatus) ? (
          <>
            {/* Yêu cầu phạt cọc */}
            {depositStatus === 'hold' ? (
              <Chip
                size="small"
                style={{
                  color: 'white',
                  fontSize: 12,
                  fontWeight: '900',
                  backgroundColor: '#3e51b5',
                }}
                label="Chờ xác nhận hoàn cọc"
              />
            ) : null}
            {/* Đồng ý yêu cầu phạt cọc */}
            {depositStatus === 'customer-complain' ? (
              <Chip
                size="small"
                style={{
                  color: 'white',
                  fontSize: 12,
                  fontWeight: '900',
                  backgroundColor: '#3e51b5',
                }}
                label="Khách gửi yêu cầu phạt cọc"
              />
            ) : null}
            {['host-complain'].includes(depositStatus) ? (
              <Chip
                size="small"
                style={{
                  color: 'white',
                  fontSize: 12,
                  fontWeight: '900',
                  backgroundColor: '#3e51b5',
                }}
                label="Chủ nhà yêu cầu phạt cọc"
              />
            ) : null}
            {['2home-handle'].includes(depositStatus) ? (
              <Chip
                size="small"
                style={{
                  color: 'white',
                  fontSize: 12,
                  fontWeight: '900',
                  backgroundColor: '#3e51b5',
                }}
                label="2home đang xử lý cọc"
              />
            ) : null}
            {['waiting-release'].includes(depositStatus) ? (
              <Chip
                size="small"
                style={{
                  color: 'white',
                  fontSize: 12,
                  fontWeight: '900',
                  backgroundColor: '#3e51b5',
                }}
                label="Chờ khách cung cấp thông tin hoàn cọc"
              />
            ) : null}
            {['processing-release'].includes(depositStatus) ? (
              <Chip
                size="small"
                style={{
                  color: 'white',
                  fontSize: 12,
                  fontWeight: '900',
                  backgroundColor: '#3e51b5',
                }}
                label="Khách chờ hoàn cọc"
              />
            ) : null}
          </>
        ) : (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#3e51b5',
            }}
            label="Hoàn thành"
          />
        )
      case 'lock':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#9e9e9e',
            }}
            label="Khoá phòng"
          />
        )
      case 'reject-lock':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#9e9e9e',
            }}
            label="Huỷ khoá phòng"
          />
        )
      case 'expired':
      default:
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontSize: 12,
              fontWeight: '900',
              backgroundColor: '#9e9e9e',
            }}
            label="Hết hạn"
          />
        )
    }
  }

  const payReservation = async (payment, images) => {
    try {
      let paidRequestImageUrls = []
      // Upload ảnh bằng chứng khách hàng yêu cầu thanh toán bằng tk 2home
      if (payment === '2home') {
        paidRequestImageUrls = await uploadApi.uploadImage(
          images.map((item) => item.file),
          `Customer/${sltReservation?.customer?.code}/Reservation/${sltReservation?.code}`,
        )
      }
      await pay(
        sltReservation.id,
        payment,
        paidRequestImageUrls.map((item) => item.shortUrl),
      )
      applyFilter({})
      enqueueSnackbar('Thanh toán đơn đặt phòng thành công.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    } catch (error) {
      enqueueSnackbar(`Thanh toán đơn đặt phòng không thành công. ${error.response?.data}`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
    setSltReservation(undefined)
    setOpenPaymentDialog(false)
  }

  const rejectReservation = async (rejectUser, payment, user, bankAccount, bankName, note, images, suggestRooms) => {
    if (sltReservation.status === 'lock') {
      // Huỷ đơn
      reject(sltReservation.id, '', {})
      applyFilter({})
      setOpenRejectDialog(false)
      setSltReservation(undefined)
      return
    }
    if (rejectUser === 'customer' && (!images.length || (payment === 'bank-transfer' && (!user || !bankAccount || !bankName)))) {
      enqueueSnackbar('Huỷ đơn không thành công, yêu cầu cung cấp đầy đủ thông tin.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      return
    }
    // Upload image and add to reject apis
    let rejectImageUrls = []
    if (images.length) {
      rejectImageUrls = await uploadApi.uploadImage(
        images.filter((file) => !file.isLink).map((item) => item.file),
        `Customer/${sltReservation?.customer?.code}/Reservation/${sltReservation?.code}`,
      )
    }
    // Huỷ đơn
    reject(sltReservation.id, rejectUser, {
      rejectNote: note,
      refundBankName: bankName,
      refundBankAccount: bankAccount,
      refundUser: user,
      paymentType: payment,
      rejectImage: rejectImageUrls.map((item) => item.shortUrl),
      sameroom: suggestRooms && suggestRooms.length ? suggestRooms?.map((r) => r.code)?.join(', ') : undefined,
    })
    setSltReservation(undefined)
    setOpenRejectDialog(false)
    applyFilter({})
  }

  const refundReservation = (bankNote) => {
    refund(sltReservation.id, bankNote)
    applyFilter({})
    setSltReservation(undefined)
    setOpenRefundDialog(false)
  }

  const confirmReservation = async (extraPaymentInfo) => {
    const { addCheckoutPoint } = sltReservation || {}
    const lastCheckoutPoint = last(addCheckoutPoint || [])
    try {
      switch (sltReservation.status) {
        case 'add-checkout-processing':
          if (lastCheckoutPoint?.status === 'processing') {
            await confirmCheckoutPoint(sltReservation.id)
          }
          if (lastCheckoutPoint?.status === 'confirmed') {
            await payCheckoutPoint(sltReservation.id, extraPaymentInfo)
          }
          applyFilter({})
          break

        default:
          await confirm(sltReservation.id)
          // await confirm(sltReservation.id)
          applyFilter({})
          break
      }
      enqueueSnackbar('Xác nhận đơn đặt phòng thành công.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    } catch (error) {
      if (error?.response?.data?.code === 'TIME_02') {
        enqueueSnackbar('Xác nhận đơn đặt phòng không thành công, trùng thời gian khoá phòng.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
      } else {
        enqueueSnackbar('Xác nhận đơn đặt phòng không thành công, đã có lỗi xảy ra.', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        })
      }
    }
    setOpenConfirmDialog(false)
    setSltReservation(undefined)
  }

  const addTimeReservation = async (data) => {
    setOpenAddTimeDialog(false)
    // Call api to add more checkout point
    const { id } = sltReservation
    try {
      await addCheckout(id, data)
      setSltReservation(null)
      enqueueSnackbar('Xác nhận ở thêm thành công.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      applyFilter({})
    } catch (e) {
      enqueueSnackbar('Xác nhận ở thêm không thành công', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
  }

  const undoReservation = async () => {
    try {
      switch (sltReservation.status) {
        case 'add-checkout-processing':
          await rejectCheckout(sltReservation.id)
          break

        default:
          await undo(sltReservation.id)
          break
      }
      applyFilter({})
      enqueueSnackbar('Huỷ thay đổi đơn đặt phòng thành công.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
      setOpenUndoDialog(false)
      setSltReservation(undefined)
    } catch (error) {
      enqueueSnackbar('Huỷ thay đổi đơn đặt phòng không thành công.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
  }

  const sendOrderEmail = async (id) => {
    try {
      await sendEmail(id)
    } catch (e) {
      console.log(e)
    }
  }

  const renderAction = (status, reservation) => {
    const {
      // id,
      checkout,
      depositStatus,
      depositAmount,
      depositWith,
      addCheckoutPoint,
    } = reservation
    const lastCheckoutPoint = last(addCheckoutPoint || [])
    switch (status) {
      case 'reject':
      case 'reject-by-host':
        return (
          <>
            {false && (
              <Tooltip title="Xem lại">
                <IconButton
                  className={classes.margin}
                  onClick={() => view(reservation.code)}
                >
                  <Visibility />
                </IconButton>
              </Tooltip>
            )}
          </>
        )
      case 'reject-processing':
        return (
          <Tooltip title="Hoàn tiền">
            <IconButton
              aria-label="Hoàn tiền"
              className={classes.margin}
              onClick={() => {
                setOpenRefundDialog(true)
                setSltReservation(reservation)
              }}
            >
              <SwapHorizontalCircle />
            </IconButton>
          </Tooltip>
        )
      case 'processing':
        return (
          <>
            <Tooltip title="Xác nhận đơn đặt phòng">
              <IconButton
                aria-label="confirm"
                className={classes.margin}
                onClick={() => {
                  setOpenConfirmDialog(true)
                  setSltReservation(reservation)
                }}
              >
                <Done />
              </IconButton>
            </Tooltip>
            <Tooltip title="Chỉnh sửa đơn đặt phòng">
              <IconButton
                aria-label="confirm"
                className={classes.margin}
                onClick={() => edit(reservation.code)}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Huỷ đơn đặt phòng">
              <IconButton
                className={classes.margin}
                onClick={() => {
                  setOpenRejectDialog(true)
                  setSltReservation(reservation)
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </>
        )
      case 'processing-refund-in-update':
      case 'waiting-refund-in-update':
      case 'processing-pay-extra':
        return (
          <>
            {/* Xác nhận */}
            <Tooltip title="Xác nhận đơn đặt phòng">
              <IconButton
                aria-label="confirm"
                className={classes.margin}
                onClick={() => {
                  setOpenConfirmDialog(true)
                  setSltReservation(reservation)
                }}
              >
                <Done />
              </IconButton>
            </Tooltip>
            {/* Từ chối thay đổi đơn (Undo) */}
            {reservation.prevState ? (
              <Tooltip title="Không đồng ý thay đổi đơn đặt phòng">
                <IconButton
                  aria-label="undo"
                  className={classes.margin}
                  onClick={() => {
                    setOpenUndoDialog(true)
                    setSltReservation(reservation)
                  }}
                >
                  <Undo />
                </IconButton>
              </Tooltip>
            ) : null}
            {/* Chỉnh sửa đơn */}
            <Tooltip title="Chỉnh sửa đơn đặt phòng">
              <IconButton
                aria-label="confirm"
                className={classes.margin}
                onClick={() => edit(reservation.code)}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            {/* Huỷ đơn */}
            <Tooltip title="Huỷ đơn đặt phòng">
              <IconButton
                aria-label="delete"
                className={classes.margin}
                onClick={() => {
                  setOpenRejectDialog(true)
                  setSltReservation(reservation)
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </>
        )
      case 'host-confirm-refund-update':
        return (
          <Tooltip title="Xác nhận đơn đặt phòng">
            <IconButton
              aria-label="confirm"
              className={classes.margin}
              onClick={() => {
                setOpenConfirmDialog(true)
                setSltReservation(reservation)
              }}
            >
              <Done />
            </IconButton>
          </Tooltip>
        )
      case 'confirmed':
      case 'confirmed-pay-extra':
        return (
          <>
            {/* Xác nhận thanh toán */}
            <Tooltip title="Xác nhận thanh toán">
              <IconButton
                aria-label="confirm"
                className={classes.margin}
                onClick={() => {
                  setOpenPaymentDialog(true)
                  setSltReservation(reservation)
                }}
              >
                <AttachMoney />
              </IconButton>
            </Tooltip>
            {/* Chỉnh sửa đơn */}
            <Tooltip title="Chỉnh sửa đơn đặt phòng">
              <IconButton
                aria-label="confirm"
                className={classes.margin}
                onClick={() => edit(reservation.code)}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            {/* Huỷ đơn */}
            <Tooltip title="Huỷ đơn đặt phòng">
              <IconButton
                aria-label="delete"
                className={classes.margin}
                onClick={() => {
                  setOpenRejectDialog(true)
                  setSltReservation(reservation)
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </>
        )
      case 'paid':
        return (
          <>
            <Tooltip title="Chỉnh sửa đơn đặt phòng">
              <IconButton
                aria-label="confirm"
                className={classes.margin}
                onClick={() => edit(reservation.code)}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Huỷ đơn đặt phòng">
              <IconButton
                aria-label="delete"
                className={classes.margin}
                onClick={() => {
                  setOpenRejectDialog(true)
                  setSltReservation(reservation)
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
            <Tooltip title="Gửi email hướng dẫn">
              <IconButton
                aria-label="send"
                className={classes.margin}
                onClick={() => sendOrderEmail(reservation.id)}
              >
                <Send />
              </IconButton>
            </Tooltip>
          </>
        )
      //! Cân nhắc đang cho phép huỷ đơn đang sử dụng
      case 'using':
        return (
          <>
            <Tooltip title="Chỉnh sửa đơn đặt phòng">
              <IconButton
                aria-label="confirm"
                className={classes.margin}
                onClick={() => edit(reservation.code)}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            <Tooltip title="Đặt phòng thêm">
              <IconButton
                aria-label="add"
                className={classes.margin}
                onClick={() => {
                  setSltReservation(reservation)
                  setOpenAddTimeDialog(true)
                }}
              >
                <Add />
              </IconButton>
            </Tooltip>
            <Tooltip title="Huỷ đơn đặt phòng">
              <IconButton
                aria-label="delete"
                className={classes.margin}
                onClick={() => {
                  setOpenRejectDialog(true)
                  setSltReservation(reservation)
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </>
        )
      case 'add-checkout-processing':
        // if (lastCheckoutPoint?.checkout < moment().valueOf()) {
        //   return null
        // }
        if (lastCheckoutPoint?.status === 'processing') {
          return (
            <>
              {/* Xác nhận */}
              <Tooltip
                title="Xác nhận ở thêm"
                aria-label="Xác nhận ở thêm"
              >
                <IconButton
                  aria-label="confirm"
                  className={classes.margin}
                  onClick={() => {
                    setOpenConfirmDialog(true)
                    setSltReservation(reservation)
                  }}
                >
                  <Done />
                </IconButton>
              </Tooltip>
              {/* Huỷ */}
              <Tooltip
                title="Không đồng ý đặt thêm giờ"
                aria-label="Không đồng ý đặt thêm giờ"
              >
                <IconButton
                  aria-label="confirm"
                  className={classes.margin}
                  onClick={() => {
                    setOpenUndoDialog(true)
                    setSltReservation(reservation)
                  }}
                >
                  <Undo />
                </IconButton>
              </Tooltip>
            </>
          )
        }
        if (lastCheckoutPoint?.status === 'confirmed') {
          return (
            <>
              {/* Xác nhận */}
              <Tooltip
                title="Xác nhận thanh toán ở thêm"
                aria-label="Xác nhận thanh toán ở thêm"
              >
                <IconButton
                  aria-label="pay"
                  className={classes.margin}
                  onClick={() => {
                    setOpenConfirmDialog(true)
                    setSltReservation(reservation)
                  }}
                >
                  <Done />
                </IconButton>
              </Tooltip>
              {/* Huỷ */}
              <Tooltip
                title="Không đồng ý đặt thêm giờ"
                aria-label="Không đồng ý đặt thêm giờ"
              >
                <IconButton
                  aria-label="confirm"
                  className={classes.margin}
                  onClick={() => {
                    setOpenUndoDialog(true)
                    setSltReservation(reservation)
                  }}
                >
                  <Undo />
                </IconButton>
              </Tooltip>
            </>
          )
        }
        return null
      //! Cân nhắc đang cho phép huỷ đơn đã hoàn thành
      case 'completed':
        return (
          <>
            <Tooltip title="Đặt phòng thêm">
              <IconButton
                aria-label="add"
                className={classes.margin}
                onClick={() => {
                  setSltReservation(reservation)
                  setOpenAddTimeDialog(true)
                }}
              >
                <Add />
              </IconButton>
            </Tooltip>
            <Tooltip title="Chỉnh sửa đơn đặt phòng">
              <IconButton
                aria-label="confirm"
                className={classes.margin}
                onClick={() => edit(reservation.code)}
              >
                <Edit />
              </IconButton>
            </Tooltip>
            {depositWith === '2home' && depositAmount > 0 && checkout < moment().valueOf() && !['none', 'release'].includes(depositStatus) ? (
              <>
                {/* Yêu cầu phạt cọc */}
                {depositStatus === 'hold' ? (
                  <>
                    <Tooltip title="Phạt cọc">
                      <IconButton
                        className={classes.margin}
                        onClick={() => {
                          setSltReservation(reservation)
                          setOpenDepositDialog(true)
                        }}
                      >
                        <Remove />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Hoàn 100% tiền cọc">
                      <IconButton
                        className={classes.margin}
                        onClick={() => {
                          setSltReservation(reservation)
                          setHasDepositPunish(false)
                          setOpenDepositDialog(true)
                        }}
                      >
                        <AssignmentTurnedIn />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : null}
                {/* Đồng ý yêu cầu phạt cọc */}
                {depositStatus === 'customer-complain' ? (
                  <Tooltip title="Đồng ý tiền phạt cọc khách đề xuất">
                    <IconButton
                      className={classes.margin}
                      onClick={() => {
                        setSltReservation(reservation)
                        setOpenDepositDialog(true)
                      }}
                    >
                      <Done />
                    </IconButton>
                  </Tooltip>
                ) : null}
                {depositStatus === '2home-handle' ? (
                  <Tooltip title="2home xử lý phạt cọc">
                    <IconButton
                      className={classes.margin}
                      onClick={() => {
                        setSltReservation(reservation)
                        setOpenDepositDialog(true)
                      }}
                    >
                      <AssignmentTurnedIn />
                    </IconButton>
                  </Tooltip>
                ) : null}
                {depositStatus === 'waiting-release' ? (
                  <Tooltip title="Cung cấp thông tin hoàn cọc giúp khách">
                    <IconButton
                      className={classes.margin}
                      onClick={() => {
                        setSltReservation(reservation)
                        setOpenDepositDialog(true)
                      }}
                    >
                      <AssignmentTurnedIn />
                    </IconButton>
                  </Tooltip>
                ) : null}
                {depositStatus === 'processing-release' ? (
                  <Tooltip title="Xác nhận hoàn cọc cho khách hàng">
                    <IconButton
                      className={classes.margin}
                      onClick={() => {
                        setSltReservation(reservation)
                        setOpenDepositDialog(true)
                      }}
                    >
                      <Done />
                    </IconButton>
                  </Tooltip>
                ) : null}
                {depositStatus === 'host-complain' ? (
                  <Tooltip title="Xác nhận phạt cọc chủ nhà đề xuất">
                    <IconButton
                      className={classes.margin}
                      onClick={() => {
                        setSltReservation(reservation)
                        setOpenDepositDialog(true)
                      }}
                    >
                      <Done />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </>
            ) : null}
            <Tooltip title="Huỷ đơn đặt phòng">
              <IconButton
                aria-label="delete"
                className={classes.margin}
                onClick={() => {
                  setOpenRejectDialog(true)
                  setSltReservation(reservation)
                }}
              >
                <Delete />
              </IconButton>
            </Tooltip>
          </>
        )
      case 'lock':
        return (
          <Tooltip title="Huỷ khoá phòng">
            <IconButton
              aria-label="delete"
              className={classes.margin}
              onClick={() => {
                setOpenRejectDialog(true)
                setSltReservation(reservation)
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        )
      case 'reject-waiting':
        return (
          <Tooltip title="Cung cấp thông tin hoàn tiền">
            <IconButton
              className={classes.margin}
              onClick={() => {
                setSltReservation(reservation)
                setOpenProvideInfoDialog(true)
              }}
            >
              <BorderColor />
            </IconButton>
          </Tooltip>
        )
      case 'expired':
      default:
        return (
          <>
            {false && (
              <Tooltip title="Xem lại">
                <IconButton
                  className={classes.margin}
                  onClick={() => view(reservation.code)}
                >
                  <Visibility />
                </IconButton>
              </Tooltip>
            )}
          </>
        )
    }
  }

  const renderCustomerInfo = (reservation) => (
    <div style={{ fontSize: '0.8rem' }}>
      <div>{`Người nhận phòng: ${reservation.receiverName ? reservation.receiverName : reservation.customerName}`}</div>
      <div className="my-2">{`Số điện thoại: ${reservation.receiverName ? reservation.receiverPhone || 'Không có' : reservation.customerPhone}`}</div>
      <div>{`Địa chỉ email: ${reservation.receiverName ? reservation.receiverEmail || 'Không có' : reservation.customerEmail}`}</div>
    </div>
  )

  const getPrevPrice = (order) => {
    if (!order || !order.prevState) return 0
    const { prevState } = order
    let total = prevState.roomPrice + prevState.guestPrice
    if (!prevState?.applyCoupon && prevState?.coupon?.creator === 'host') {
      switch (prevState?.coupon?.valueType) {
        case 'money':
          total -= prevState?.coupon?.value
          break
        case 'percent':
          total -= (prevState?.coupon?.value * total) / 100
          break
        default:
      }
    }
    if (prevState.services?.length) {
      prevState.services.forEach((s) => {
        total += s.price
      })
    }
    return total
  }

  const getPrice = (order) => {
    let total = order.roomPrice + order.guestPrice + (order.depositWith === '2home' ? order.depositAmount || 0 : 0)
    let checkoutPoints = order?.addCheckoutPoint?.filter((item) => item.status !== 'reject')
    if (moment().valueOf() > last(checkoutPoints)?.checkout) {
      checkoutPoints = order?.addCheckoutPoint?.filter((item) => item.status === 'paid')
    }
    if (checkoutPoints && checkoutPoints.length) {
      checkoutPoints.forEach((o) => {
        total += o.value
      })
    }
    if (!order?.applyCoupon && order?.coupon?.creator !== 'host') {
      switch (order?.coupon?.valueType) {
        case 'money':
          total -= order?.coupon?.value
          break
        case 'percent':
          total -= (order?.coupon?.value * total) / 100
          break
        default:
      }
    }
    if (order.services?.length) {
      order.services.forEach((s) => {
        total += s.price
      })
    }
    return total
  }

  const getCheckout = (order) => {
    let time = order.checkout
    let lastPaidCheckout = last(order?.addCheckoutPoint?.filter((item) => item.status !== 'reject'))
    if (moment().valueOf() > lastPaidCheckout?.checkout) {
      lastPaidCheckout = last(order?.addCheckoutPoint?.filter((item) => item.status === 'paid'))
    }
    if (lastPaidCheckout) {
      time = lastPaidCheckout.checkout
    }
    return time
  }

  const confirmPunishDeposit = async (customerAgree, hostAgree, punishDepositInfo, bankNote) => {
    switch (sltReservation?.depositStatus) {
      case 'hold':
        try {
          if (hasDepositPunish) {
            if (punishDepositInfo.images?.length) {
              const punishImageUrls = await uploadApi.uploadImage(
                punishDepositInfo.images.filter((file) => !file.isLink).map((item) => item.file),
                `Customer/${sltReservation?.customer?.code}/Reservation/${sltReservation?.code}`,
              )
              punishDepositInfo.images = punishImageUrls?.map((item) => item.shortUrl)
            }
            await punishDepositByHost(sltReservation.id, punishDepositInfo)
            enqueueSnackbar('Phạt cọc cho đơn đặt phòng thành công.')
          } else {
            await releaseDeposit(sltReservation.id)
            enqueueSnackbar('Hoàn cọc cho đơn đặt phòng thành công.')
          }
        } catch (e) {
          enqueueSnackbar('Xác nhận không thành công.')
        }
        break

      case 'customer-complain':
        try {
          await agreePunishDeposit(sltReservation.id, hostAgree === '1')
          enqueueSnackbar('Xác nhận phạt cọc cho đơn đặt phòng thành công.')
        } catch (e) {
          enqueueSnackbar('Xác nhận phạt cọc cho đơn đặt phòng không thành công.')
        }
        break

      case 'host-complain':
        try {
          if (punishDepositInfo.images?.length) {
            const punishImageUrls = await uploadApi.uploadImage(
              punishDepositInfo.images.filter((file) => !file.isLink).map((item) => item.file),
              `Customer/${sltReservation?.customer?.code}/Reservation/${sltReservation?.code}`,
            )
            punishDepositInfo.images = punishImageUrls?.map((item) => item.shortUrl)
          }
          await punishDepositByCustomer(sltReservation.id, { ...punishDepositInfo, isAgree: customerAgree === '1' })
          enqueueSnackbar('Đề xuất phạt cọc cho đơn đặt phòng thành công.')
        } catch (e) {
          enqueueSnackbar('Đề xuất phạt cọc cho đơn đặt phòng không thành công.')
        }
        break

      case 'processing-release':
        try {
          await handlePunishDeposit(sltReservation.id, { amount: 0, refundBankNote: bankNote })
          enqueueSnackbar('Hoàn cọc cho đơn đặt phòng thành công.')
          setOpenDepositDialog(false)
        } catch (e) {
          enqueueSnackbar('Hoàn cọc cho đơn đặt phòng không thành công.')
          setOpenDepositDialog(false)
        }
        break

      case 'waiting-release':
        try {
          await provideCustomerDepositInfo(sltReservation.id, {
            bankAccount: punishDepositInfo.bankAccount,
            bankName: punishDepositInfo.bankName,
            accountName: punishDepositInfo.accountName,
            paymentType: punishDepositInfo.paymentType,
          })
          enqueueSnackbar('Cung cấp thông tin hoàn cọc cho đơn đặt phòng thành công.')
          setOpenDepositDialog(false)
        } catch (e) {
          enqueueSnackbar('Hoàn cọc cho đơn đặt phòng không thành công.')
          setOpenDepositDialog(false)
        }
        break

      case '2home-handle':
        try {
          await handlePunishDeposit(sltReservation.id, punishDepositInfo)
          enqueueSnackbar('Xác nhận phạt cọc cho đơn đặt phòng thành công.')
        } catch (e) {
          enqueueSnackbar('Xác nhận phạt cọc cho đơn đặt phòng không thành công.')
        }
        break

      default:
        break
    }
    applyFilter({})
    setHasDepositPunish(true)
    setOpenDepositDialog(false)
  }

  const provideRefundInfo = async () => {
    try {
      await provideCustomerRefundInfo(sltReservation.id, {
        refundUser,
        refundBankAccount,
        refundBankName,
        refundPaymentType: 'bank-transfer',
      })
      applyFilter({})
      enqueueSnackbar('Cung cáp thông tin hoàn tiền cho khách thành công.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    } catch (error) {
      enqueueSnackbar(`Cung cáp thông tin hoàn tiền cho khách không thành công. ${error.response?.data}`, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      })
    }
    setSltReservation(undefined)
    setRefundUser(undefined)
    setRefundBankName(undefined)
    setRefundBankAccount(undefined)
    setOpenProvideInfoDialog(false)
  }

  return (
    <>
      {/* Service dialog */}
      <Dialog
        open={openServiceDialog}
        onClose={() => setOpenServiceDialog(false)}
      >
        <DialogTitle disableTypography>
          <h3>Chi tiết dịch vụ</h3>
        </DialogTitle>
        <DialogContent style={{ width: 600 }}>
          <List>
            {services &&
              services.map((service) => (
                <ListItem key={service.id}>
                  <ListItemAvatar>
                    <Avatar
                      className={classes.serviceThumbnail}
                      src={`${AppConfig.BASE_IMG_URL}/${service.image}`}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    className={classes.serviceName}
                    primary={
                      <RouterLink
                        to=""
                        target="_blank"
                        className={classes.link}
                      >
                        {service.name}
                      </RouterLink>
                    }
                  />
                  <ListItemText
                    className={classes.servicePrice}
                    primary={`${formatPrice(service.price)} VND`}
                  />
                </ListItem>
              ))}
          </List>
        </DialogContent>
      </Dialog>
      {/* Payment dialog */}
      <PaymentDialog
        classes={classes}
        reservation={sltReservation}
        open={openPaymentDialog}
        onClose={() => setOpenPaymentDialog(false)}
        onConfirm={payReservation}
      />
      {/* Reject dialog */}
      <RejectDialog
        classes={classes}
        reservation={sltReservation}
        open={openRejectDialog}
        onClose={() => setOpenRejectDialog(false)}
        onConfirm={rejectReservation}
      />
      {/* Refund dialog */}
      <RefundDialog
        classes={classes}
        open={openRefundDialog}
        reservation={sltReservation}
        onClose={() => setOpenRefundDialog(false)}
        onConfirm={refundReservation}
      />
      {/* Confirm dialog */}
      <ConfirmDialog
        classes={classes}
        reservation={sltReservation}
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        onConfirm={confirmReservation}
      />
      {/* Phạt cọc */}
      <PunishDepositDialog
        classes={classes}
        reservation={sltReservation}
        open={openDepositDialog}
        hasDepositPunish={hasDepositPunish}
        onClose={() => {
          setOpenDepositDialog(false)
          setHasDepositPunish(true)
        }}
        onConfirm={confirmPunishDeposit}
      />
      {/* Undo dialog */}
      <UndoDialog
        classes={classes}
        reservation={sltReservation}
        open={openUndoDialog}
        onClose={() => setOpenUndoDialog(false)}
        onConfirm={undoReservation}
      />
      {/* Cung cấp thông tin hoàn tiền */}
      <Dialog
        open={openProvideInfoDialog}
        onClose={() => {
          setOpenProvideInfoDialog(false)
          setSltReservation(undefined)
        }}
        aria-labelledby="simple-dialog-title"
      >
        <DialogTitle disableTypography>
          <h3>Cung cấp thông tin hoàn tiền</h3>
        </DialogTitle>
        <DialogContent style={{ minWidth: 500, minHeight: 80, paddingTop: 0 }}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              xs={12}
            >
              {/* Payment type: 2home/bank-transfer */}
              {/* <FormControl
                variant="outlined"
                className={classes.formControl}
                fullWidth
              >
                <InputLabel
                  style={{ backgroundColor: 'white', padding: '0px 5px' }}
                  id="refund-payment-type"
                >
                  Hình thức hoàn tiền
                </InputLabel>
                <Select
                  labelId="refund-payment-type"
                  id="refund-payment-type-label"
                  value={sltReservation?.customer?.email === 'customer@2home.vn' ? 'bank-transfer' : paymentType}
                  onChange={(e) => {
                    setPaymentType(e.target.value)
                  }}
                  disabled={sltReservation?.customer?.email === 'customer@2home.vn' || sltReservation?.paymentType === '2home'}
                >
                  {PAYMENT_TYPE.map(item => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
              </FormControl> */}
              {/* Bank information */}
              <TextField
                label="Chủ tài khoản"
                variant="outlined"
                fullWidth
                value={refundUser}
                onChange={(e) => setRefundUser(e.target.value)}
                className={classes.formControl}
              />
              <TextField
                label="Số tài khoản"
                variant="outlined"
                fullWidth
                value={refundBankAccount}
                onChange={(e) => setRefundBankAccount(e.target.value)}
                className={classes.formControl}
              />
              <TextField
                label="Ngân hàng"
                variant="outlined"
                fullWidth
                value={refundBankName}
                onChange={(e) => setRefundBankName(e.target.value)}
                className={classes.formControl}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={provideRefundInfo}
            color="primary"
            disabled={!refundUser || !refundBankAccount || !refundBankName}
          >
            Xác nhận
          </Button>
        </DialogActions>
      </Dialog>
      {/* Add time dialog */}
      <AddCheckoutPointDialog
        classes={classes}
        reservation={sltReservation}
        open={openAddTimeDialog}
        onClose={() => setOpenAddTimeDialog(false)}
        onConfirm={addTimeReservation}
      />
      {/* Reservation list */}
      <Table
        data={reservations}
        columns={[
          {
            Head: 'Mã',
            Cell: (value, origin) => (
              <div className="d-flex align-items-center">
                {moment.duration(moment().diff(moment(origin.updatedAt))).asMinutes() > 15 && origin.status === 'processing' ? (
                  <FiberManualRecordIcon
                    fontSize="small"
                    style={{ color: '#ffa854', marginRight: 8 }}
                  />
                ) : null}
                {['processing-refund-in-update', 'waiting-refund-in-update', 'processing-pay-extra', 'confirmed-pay-extra'].includes(
                  origin.status,
                ) ? (
                  <FiberManualRecordIcon
                    fontSize="small"
                    style={{ color: 'red', marginRight: 8 }}
                  />
                ) : null}
                <RouterLink
                  to={`/reservation/${value}/view`}
                  target="_blank"
                  className={classes.codeCell}
                >
                  <Typography variant="body1">{value}</Typography>
                  <Typography variant="body2">{moment(origin.createdAt).format('DD/MM/YYYY HH:mm')}</Typography>
                </RouterLink>
              </div>
            ),
            accessor: 'code',
            sortable: false,
          },
          {
            Head: 'Phòng',
            Cell: (value, origin) => (
              <>
                <a
                  href={`${AppConfig.endpoint.customer}/phong-can-ho/${origin.room.code}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Tooltip
                    title={value.name}
                    aria-label={value.name}
                  >
                    <Typography variant="body1">{value.name}</Typography>
                  </Tooltip>
                </a>
                <Typography variant="body2">
                  {`Mã: ${value.code} - `}
                  {renderRoomType(value.bookingType)}
                </Typography>
                {origin.host ? (
                  <Typography variant="body2">
                    <Tooltip title="Xem thông tin chủ nhà">
                      <RouterLink
                        to={`/host/${origin.host.id}/update`}
                        target="_blank"
                      >
                        {origin.host.name}
                        {` (${origin.host.code})`}
                      </RouterLink>
                    </Tooltip>
                  </Typography>
                ) : null}
                <Typography variant="body2">
                  <a
                    onClick={() => {
                      setOpenServiceDialog(true)
                      setServices(origin.services)
                    }}
                    type="button"
                    onKeyDown={() => {}}
                    role="button"
                    tabIndex={0}
                  >
                    {`${origin.services && origin.services.length ? `${origin.services.length} dịch vụ` : ''}`}
                  </a>
                </Typography>
              </>
            ),
            accessor: 'room',
            sortable: false,
          },
          {
            Head: 'Khách hàng',
            Cell: (value, origin) => (
              <>
                <Typography variant="body1">
                  <span>{value || origin.customerName}</span>
                  <Tooltip
                    title={renderCustomerInfo(origin)}
                    placement="top"
                  >
                    <Info
                      size="small"
                      className={classes.customerInfoIcon}
                    />
                  </Tooltip>
                </Typography>
                {!origin.customer ? (
                  <Typography variant="body2">{`Người đặt: ${origin.customerName}`}</Typography>
                ) : (
                  <Typography variant="body2">
                    <span>Người đặt: </span>
                    <Tooltip title="Xem thông tin khách hàng">
                      <RouterLink
                        to={`/customer/${origin.customer.id}/update`}
                        target="_blank"
                      >
                        {origin.customerName}
                        {` (${origin.customer.code})`}
                      </RouterLink>
                    </Tooltip>
                  </Typography>
                )}
                <Typography variant="body2">{`Số khách: ${origin.guest} người`}</Typography>
                {origin.purpose ? <Typography variant="body2">{`Mục đích: ${origin.purpose}`}</Typography> : null}
              </>
            ),
            accessor: 'receiverName',
            sortable: false,
          },
          {
            Head: 'Tổng tiền',
            Cell: (value, origin) => (
              <>
                <Typography variant="body1">
                  {origin.prevState ? `${formatPrice(getPrevPrice(origin))}` : `${formatPrice(getPrice(origin))}`}
                </Typography>
                {origin.prevState ? (
                  origin.prevState.services && origin.prevState.services.length ? (
                    <Typography variant="body2">
                      <a
                        onClick={() => {
                          setOpenServiceDialog(true)
                          setServices(origin.prevState.services)
                        }}
                        type="button"
                        onKeyDown={() => {}}
                        role="button"
                        tabIndex={0}
                      >
                        {`${origin.prevState.services && origin.prevState.services.length ? `${origin.prevState.services.length} dịch vụ` : ''}`}
                      </a>
                    </Typography>
                  ) : null
                ) : origin.services && origin.services.length ? (
                  <Typography variant="body2">
                    <a
                      onClick={() => {
                        setOpenServiceDialog(true)
                        setServices(origin.services)
                      }}
                      type="button"
                      onKeyDown={() => {}}
                      role="button"
                      tabIndex={0}
                    >
                      {`${origin.services && origin.services.length ? `${origin.services.length} dịch vụ` : ''}`}
                    </a>
                  </Typography>
                ) : null}
                {!origin?.applyCoupon && origin?.coupon && origin?.coupon?.creator !== 'host' ? (
                  <Typography variant="body2">
                    {`Mã khuyến mãi (${origin?.coupon?.code}): ${
                      origin?.coupon?.valueType === 'money' ? `${formatPrice(origin?.coupon?.value)}VND` : `${origin?.coupon?.value}%`
                    }`}
                  </Typography>
                ) : null}
                {origin.depositWith === '2home' ? (
                  <Typography variant="body2">{`Đặt cọc: ${formatPrice(origin.depositAmount)}`}</Typography>
                ) : (
                  <Typography variant="body2">&nbsp;</Typography>
                )}
                {origin.prevState ? (
                  <div>
                    <ArrowDownward
                      className="mx-auto"
                      fontSize="small"
                    />
                    <Typography
                      variant="body1"
                      className="text-left"
                    >
                      {`${formatPrice(getPrice(origin))}`}
                    </Typography>
                    {origin.services && origin.services.length ? (
                      <Typography variant="body2">
                        <a
                          onClick={() => {
                            setOpenServiceDialog(true)
                            setServices(origin.services)
                          }}
                          type="button"
                          onKeyDown={() => {}}
                          role="button"
                          tabIndex={0}
                        >
                          {`${origin.services && origin.services.length ? `${origin.services.length} dịch vụ` : ''}`}
                        </a>
                      </Typography>
                    ) : null}
                  </div>
                ) : null}
              </>
            ),
            accessor: 'totalPrice',
            sortable: true,
          },
          {
            Head: 'Thời gian',
            Cell: (value, origin) => (
              <>
                {origin.prevState ? (
                  <div className="text-center">
                    <Typography
                      variant="body1"
                      className="d-flex"
                    >
                      <div style={{ width: '40%' }}>
                        <div>{moment(origin.prevState.checkin).format('HH:mm')}</div>
                        <div>{moment(origin.prevState.checkin).format('DD/MM')}</div>
                      </div>
                      <div style={{ margin: 'auto 20px' }}>-</div>
                      <div style={{ width: '40%' }}>
                        <div>{moment(getCheckout(origin.prevState)).format('HH:mm')}</div>
                        <div>{moment(getCheckout(origin.prevState)).format('DD/MM')}</div>
                      </div>
                    </Typography>
                    {origin.prevState.useServiceAt && origin.prevState.services && origin.prevState.services.length ? (
                      <div className="text-center">
                        <Typography
                          variant="body2"
                          className="d-flex"
                        >
                          <div style={{ width: '40%' }}>
                            <div>{moment(origin.prevState.roomCheckin).format('HH:mm')}</div>
                            <div>{moment(origin.prevState.roomCheckin).format('DD/MM')}</div>
                          </div>
                          <div style={{ margin: 'auto 20px' }}>-</div>
                          <div style={{ width: '40%' }}>
                            <div>{moment(origin.prevState.useServiceAt).format('HH:mm')}</div>
                            <div>{moment(origin.prevState.useServiceAt).format('DD/MM')}</div>
                          </div>
                        </Typography>
                      </div>
                    ) : null}
                    <ArrowDownward
                      className="mx-auto"
                      fontSize="small"
                    />
                  </div>
                ) : null}
                <Typography
                  variant="body1"
                  className="d-flex text-center"
                >
                  <div style={{ width: '40%' }}>
                    <div>{moment(origin.checkin).format('HH:mm')}</div>
                    <div>{moment(origin.checkin).format('DD/MM')}</div>
                  </div>
                  <div style={{ margin: 'auto 20px' }}>-</div>
                  <div style={{ width: '40%' }}>
                    <div>{moment(getCheckout(origin)).format('HH:mm')}</div>
                    <div>{moment(getCheckout(origin)).format('DD/MM')}</div>
                  </div>
                </Typography>
                {origin.useServiceAt && origin.services && origin.services.length ? (
                  <div className="text-center">
                    <Typography
                      variant="body2"
                      className="d-flex"
                    >
                      <div style={{ width: '40%' }}>
                        <div>{moment(origin.roomCheckin).format('HH:mm')}</div>
                        <div>{moment(origin.roomCheckin).format('DD/MM')}</div>
                      </div>
                      <div style={{ margin: 'auto 20px' }}>-</div>
                      <div style={{ width: '40%' }}>
                        <div>{moment(origin.useServiceAt).format('HH:mm')}</div>
                        <div>{moment(origin.useServiceAt).format('DD/MM')}</div>
                      </div>
                    </Typography>
                  </div>
                ) : null}
                {/* {origin.addCheckoutPoint
                  ? (
                    origin.addCheckoutPoint.map(item => <Typography variant="body2">{`- Ở thêm ${item.amount} tiếng tới ${moment(item.checkout).format('HH:mm DD/MM/YYYY')}`}</Typography>)
                  )
                  : null} */}
              </>
            ),
            accessor: 'checkin',
            sortable: false,
          },
          {
            Head: 'Trạng thái',
            Cell: (value, origin) => renderStatus(value, origin),
            accessor: 'status',
            sortable: false,
          },
          {
            Head: '',
            Cell: (value, origin) => renderAction(value, origin),
            accessor: 'status',
          },
        ]}
        loading={loading}
        page={meta.page}
        limit={meta.limit}
        total={meta.total}
        defaultSort={defaultSort}
        onSortChange={handleSortChange}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </>
  )
}

ReservationsTable.propTypes = {
  classes: PropTypes.shape(),
  loading: PropTypes.bool,
  reservations: PropTypes.arrayOf(PropTypes.shape()),
  meta: PropTypes.shape(),
  defaultSort: PropTypes.shape(),
  onSortChange: PropTypes.func,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  applyFilter: PropTypes.func,
  // pay: PropTypes.func,
  // confirm: PropTypes.func,
  reject: PropTypes.func,
  edit: PropTypes.func,
  undo: PropTypes.func,
  view: PropTypes.func,
  refund: PropTypes.func,
  addCheckout: PropTypes.func,
  rejectCheckout: PropTypes.func,
  sendEmail: PropTypes.func,
}

export default ReservationsTable
