import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Tabs,
  Tab,
} from '@material-ui/core'
import * as orderApi from 'provider/reservation'
import Revenue from './OrderRevenue'
import OrderInfo from './OrderInfo'

const DetailInfoDialog = ({
  // classes,
  visible,
  onClose,
  reservation,
}) => {
  const [tab, setTab] = useState(0)
  const [revenueInfo, setRevenueInfo] = useState(null)
  const [remainingRoomPrice, setRemainingRoomPrice] = useState({
    remainingRoom: 0,
    remainingGuest: 0,
  })
  const [remainingServicePrice, setRemainingServicePrice] = useState(0)

  const fetchRevenueInfo = async (id) => {
    if (!id) {
      setRevenueInfo(null)
      return
    }
    // Lấy thông tin tiền phòng còn lại
    try {
      const resp = await orderApi.getRevenueInfo(id)
      setRevenueInfo(resp)
    } catch (e) {
      setRevenueInfo(null)
    }
  }

  const fetchRemainingRoomPrice = async (id, checkin, checkout, guest, limitRoom) => {
    if (!id) {
      setRemainingRoomPrice({
        remainingRoom: 0,
        remainingGuest: 0,
      })
      return
    }
    // Lấy thông tin tiền phòng còn lại
    try {
      const remaining = await orderApi.getRemainingRoomPrice({
        id,
        checkin,
        checkout,
        guest,
        limitRoom,
      })
      setRemainingRoomPrice(remaining)
    } catch (e) {
      setRemainingRoomPrice({
        remainingRoom: 0,
        remainingGuest: 0,
      })
    }
  }

  const fetchRemainingServicePrice = async (id) => {
    if (!id) {
      setRemainingServicePrice(0)
      return
    }
    // Lấy thông tin tiền phòng còn lại
    try {
      const remaining = await orderApi.getRemainingServicePrice(id)
      setRemainingServicePrice(remaining)
    } catch (e) {
      setRemainingServicePrice(0)
    }
  }

  useEffect(() => {
    if (reservation?.id && reservation?.prevState) {
      const {
        id,
        checkin,
        checkout,
        guest,
        limitRoom,
      } = reservation
      fetchRemainingRoomPrice(id, checkin, checkout, guest, limitRoom)
      fetchRemainingServicePrice(id)
    }
    return () => {
    }
  }, [reservation])

  useEffect(() => {
    if (reservation?.id && [
      'paid',
      'processing-pay-extra',
      'confirmed-pay-extra',
      'waiting-refund-in-update',
      'processing-refund-in-update',
      'host-confirm-refund-update',
      'add-checkout-processing',
      'reject-waiting',
      'reject-processing',
      'reject-refund',
      'reject-norefund',
      'using',
      'completed',
    ].includes(reservation.status)) {
      const {
        id,
      } = reservation
      fetchRevenueInfo(id)
    }
    return () => {
    }
  }, [reservation])

  return (
    <Dialog
      open={visible}
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      maxWidth="100vw"
    >
      <DialogContent>
        <h5 className="font-weight-bold">{`Thông tin đơn đặt phòng ${reservation.code}`}</h5>
        <Box className="mb-3" sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tab} onChange={(e, value) => setTab(value)} aria-label="basic tabs example">
            <Tab label="Chi tiết đơn" id="order-info" />
            <Tab label="Doanh thu" id="order-revenue" />
          </Tabs>
        </Box>
        <div style={{ overflowY: 'auto', maxHeight: '60vh' }}>
          {tab === 0 ? <OrderInfo reservation={reservation} remainingRoomPrice={remainingRoomPrice} remainingServicePrice={remainingServicePrice} /> : null}
          {tab === 1 ? <Revenue data={revenueInfo} /> : null}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">Đóng</Button>
      </DialogActions>
    </Dialog>
  )
}

DetailInfoDialog.propTypes = {
  // classes: PropTypes.shape(),
  reservation: PropTypes.shape(),
  visible: PropTypes.bool,
  onClose: PropTypes.func,
}

export default DetailInfoDialog
