import moment from 'moment'
import React from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import {
  withStyles,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Tooltip,
  Typography,
  Chip,
  IconButton,
} from '@material-ui/core'
import {
  ArrowRight as ArrowRightIcon, Refresh,
} from '@material-ui/icons'
import { Table } from 'components'
import { NavLink as RouterLink } from 'react-router-dom'
import { formatPrice } from 'utils/formatter'
import AppConfig from 'config'
import styles from './styled'

const LatestOrders = ({
  classes,
  orders,
  onRefresh,
}) => {
  const renderStatus = (status) => {
    switch (status) {
      case 'reject':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontWeight: '900',
              backgroundColor: '#999999',
            }}
            label="Đã huỷ"
          />
        )
      case 'processing':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontWeight: '900',
              backgroundColor: '#ffa854',
            }}
            label="Chờ xác nhận"
          />
        )
      case 'confirmed':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontWeight: '900',
              backgroundColor: '#ff1744',
            }}
            label="Chờ thanh toán"
          />
        )
      case 'paid':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontWeight: '900',
              backgroundColor: '#00b0ff',
            }}
            label="Đã thanh toán"
          />
        )
      case 'using':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontWeight: '900',
              backgroundColor: '#00e676',
            }}
            label="Đang sử dụng"
          />
        )
      case 'completed':
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontWeight: '900',
              backgroundColor: '#3e51b5',
            }}
            label="Hoàn thành"
          />
        )
      case 'expired':
      default:
        return (
          <Chip
            size="small"
            style={{
              color: 'white',
              fontWeight: '900',
              backgroundColor: '#9e9e9e',
            }}
            label="Hết hạn"
          />
        )
    }
  }

  return (
    <Card>
      <CardHeader
        action={(
          <IconButton size="small" onClick={onRefresh}>
            <Refresh />
          </IconButton>
        )}
        title="Đơn đặt phòng mới"
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table
              data={orders}
              columns={[
                {
                  Head: 'Mã',
                  Cell: (value, origin) => (
                    <>
                      <a href={`/reservation/${value}/view`} className={classes.codeCell} target="_blank" rel="noopener noreferrer">
                        <Typography variant="body1">{value}</Typography>
                        {/* <Typography variant="body2">
                          {moment(origin.createdAt).format('DD/MM/YYYY')}
                        </Typography> */}
                      </a>
                      <div className="mt-1">{renderStatus(origin.status)}</div>
                    </>
                  ),
                  accessor: 'code',
                  sortable: false,
                },
                {
                  Head: 'Phòng',
                  Cell: (value, origin) => (
                    <>
                      <a href={`${AppConfig.endpoint.customer}/phong-can-ho/${origin.room.id}`} target="_blank" rel="noopener noreferrer">
                        <Tooltip title={value.name} aria-label={value.name}>
                          <Typography variant="body1" className="text-truncate" style={{ maxWidth: 200 }}>{value.name}</Typography>
                        </Tooltip>
                      </a>
                      <Typography variant="body2">
                        {`Mã: ${value.code}`}
                      </Typography>
                      {origin.host ? (
                        <Typography variant="body2">
                          <Tooltip title="Xem thông tin chủ nhà">
                            <RouterLink
                              to={`/host/${origin.host.id}/update`}
                              target="_blank"
                            >
                              {origin.host.name}
                              {` (${origin.host.code})`}
                            </RouterLink>
                          </Tooltip>
                        </Typography>
                      ) : null}
                    </>
                  ),
                  accessor: 'room',
                  sortable: false,
                },
                {
                  Head: 'Khách hàng',
                  Cell: (value, origin) => (
                    <>
                      <Typography variant="body1">
                        <span>{value || origin.customerName}</span>
                        {/* <Tooltip title={renderCustomerInfo(origin)} placement="top">
                          <Info size="small" className={classes.customerInfoIcon} />
                        </Tooltip> */}
                      </Typography>
                      {!origin.customer ? (
                        <Typography variant="body2">{`Người đặt: ${origin.customerName}`}</Typography>
                      ) : (
                        <Typography variant="body2">
                          {/* <span>Người đặt: </span> */}
                          <Tooltip title="Xem thông tin khách hàng">
                            <RouterLink
                              to={`/customer/${origin.customer.id}/update`}
                              target="_blank"
                            >
                              {origin.customerName}
                              {` (${origin.customer.code})`}
                            </RouterLink>
                          </Tooltip>
                        </Typography>
                      )}
                    </>
                  ),
                  accessor: 'receiverName',
                  sortable: false,
                },
                {
                  Head: 'Tổng tiền',
                  Cell: (value, origin) => (
                    <>
                      <Typography variant="body1">
                        {`${formatPrice(value)}VND`}
                      </Typography>
                      <Typography variant="body2">
                        <a
                          onClick={() => {
                            // setOpenServiceDialog(true)
                            // setServices(origin.services)
                          }}
                          type="button"
                          onKeyDown={() => {}}
                          role="button"
                          tabIndex={0}
                        >
                          {`${
                            origin.services && origin.services.length
                              ? `${origin.services.length} dịch vụ`
                              : ''
                          }`}
                        </a>
                      </Typography>
                    </>
                  ),
                  accessor: 'totalPrice',
                  sortable: true,
                },
                {
                  Head: 'Thời gian',
                  Cell: (value, origin) => (
                    <Typography variant="body1" style={{ display: 'flex' }}>
                      <div>
                        <div>{moment(origin.checkin).format('HH:mm')}</div>
                        <div>{moment(origin.checkin).format('DD/MM/YYYY')}</div>
                      </div>
                      <div style={{ margin: 'auto 10px' }}>-</div>
                      <div>
                        <div>{moment(origin.checkout).format('HH:mm')}</div>
                        <div>{moment(origin.checkout).format('DD/MM/YYYY')}</div>
                      </div>
                    </Typography>
                  ),
                  accessor: 'checkin',
                  sortable: false,
                },
              ]}
              showPaginate={false}
            />
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <RouterLink to="/reservations">
          <Button color="primary" size="small" variant="text">
            Toàn bộ
            <ArrowRightIcon />
          </Button>
        </RouterLink>
      </CardActions>
    </Card>
  )
}

LatestOrders.propTypes = {
  classes: PropTypes.shape(),
  orders: PropTypes.arrayOf(PropTypes.shape()),
  onRefresh: PropTypes.func,
}

export default withStyles(styles)(LatestOrders)
