import * as commonApi from 'provider/common'
import { COMMON_ACTION } from '../type'

export const getDashboard = () => async (dispatch) => {
  dispatch({
    type: COMMON_ACTION.FETCH_DASHBOARD,
  })
  try {
    const dashboard = await commonApi.getDashboard()
    dispatch({
      type: COMMON_ACTION.FETCH_DASHBOARD_OK,
      payload: dashboard,
    })
  } catch (e) {
    dispatch({
      type: COMMON_ACTION.FETCH_DASHBOARD_ERR,
      payload: { message: 'Something went wrong' },
    })
  }
}

export const getLastOrders = () => async (dispatch) => {
  dispatch({
    type: COMMON_ACTION.FETCH_LAST_ORDER,
  })
  try {
    const orders = await commonApi.getLastOrders()
    dispatch({
      type: COMMON_ACTION.FETCH_LAST_ORDER_OK,
      payload: orders,
    })
  } catch (e) {
    dispatch({
      type: COMMON_ACTION.FETCH_LAST_ORDER_ERR,
      payload: { message: 'Something went wrong' },
    })
  }
}

export const getRevenue = () => async (dispatch) => {
  dispatch({
    type: COMMON_ACTION.FETCH_REVENUE,
  })
  try {
    const revenue = await commonApi.getRevenue()
    dispatch({
      type: COMMON_ACTION.FETCH_REVENUE_OK,
      payload: revenue,
    })
  } catch (e) {
    dispatch({
      type: COMMON_ACTION.FETCH_REVENUE_ERR,
      payload: { message: 'Something went wrong' },
    })
  }
}
