import { fromJS } from 'immutable'
import { SERVICE_ACTION } from '../type'

export default function room(state, action) {
  const { type, payload } = action

  switch (type) {
    case SERVICE_ACTION.FETCH:
      return state.set('loading', true).set('error', null)

    case SERVICE_ACTION.FETCH_OK:
      const { data, query, ...meta } = payload
      return state
        .set('loading', false)
        .set('data', fromJS(data))
        .set('query', fromJS(query))
        .set('meta', fromJS(meta))

    case SERVICE_ACTION.FETCH_ERR:
      return state
        .set('loading', false)
        .set('error', payload ? payload.message : '')

    default:
      return state
  }
}
