import * as areaApi from 'provider/area'
import { AREA_ACTION } from '../type'

export const find = () => async (dispatch) => {
  dispatch({
    type: AREA_ACTION.FETCH,
  })
  try {
    const area = await areaApi.find()
    dispatch({
      type: AREA_ACTION.FETCH_OK,
      payload: {
        data: area,
      },
    })
  } catch (e) {
    dispatch({
      type: AREA_ACTION.FETCH_ERR,
      payload: { message: 'Something went wrong' },
    })
  }
}
