import moment from 'moment'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { DATETIME_FORMAT } from 'config'

const History = ({
  // classes,
  data,
}) => {
  const [open, setOpen] = useState(true)

  return (
    <div className="border">
      <ListItem button onClick={() => setOpen(!open)} style={{ backgroundColor: '#f0f0f0' }}>
        <ListItemText primary={<Typography variant="h5">Lịch sử giao dịch</Typography>} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Divider className="w-100" />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {data?.map((item, idx) => (
            <>
              <div className="py-2 px-3">
                <Typography variant="body1">{item.content}</Typography>
                <Typography variant="body2">{moment(item.createdAt).format(DATETIME_FORMAT)}</Typography>
              </div>
              {idx !== data?.length - 1 ? <Divider className="w-100" /> : null}
            </>
          ))}
        </List>
      </Collapse>
    </div>
  )
}

History.propTypes = {
  // classes: PropTypes.shape(),
  data: PropTypes.arrayOf(PropTypes.shape()),
}

export default History
